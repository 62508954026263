import { DataEnvelope, Place } from '@model/iceberg';
import { ProductBookingPageSections } from '@model/pages';
import { Image, Page, Price, TileDiscount } from '@model/common';
import { Occupancy as StateOccupancy, Destination } from '@model/state';
import { OfferType } from '@model/offers/offers';
import { PartialDealFinderState } from '@state/deal-finder/dealFinderOperations';

export interface Results {
  searchToken: string;
  expires: string;
  results: Array<Result>;
  facets: Facets;
  nextSet: number;
}

export interface Facets {
  filters: Array<any>;
  counts: Counts;
}

export interface Counts {
  total: number;
  filtered: number;
}

export interface Result {
  summary: Summary;
  leadInPrice: LeadInPrice;
  otherPrices: Array<OtherPrice>;
  popularity?: number;
  hotelOnly?: boolean;
}

export interface Summary {
  place: Place;
  connectId: number;
  startDate: string;
  nights: number;
  hotelRating: number;
  tripAdvisorRating: string;
  gallery: Array<Image>;
  featuredImage: Image;
}

export interface LeadInPrice {
  packageReferences: Array<string>;
  perPerson: Price;
  total: Price;
  discount?: TileDiscount;
  mealPlan: string;
}

export interface OtherPrice {
  perPerson: Price;
  total: Price;
  mealPlan: string;
}

export enum SearchType {
  MAIN = 'MAIN',
  HOTEL_ONLY = 'HOTEL_ONLY'
}

export interface Occupancy {
  adults: number;
  children: Array<string>;
}

export interface SearchCriteria {
  searchType: SearchType;
  places: Array<Destination>;
  from: Array<string>;
  dateFrom?: string;
  flexible: boolean;
  month?: string;
  duration: number;
  occupancy: Array<Occupancy>;
  preferredBoardBasis?: string;
  marketingCode?: string;
}

export type CriteriaResponse = DataEnvelope<SearchCriteria>;

export type RoomsResponse = DataEnvelope<Rooms>;

export interface Rooms {
  rooms: Array<Room>;
}

export interface Room {
  roomType: string;
  rates?: Array<Rate>;
  sleepsAdults: number;
  sleepsChildren: number;
  wifi: boolean;
  squareMetres: number;
  sequence?: number;
  gallery: Array<Image>;
  description: string;
}

export interface Rate {
  token: string;
  boardBasis: string;
  mealPlanDescription?: string;
  price: Price;
  cheapest: boolean;
  offersApplied: Array<string>;
}

export interface PackageResponse {
  searchToken: string;
  expires: string;
}

export interface PackagesParams {
  searchToken: string;
  nextSet?: number;
  query?: Record<string, string>;
}

export type PackagesResponse = DataEnvelope<Results>;

export interface ReferenceParams {
  searchToken: string;
  packageReferences: Array<string>;
}

export interface DestinationParams {
  path: string;
  offerType?: OfferType;
}

export interface DestinationResponse {
  searchToken: string;
  expires: string;
}

export interface UrlSearchParams {
  year?: string;
  month?: string;
  from?: string;
  duration?: string;
  adults?: string;
  children?: string;
  boardBasis?: string;
}

export type DealFinderSearchParams = Partial<PartialDealFinderState>;

export interface DealFinderUrlParams {
  token: string;
  path: string;
  page: Page;
  from: string | Array<string>;
  duration: string | number;
  occupancy: StateOccupancy[];
  date: string;
  searchType: SearchType;
  section?: ProductBookingPageSections;
  preferredBoardBasis: string;
}

export interface SearchPageUrlParams {
  airports: string;
  destinations: string;
  date: string;
  month: string;
  year?: string;
  duration: string;
  adults: string;
  children: string;
  searchType: string;
  flexibleDays: string;
}

export interface FromTourReferenceResponse extends PackageResponse {
  searchType: SearchType;
}
