import React from 'react';
import { getLocalisedString, LOCALISE_KEYS } from '@util/localisation';

export const getLabelsFromConfig = (items: Array<any>) =>
  items
    .map((x: any) => x.fields)
    .reduce(
      (acc: Record<string, string>, curr: { key: string; value: string }) => ({
        ...acc,
        [curr.key]: curr.value
      }),
      {}
    );

export const I18NextContext: any = React.createContext(null);

export const WithT = (props: any) => {
  const t = (key: string, params: Object = {}) => {
    const { localeLabels } = props;
    if (key && localeLabels) {
      const augmentedKeys = LOCALISE_KEYS.reduce((acc, { contentKey, localesKey }) => {
        acc[contentKey] = localeLabels[localesKey];
        return acc;
      }, {});
      const augmentedParams = { ...augmentedKeys, ...params };
      return getLocalisedString(localeLabels, { key, params: augmentedParams });
    }
    return key;
  };
  return <I18NextContext.Provider value={t}>{props.children}</I18NextContext.Provider>;
};
