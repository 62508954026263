export interface Query {
  key: string;
  params: Object;
}

export const getLocalisedString = (labels: Record<string, string>, query: Query) => {
  const match = labels[query.key];
  const result = query.params
    ? Object.entries(query.params).reduce((acc: string, [key, value]) => {
        const regex: RegExp = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
        return regex.test(acc) ? acc.replace(regex, value) : acc;
      }, match || query.key)
    : match || query.key;
  return result;
};
