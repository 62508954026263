import { takeLatest, put, call, select } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import { TourResults, ToursSearchState, ToursApiParams } from '@model/tours';
import { DealFinderApi } from '@model/iceberg/service/deal-finder/deal-finder-api';
import { PackagesParams, PackagesResponse, Result } from '@model/iceberg/deal-finder/deal-finder';
import { FiltersAdapter } from '@model/iceberg/filters/deal-finder/filters-adapter';
import { BaseAction } from '@model/redux';
import { RequestType, setLoading } from '@state/app';
import { getToursSearchData } from '@state/search-results/tours/toursSelectors';
import { getErrorCode } from '@util/error';
import { getTourSpecialOffersQuery } from '@util/tours';

export enum ToursActions {
  FETCH_TOURS = '@TOURS/FETCH_TOURS',
  RECEIVE_TOURS_SUCCESS = '@TOURS/RECEIVE_TOURS_SUCCESS',
  RECEIVE_TOURS_FAILURE = '@TOURS/RECEIVE_TOURS_FAILURE',
  SET_TOURS_LOADING = '@TOURS/SET_TOURS_LOADING',
  RECEIVE_TOURS_TOKEN_SUCCESS = '@TOURS/RECEIVE_TOURS_TOKEN_SUCCESS',
  RECEIVE_TOURS_TOKEN_FAILURE = '@TOURS/RECEIVE_TOURS_TOKEN_FAILURE',
  SET_FILTERS = '@TOURS/SET_FILTERS',
  LOAD_MORE = '@TOURS/LOAD_MORE',
  CLEAR_TOURS = '@TOURS/CLEAR_TOURS'
}

export const INITIAL_TOURS_STATE: ToursSearchState = {
  data: {
    searchToken: '',
    expires: '',
    results: [],
    facets: {
      filters: [],
      counts: {
        total: 0,
        filtered: 0
      }
    },
    nextSet: 0
  },
  loading: false,
  error: null
};

export const mergeToursFilters = (state: ToursSearchState, payload: any) => {
  const newState: ToursSearchState = cloneDeep(state);
  newState.data.facets.filters = newState.data.facets.filters.map((filter) => {
    const updatedFilter = payload[filter.name];
    const selectedValues = (updatedFilter || '').split(',');
    filter.values = filter.values.map((value) => {
      value.selected = selectedValues.includes(value.value);
      return value;
    });
    return filter;
  });
  return newState;
};

export interface FetchToursAction extends BaseAction {
  type: ToursActions.FETCH_TOURS;
  payload: { params: ToursApiParams };
}

export const fetchTours: (params: ToursApiParams) => FetchToursAction = (params: ToursApiParams) => ({
  type: ToursActions.FETCH_TOURS,
  payload: { params }
});

export interface ReceiveToursSuccessAction {
  type: ToursActions.RECEIVE_TOURS_SUCCESS;
  data: TourResults;
}

export const receiveToursSuccess: (data: TourResults) => ReceiveToursSuccessAction = (data: TourResults) => ({
  type: ToursActions.RECEIVE_TOURS_SUCCESS,
  data
});

export interface ReceiveToursFailureAction {
  type: ToursActions.RECEIVE_TOURS_FAILURE;
  payload?: string;
}

export const receiveToursFailure: (error?: string) => ReceiveToursFailureAction = (error) => ({
  type: ToursActions.RECEIVE_TOURS_FAILURE,
  payload: error
});

export interface SetToursLoadingAction extends BaseAction {
  type: ToursActions.SET_TOURS_LOADING;
  payload: boolean;
}

export const setToursLoading = (payload: boolean) => ({ type: ToursActions.SET_TOURS_LOADING, payload });

export function* onFetchTours() {
  yield takeLatest(ToursActions.FETCH_TOURS, performFetchTours);
}

export const callGetToken: any = async (params: ToursApiParams) => {
  const dealFinderAPI: DealFinderApi = new DealFinderApi();
  try {
    return await dealFinderAPI.getTourSearchToken(params);
  } catch (error: any) {
    return error;
  }
};

export interface ReceiveToursTokenSuccessAction extends BaseAction {
  type: ToursActions.RECEIVE_TOURS_TOKEN_SUCCESS;
  payload: string;
}

export const receiveToursTokenSuccess: (searchToken: string) => ReceiveToursTokenSuccessAction = (
  searchToken: string
) => ({
  type: ToursActions.RECEIVE_TOURS_TOKEN_SUCCESS,
  payload: searchToken
});

export interface ReceiveToursTokenFailureAction extends BaseAction {
  type: ToursActions.RECEIVE_TOURS_TOKEN_FAILURE;
  payload: any;
}

export const receiveToursTokenFailure: (error?: string) => ReceiveToursTokenFailureAction = (error?: string) => ({
  type: ToursActions.RECEIVE_TOURS_TOKEN_FAILURE,
  payload: error
});

export const setFilters = (payload: Record<string, string>) => ({
  type: ToursActions.SET_FILTERS,
  payload
});

export function* onSetFilters() {
  yield takeLatest(ToursActions.SET_FILTERS, handleSetFilters);
}

export function* handleSetFilters({ payload: query }: any) {
  const { searchToken } = yield select(getToursSearchData);
  const { response, error } = yield call(callGetPackages, { searchToken, query });
  if (response) {
    yield put(
      receiveToursSuccess({
        ...response.data
      })
    );
  } else {
    const errorCode = getErrorCode(error);
    yield put(receiveToursFailure(errorCode));
  }
}

export function callGetPackages(payload: PackagesParams) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .packages(payload)
    .then((response: PackagesResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* performGetPackages(searchToken: string, query: any) {
  const { response, error } = yield call(callGetPackages, { searchToken, query });
  if (response) {
    if (response.data.results.length) {
      yield put(
        receiveToursSuccess({
          ...response.data
        })
      );
      return response.data;
    } else {
      const errorCode = getErrorCode(error);
      yield put(receiveToursFailure(errorCode));
      return null;
    }
  } else {
    const errorCode = getErrorCode(error);
    yield put(receiveToursFailure(errorCode));
    return null;
  }
}

export function* performFetchTours({ payload: { params } }: any) {
  const { searchToken } = params;
  const specialOffersQuery = getTourSpecialOffersQuery(params);
  if (searchToken) {
    yield performGetPackages(searchToken, specialOffersQuery);
  } else {
    const tokenResponse = yield call(callGetToken, params);
    const { searchToken, error } = tokenResponse;
    if (searchToken) {
      yield put(receiveToursTokenSuccess(searchToken));
      yield performGetPackages(searchToken, specialOffersQuery);
    } else {
      const errorCode = getErrorCode(error);
      yield put(receiveToursTokenFailure(errorCode));
    }
  }
}

export const loadMoreTours = () => ({
  type: ToursActions.LOAD_MORE
});

export function* handleOnLoadMoreTours() {
  yield takeLatest(ToursActions.LOAD_MORE, onLoadMoreTours);
}

export function* onLoadMoreTours() {
  yield put(setLoading(true, RequestType.LOAD_MORE_RESULTS));
  const {
    searchToken,
    nextSet,
    results,
    facets: { filters }
  } = yield select(getToursSearchData);
  const query: Record<string, string> = FiltersAdapter.getQuery(filters);
  const { response } = yield call(callGetPackages, { searchToken, nextSet, query });
  if (response) {
    const nextSet: Array<Result> = response.data.results;
    const update: Array<Result> = results.concat(nextSet);
    yield put(
      receiveToursSuccess({
        ...response.data,
        results: update
      })
    );
    yield put(setLoading(false, RequestType.LOAD_MORE_RESULTS));
  } else {
    yield put(receiveToursFailure());
  }
}

export const clearSearchResultsTours = () => ({
  type: ToursActions.CLEAR_TOURS
});

export const toursReducer: any = (state: any = INITIAL_TOURS_STATE, { type, data, payload }: any) => {
  switch (type) {
    case ToursActions.FETCH_TOURS:
      return { ...INITIAL_TOURS_STATE, loading: true };
    case ToursActions.RECEIVE_TOURS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case ToursActions.RECEIVE_TOURS_FAILURE:
      return { ...state, data: INITIAL_TOURS_STATE.data, error: payload || null, loading: false };
    case ToursActions.SET_TOURS_LOADING:
      return { ...state, loading: payload };
    case ToursActions.RECEIVE_TOURS_TOKEN_SUCCESS:
      return { ...state, data: { ...state.data, searchToken: payload }, error: null };
    case ToursActions.RECEIVE_TOURS_TOKEN_FAILURE:
      return { ...state, data: { ...state.data, searchToken: '' }, error: payload || null, loading: false };
    case ToursActions.CLEAR_TOURS:
      return INITIAL_TOURS_STATE;
    case ToursActions.SET_FILTERS:
      return {
        ...mergeToursFilters(state, payload),
        loading: true
      };
    default:
      return state;
  }
};
