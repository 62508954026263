import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';
import { ErrorResponse } from '@model/common/error';
import { RetargetingPayload, TourRetargetingPayload } from '@model/retargeting';

export interface RetargetingResponse {
  id: number;
}

interface WebhooksApiRequests {
  retargeting: (payload: RetargetingPayload) => Promise<RetargetingResponse | ErrorResponse>;
  tourRetargeting: (payload: TourRetargetingPayload) => Promise<RetargetingResponse | ErrorResponse>;
}

export class WebhooksApi implements WebhooksApiRequests {
  private api: Request = new Request();

  public async retargeting(payload: RetargetingPayload): Promise<RetargetingResponse> {
    return await this.api.post(uri(getURL()).path(EndPoint.WEBHOOKS.concat(EndPoint.RETARGETING)).href(), payload);
  }

  public async tourRetargeting(payload: TourRetargetingPayload): Promise<RetargetingResponse> {
    return await this.api.post(uri(getURL()).path(EndPoint.WEBHOOKS.concat(EndPoint.TOUR_RETARGETING)).href(), payload);
  }
}
