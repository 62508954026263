export enum AspectRatios {
  NONE = 'NONE',
  '16:9' = '16:9',
  '4:3' = '4:3',
  '3:2' = '3:2',
  '8:5' = '8:5',
  'Gallery' = 'Gallery'
}

export const aspectRatioPaddings: any = {
  NONE: 0,
  [AspectRatios['16:9']]: '56.25%',
  [AspectRatios['4:3']]: '75%',
  [AspectRatios['3:2']]: '66.66%',
  [AspectRatios['8:5']]: '62.5%',
  [AspectRatios['Gallery']]: '60.94%'
};
