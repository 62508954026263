import { Amount, Price } from '@model/common/currency';
import { Flight } from '@model/iceberg/booking/package';

export const calculateTotalPriceWithSelectedFlight = (
  flightPrice: Flight | null,
  basketPerPerson: Price,
  guests: number
) => {
  if (!flightPrice) return new Amount(0);
  const flightPerPerson: Amount = new Amount(flightPrice.price.amount, flightPrice.price.currency.code);
  const basketPrice: Amount = new Amount(basketPerPerson.amount, basketPerPerson.currency.code);
  return flightPerPerson.add(basketPrice.value).multiply(guests);
};

export const calculatePerPersonPriceWithSelectedFlight = (flightPrice: Flight | null, basketPerPerson: Price) => {
  if (!flightPrice) return new Amount(0);
  const flightPerPerson: Amount = new Amount(flightPrice.price.amount, flightPrice.price.currency.code);
  const basketPrice: Amount = new Amount(basketPerPerson.amount, basketPerPerson.currency.code);
  return flightPerPerson.add(basketPrice.value);
};
