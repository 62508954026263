import { BaseAction } from '@model/redux';

export enum TourFiltersActions {
  SET_TOUR_FILTERS = '@TOURS/SET_TOUR_FILTERS',
  UNSET_TOUR_FILTERS = '@TOURS/UNSET_TOUR_FILTERS'
}

export interface TourFiltersState {
  airport: string | null;
  adults: number | null;
}

export const INITIAL_TOUR_FILTERS_STATE: TourFiltersState = {
  airport: null,
  adults: null
};

export interface SetTourFiltersAction extends BaseAction {
  type: TourFiltersActions.SET_TOUR_FILTERS;
  payload: TourFiltersState;
}

export const setTourFilters: (params: TourFiltersState) => SetTourFiltersAction = (params: TourFiltersState) => ({
  type: TourFiltersActions.SET_TOUR_FILTERS,
  payload: params
});

export interface UnsetTourFiltersAction extends BaseAction {
  type: TourFiltersActions.UNSET_TOUR_FILTERS;
}

export const unsetTourFilters: () => UnsetTourFiltersAction = () => ({
  type: TourFiltersActions.UNSET_TOUR_FILTERS
});

export const tourFiltersReducer: any = (state: any = INITIAL_TOUR_FILTERS_STATE, { type, payload }: any) => {
  switch (type) {
    case TourFiltersActions.SET_TOUR_FILTERS:
      return payload;
    case TourFiltersActions.UNSET_TOUR_FILTERS:
      return INITIAL_TOUR_FILTERS_STATE;
    default:
      return state;
  }
};
