export enum CMSContentTypes {
  LABEL = 'label',
  GLOBAL_CONFIG = 'global',
  INFO_TILE_GROUP = 'infoTileGroupComponent',
  PAGE = 'page',
  DESTINATION_PAGE = 'destinationPage',
  RESORT = 'resort',
  DESTINATION = 'destination',
  TRIP_TYPE = 'tripType',
  TOURS_PRODUCT_PAGE = 'toursProductPage',
  TOUR = 'tour',
  TRIP_TYPE_PAGE = 'tripTypePage',
  CONTINENT = 'continent',
  PROMO_BANNER = 'promoBannerComponent',
  AGENTS_PAGE = 'agentsPageConfig',
  TEAM_MEMBER = 'teamMember',
  AGENTS_ARTICLE = 'agentsArticlePageConfig',
  EXPERIENCE = 'miniTour',
  EXPERIENCE_LANDING_PAGE = 'experienceLandingPageConfig',
  FOOTER = 'footer',
  SIDEBAR = 'uspSidebar',
  TRAVEL_GUIDE = 'travelGuide',
  VIDEO_GUIDE = 'videoGuide'
}

export enum CMSComponentIdentifiers {
  HERE_TO_HELP = 'More Info'
}

export enum AgentPageId {
  MEET_YOUR_TEAM = 'AgentsMeetYourTeam',
  WINDOW_POSTERS = 'AgentsWindowPosters',
  NEWS_AND_PROMOTIONS = 'AgentsNewsAndPromotions',
  INCENTIVES = 'AgentsIncentives',
  CLAIM_INCENTIVES = 'AgentsClaimIncentives',
  BOOKINGS = 'AgentsBookings',
  FAM_TRIP_COMPETITION = 'AgentsFamTripCompetition'
}
