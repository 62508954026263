import { GlobalAppState } from '@model/state';
import { INITIAL_PRICE_CALENDAR_FILTERS_STATE } from '@state/price-calendar/filter/priceCalendarFilterOperations';
import { createSelector } from 'reselect';
import { Filters } from '@model/state/price-calendar-state';
import { hasChildrenWithErrors } from '@model/search';

export const getPriceCalendarFilters = (state: GlobalAppState) => state.priceCalendar.filters;
export const getPriceCalendarFiltersDuration = (state: GlobalAppState) => state.priceCalendar.filters.duration;
export const getPriceCalendarFiltersOccupancy = (state: GlobalAppState) => state.priceCalendar.filters.occupancy;
export const getPriceCalendarFiltersAirport = (state: GlobalAppState) => state.priceCalendar.filters.airport;
export const getPriceCalendarFiltersBoard = (state: GlobalAppState) => state.priceCalendar.filters.board;
export const getIsPriceCalendarFiltersLoading = (state: GlobalAppState) => state.priceCalendar.filters.response.loading;
export const getIsPriceCalendarFiltersEmpty = (state: GlobalAppState) =>
  state.priceCalendar.filters === INITIAL_PRICE_CALENDAR_FILTERS_STATE;
export const getPriceCalendarFiltersHasError = (state: GlobalAppState) => {
  return state.priceCalendar.filters.response.error;
};

export const getPriceCalendarOccupancyError = createSelector(getPriceCalendarFilters, (filters: Filters) => {
  return !filters.occupancy || hasChildrenWithErrors(filters.occupancy);
});
