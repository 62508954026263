// Image is used as a fallback when an image request fails
import { ImageResponse } from '@model/iceberg';

export const HERO_IMAGE_FALLBACK: Array<ImageResponse> = [
  {
    imageSet: {
      '600': '/assets/images/my-booking-fallback-image.jpg',
      '960': '/assets/images/my-booking-fallback-image.jpg',
      '1280': '/assets/images/my-booking-fallback-image.jpg',
      '1920': '/assets/images/my-booking-fallback-image.jpg'
    },
    destination: {
      name: '',
      parent: '',
      path: '',
      type: ''
    }
  }
];

export const IMAGE_FALLBACK: Array<ImageResponse> = [
  {
    imageSet: {
      '600': '/assets/images/fallback-images/placeholder_600x338.jpg',
      '960': '/assets/images/fallback-images/placeholder_960x540.jpg',
      '1280': '/assets/images/fallback-images/placeholder_1280x720.jpg',
      '1920': '/assets/images/fallback-images/placeholder_1920x1080.jpg'
    },
    destination: {
      name: 'Malta',
      parent: 'Europe',
      path: 'europe/malta-and-gozo/malta',
      type: 'country'
    }
  }
];

export const DEFAULT_SPINNER_IMAGE_FALLBACK: Array<ImageResponse> = [
  {
    imageSet: {
      '960':
        'https://image-stock2.mercuryholidays.co.uk/resized/scenery/europe/malta-and-gozo/malta/sliema/w960-116156708_l.jpg'
    },
    destination: {
      type: 'resort',
      name: 'Sliema',
      parent: 'Malta',
      path: 'europe/malta-and-gozo/malta/sliema'
    }
  },
  {
    imageSet: {
      '960':
        'https://image-stock2.mercuryholidays.co.uk/resized/scenery/europe/malta-and-gozo/malta/w960-ghajn-tuffieha-viewpoint.jpg'
    },
    destination: {
      type: 'region',
      name: 'Malta',
      parent: 'Malta and Gozo',
      path: 'europe/malta-and-gozo/malta'
    }
  },
  {
    imageSet: {
      '960':
        'https://image-stock2.mercuryholidays.co.uk/resized/scenery/europe/malta-and-gozo/malta/w960-valletta-street-scene.jpg'
    },
    destination: {
      type: 'region',
      name: 'Malta',
      parent: 'Malta and Gozo',
      path: 'europe/malta-and-gozo/malta'
    }
  },
  {
    imageSet: {
      '960':
        'https://image-stock2.mercuryholidays.co.uk/resized/scenery/europe/malta-and-gozo/malta/san-gwann/w960-95851155_l.jpg'
    },
    destination: {
      type: 'resort',
      name: 'San Gwann',
      parent: 'Malta',
      path: 'europe/malta-and-gozo/malta/san-gwann'
    }
  },
  {
    imageSet: {
      '960':
        'https://image-stock2.mercuryholidays.co.uk/resized/scenery/europe/malta-and-gozo/malta/paradise-bay/w960-Paradise_Bay_20.jpg'
    },
    destination: {
      type: '',
      name: '',
      parent: '',
      path: ''
    }
  }
];

export const TILE_FALLBACK_IMAGE = IMAGE_FALLBACK[0].imageSet['600' as any];
