export enum Icons {
  NONE = '',
  AIRPORT_EXTRAS = 'mhi-airport-extras',
  ARROW_RIGHT = 'mhi-arrow-right',
  ARROW_LEFT = 'mhi-arrow-left',
  ARROW_UP = 'mhi-arrow-up',
  ABTA_ATOL = 'mhi-abta-atol',
  BOAT = 'mhi-boat',
  BOOKING = 'mhi-booking',
  CAR = 'mhi-car',
  CARE = 'mhi-care',
  CARET_DOWN = 'mhi-caret-down',
  CARET_UP = 'mhi-caret-up',
  CALENDAR = 'mhi-calendar',
  CHECK = 'mhi-check',
  CHEVRON_DOWN = 'mhi-chevron-down',
  CHEVRON_UP = 'mhi-chevron-up',
  CHEVRON_RIGHT = 'mhi-chevron-right',
  CHEVRON_LEFT = 'mhi-chevron-left',
  CHECK_IN_BAG = 'mhi-check-in-bag',
  CLEAR = 'mhi-clear',
  COACH = 'mhi-coach',
  CURRENCY = 'mhi-currency',
  DEPARTURE = 'mhi-departure',
  DOCUMENT = 'mhi-document',
  DOWNLOAD = 'mhi-download',
  EXTENSIONS = 'mhi-extensions',
  EMAIL = 'mhi-email',
  EYE = 'mhi-eye',
  FAILURE = 'mhi-failure',
  FASTEST_TIME = 'mhi-fastest-time',
  FINANCIAL_PROTECTION = 'mhi-financial-protection',
  FLIGHT = 'mhi-flight',
  GUARANTEE = 'mhi-guarantee',
  HAND_LUGGAGE = 'mhi-hand-luggage',
  HOLIDAYS = 'mhi-holidays',
  HOTEL = 'mhi-hotel',
  INFO = 'mhi-info',
  LOCK = 'mhi-lock',
  MAP = 'mhi-map',
  MAP_PIN = 'mhi-map-pin',
  MAP_PIN_OUTLINE = 'mhi-map-pin-outline',
  MEAL = 'mhi-meal',
  MOBILITY = 'mhi-mobility',
  MULTI_STAY = 'mhi-multi-stay',
  NIGHTS = 'mhi-nights',
  ONLINE_BOOKINGS = 'mhi-online-booking',
  PERSON = 'mhi-person',
  PHOTO = 'mhi-photo',
  ROOM = 'mhi-room',
  SEARCH = 'mhi-search',
  SEARCH_ERROR = 'mhi-search-error',
  SETTINGS = 'mhi-setting',
  SIZE = 'mhi-size',
  SUCCESS = 'mhi-success',
  SUN = 'mhi-sun',
  STAR = 'mhi-star',
  STOP = 'mhi-stop',
  THUMBS_UP = 'mhi-thumbs-up',
  TOURS = 'mhi-tours',
  TRAIN = 'mhi-train',
  TROPHY = 'mhi-trophy',
  VALUE = 'mhi-value',
  WALK = 'mhi-walk',
  WARNING = 'mhi-warning',
  WIFI = 'mhi-wifi'
}

export const formatIconName = (name: string) => name.toUpperCase().replace(/-/g, '_');
