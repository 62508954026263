import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { Guest } from '@model/iceberg/booking/basket/guest/guest';
import { BookingEndpoints } from '@model/service/booking-endpoints';

export interface GuestsResponse {
  guests: Array<Guest>;
}

export class GuestsApi {
  private api: Request = new Request();
  public async put(token: string, payload: Array<Guest>): Promise<GuestsResponse> {
    return await this.api.put(uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.GUESTS}`).href(), {
      searchToken: token,
      guests: payload
    });
  }

  public async get(token: string): Promise<GuestsResponse> {
    return await this.api.get(uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.GUESTS}`).href());
  }

  public async delete(token: string): Promise<GuestsResponse> {
    if (!token) return Promise.reject();
    return await this.api.delete(uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.GUESTS}`).href());
  }
}
