import { GlobalActions } from '@state/store';
import { ComponentTypes } from '@model/contentful';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { GlobalAppState } from '@model/state';
import { CMSComponentTypes } from '@components/cms';
import { BaseAction } from '@model/redux';

/* ***************** *
 *       TYPES       *
 * ***************** */

export type ComponentsDataState = {
  main: Array<ComponentData> | [];
  sidebar: Array<ComponentData> | [];
};

export interface ComponentData {
  componentType: ComponentTypes;
  data: any;
}

export type ComponentDataPayload = Array<ComponentData>;

export interface ComponentDataAction extends BaseAction {
  type: ComponentDataActionTypes | GlobalActions;
  payload: ComponentDataPayload | GlobalAppState;
  cmsComponentType: CMSComponentTypes;
}

/* ***************** *
 *   ACTION TYPES    *
 * ***************** */

export enum ComponentDataActionTypes {
  SET_COMPONENTS_DATA = '@COMPONENTS/SET_COMPONENTS_DATA'
}

/* ***************** *
 *     ACTIONS       *
 * ***************** */

export interface SetComponentData extends BaseAction {
  type: ComponentDataActionTypes.SET_COMPONENTS_DATA;
  payload: ComponentDataPayload;
  cmsComponentType: CMSComponentTypes;
}

export const setComponentsData: (
  cmsComponentType: CMSComponentTypes,
  payload: ComponentDataPayload
) => ComponentDataAction = (cmsComponentType: CMSComponentTypes, payload: ComponentDataPayload) => ({
  type: ComponentDataActionTypes.SET_COMPONENTS_DATA,
  payload,
  cmsComponentType
});

/* ***************** *
 *     REDUCER       *
 * ***************** */

export const INITIAL_COMPONENTS_DATA_STATE: ComponentsDataState = {
  main: [],
  sidebar: []
};

export const componentsDataReducer = (state: ComponentsDataState = INITIAL_COMPONENTS_DATA_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (
        !isEqual(state, (action.payload as GlobalAppState).componentsData) &&
        ((action.payload as GlobalAppState).componentsData.main.length ||
          (action.payload as GlobalAppState).componentsData.sidebar.length)
      ) {
        return (action.payload as GlobalAppState)?.componentsData || state;
      }
      return state;
    case ComponentDataActionTypes.SET_COMPONENTS_DATA:
      return {
        ...state,
        [action.cmsComponentType]: action.payload
      };
    default:
      return state;
  }
};
