import { isString, isArray, isObject } from 'lodash';
import locales from '../../../public/assets/locales/locales.json';
import { Keyable } from '@model/common';

export const LOCALISE_KEYS = [
  {
    contentKey: 'brandName',
    localesKey: 'common__brand_display'
  },
  { contentKey: 'phoneNumber', localesKey: 'common__brand_telephone' }
];

export const replaceValue = (localisations: Keyable, value: string) => {
  return LOCALISE_KEYS.reduce((replaceString, { contentKey, localesKey }) => {
    const grep = new RegExp(`\\{\\{\\s*${contentKey}\\s*\\}\\}`, 'gi');
    const replaceValue = localisations[localesKey];
    return replaceString.replace(grep, replaceValue);
  }, value);
};

export const localiseObject = (appVariantId: string, obj: Keyable): Keyable => {
  const entries = Object.entries(obj);
  const localisations: Keyable = { ...locales.labels, ...locales[appVariantId] };
  const returnObj = entries.reduce((acc, [key, value]) => {
    if (isArray(value)) {
      acc[key] = value.map((item) => {
        if (isString(item)) {
          return replaceValue(localisations, item);
        } else {
          return localiseObject(appVariantId, item);
        }
      });
    } else {
      if (isObject(value)) {
        acc[key] = localiseObject(appVariantId, value);
      } else {
        if (!isString(value) || !/\{\{\s*\w+\s*\}\}/.test(value)) {
          acc[key] = value;
        } else {
          acc[key] = replaceValue(localisations, value);
        }
      }
    }

    return acc;
  }, {});
  return returnObj;
};
