export enum EndPoint {
  IMAGE = '/v1/images',
  TESTIMONIALS = '/v1/testimonials',
  GEOGRAPHY = '/v1/geography',
  BOOKING = '/v1/booking',
  DEAL_FINDER = '/v2/booking',
  HOTEL_CONTENT = '/v2/hotel',
  HOTEL_TILE = '/v2/hotel/tile',
  DEPARTURE_AIRPORTS = '/v1/product/departure-airports',
  PACKAGE = '/package',
  PRICE_PANEL = '/v2/package/price-panel',
  POSTCODE = '/v1/postcode',
  PSP_SHA = '/v1/psp/sha',
  PSP_ALIAS = '/v1/psp/alias',
  BOOKING_ENQUIRY = '/v1/contact-us/bookingenquiry',
  GENERAL_ENQUIRY = '/v1/contact-us/generalenquiry',
  REQUEST_QUOTE = '/v1/contact-us/requestquote',
  WEBSITE_COMMENTS = '/v1/contact-us/websitecomments',
  TOUR_AVAILABILITY = '/v2/tour/availability',
  TOUR_CONTENT = '/v2/tour/content',
  TOUR_DETAIL = '/v2/tour/detail',
  TOUR_ITINERARY = '/v2/tour/itinerary',
  TOUR_PACKAGES = '/v2/tour/packages',
  TOUR_DOCUMENTS = '/v2/tour/documents',
  TOUR_TESTIMONIALS = '/v2/tour/testimonials',
  TOUR_HOTELS = '/hotels',
  CUSTOMER = '/v1/customer',
  DESTINATIONS = '/destinations',
  AIRPORTS = '/airports',
  PLACE = '/place',
  TRIP_TYPE_TOURS = '/v2/trip-type',
  HOTEL_PATHS = '/v1/product/hotel-paths',
  OFFERS = '/v2/offers',
  OFFERS_DESTINATION = '/v2/offers/destination',
  MMB_BOOKING = '/v1/customer/booking',
  TRADE = '/v1/trade',
  AUTHENTICATE = '/v1/trade/authenticate',
  REGISTER = '/v1/trade/register',
  LOGOUT = '/v1/trade/logout',
  BOOKINGS = '/v1/trade/bookings',
  CLAIM_INCENTIVES = '/v1/trade/claim-incentives',
  PRIVATE_TOUR_ENQUIRY = 'v1/contact-us/private-tour-enquiry',
  CREDIT_AGENT_BOOK = '/v1/trade/book',
  APIS_FORM = '/v1/contact-us/apis-submission',
  TRAVEL_DISCLAIMER = '/v1/contact-us/travel-disclaimer',
  RESET_PASSWORD = '/reset-password',
  VALIDATE_TOKEN = '/validate-token',
  CHANGE_PASSWORD = '/change-password',
  HELP_CENTRE = '/v1/help-centre',
  FAM_TRIP_COMPETITION = '/v1/trade/fam-trip-competition',
  MEDIA_POST = '/v1/trade/social-posts/list',
  MEDIA_POST_TRIP_TYPE = '/v1/trade/social-posts/trip-types',
  PROMO_BANNER = '/content/v2/promo-banners',
  DESTINATION_FAQ = '/v2/destination-faq',
  WEBHOOKS = '/webhooks/sunspot/v1',
  RETARGETING = '/retargeting',
  TOUR_RETARGETING = '/tour-retargeting',
  CONTENT = '/content/v2',
  GEOGRAPHY_DESTINATIONS = '/geography/destinations',
  CAROUSEL = '/carousel',
  FOOTER = '/content/footer',
  MAIN = '/main',
  CHECKOUT = '/checkout',
  CMS = '/v1/cms',
  RESORT_LANDING_PAGE = '/resort-landing-page',
  UP = '/up'
}
