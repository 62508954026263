import { AsyncData } from '@model/common';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GetMediaPostsResponse,
  MediaPost,
  MediaPostBaseRequestPayload,
  MediaPostErrorDetails,
  MediaPostRequestPayload,
  MediaPostTripTypes
} from '@model/agents/agents-social-posts-models';
import { MediaPostApi } from '@model/iceberg/service/trade/MediaPostApi';
import { TripTypes } from '@model/contentful';
import { BaseAction } from '@model/redux';

export enum MediaPostActions {
  PERFORM_FETCH_POST = '@AGENTS/PERFORM_FETCH_POST',
  PERFORM_FETCH_POST_TRIP_TYPE = '@AGENTS/PERFORM_FETCH_POST_TRIP_TYPE',
  RECEIVE_POST_SUCCESS = '@AGENTS/RECEIVE_POST_SUCCESS',
  RECEIVE_POST_FAILURE = '@AGENTS/RECEIVE_POST_FAILURE',
  RECEIVE_POST_TRIP_TYPE_SUCCESS = '@AGENTS/RECEIVE_POST_TRIP_TYPE_SUCCESS',
  RECEIVE_POST_TRIP_TYPE_FAILURE = '@AGENTS/RECEIVE_POST_TRIP_TYPE_FAILURE'
}

export interface InitialMediaPostStateType {
  posts: AsyncData<Array<MediaPost>>;
  tripTypes: AsyncData<Array<TripTypes>>;
}

export const INITIAL_POST_STATE: AsyncData<Array<MediaPost>> = {
  data: {} as any,
  loading: false,
  error: null
};
export const INITIAL_POST_TRIP_TYPE_STATE: AsyncData<Array<TripTypes>> = {
  data: [] as any,
  loading: false,
  error: null
};
export const INITIAL_MEDIA_POST_STATE: InitialMediaPostStateType = {
  posts: INITIAL_POST_STATE,
  tripTypes: INITIAL_POST_TRIP_TYPE_STATE
};

export interface RequestMediaPosts extends BaseAction {
  type: MediaPostActions.PERFORM_FETCH_POST;
  payload: MediaPostRequestPayload;
}

export const performFetchPost: (payload: MediaPostRequestPayload) => RequestMediaPosts = (
  payload: MediaPostRequestPayload
) => {
  return {
    type: MediaPostActions.PERFORM_FETCH_POST,
    payload
  };
};

export interface RequestMediaPostTripType extends BaseAction {
  type: MediaPostActions.PERFORM_FETCH_POST_TRIP_TYPE;
  payload: MediaPostBaseRequestPayload;
}

export const performFetchPostTripType: (payload: MediaPostBaseRequestPayload) => RequestMediaPostTripType = (
  payload: MediaPostBaseRequestPayload
) => {
  return {
    type: MediaPostActions.PERFORM_FETCH_POST_TRIP_TYPE,
    payload
  };
};

export interface RequestFetchPostSuccess extends BaseAction {
  type: MediaPostActions.RECEIVE_POST_SUCCESS;
  payload: MediaPost[];
}

export const receiveFetchPostSuccess: (payload: MediaPost[]) => RequestFetchPostSuccess = (payload: MediaPost[]) => {
  return {
    type: MediaPostActions.RECEIVE_POST_SUCCESS,
    payload
  };
};

export interface MediaPostError {
  type: MediaPostActions.RECEIVE_POST_FAILURE;
  payload?: MediaPostErrorDetails;
}

export const receivePostError: (payload?: MediaPostErrorDetails) => MediaPostError = (
  payload?: MediaPostErrorDetails
) => {
  return {
    type: MediaPostActions.RECEIVE_POST_FAILURE,
    payload
  };
};

export interface RequestPostTripTypeSuccess extends BaseAction {
  type: MediaPostActions.RECEIVE_POST_TRIP_TYPE_SUCCESS;
  payload: MediaPostTripTypes;
}

export const receivePostTripTypeSuccess: (payload: MediaPostTripTypes) => RequestPostTripTypeSuccess = (
  payload: MediaPostTripTypes
) => {
  return {
    type: MediaPostActions.RECEIVE_POST_TRIP_TYPE_SUCCESS,
    payload
  };
};

export interface MediaPostTripTypeError {
  type: MediaPostActions.RECEIVE_POST_TRIP_TYPE_FAILURE;
  payload?: MediaPostErrorDetails;
}

export const receivePostTripTypeFailure: (payload?: MediaPostErrorDetails) => MediaPostTripTypeError = (
  payload?: MediaPostErrorDetails
) => {
  return {
    type: MediaPostActions.RECEIVE_POST_TRIP_TYPE_FAILURE,
    payload
  };
};

export function* onPerformFetchPost() {
  yield takeLatest(MediaPostActions.PERFORM_FETCH_POST, handleOnPerformFetchPost);
}

export function* onPerformFetchPostTripType() {
  yield takeLatest(MediaPostActions.PERFORM_FETCH_POST_TRIP_TYPE, handleFetchPostTripType);
}

export function* handleOnPerformFetchPost({ payload }: any) {
  const { response, error } = yield call(fetchPost, payload);

  if (response) {
    yield put(receiveFetchPostSuccess(response.data));
  }

  if (error) {
    const {
      response: { status, data }
    } = error;

    yield put(receivePostError({ status, data }));
  }
}

export function* handleFetchPostTripType({ payload }: any) {
  const { response, error } = yield call(getPostTripType, payload);

  if (response) {
    yield put(receivePostTripTypeSuccess(response.data));
  }

  if (error) {
    const {
      response: { status, data }
    } = error;

    yield put(receivePostTripTypeFailure({ status, data }));
  }
}

export function getPostTripType(loginToken: MediaPostBaseRequestPayload) {
  const api: MediaPostApi = new MediaPostApi();
  return api
    .getTripTypes(loginToken)
    .then((response: MediaPostTripTypes) => ({ response }))
    .catch((error: any) => ({
      error
    }));
}

export function fetchPost(payload: MediaPostRequestPayload) {
  const api: MediaPostApi = new MediaPostApi();
  return api
    .getPostList(payload)
    .then((response: GetMediaPostsResponse) => ({ response }))
    .catch((error: any) => ({
      error
    }));
}

export const mediaPostTripTypeReducer: any = (state: any = INITIAL_MEDIA_POST_STATE, { type, error, payload }: any) => {
  switch (type) {
    case MediaPostActions.RECEIVE_POST_TRIP_TYPE_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case MediaPostActions.RECEIVE_POST_TRIP_TYPE_FAILURE:
      return { ...state, data: INITIAL_MEDIA_POST_STATE.tripTypes.data, error, loading: false };
    case MediaPostActions.PERFORM_FETCH_POST_TRIP_TYPE:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export const mediaPostReducer: any = (state: any = INITIAL_MEDIA_POST_STATE, { type, error, payload }: any) => {
  switch (type) {
    case MediaPostActions.RECEIVE_POST_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case MediaPostActions.RECEIVE_POST_FAILURE:
      return { ...state, data: INITIAL_MEDIA_POST_STATE.posts.data, error, loading: false };
    case MediaPostActions.PERFORM_FETCH_POST:
      return { ...state, loading: true };
    default:
      return state;
  }
};
