export enum ComponentTypes {
  benefitGroupComponent = 'benefitGroupComponent',
  destinationGroupComponent = 'destinationGroupComponent',
  productTileGroupComponent = 'productTileGroupComponent',
  footer = 'footer',
  expandableListGroup = 'expandableListsGroup',
  uspSidebar = 'uspSidebar',
  infoTileGroupComponent = 'infoTileGroupComponent',
  whatHappensNextComponent = 'whatHappensNextComponent',
  nextStepsComponent = 'nextStepsComponent',
  infoRowGroup = 'infoRowGroup',
  contactDetails = 'contactDetails',
  toursProductTileGroupComponent = 'toursProductTileGroupComponent',
  article = 'article',
  linkCard = 'linkCard',
  genericDynamicComponent = 'genericDynamicComponent',
  teamMember = 'teamMember',
  windowPosters = 'windowPosters',
  video = 'video',
  callToBook = 'callToBook'
}

export enum CMSDynamicComponentTypes {
  orderBrochureFormComponent = 'order-brochure',
  testimonialsComponent = 'testimonials-component',
  onlineEnquiryComponent = 'online-enquiry',
  homepageTripTypes = 'homepage-trip-types',
  mediaEnquiriesComponent = 'media-enquiries',
  spacing = 'spacing-component',
  discoverTheWorld = 'discover-the-world'
}
