export enum Page {
  HOME = '/',
  PRODUCT = '/product',
  FLIGHTS = '/flights',
  SEARCH = '/search',
  DESTINATION = '/destination',
  DESTINATION_HOTELS = '/destination/hotels',
  GUESTS = '/guests',
  REVIEW = '/review',
  PAYMENT = '/payment',
  CONFIRMATION = '/confirmation',
  HELP = '/info',
  PRIVACY_POLICY = '/privacy-policy',
  RESORTS = '/resorts',
  TRAVEL_GUIDES = '/travel-guides',
  VIDEO_GUIDES = '/video-guides',
  EXPERIENCES = '/experiences',
  TOURS_SEARCH = '/tours-search',
  TOURS_PRODUCT = '/tours-product',
  TOURS_EXTRAS = '/tours-extras',
  HOLIDAYS = '/holidays',
  HOTELS = '/hotels',
  TRIP_TYPES = '/trip-types',
  ALL_TOURS = '/tours',
  OFFERS = '/offers',
  MY_BOOKING = '/my-booking',
  MY_BOOKING_OVERVIEW = '/my-booking/overview',
  MY_BOOKING_EXTRAS = '/my-booking/extras',
  MY_BOOKING_PAYMENT = '/my-booking/payment',
  MY_BOOKING_DOCUMENTS = '/my-booking/documents',
  MY_BOOKING_CONTACT_US = '/my-booking/contact-us',
  MY_BOOKING_APIS_FORM = '/my-booking/apis-form',
  AGENTS = '/agents',
  TRAVEL_DISCLAIMER_FORM = '/travel-disclaimer',
  DEV_SEARCH = '/dev/search',
  DEV_PRODUCT = '/dev/product',
  SITEMAP = '/sitemap',
  HELP_CENTRE = '/help',
  CONTACT_US = '/contact-us'
}
