import { GlobalAppState } from '@model/state';

export const getBasket = (state: GlobalAppState) => state.basket.data;
export const getBasketLoading = (state: GlobalAppState) => state.basket.loading;
export const getBasketHotel = (state: GlobalAppState) => state.basket.data.hotel;
export const getBasketMealPlan = (state: GlobalAppState) => {
  if (state.basket.data.hotel?.rooms && state.basket.data.hotel?.rooms.length > 0) {
    return state.basket.data.hotel?.rooms[0].mealPlan;
  }
};
export const getBasketFlight = (state: GlobalAppState) => state.basket.data.flight;
export const getGuestCount = (state: GlobalAppState) => {
  if (state.basket.data.hotel) {
    const { adults, children, infants } = state.basket.data.hotel!.occupancy;
    return adults + children + infants;
  }
  return 0;
};
export const getAdultsAndChildrenCount = (state: GlobalAppState) => {
  if (state.basket.data.hotel) {
    const { adults, children } = state.basket.data.hotel!.occupancy;
    return adults + children;
  }
  return 0;
};
