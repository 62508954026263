import React from 'react';
import styled, { Interpolation } from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Icon } from '../generic-icon/Icon';
import { Theme } from '@theme/base';
import { RatingType } from '@model/tours';
import AnchorIcon from '@components/svg/AnchorIcon';

export interface StarRatingProps {
  rating: number | null;
  ratingType?: string;
  numOfStars?: number;
  activeStarColor?: string;
  hoverActiveStarColor?: string;
  isHover?: boolean;
  inactiveStarColor?: string;
  theme: Theme;
  size?: number | string;
  testId?: string;
  containerStyling?: Interpolation<Theme>;
}

const StarContainer: any = styled.div(({ size }: any) => ({
  width: size,
  position: 'relative',
  marginRight: 2
}));

const HalfStar: any = styled.div(({ size }: any) => ({
  width: `calc(${size}${typeof size === 'number' ? 'px' : ''} / 2)`,
  height: '100%',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0
}));

const AnchorIconStyle = styled(AnchorIcon)(() => ({}));

const StarRatingContainer = styled.div({
  display: 'flex',
  flexWrap: 'nowrap'
});

export const checkHalfRating = (rating: number) => rating - Math.floor(rating) === 0.5;

export const StarRatingComponent = (props: StarRatingProps) => {
  const {
    rating,
    ratingType,
    numOfStars,
    activeStarColor,
    inactiveStarColor,
    theme,
    hoverActiveStarColor,
    isHover,
    testId,
    size,
    containerStyling
  } = props;

  if (!rating) {
    return null;
  }

  /* *** RENDERERS *** */
  const renderStars = () => {
    const activeColor = activeStarColor || theme.custom.colors.group9.base;
    const inactiveColor = inactiveStarColor || theme.custom.colors.group10.light;

    const starStyling = {
      fontSize: props.size,
      marginRight: theme.custom.spacing.xSmall,
      transition: `color ${theme.custom.transitions.default}`,
      width: size,
      height: size
    };

    const isAnchor: boolean = ratingType === RatingType.ANCHORS;
    const isHalfStarRating = checkHalfRating(rating);
    const fullStars = isHalfStarRating ? Math.floor(rating) : Math.round(rating);

    return [...Array(numOfStars)].map((_, index) =>
      isAnchor ? (
        <StarContainer size={size} key={index}>
          {index === fullStars && isHalfStarRating && (
            <HalfStar size={size}>
              <AnchorIconStyle style={{ ...starStyling }} fillColor={activeColor} viewBoxSize={`0 0 32 32`} />
            </HalfStar>
          )}
          <AnchorIconStyle
            style={{ ...starStyling }}
            fillColor={
              index < fullStars ? (isHover && hoverActiveStarColor ? hoverActiveStarColor : activeColor) : inactiveColor
            }
            viewBoxSize={`0 0 32 32`}
          />
        </StarContainer>
      ) : (
        <StarContainer size={size} key={index}>
          {index === fullStars && isHalfStarRating && (
            <HalfStar size={size}>
              <Icon
                testId={'mh-half-star'}
                name={'mhi-star'}
                css={{
                  ...starStyling,
                  color: activeColor
                }}
              />
            </HalfStar>
          )}
          <Icon
            testId={index < fullStars ? 'mh-active-star' : 'mh-inactive-star'}
            name={'mhi-star'}
            css={{
              ...starStyling,
              color:
                index < fullStars
                  ? isHover && hoverActiveStarColor
                    ? hoverActiveStarColor
                    : activeColor
                  : inactiveColor
            }}
          />
        </StarContainer>
      )
    );
  };

  return (
    <StarRatingContainer data-testid={testId || 'mh-star-rating'} css={containerStyling}>
      {renderStars()}
    </StarRatingContainer>
  );
};

StarRatingComponent.defaultProps = {
  size: '1.4rem',
  rating: 0,
  numOfStars: 5
};

export const StarRating = withTheme(StarRatingComponent);
