import { GlobalAppState } from '@model/state';
import { FiltersSideBarType, FilterNames } from '@model/filters';

export const getFilteredTokens = (type: FiltersSideBarType) => (state: GlobalAppState) =>
  state.filters.filteredTokens[type];

export const getFlightsFilteredTokens = (state: GlobalAppState) => state.filters.filteredTokens.flights;
export const getToursFilteredTokens = (state: GlobalAppState) => state.filters.filteredTokens.tours;

export const getSelectedFilters = (state: GlobalAppState, type: FiltersSideBarType, filterName?: FilterNames) => {
  if (filterName) {
    return state.filters.selectedFilters[type] && state.filters.selectedFilters[type][filterName];
  }
  return state.filters.selectedFilters[type];
};

export const getSortingOption = (state: GlobalAppState) => state.filters.sorting;
