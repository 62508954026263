import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';
import { PriceCalendarEndpoints } from '@model/iceberg/service/price-calendar/price-calendar-endpoints';
import { FiltersResponse } from './filters-response';
import { MonthlyResponse } from '@model/iceberg/service/price-calendar/monthly-response';
import { Occupancy } from '@model/iceberg/deal-finder/deal-finder';
import { PriceCalendarParams } from '@model/price-calendar';
import { sentryException } from '@util/sentry';
import { FILTER_DEFAULTS } from '@model/price-calendar';

export interface FromPricePayload {
  path: string;
  duration: number;
  from: string;
  occupancy: Array<Occupancy>;
  month: string;
  year: string;
  boardBasis: string;
}

interface PriceCalendarApiRequests {
  filters: (path: string, payload: PriceCalendarParams) => Promise<FiltersResponse>;
  monthly: (payload: FromPricePayload) => Promise<MonthlyResponse>;
}

export class PriceCalendarApi implements PriceCalendarApiRequests {
  private api: Request = new Request();

  public async filters(path: string, payload: PriceCalendarParams): Promise<FiltersResponse> {
    const { from, boardBasis, duration, occupancy, month, year } = payload;
    if (!duration) {
      sentryException(`FS duration: ${window.location.pathname}${window.location.search}; ${payload.duration}`);
    }
    return await this.api.post(
      uri(getURL()).path(EndPoint.PRICE_PANEL.concat(PriceCalendarEndpoints.FILTERS)).search({ path }).href(),
      {
        duration: duration || FILTER_DEFAULTS.duration,
        from,
        month,
        year,
        boardBasis,
        occupancy: occupancy || FILTER_DEFAULTS.occupancy
      }
    );
  }

  public async monthly(payload: FromPricePayload): Promise<MonthlyResponse> {
    return await this.api.post(
      uri(getURL())
        .path(EndPoint.PRICE_PANEL.concat(PriceCalendarEndpoints.MONTHLY))
        .search({ path: payload.path })
        .href(),
      payload
    );
  }
}
