import { takeLatest, call, put } from 'redux-saga/effects';
import { DealFinderApi } from '@model/iceberg/service/deal-finder/deal-finder-api';
import { PackageReferencesActions } from '@state/app';
import { BaseAction } from '@model/redux';
import { getErrorCode } from '@util/error';

export enum TourReferencesActions {
  SET_TOUR_REFERENCES = '@TOURS/SET_TOUR_REFERENCES',
  UNSET_TOUR_REFERENCES = '@TOURS/UNSET_TOUR_REFERENCES',
  TOUR_REFERENCES_SUCCESS = '@TOURS/TOUR_REFERENCES_SUCCESS',
  TOUR_REFERENCES_FAILURE = '@TOURS/TOUR_REFERENCES_FAILURE',
  GET_FROM_TOUR_REFERENCE = '@TOURS/GET_FROM_TOUR_REFERENCE',
  FROM_TOUR_REFERENCE_SUCCESS = '@TOURS/FROM_TOUR_REFERENCE_SUCCESS',
  FROM_TOUR_REFERENCE_FAILURE = '@TOURS/FROM_TOUR_REFERENCE_FAILURE',
  SET_LEADIN_TOUR_REFERENCES = '@TOURS/SET_LEADIN_TOUR_REFERENCES',
  UNSET_LEADIN_TOUR_REFERENCES = '@TOURS/UNSET_LEADIN_TOUR_REFERENCES'
}

export interface TourReferencesState {
  packageReferences: Array<string>;
  fromTourReference: string;
  leadInTourReferences: Array<string>;
  error: string | null;
}

export interface SetTourReferencesPayload {
  packageReferences: Array<string>;
  searchToken?: string;
}

export interface SetPackagePayload {
  packageReferences: Array<string>;
  searchToken: string;
}

export interface TourReferencesSuccessPayload {
  packageReferences: Array<string>;
}

export interface SetTourReferencesAction extends BaseAction {
  type: TourReferencesActions.SET_TOUR_REFERENCES;
  payload: SetTourReferencesPayload;
}

export interface UnsetTourReferencesAction extends BaseAction {
  type: TourReferencesActions.UNSET_TOUR_REFERENCES;
}

export interface TourReferencesSuccessAction extends BaseAction {
  type: TourReferencesActions.TOUR_REFERENCES_SUCCESS;
  payload: TourReferencesSuccessPayload;
}

export interface TourReferencesFailureAction extends BaseAction {
  type: TourReferencesActions.TOUR_REFERENCES_FAILURE;
  payload: string;
}

export interface GetFromTourReferenceAction extends BaseAction {
  type: TourReferencesActions.GET_FROM_TOUR_REFERENCE;
  payload: string;
}

export interface FromTourReferenceSuccessAction extends BaseAction {
  type: TourReferencesActions.FROM_TOUR_REFERENCE_SUCCESS;
  payload: string;
}

export interface FromTourReferenceFailureAction extends BaseAction {
  type: TourReferencesActions.FROM_TOUR_REFERENCE_FAILURE;
  payload: string;
}

export interface SetLeadInTourReferencesAction extends BaseAction {
  type: TourReferencesActions.SET_LEADIN_TOUR_REFERENCES;
  payload: TourReferencesSuccessPayload;
}

export interface UnsetLeadInTourReferencesAction extends BaseAction {
  type: TourReferencesActions.UNSET_LEADIN_TOUR_REFERENCES;
}

export const INITIAL_TOUR_REFERENCES_STATE: TourReferencesState = {
  packageReferences: [],
  error: null,
  fromTourReference: '',
  leadInTourReferences: []
};

export const setTourReferences: (payload: SetTourReferencesPayload) => SetTourReferencesAction = (
  payload: SetTourReferencesPayload
) => ({
  type: TourReferencesActions.SET_TOUR_REFERENCES,
  payload
});

export const unsetTourReferences: () => UnsetTourReferencesAction = () => ({
  type: TourReferencesActions.UNSET_TOUR_REFERENCES
});

export const tourReferencesSuccess: (payload: TourReferencesSuccessPayload) => TourReferencesSuccessAction = (
  payload: TourReferencesSuccessPayload
) => ({
  type: TourReferencesActions.TOUR_REFERENCES_SUCCESS,
  payload
});

export const tourReferencesFailure: (payload: string) => TourReferencesFailureAction = (payload: string) => ({
  type: TourReferencesActions.TOUR_REFERENCES_FAILURE,
  payload
});

export const fetchFromTourReference: (payload: string) => GetFromTourReferenceAction = (payload: string) => ({
  type: TourReferencesActions.GET_FROM_TOUR_REFERENCE,
  payload
});

export const fromTourReferenceSuccess: (payload: string) => FromTourReferenceSuccessAction = (payload: string) => ({
  type: TourReferencesActions.FROM_TOUR_REFERENCE_SUCCESS,
  payload
});

export const fromTourReferenceFailure: (payload: string) => FromTourReferenceFailureAction = (payload: string) => ({
  type: TourReferencesActions.FROM_TOUR_REFERENCE_FAILURE,
  payload
});

export const setLeadInTourReferences: (payload: TourReferencesSuccessPayload) => SetLeadInTourReferencesAction = (
  payload: TourReferencesSuccessPayload
) => ({
  type: TourReferencesActions.SET_LEADIN_TOUR_REFERENCES,
  payload
});

export const unsetLeadInTourReferences: () => UnsetLeadInTourReferencesAction = () => ({
  type: TourReferencesActions.UNSET_LEADIN_TOUR_REFERENCES
});

export function* handleOnSetTourReferences() {
  yield takeLatest(TourReferencesActions.SET_TOUR_REFERENCES, onSetTourReferences);
}

export function callSetPackage(payload: SetPackagePayload) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .reference(payload)
    .then((response) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* onSetTourReferences(action: SetTourReferencesAction) {
  const {
    payload: { packageReferences, searchToken }
  } = action;
  if (searchToken) {
    const { error } = yield call(callSetPackage, { packageReferences, searchToken });
    if (error) {
      yield put(tourReferencesFailure(getErrorCode(error)));
    } else {
      yield put(tourReferencesSuccess({ packageReferences }));
    }
  }
}

export function* handleOnGetFromTourReference() {
  yield takeLatest(TourReferencesActions.GET_FROM_TOUR_REFERENCE, onGetFromTourReference);
}

export function callGetFromTourReference(payload: string) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .fromTourReference(payload)
    .then((response) => response)
    .catch((error: any) => ({
      error
    }));
}

export function* onGetFromTourReference(action: GetFromTourReferenceAction) {
  const { payload } = action;
  const { searchToken, error } = yield call(callGetFromTourReference, payload);
  if (searchToken) {
    yield put(fromTourReferenceSuccess(searchToken));
  } else {
    yield put(fromTourReferenceFailure(getErrorCode(error)));
  }
}

export const tourReferencesReducer = (state: TourReferencesState = INITIAL_TOUR_REFERENCES_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case TourReferencesActions.SET_TOUR_REFERENCES:
      return {
        ...INITIAL_TOUR_REFERENCES_STATE,
        leadInTourReferences: state.leadInTourReferences
      };
    case PackageReferencesActions.SET_PACKAGE_REFERENCES:
    case TourReferencesActions.UNSET_TOUR_REFERENCES:
      return {
        ...INITIAL_TOUR_REFERENCES_STATE,
        leadInTourReferences: state.leadInTourReferences
      };
    case TourReferencesActions.TOUR_REFERENCES_SUCCESS: {
      const { packageReferences } = payload;
      return {
        ...state,
        error: null,
        packageReferences
      };
    }
    case TourReferencesActions.TOUR_REFERENCES_FAILURE: {
      return { ...state, error: payload, packageReferences: INITIAL_TOUR_REFERENCES_STATE.packageReferences };
    }
    case TourReferencesActions.GET_FROM_TOUR_REFERENCE:
      return { ...state, packageReferences: [payload] };
    case TourReferencesActions.FROM_TOUR_REFERENCE_SUCCESS:
      return { ...state, fromTourReference: payload };
    case TourReferencesActions.FROM_TOUR_REFERENCE_FAILURE:
      return { ...state, error: payload, fromTourReference: '' };
    case TourReferencesActions.SET_LEADIN_TOUR_REFERENCES: {
      const { packageReferences } = payload;
      return {
        ...state,
        leadInTourReferences: packageReferences
      };
    }
    case TourReferencesActions.UNSET_LEADIN_TOUR_REFERENCES:
      return {
        ...state,
        leadInTourReferences: INITIAL_TOUR_REFERENCES_STATE.leadInTourReferences
      };
    default:
      return state;
  }
};
