import { TripTypes } from '@model/contentful';
import { AsyncData, Image, Price } from '@model/common';
import { Results, LeadInPrice, OtherPrice } from '@model/iceberg/deal-finder/deal-finder';
import { DataEnvelope, Place } from '@model/iceberg';
import { Destination, Occupancy } from '@model/state';

export interface TourSummary {
  place: Place;
  startDate: string;
  nights: number;
  isExtension: boolean;
  featuredImage: Image;
  gallery: Array<Image>;
  description: string;
  subTitle: string;
}

export interface TourResultInformation {
  excursions: number;
  meals: number;
  pace: number;
}

export interface TourPrice {
  references: Array<string>;
  date: string;
  adults: number;
  children: number;
  airport: string;
}

export interface TourLeadInPrice extends Omit<LeadInPrice, 'packageReferences' | 'mealPlan'>, TourPrice {}

export interface TourOtherPrice extends Omit<OtherPrice, 'mealPlan'>, TourPrice {}

export type TourPriceSummary = Pick<TourPrice, 'references' | 'date'>;

export interface TourResult {
  information: TourResultInformation;
  summary: TourSummary;
  leadInPrice: TourLeadInPrice;
  popularity?: number;
}

export interface TourResults extends Omit<Results, 'results'> {
  results: Array<TourResult>;
}

export type ToursSearchState = AsyncData<TourResults>;

export interface TourSearchUrlParams {
  searchToken?: string;
  tripType?: string;
  destinations?: string;
  airports?: string;
  adults?: string;
  month?: string;
  specialOffers?: string;
}

export interface TourProductUrlParams extends Omit<TourSearchUrlParams, 'destinations'>, TourSearchUrlParams {
  productPath: string;
}

export interface ToursApiParams {
  searchToken?: string;
  searchType?: string;
  places?: Array<Destination>;
  from?: Array<string>;
  duration?: string;
  month?: Array<string>;
  occupancy?: Array<Occupancy>;
  specialOffers?: string;
  flexible?: false;
}

export interface ToursPackageResponse {
  searchToken: string;
  expires: string;
  searchType?: string;
}

export type ToursPackagesResponse = DataEnvelope<TourResults>;

export enum ToursSearchType {
  'DESTINATION' = 'destination',
  'TOUR' = 'tour'
}

export interface ToursSearch {
  tours: Array<TourSearchItem>;
  filters: ToursSearchFilters | object;
}

export interface TourSearchItem {
  name: string;
  days: number;
  price: Price;
  image: string;
  tripType: TripTypes;
  isExtension: boolean;
  isPrivateTour?: boolean;
  tourId: number;
  definitionId: number;
  path: string;
  token: string;
  subTitle: string;
}

export interface ToursSearchFilters {
  duration: ToursSearchFilter;
  tripType: ToursSearchFilter;
  minimumPrice: number;
  maximumPrice: number;
}

export interface ToursSearchFilter {
  [key: string]: number;
}

export enum TourDurationFilters {
  LESS_THAN_A_WEEK = '0-1-weeks',
  ONE_TWO_WEEKS = '1-2-weeks',
  TWO_FOUR_WEEKS = '2-4-weeks',
  MORE_THAN_FOUR_WEEKS = '4-plus-weeks'
}

export enum TourResultsSortFields {
  TOUR_LENGTH = 'days',
  TOUR_PRICE = 'price'
}

export interface TourSpecialOffersQueryUrlParams {
  profile: string;
  special_offers?: string;
}
