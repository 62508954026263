import { Logging } from '@util/logging';
import Router from 'next/router';
import { takeEvery } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { BaseAction } from '@model/redux';

export enum RoutingActions {
  PERFORM_NAVIGATE = '@ROUTING/PERFORM_NAVIGATE',
  LOCATION_CHANGE = '@ROUTING/LOCATION_CHANGE',
  INITIALIZE_PATH = '@ROUTING/INITIALIZE_PATH',
  REDIRECT = '@ROUTING/REDIRECT'
}

export interface NavigationParams {
  pathname: string;
  query?: {
    [key: string]: string;
  };
}

export const performNavigate = (params: NavigationParams | string, as?: string, options?: any) => ({
  type: RoutingActions.PERFORM_NAVIGATE,
  params,
  as,
  ...(!!options && { options })
});

export const performRedirect = (params: NavigationParams | string, as?: string) => ({
  type: RoutingActions.REDIRECT,
  params,
  as
});

export const locationChanged = (url: string) => ({
  type: RoutingActions.LOCATION_CHANGE,
  url
});

export function* onNavigate() {
  yield takeEvery(RoutingActions.PERFORM_NAVIGATE, handlePerformNavigate);
}

export function* onRedirect() {
  yield takeEvery(RoutingActions.REDIRECT, handleRedirect);
}

export function* handlePerformNavigate({ params, as, options }: any) {
  if (typeof params === 'string') {
    Logging.log({
      text: `Navigating to ${params}`,
      category: 'Routing'
    });
  } else {
    const { pathname, query } = params;
    const { path, ...queryParams } = query || {};
    const queryString = queryParams
      ? Object.entries(queryParams)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      : '';
    Logging.log({
      text: `Navigating to ${
        pathname ? `${pathname}${path ? `/${path}` : ''}${queryString.length > 0 ? `&${queryString}` : ''}` : params
      }`,
      category: 'Routing'
    });
  }
  yield Router.push(params, as, options);
}

export function* handleRedirect({ params, as }: any) {
  if (typeof params === 'string') {
    Logging.log({
      text: `Redirecting to ${params}`,
      category: 'Routing'
    });
  } else {
    Logging.log({
      text: `Redirecting to ${params.pathname ? `${params.pathname}/${params.query && params.query.path}` : params}`,
      category: 'Routing'
    });
  }
  yield Router.replace(params, as);
}

export interface InitializePathType extends BaseAction {
  type: RoutingActions.INITIALIZE_PATH;
  payload: string;
}

export const initializePath: (payload: string) => InitializePathType = (payload: string) => ({
  type: RoutingActions.INITIALIZE_PATH,
  payload
});

export const INITIAL_PATH_STATE: string = '';

export const pathReducer = (state: string = INITIAL_PATH_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (action.payload?.app?.path !== state) {
        return action.payload?.app?.path || state;
      }
      return state;
    case RoutingActions.INITIALIZE_PATH:
      return action.payload;
    default:
      return state;
  }
};
