import { takeLatest, put, call } from 'redux-saga/effects';
import { ToursApi, TourAvailabilityParams } from '@model/iceberg/service/tours';
import { TourAvailabilityList, TourAvailabilityResult } from '@model/tours';
import { BaseAction } from '@model/redux';

export enum TourAvailabilityActions {
  FETCH_TOUR_AVAILABILITY = '@TOURS/FETCH_TOUR_AVAILABILITY',
  RECEIVE_TOUR_AVAILABILITY_SUCCESS = '@TOURS/RECEIVE_TOUR_AVAILABILITY_SUCCESS',
  RECEIVE_TOUR_AVAILABILITY_FAILURE = '@TOURS/RECEIVE_TOUR_AVAILABILITY_FAILURE'
}

export interface TourAvailabilityState {
  data: TourAvailabilityList | null;
  loading: boolean;
  error: string | null;
}

export const INITIAL_TOUR_AVAILABILITY_STATE: TourAvailabilityState = {
  data: null,
  loading: false,
  error: null
};

export interface FetchTourAvailabilityAction extends BaseAction {
  type: TourAvailabilityActions.FETCH_TOUR_AVAILABILITY;
  payload: TourAvailabilityParams;
}

export const fetchTourAvailability: (params: TourAvailabilityParams) => FetchTourAvailabilityAction = (
  params: TourAvailabilityParams
) => ({
  type: TourAvailabilityActions.FETCH_TOUR_AVAILABILITY,
  payload: params
});

export interface ReceiveTourAvailabilitySuccessAction {
  type: TourAvailabilityActions.RECEIVE_TOUR_AVAILABILITY_SUCCESS;
  data: TourAvailabilityList;
}

export const receiveTourAvailabilitySuccess: (data: TourAvailabilityList) => ReceiveTourAvailabilitySuccessAction = (
  data: TourAvailabilityList
) => ({
  type: TourAvailabilityActions.RECEIVE_TOUR_AVAILABILITY_SUCCESS,
  data
});

export interface ReceiveTourAvailabilityFailureAction {
  type: TourAvailabilityActions.RECEIVE_TOUR_AVAILABILITY_FAILURE;
  error: boolean;
}

export const receiveTourAvailabilityFailure: () => ReceiveTourAvailabilityFailureAction = () => ({
  type: TourAvailabilityActions.RECEIVE_TOUR_AVAILABILITY_FAILURE,
  error: true
});

export function* onFetchTourAvailability() {
  yield takeLatest(TourAvailabilityActions.FETCH_TOUR_AVAILABILITY, performFetchTourAvailability);
}

export function callGetTourAvailability(payload: TourAvailabilityParams) {
  const api = new ToursApi();
  return api
    .getTourAvailability(payload)
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* performGetTourAvailability(payload: TourAvailabilityParams) {
  const { response } = yield call(callGetTourAvailability, payload);
  if (response) {
    if (response.data) {
      const { availability = {}, cheapest } = response?.data || {};
      const availabilityArray: Array<TourAvailabilityResult> = Object.values(availability);

      yield put(
        receiveTourAvailabilitySuccess({
          availability: availabilityArray,
          cheapest
        })
      );
      return response.data;
    } else {
      yield put(receiveTourAvailabilityFailure());
      return null;
    }
  } else {
    yield put(receiveTourAvailabilityFailure());
    return null;
  }
}

export function* performFetchTourAvailability({ payload }: any) {
  yield performGetTourAvailability(payload);
}

export const tourAvailabilityReducer: any = (state: any = INITIAL_TOUR_AVAILABILITY_STATE, { type, data }: any) => {
  switch (type) {
    case TourAvailabilityActions.FETCH_TOUR_AVAILABILITY:
      return { ...state, data: null, loading: true };
    case TourAvailabilityActions.RECEIVE_TOUR_AVAILABILITY_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case TourAvailabilityActions.RECEIVE_TOUR_AVAILABILITY_FAILURE:
      return { ...state, data: INITIAL_TOUR_AVAILABILITY_STATE.data, error: true, loading: false };
    default:
      return state;
  }
};
