import { PersonTitle, RadioToggleOptions } from './generic-forms';

/* *** MODELS *** */
export interface OrderBrochureFormValues {
  title: PersonTitle | '';
  firstNames: string;
  lastName: string;
  address: string;
  address2: string;
  townCity: string;
  county: string;
  postCode: string;
  email: string;
  contactByPost: RadioToggleOptions;
  contactByEmail: RadioToggleOptions;
}

/* *** INITIAL VALUES *** */
export const orderBrochureInitialValues: OrderBrochureFormValues = {
  title: '',
  firstNames: '',
  lastName: '',
  address: '',
  address2: '',
  townCity: '',
  county: '',
  postCode: '',
  email: '',
  contactByPost: RadioToggleOptions.NO,
  contactByEmail: RadioToggleOptions.NO
};
