import Router from 'next/router';
import * as Sentry from '@sentry/browser';
import { OnInit } from '@model/bootstrap/on-init';
import { isServer } from '@util/is-server';
import { Uuid } from '@model/bootstrap/uuid-bootstrap';

export class SentryHistory implements OnInit {
  history: Array<string> = [];
  initialize(): void {
    if (!isServer) {
      this.history.push(window.location.pathname);
    }
    if (Router.events) {
      Router.events.on('routeChangeComplete', (url: string) => {
        const prev = this.history.slice().pop();
        if (prev !== url) {
          this.history.push(url);
        }
      });
    }
  }
  get(): Array<string> {
    return this.history.slice();
  }
}

const history = new SentryHistory();
history.initialize();

export const sentryException = (msg: string) => {
  if (!isServer) {
    const uuid = new Uuid().getUuid();
    const pageHistory = history.get();
    const error = `${msg}; url=${window.location.href};${uuid && ` uuid=${uuid};`}${
      document.referrer && ` referrer=${document.referrer};`
    }${pageHistory.length > 1 ? ` previous=${pageHistory.pop()};` : ' no history;'}`;
    Sentry.captureException(new Error(error));
  } else {
    Sentry.captureException(new Error(msg));
  }
};
