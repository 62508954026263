import { BaseAction } from '@model/redux';

export enum ShareLinkActions {
  SET_SHARE_LINK = '@APP/SET_SHARE_LINK'
}

export interface SetShareLinkAction extends BaseAction {
  type: ShareLinkActions.SET_SHARE_LINK;
  payload: string;
}

export const setShareLink: (payload: string) => SetShareLinkAction = (payload: string) => ({
  type: ShareLinkActions.SET_SHARE_LINK,
  payload
});

export const INITIAL_SHARE_LINK_STATE: string = '';

export const shareLinkReducer = (state: string = INITIAL_SHARE_LINK_STATE, action: any) => {
  switch (action.type) {
    case ShareLinkActions.SET_SHARE_LINK:
      return action.payload;
    default:
      return state;
  }
};
