import React from 'react';

const AnchorIcon = ({ fillColor, viewBoxSize, ...props }) => (
  <>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBoxSize || '0 0 32 32'} {...props}>
      <g
        fill={fillColor || 'currentColor'}
        stroke={fillColor || 'currentColor'}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M28.999 19.095l-1.136 7.729-2.299-2.23c-1.821 1.539-5.198 3.633-10.104 3.633-4.935 0-8.348-2.043-10.164-3.507l-2.098 2.035-1.198-7.659 7.893 1.162-2.175 2.111c1.526 1.133 3.92 2.499 6.304 2.499l-0.007-12.609c-1.741-0.606-2.994-2.207-2.994-4.105 0-2.418 2.019-4.378 4.51-4.378 2.49 0 4.51 1.96 4.51 4.378 0 1.927-1.292 3.545-3.074 4.129 0.011 2.46 0.051 12.632-0.004 12.632 2.28 0 4.634-1.484 6.167-2.684l-2.032-1.973 7.901-1.163zM17.856 8.083c0-1.246-1.041-2.257-2.325-2.257s-2.326 1.011-2.326 2.257c0 1.247 1.042 2.258 2.326 2.258s2.325-1.011 2.325-2.258z"></path>
      </g>
    </svg>
  </>
);

export default AnchorIcon;
