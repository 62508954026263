import { LogType } from '@model/common/logging';
import { isServer } from '@util/is-server';
import { isDev } from './common';
import moment from 'moment';

export const log = ({
  statusCode,
  url,
  text,
  logType,
  data
}: {
  statusCode?: number | string;
  url?: string;
  text?: string;
  logType: LogType;
  data?: any;
}) => {
  if (isDev) {
    console[logType](
      `[${logType.toUpperCase()}] [${new Date()}] ${statusCode ? ` [${statusCode}]` : ''}${url ? ` [${url}]` : ''} ${
        text ? ` ${text}` : ''
      }`,
      !isServer ? data : ''
    );
  }
};

export interface LoggingParams {
  statusCode?: string | number;
  url?: string;
  text?: string;
  data?: any;
  category?: string;
}

const colorMap = {
  [LogType.LOG]: 'green',
  [LogType.WARN]: 'black',
  [LogType.ERROR]: 'black',
  [LogType.INFO]: 'blue'
};

export class Logging {
  public static log(params: LoggingParams) {
    this.print(params, LogType.LOG);
  }

  public static error(params: LoggingParams) {
    this.print(params, LogType.ERROR);
  }

  public static warn(params: LoggingParams) {
    this.print(params, LogType.WARN);
  }

  public static network(params: LoggingParams) {
    this.print({ ...params, category: 'Network' }, LogType.INFO);
  }

  private static print(params: LoggingParams, logType: LogType) {
    if (process.env.JEST_WORKER_ID && typeof jest !== 'undefined') {
      return;
    }
    const { url = '', statusCode = '', text = '', category, data } = params;
    const logTypeParam: string = `[${logType.toUpperCase()}] `;
    const categoryParam: string = category ? `[${category}] ` : ' ';
    const dateParam: string = `[${moment().format('ddd MMM YYYY HH:MM:SS')}] `;
    const statusCodeParam: string = statusCode ? `[${statusCode}] ` : ' ';
    const urlParam: string = url ? `[${url}] ` : ' ';
    const textParam: string = text ? ` ${text}` : '';
    const colorParam: string = !isServer ? '%c' : '';
    console[logType](
      `${colorParam}${logTypeParam}${dateParam}${categoryParam}${statusCodeParam}${urlParam}${textParam}`,
      !isServer ? `color: ${colorMap[logType]};` : '',
      (!isServer || logType === LogType.ERROR) && !!data ? data : ''
    );
  }
}
