import { ChildDOB } from '../rooms/child-dob';
import { Guests } from '../rooms/guests';
import { Rooms } from '../rooms/rooms';
import { Child } from '@model/guests/child';

export const hasChildrenWithErrors = (rooms: Rooms) =>
  rooms.some(
    ({ children }: Guests) =>
      children &&
      children.some(({ day, month, year }: ChildDOB) => {
        const child: Child = new Child(day, month, year);
        return child.isEmpty || child.isUnderAge || child.isOverAge || !child.isValid;
      })
  );

export const hasEmptyChildrenDOB = (rooms: Rooms) =>
  rooms.some(
    ({ children }: Guests) =>
      children &&
      children.some(({ day, month, year }: ChildDOB) => {
        const child: Child = new Child(day, month, year);
        return child.isEmpty;
      })
  );
export const hasChildrenWithTypoErrors = (rooms: Rooms) =>
  rooms.some(
    ({ children }: Guests) =>
      children &&
      children.some(({ day, month, year }) => {
        {
          const child: Child = new Child(day, month, year);
          if (child.isEmpty) return false;
          return child.isUnderAge || child.isOverAge || !child.isValid;
        }
      })
  );
