import React, { useEffect } from 'react';
import { legacy_createStore as createStore } from 'redux';
import { Provider, useSelector } from 'react-redux';
import { getCMSAppVariantId } from '@state/cms';
import { INITIAL_GLOBAL_APP_STATE, rootReducer } from '@state/store';
import { WithT } from '@components/hoc';
import { Error404 } from '@components/common/hero/error/Error404';
import { Error500 } from '@components/common/hero/error/Error500';
import { ThemeProvider, Global } from '@emotion/react';
import { MuiTheme } from '@theme/mercuryHolidaysTheme';
import { CssBaseline } from '@material-ui/core';
import { globalStyles } from '@theme/base';
import { Error401 } from '@components/common/hero/error/Error401';
import { withIsServer } from '@components/hoc';
import { GtmBootstrap } from '@model/bootstrap/gtm-bootstrap';
import locales from '../public/assets/locales/locales.json';
import { ErrorPageProps } from '@model/common/error-codes';

const ErrorMapping = {
  '401': Error401,
  '404': Error404,
  '500': Error500
};

const ErrorWrapper = ({ statusCode, code }: any) => {
  const store = createStore(rootReducer, INITIAL_GLOBAL_APP_STATE);
  return (
    <Provider store={store}>
      <Error statusCode={statusCode} code={code} />
    </Provider>
  );
};

export const Error = ({ statusCode, code, isServer }: ErrorPageProps) => {
  const ErrorComponent = (statusCode && ErrorMapping[statusCode]) || Error500;
  const appVariantId = useSelector(getCMSAppVariantId);
  const localisations = { ...locales.labels, ...locales[appVariantId] };

  useEffect(() => {
    if (!isServer) {
      new GtmBootstrap().initialize();
    }
  }, []);
  return (
    <ThemeProvider theme={MuiTheme}>
      <WithT localeLabels={localisations}>
        <Global styles={globalStyles(MuiTheme)} />
        <CssBaseline />
        <ErrorComponent code={code} />
      </WithT>
    </ThemeProvider>
  );
};

ErrorWrapper.getInitialProps = ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  return { statusCode };
};

export default withIsServer(ErrorWrapper);
