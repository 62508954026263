import { isEqual } from 'lodash';
import { FormikValues } from 'formik';
import { RadioToggleOptions } from '../generic-forms';
import { Validation } from '@model/forms/validation/validation';

export const validateOrderBrochureForm = (values: FormikValues) => {
  const errors: any = {};

  const validFormErrors = {};

  if (!values.title) {
    errors.title = 'form__field-error--required';
  }

  if (!values.firstNames) {
    errors.firstNames = 'form__field-error--required';
  }

  if (!values.lastName) {
    errors.lastName = 'form__field-error--required';
  }

  if (values.contactByEmail === RadioToggleOptions.YES) {
    if (!values.email) {
      errors.email = 'form__field-error--required';
    } else if (!Validation.isEmailValid(values.email)) {
      errors.email = 'form__field-error--email';
    }
  }

  if (!values.address) {
    errors.address = 'form__field-error--required';
  }

  if (!values.townCity) {
    errors.townCity = 'form__field-error--required';
  }

  if (!values.postCode) {
    errors.postCode = 'form__field-error--required';
  }

  const isValid = isEqual(validFormErrors, errors);

  return { errors, isValid };
};
