import Link from 'next/link';
import { Page } from '@model/common';
import { TestId } from '@components/test-ids';
import React from 'react';
import { mixins } from '@styles/mixins';
import { breakpoints, mq } from '@styles/breakpoints';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { getImageUrl } from '@util/images';
import { useI18NextContext } from '@components/hooks';

const LogoContainer: any = styled.span({});

const StyledLink: any = styled(Link)({
  position: 'absolute',
  left: 0,
  right: 0,
  [mq.medium]: {
    position: 'relative'
  }
});

const MercuryHolidaysLogo: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: 30,
  margin: `${theme.custom.spacing.xSmall}px 10% 0 10%`,
  cursor: 'pointer',
  ...mixins(theme).defaultDropShadow.filter,
  ['& > img']: {
    maxWidth: 200,
    width: '100%',

    [mq.small]: {
      maxWidth: 245
    }
  },

  [mq.medium]: {
    margin: `${theme.custom.spacing.xSmall}px 0 0 0`,
    maxWidth: 245,
    flex: 'none',
    maxHeight: 30
  }
}));

const LogoImage: any = styled.img({
  flex: '1 0 auto'
});

export interface LogoProps {
  className?: string;
  disableLink?: boolean;
  logoDarkUrl: string;
}

export const Logo = ({ className, disableLink, logoDarkUrl }: LogoProps) => {
  const url: string = logoDarkUrl;
  const t = useI18NextContext();

  if (!url) return null;

  const renderLogo = () => (
    <LogoContainer className={className}>
      <MercuryHolidaysLogo data-testid={TestId.navBar.logo}>
        <LogoImage
          src={getImageUrl(url, breakpoints.medium, true)}
          alt={t('common__brand_logo_alt')}
          width={200}
          height={30}
        />
      </MercuryHolidaysLogo>
    </LogoContainer>
  );

  return disableLink ? (
    renderLogo()
  ) : (
    <StyledLink prefetch={false} href={Page.HOME} passHref>
      {renderLogo()}
    </StyledLink>
  );
};
