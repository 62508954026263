import { putResolve, takeEvery } from 'redux-saga/effects';
import { fetchBasket } from '@state/basket';
import { BookingApi, PromoCodeResponse } from '@model/iceberg';
import { PromoCodeState } from '@model/state/promo-code-state';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';

export enum PromoCodeActions {
  ON_SET_PROMO_CODE_INPUT = '@PROMO_CODE/ON_SET_PROMO_CODE_INPUT',
  ON_SET_PROMO_CODE_ERROR = '@PROMO_CODE/ON_SET_PROMO_CODE_ERROR',
  ON_SUBMIT = '@PROMO_CODE/ON_SUBMIT'
}

export const INITIAL_PROMO_CODE_STATE: PromoCodeState = {
  input: '',
  error: false
};

export const onSetPromoCodeInput = (input: string) => ({ type: PromoCodeActions.ON_SET_PROMO_CODE_INPUT, input });
export const onSetPromoCodeError = (error: boolean) => ({ type: PromoCodeActions.ON_SET_PROMO_CODE_ERROR, error });
export const onSubmitPromoCode = (token: string, input: string) => ({ type: PromoCodeActions.ON_SUBMIT, token, input });

export function* onPromoCodeSubmit() {
  yield takeEvery(PromoCodeActions.ON_SUBMIT, handleOnPromoCodeSubmit);
}

export function* handleOnPromoCodeSubmit({ token, input }: any) {
  if (input) {
    try {
      const response: PromoCodeResponse = yield new BookingApi().promoCode(token, input);
      if (!response.data.valid) {
        yield putResolve(onSetPromoCodeError(true));
      } else {
        yield putResolve(onSetPromoCodeError(false));
        yield putResolve(fetchBasket(token));
      }
    } catch (e) {
      yield putResolve(onSetPromoCodeError(true));
    }
  }
}

export const promoCodeReducer: any = (state: PromoCodeState = INITIAL_PROMO_CODE_STATE, action: any) => {
  switch (action.type) {
    case PromoCodeActions.ON_SET_PROMO_CODE_INPUT:
      return { ...state, input: action.input };
    case PromoCodeActions.ON_SUBMIT:
      return {
        ...state,
        input: ''
      };
    case PromoCodeActions.ON_SET_PROMO_CODE_ERROR:
      return {
        ...state,
        error: action.error
      };
    case DealFinderResultsActions.CLEAR_RESULTS:
      return INITIAL_PROMO_CODE_STATE;
    default:
      return state;
  }
};
