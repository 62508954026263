import styled from '@emotion/styled';
import { TextMarkdownComponent } from '@components/common/description';
import { ExpandableListGroupItem, SpecialOfferItem } from '@model/common';
import React from 'react';
import { Accordion } from '@components/material-ui/expandable-list-item/Accordion';
import { ThemeProps } from '@theme/base';

const Text = styled(TextMarkdownComponent)(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium
}));

const TermText = styled.p(({ theme }: ThemeProps) => ({
  fontSize: '1.4rem',
  padding: `0 ${theme.custom.spacing.medium}px`,
  margin: `0 0 ${theme.custom.spacing.medium}px`
}));

export interface AccordionProps {
  items: Array<ExpandableListGroupItem>;
}

const renderItemTitle = (item: ExpandableListGroupItem): JSX.Element => {
  return item.icon ? (
    <>
      <>{item.icon}</> <>{item.title}</>
    </>
  ) : (
    <>{item.title}</>
  );
};

const renderItemText = (textItem: any): JSX.Element => {
  const isSpecialOffer: SpecialOfferItem = textItem.description && textItem.terms;
  return isSpecialOffer ? (
    <>
      <Text description={textItem.description} />
      <TermText>{textItem.terms}</TermText>
    </>
  ) : (
    <Text description={textItem} />
  );
};

export const ExpandableListItem = ({ items }: AccordionProps) => {
  const filteredItems: Array<ExpandableListGroupItem> = items.filter(
    (item: ExpandableListGroupItem) => !!item.text && !!item.title
  );
  return (
    <Accordion
      items={filteredItems}
      renderTitle={(item: ExpandableListGroupItem) => renderItemTitle(item)}
      renderItem={(item: ExpandableListGroupItem) => renderItemText(item.text)}
    />
  );
};
