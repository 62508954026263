import { BasketResponse, Luggage } from '@model/iceberg';
import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { BookingEndpoints } from '@model/service/booking-endpoints';

export class LuggageApi {
  private api: Request = new Request();
  public async put(token: string, luggageToken: string, count: number): Promise<BasketResponse> {
    return await this.api
      .put(
        uri(getURL())
          .path(
            `${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.LUGGAGE}/${luggageToken}/${count}`
          )
          .href()
      )
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  public async delete(token: string, luggage: Array<Luggage>): Promise<Array<BasketResponse>> {
    const requests: Array<Promise<BasketResponse>> = luggage.map(
      async (item: Luggage) =>
        await this.api
          .put(
            uri(getURL())
              .path(
                `${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.LUGGAGE}/${item.token}/${0}`
              )
              .href()
          )
          .catch((e) => {
            return Promise.reject(e);
          })
    );
    return Promise.all(requests);
  }
}
