import { Filter, FilterOption, FilterType } from '@model/iceberg';
import { FilterQueryFactory } from '@model/iceberg/filters/deal-finder/filter-query-factory';

export class FiltersAdapter {
  static getQuery(filters: Array<Filter>): Record<string, string> {
    return FiltersAdapter.getFilters(filters);
  }

  private static getFilters(filters: Array<Filter>): Record<string, string> {
    return filters.reduce((query: Record<string, string>, { type, name, values }: Filter) => {
      const param: Record<string, string> = FilterQueryFactory.getFactory(type).getQuery({ name, values });
      if (!query) {
        return param;
      } else if (param) {
        return { ...query, ...param };
      }
      return query;
    }, {});
  }

  static hasAppliedFilters(filters: Array<Filter>): boolean {
    return filters.some((filter: Filter) => {
      switch (filter.type) {
        case FilterType.CHECKBOX:
        case FilterType.STARS:
        case FilterType.RADIO:
          return filter.values.some((f: FilterOption) => !!f.selected);
        case FilterType.SORT:
          return false;
        case FilterType.PRICE:
          return filter.values.some((f: FilterOption) => f.selected && f.selected !== f.value);
        case FilterType.TOGGLE:
          return filter.values.some((f: FilterOption) => !!f.value && !!f.selected);
        default:
          return false;
      }
    }, false);
  }
}
