import { put, takeLatest } from 'redux-saga/effects';
import { EarlyFlight, EarlyFlightResponse } from '@model/flights';
import { FlightApi } from '@model/iceberg/service/booking/flight-api';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { PackageReferencesActions } from '@state/app/package-references/packageReferencesOperations';
import { BasketActions } from '@state/basket';
import { Logging } from '@util/logging';

export enum EarlyFlightsActions {
  FETCH_EARLY_FLIGHTS = '@EARLY_FLIGHTS/FETCH_EARLY_FLIGHTS',
  RECEIVE_EARLY_FLIGHTS_SUCCESS = '@EARLY_FLIGHTS/RECEIVE_EARLY_FLIGHTS_SUCCESS',
  RECEIVE_EARLY_FLIGHTS_FAILURE = '@EARLY_FLIGHTS/RECEIVE_EARLY_FLIGHTS_FAILURE',
  APPLY_EARLY_FLIGHTS = '@EARLY_FLIGHTS/APPLY_EARLY_FLIGHTS',
  APPLY_EARLY_FLIGHTS_SUCCESS = '@EARLY_FLIGHTS/APPLY_EARLY_FLIGHTS_SUCCESS',
  APPLY_EARLY_FLIGHTS_FAILURE = '@EARLY_FLIGHTS/APPLY_EARLY_FLIGHTS_FAILURE'
}

export interface EarlyFlightState {
  data: Array<EarlyFlight> | null;
  token: string | null;
  loading: boolean;
  error: string | null;
}

export const INITIAL_EARLY_FLIGHTS_STATE: EarlyFlightState = {
  data: null,
  token: null,
  loading: false,
  error: null
};

export const fetchEarlyFlights = (searchToken: string) => ({
  type: EarlyFlightsActions.FETCH_EARLY_FLIGHTS,
  searchToken
});

export const receiveEarlyFlightsSuccess = (data: Array<EarlyFlight>) => ({
  type: EarlyFlightsActions.RECEIVE_EARLY_FLIGHTS_SUCCESS,
  data
});

export const receiveEarlyFlightsFailure = () => ({
  type: EarlyFlightsActions.RECEIVE_EARLY_FLIGHTS_FAILURE
});

export function* onFetchEarlyFlights() {
  yield takeLatest(EarlyFlightsActions.FETCH_EARLY_FLIGHTS, performFetchEarlyFlights);
}

export interface PerformFetchEarlyFlightsParams {
  searchToken: string;
}

export function* performFetchEarlyFlights({ searchToken }: any) {
  const api: FlightApi = new FlightApi();
  try {
    const earlyFlights: EarlyFlightResponse = yield api.fetchEarlyFlights(searchToken);
    yield put(receiveEarlyFlightsSuccess(earlyFlights.data));
  } catch (e) {
    yield put(receiveEarlyFlightsFailure());
  }
}

export interface ApplyEarlyFlightsParams {
  searchToken: string;
  roomTokens: Array<string>;
  flightToken: string;
}

export const applyEarlyFlights = (payload: ApplyEarlyFlightsParams) => ({
  type: EarlyFlightsActions.APPLY_EARLY_FLIGHTS,
  payload
});

export function* onApplyEarlyFlights() {
  yield takeLatest(EarlyFlightsActions.APPLY_EARLY_FLIGHTS, performApplyEarlyFlights);
}

export function* performApplyEarlyFlights({ payload: { searchToken, roomTokens, flightToken } }: any) {
  const api: FlightApi = new FlightApi();
  try {
    yield api.applyEarlyFlights(searchToken, roomTokens);
    yield put(setEarlyFlightsToken(flightToken));
  } catch (e) {
    Logging.error({ text: `api error ${e} applying early flights ${searchToken} : ${roomTokens.join(', ')}` });
    yield put(applyEarlyFlightsFailure());
  }
}

export const setEarlyFlightsToken = (payload: string) => ({
  type: EarlyFlightsActions.APPLY_EARLY_FLIGHTS_SUCCESS,
  payload
});

export const applyEarlyFlightsFailure = () => ({
  type: EarlyFlightsActions.APPLY_EARLY_FLIGHTS_FAILURE
});

export const earlyFlightsReducer: any = (state: any = INITIAL_EARLY_FLIGHTS_STATE, { type, data, payload }: any) => {
  switch (type) {
    case EarlyFlightsActions.RECEIVE_EARLY_FLIGHTS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case EarlyFlightsActions.RECEIVE_EARLY_FLIGHTS_FAILURE:
      return { ...state, data: INITIAL_EARLY_FLIGHTS_STATE.data, error: true, loading: false };
    case EarlyFlightsActions.FETCH_EARLY_FLIGHTS:
      return { ...state, data: INITIAL_EARLY_FLIGHTS_STATE.data, error: null, token: null, loading: true };
    case EarlyFlightsActions.APPLY_EARLY_FLIGHTS_SUCCESS:
      return { ...state, token: payload };
    case EarlyFlightsActions.APPLY_EARLY_FLIGHTS:
    case DealFinderResultsActions.CLEAR_RESULTS:
    case BasketActions.ADD_FLIGHT_TO_BASKET:
    case BasketActions.PREBOOK_FLIGHT_TO_BASKET:
    case PackageReferencesActions.SET_PACKAGE_REFERENCES:
      return { ...INITIAL_EARLY_FLIGHTS_STATE, token: state.token };
    default:
      return state;
  }
};
