import { Currency as CurrencyCode } from 'dinero.js';

export enum CurrencyLocaleMapping {
  'GBP' = 'en-GB',
  'EUR' = 'en-IE'
}

export enum CurrencySymbolMapping {
  'GBP' = '£',
  'EUR' = '€'
}

export const DEFAULT_CURRENCY: CurrencyCode = 'GBP';
export const DEFAULT_CURRENCY_LOCALE: string = CurrencyLocaleMapping[DEFAULT_CURRENCY];
export const DEFAULT_CURRENCY_SYMBOL: string = CurrencySymbolMapping[DEFAULT_CURRENCY];
export const DEFAULT_PRICE_OBJECT = {
  currency: {
    code: DEFAULT_CURRENCY,
    symbol: DEFAULT_CURRENCY_SYMBOL
  }
};

export interface Currency {
  code: CurrencyCode;
  symbol: string;
}
