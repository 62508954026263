import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { HotelContent } from '@model/iceberg/hotel-content/hotel-content';

export enum HotelActions {
  SET_HOTEL_PATH = '@HOTEL/SET_HOTEL_PATH'
}

export interface HotelState {
  path: string;
}

export const INITIAL_HOTEL_STATE: HotelState = {
  path: ''
};

export interface SetHotelProps {
  data?: HotelContent;
  shouldSendDataLayer: boolean;
}

export const setHotelPath = (payload: SetHotelProps) => ({
  type: HotelActions.SET_HOTEL_PATH,
  payload
});

export const hotelReducer: any = (state: any = INITIAL_HOTEL_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, INITIAL_HOTEL_STATE)) {
        const path = action.payload.hotel?.path;
        return path ? { path } : state;
      }
      return state;
    case HotelActions.SET_HOTEL_PATH: {
      const path = action.payload.data?.place.hotel?.path || INITIAL_HOTEL_STATE.path;
      return { path };
    }
    default:
      return state;
  }
};
