import { Keyable } from '@model/common';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AsyncData } from '@model/common';
import { DataEnvelope } from '@model/iceberg';
import { BaseAction } from '@model/redux';
import { MyBookingDocument, MyBookingDocuments } from '@model/mmb';
import { MyBookingDocumentsApi } from '@model/iceberg/service/mmb/my-booking-documents-api';

export enum MyBookingDocumentsActions {
  PERFORM_FETCH_BOOKING_DOCUMENTS = '@MMBDOCUMENTS/PERFORM_FETCH_BOOKING_DOCUMENTS',
  RECEIVE_BOOKING_DOCUMENTS_SUCCESS = '@MMBDOCUMENTS/RECEIVE_BOOKING_DOCUMENTS_SUCCESS',
  RECEIVE_BOOKING_DOCUMENTS_FAILURE = '@MMBDOCUMENTS/RECEIVE_BOOKING_DOCUMENTS_FAILURE'
}

export type MMBDocumentsState = Array<MyBookingDocument>;

export type MyBookingDocumentsResponse = DataEnvelope<MyBookingDocuments>;

export interface ErrorDetails {
  status: number | string;
  data?: Keyable;
}

export interface MyBookingDocumentsRequestPayload {
  bookingId: number;
  loginToken: string;
}

export const INITIAL_BOOKING_DOCUMENTS_STATE: AsyncData<Array<MyBookingDocument>> = {
  data: [] as any,
  loading: false,
  error: null
};

export interface RequestFAQ extends BaseAction {
  type: MyBookingDocumentsActions.PERFORM_FETCH_BOOKING_DOCUMENTS;
  payload: MyBookingDocumentsRequestPayload;
}

export const performFetchBookingDocuments: (payload: MyBookingDocumentsRequestPayload) => RequestFAQ = (
  payload: MyBookingDocumentsRequestPayload
) => {
  return {
    type: MyBookingDocumentsActions.PERFORM_FETCH_BOOKING_DOCUMENTS,
    payload
  };
};

export interface RequestFetchBookingDocumentsSuccess extends BaseAction {
  type: MyBookingDocumentsActions.RECEIVE_BOOKING_DOCUMENTS_SUCCESS;
  payload: Array<MyBookingDocument>;
}

export const receiveFetchBookingDocumentsSuccess: (
  payload: Array<MyBookingDocument>
) => RequestFetchBookingDocumentsSuccess = (payload: Array<MyBookingDocument>) => {
  return {
    type: MyBookingDocumentsActions.RECEIVE_BOOKING_DOCUMENTS_SUCCESS,
    payload
  };
};

interface BookingDocumentsError {
  type: MyBookingDocumentsActions.RECEIVE_BOOKING_DOCUMENTS_FAILURE;
  payload?: ErrorDetails;
}

export const receiveBookingDocumentsFailure: (payload?: ErrorDetails) => BookingDocumentsError = (
  payload?: ErrorDetails
) => {
  return {
    type: MyBookingDocumentsActions.RECEIVE_BOOKING_DOCUMENTS_FAILURE,
    payload
  };
};

export function* onFetchBookingDocuments() {
  yield takeLatest(MyBookingDocumentsActions.PERFORM_FETCH_BOOKING_DOCUMENTS, handleOnFetchBookingDocuments);
}

export function* handleOnFetchBookingDocuments({ payload }: any) {
  const { response, error } = yield call(fetchBookingDocuments, payload);

  if (response) {
    yield put(receiveFetchBookingDocumentsSuccess(response.data.documents));
  }

  if (error) {
    const {
      response: { status, data }
    } = error;

    yield put(receiveBookingDocumentsFailure({ status, data }));
  }
}

export async function fetchBookingDocuments(payload: MyBookingDocumentsRequestPayload) {
  const api: MyBookingDocumentsApi = new MyBookingDocumentsApi();
  try {
    const response = await api.getBookingDocuments(payload);
    return { response };
  } catch (error) {
    return { error };
  }
}

export const mmbDocumentsReducer: any = (
  state: any = INITIAL_BOOKING_DOCUMENTS_STATE,
  { type, error, payload }: any
) => {
  switch (type) {
    case MyBookingDocumentsActions.RECEIVE_BOOKING_DOCUMENTS_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case MyBookingDocumentsActions.RECEIVE_BOOKING_DOCUMENTS_FAILURE:
      return { ...state, data: INITIAL_BOOKING_DOCUMENTS_STATE.data, error, loading: false };
    case MyBookingDocumentsActions.PERFORM_FETCH_BOOKING_DOCUMENTS:
      return { ...state, loading: true };
    default:
      return state;
  }
};
