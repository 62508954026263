import { DealFinderState, Occupancy, Destination, GlobalAppState } from '@model/state';
import { isEqual, values } from 'lodash';
import { ChildDOB, Guests } from '@model/search';
import { Child } from '@model/guests/child';
import { DateRange, ISO_8601_DATE_FORMAT } from '@model/common';
import moment from 'moment';
import { createSelector } from 'reselect';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { hasChildrenWithErrors } from '@model/search/fit/validation/room-validation';
import { FILTER_DEFAULTS, MAXIMUM_DESTINATIONS } from '@model/price-calendar';

export const getDealFinderState = (state: GlobalAppState) => state.dealFinder;
export const getDealFinderAirports = (state: GlobalAppState) => state.dealFinder.airports;
export const getDealFinderOccupancy = (state: GlobalAppState) => state.dealFinder.occupancy;
export const getDealFinderBoardBasis = (state: GlobalAppState) => state.dealFinder.preferredBoardBasis;
export const getHasMaximumDestinations = (state: GlobalAppState) =>
  state.dealFinder.destinations.length >= MAXIMUM_DESTINATIONS;
export const getSearchType = (state: GlobalAppState) => state.dealFinder.searchType;
export const getHotelOnlySearch = (state: GlobalAppState) => state.dealFinder.searchType === SearchType.HOTEL_ONLY;
export const getRestoreState = (state: GlobalAppState) => state.dealFinder.restoreState;

export const getDealFinderErrors = (dealFinderState: DealFinderState) => {
  const { airports, destinations, date, month, occupancy, searchType } = dealFinderState;
  const hasDate: boolean = !!date || !!month;
  const hasOccupancy: boolean = !hasChildrenWithErrors(occupancy);
  const isHotelOnly: boolean = searchType === SearchType.HOTEL_ONLY;
  return {
    airports: !isHotelOnly && !airports.length,
    destinations: !destinations.length,
    dates: !hasDate,
    occupancy: !hasOccupancy
  };
};
export const validateDealFinderState = (dealFinderState: DealFinderState) => {
  const errors = getDealFinderErrors(dealFinderState);
  return values(errors).every((error) => !error);
};

export const getDealFinderFormValid = (state: GlobalAppState) => {
  return validateDealFinderState(state.dealFinder);
};

export const getDealFinderAvailableAirports = createSelector(getDealFinderState, (dealFinder: DealFinderState) => {
  const availableAirports = dealFinder.destinations.reduce(
    (airports: Array<string>, destination: Destination) => airports.concat(destination.airports || []),
    []
  );
  return Array.from(new Set(availableAirports));
});

export const getDealFinderHotelLandingSearch = (state: GlobalAppState) => state.dealFinder.hotelLandingSearch;

export const getDealFinderDestinationSearch = (state: GlobalAppState) => state.dealFinder.destinationSearch;

// Occupancy
export const isNotDefaultOccupancy = (occupancy: Array<Occupancy>) => !isEqual(occupancy, FILTER_DEFAULTS.occupancy);
export const getTotalGuests = (occupancy: Array<Occupancy>) =>
  occupancy.reduce((accumulator: any, guests: any) => {
    return accumulator + guests.adults + guests.children.length;
  }, 0);

export const getIsOccupancyClearable = (state: GlobalAppState) => isNotDefaultOccupancy(state.dealFinder.occupancy);

export const getNumberOfGuests = (state: GlobalAppState) => getTotalGuests(state.dealFinder.occupancy);

export const isChildValidAgeOnHoliday = (state: GlobalAppState) => {
  const dates: DateRange = {
    startDate: state.dealFinder.date,
    endDate: moment(state.dealFinder.date, ISO_8601_DATE_FORMAT).add(state.dealFinder.duration, 'days').toISOString()
  };
  return state.dealFinder.occupancy
    .reduce((acc: Array<ChildDOB>, room: Guests) => acc.concat(room.children), [])
    .filter(({ day, month, year }: ChildDOB) => {
      const child: Child = new Child(day, month, year);
      return !child.isEmpty && !child.isUnderAge && !child.isOverAge && child.isValid;
    })
    .every(({ day, month, year }: ChildDOB) => {
      const child: Child = new Child(day, month, year);
      return !child.isOverAgeOnDate(dates.endDate);
    });
};
