import { BasketActions } from './basketActions';
import { Basket } from '@model/iceberg';
import { AsyncData } from '@model/common';
import { HYDRATE } from 'next-redux-wrapper';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';

const INITIAL_BASKET: Basket = {
  hotel: null,
  hotels: null,
  flight: null,
  transfer: null,
  luggage: null,
  pricing: {
    perPerson: {
      amount: 0,
      currency: {
        code: 'GBP',
        symbol: '£'
      }
    },
    total: {
      amount: 0,
      currency: {
        code: 'GBP',
        symbol: '£'
      }
    },
    deposit: {
      amount: 0,
      currency: {
        code: 'GBP',
        symbol: '£'
      }
    },
    saving: {
      amount: 0,
      currency: {
        code: 'GBP',
        symbol: '£'
      }
    },
    fees: [],
    paymentsDue: [],
    paymentRequiredToday: {
      amount: 0,
      currency: {
        code: 'GBP',
        symbol: '£'
      }
    },
    trade: {
      commission: {
        amount: 0,
        currency: {
          code: 'GBP',
          symbol: '£'
        }
      },
      vatOnCommission: {
        amount: 0,
        currency: {
          code: 'GBP',
          symbol: '£'
        }
      }
    },
    preBookPriceChange: false
  },
  promoCode: '',
  confirmationEhId: '',
  expires: 0,
  bookingReference: ''
};

export const INITIAL_BASKET_STATE: AsyncData<Basket> = {
  data: INITIAL_BASKET,
  loading: false,
  error: null
};

export const basketReducer: any = (state: any = INITIAL_BASKET_STATE, { type, error, payload }: any) => {
  switch (type) {
    case HYDRATE:
      return payload.basket?.data?.hotel ? payload.basket : state;
    case BasketActions.RECEIVE_ADD_TO_BASKET_SUCCESS:
    case BasketActions.RECEIVE_BASKET_SUCCESS:
      return {
        ...state,
        data: { ...payload.basket, bookingReference: payload.bookingReference },
        error: null,
        loading: false
      };
    case BasketActions.RECEIVE_ADD_TO_BASKET_NOOP:
      return { ...state, loading: false };
    case BasketActions.RECEIVE_ADD_TO_BASKET_FAILURE:
    case BasketActions.RECEIVE_BASKET_FAILURE:
      return { ...state, error: error || payload, loading: false };
    case BasketActions.FETCH_BASKET:
    case BasketActions.PREBOOK_FLIGHT_TO_BASKET:
    case BasketActions.ADD_SUPPLEMENTS_TO_BASKET:
    case BasketActions.ADD_SPECIAL_REQUESTS_TO_BASKET:
    case BasketActions.ADD_TRANSFERS_TO_BASKET:
    case BasketActions.ADD_LUGGAGE_TO_BASKET:
    case BasketActions.ADD_FLIGHT_TO_BASKET:
    case BasketActions.ADD_ROOMS_TO_BASKET_AND_NAVIGATE:
    case BasketActions.ADD_ROOMS_TO_BASKET:
      return { ...state, loading: true };
    case DealFinderResultsActions.CLEAR_RESULTS:
      return INITIAL_BASKET_STATE;
    default:
      return state;
  }
};
