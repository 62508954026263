import { Basket, BasketResponse } from '@model/iceberg';
import { AsyncData } from '@model/common';
import { putResolve, select, takeLatest } from 'redux-saga/effects';
import { Extras } from '@model/iceberg/booking/extras';
import { BookingApi } from '@model/iceberg/service/booking';
import { BasketActions, getBasket, receiveBasketSuccess } from '@state/basket';
import { FlightApi } from '@model/iceberg/service/booking/flight-api';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { getErrorCode } from '@util/error';
import { TourReferencesActions } from '@state/tours/tour-references';

export enum ExtrasActions {
  FETCH_EXTRAS = '@EXTRAS/FETCH_EXTRAS',
  CLEAR_EXTRAS = '@EXTRAS/CLEAR_EXTRAS',
  RECEIVE_EXTRAS_SUCCESS = '@EXTRAS/RECEIVE_EXTRAS_SUCCESS',
  RECEIVE_EXTRAS_FAILURE = '@EXTRAS/RECEIVE_EXTRAS_FAILURE'
}

export const INITIAL_EXTRAS_STATE: AsyncData<Extras> = {
  data: {
    transfers: [],
    luggageExtras: [],
    optionalSupplements: []
  },
  loading: false,
  error: null
};

export const fetchExtras = (searchToken: string) => ({ type: ExtrasActions.FETCH_EXTRAS, searchToken });

export const clearExtras = () => ({ type: ExtrasActions.CLEAR_EXTRAS });

export const receiveExtrasSuccess = (data: Extras) => ({
  type: ExtrasActions.RECEIVE_EXTRAS_SUCCESS,
  data
});

export const receiveExtrasFailure = (error: string) => ({
  type: ExtrasActions.RECEIVE_EXTRAS_FAILURE,
  error
});

export function* onFetchExtras() {
  yield takeLatest(ExtrasActions.FETCH_EXTRAS, performFetchExtras);
}

export function* performFetchExtras({ searchToken }: any) {
  const bookingApi: BookingApi = new BookingApi();
  try {
    const response: Extras = yield bookingApi.getExtras(searchToken);
    yield putResolve(receiveExtrasSuccess(response));
  } catch (e: any) {
    yield putResolve(receiveExtrasFailure(getErrorCode(e)));
    if (e.errorCode === 400) {
      const basket: Basket = yield select(getBasket);
      const flight: string | undefined = basket?.flight?.token;
      if (flight) {
        const basketResponse: BasketResponse = yield new FlightApi().put(searchToken, flight, true);
        yield putResolve(receiveBasketSuccess(basketResponse));
        try {
          const response: Extras = yield bookingApi.getExtras(searchToken);
          yield putResolve(receiveExtrasSuccess(response));
        } catch (e: any) {
          yield putResolve(receiveExtrasFailure(getErrorCode(e)));
        }
      }
    }
  }
}

export const extrasReducer: any = (state: any = INITIAL_EXTRAS_STATE, { type, data, error }: any) => {
  switch (type) {
    case ExtrasActions.RECEIVE_EXTRAS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case ExtrasActions.RECEIVE_EXTRAS_FAILURE:
      return { ...state, data: INITIAL_EXTRAS_STATE.data, error, loading: false };
    case ExtrasActions.FETCH_EXTRAS:
      return { ...state, loading: true };
    case BasketActions.ADD_FLIGHT_TO_BASKET:
      return INITIAL_EXTRAS_STATE;
    case DealFinderResultsActions.CLEAR_RESULTS:
    case ExtrasActions.CLEAR_EXTRAS:
    case TourReferencesActions.SET_TOUR_REFERENCES:
    case TourReferencesActions.FROM_TOUR_REFERENCE_SUCCESS:
      return INITIAL_EXTRAS_STATE;
    default:
      return state;
  }
};
