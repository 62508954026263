export enum ZIndex {
  BASE = 0,
  CONTENT = 10,
  LOADING_OVERLAY = 30,
  PRODUCT_PRICE = 40,
  FILTERS_STICKY_BUTTON = 50,
  PROMO_BANNER = 70,
  SEARCH_DROPDOWN = 80,
  SEARCHABLE_DESTINATIONS_DROPDOWN = 90,
  NAVIGATION = 100,
  TAB_NAVIGATION = 110,
  BACKDROP = 120,
  AGENT_NAV = 130,
  LOADING_MODAL = 150,
  FILTERS_FIXED_HEADER = 160,
  LOADING_SPINNER = 200,
  COOKIES_BANNER = 1500,
  MOBILE_SEARCH = 1600,
  FULL_SCREEN_SPINNER = 2000,
  TOOLTIP = 1610,
  DEV_BANNER = 3000
}
