import React, { useEffect, useState } from 'react';

type ShadowInjected<T1, T2> = Omit<T1, keyof T2>;

interface HOCProps {
  isServer: boolean;
}

export const withIsServer =
  <T,>(WrappedComponent: React.ComponentType<T>): React.FC<ShadowInjected<T, HOCProps>> =>
  (props: ShadowInjected<T, HOCProps>) => {
    const [isServer, setIsServer] = useState(true);

    useEffect(() => {
      setIsServer(false);
    }, []);

    return <WrappedComponent {...(props as T)} isServer={isServer} />;
  };
