import { HYDRATE } from 'next-redux-wrapper';
import { BaseAction } from '@model/redux';
import { Footer, FooterTypes } from '@model/content';

/* ***************** *
 *       TYPES       *
 * ***************** */

export type FooterState = {
  main: Footer | null;
  checkout: Footer | null;
};

export interface SetFooterPayload {
  footerType: FooterTypes;
  footer: Footer;
}

/* ***************** *
 *   ACTION TYPES    *
 * ***************** */

export enum FooterActions {
  SET_FOOTER = '@CONTENT/SET_FOOTER'
}

/* ***************** *
 *     ACTIONS       *
 * ***************** */

export interface SetFooterAction extends BaseAction {
  type: FooterActions.SET_FOOTER;
  payload: SetFooterPayload;
}

export const setFooter: (payload: SetFooterPayload) => SetFooterAction = (payload: SetFooterPayload) => ({
  type: FooterActions.SET_FOOTER,
  payload
});

/* ***************** *
 *     REDUCER       *
 * ***************** */

export const INITIAL_FOOTER_STATE: FooterState = {
  main: null,
  checkout: null
};

export const footerReducer = (state: FooterState = INITIAL_FOOTER_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      if (payload.footer.main || payload.footer.checkout) {
        return payload.footer;
      }
      return state;
    case FooterActions.SET_FOOTER:
      return {
        ...state,
        [payload.footerType]: payload.footer
      };
    default:
      return state;
  }
};
