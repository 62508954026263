import { EnvConfig } from './env-config';
import { Environment } from './environment';

export enum ContentfulEnvironment {
  DEVELOP = 'develop',
  MASTER = 'master'
}

export class ContentfulEnv {
  public static getEnv(): ContentfulEnvironment {
    return EnvConfig.get().APP_ENV === Environment.PRODUCTION
      ? ContentfulEnvironment.MASTER
      : ContentfulEnvironment.DEVELOP;
  }
}
