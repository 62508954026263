import { HYDRATE } from 'next-redux-wrapper';
import { BaseAction } from '@model/redux';

export enum CMSConfigIdActions {
  SET_CMS_CONFIG_ID = '@APP/SET_CMS_CONFIG_ID'
}

export interface SetCMSConfigIdType extends BaseAction {
  type: CMSConfigIdActions.SET_CMS_CONFIG_ID;
  payload: string;
}

export const setCMSConfigId: (payload: string) => SetCMSConfigIdType = (payload: string) => ({
  type: CMSConfigIdActions.SET_CMS_CONFIG_ID,
  payload
});

export const INITIAL_CMS_CONFIG_ID_STATE: string = '';

export const cmsConfigIdReducer = (state: string = INITIAL_CMS_CONFIG_ID_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (action.payload?.app?.cmsConfigId !== state) {
        return action.payload?.app?.cmsConfigId || state;
      }
      return state;
    case CMSConfigIdActions.SET_CMS_CONFIG_ID:
      return action.payload;
    default:
      return state;
  }
};
