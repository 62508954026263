import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { AsyncData } from '@model/common';
import { Flights } from '@model/iceberg';

export const getFlightAvailability = (state: GlobalAppState) => state.searchResults.flights;
export const getFlightAvailabilityFilters = (state: GlobalAppState) => state.searchResults.flights.data.filters;
export const getFlightAvailabilityFlights = (state: GlobalAppState) => state.searchResults.flights.data.flights;
export const getHasFlights = (state: GlobalAppState) => !!state.searchResults.flights.data.flights.length;
export const getIsFlightsLoading = (state: GlobalAppState) => state.searchResults.flights.loading;
export const getHasNoFlights = createSelector(
  getHasFlights,
  getFlightAvailability,
  (hasFlights: boolean, flights: AsyncData<Flights>) => {
    return !hasFlights && flights.error;
  }
);
export const getCanShowFlights = createSelector(
  getIsFlightsLoading,
  getHasFlights,
  (loading: boolean, hasFlights: boolean) => !loading && hasFlights
);
