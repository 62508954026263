import { Occupancy, Route, TravelTime } from '@model/iceberg';
import { Price, Keyable, TripTypes } from '@model/common';
import { TourExtra } from '@model/iceberg/booking/basket/extras';
import { TourContent } from '@model/tours';

export interface MyBookingResponse<T> {
  data: T;
}

export interface MyBookingToken {
  loginToken: string;
  bookingID: number;
}

export interface MyBookingTourDetails
  extends Pick<TourContent, 'days' | 'excursionsCount' | 'mealsCount' | 'name' | 'pace' | 'subTitle'> {
  path: string;
  image: string;
  tripType: TripTypes;
  information?: Keyable;
}

export interface MyBookingDetails {
  bookingID: number;
  isTour: boolean;
  apisSubmitted: boolean;
  apisRequired: boolean;
  status: string;
  daysToDepartureDate: number;
  departureDate: string;
  returnDate: string;
  imageURL: string;
  accommodation: Array<MyBookingAccommodation>;
  flights: Array<MyBookingFlights>;
  transfers: Array<MyBookingTransfer>;
  leadGuest: MyBookingLeadGuest;
  guests: Array<MyBookingGuest>;
  payments: MyBookingPayment;
  extras: Array<MyBookingExtra>;
  tours: Array<TourExtra>;
  tourDetails?: MyBookingTourDetails;
  destinationInformation?: Keyable;
}

export interface MyBookingAccommodation {
  hotel: string;
  resort: string;
  country: string;
  checkInDate: string;
  checkOutDate: string;
  status: string;
  rooms: Array<MyBookingRoom>;
  comments: Array<string>;
  notes: Array<string>;
  hero: string;
  documentation?: Keyable;
}

export interface MyBookingRoom {
  roomType: string;
  boardBasis: string;
  occupancy: Occupancy;
  adjustments: Array<MyBookingPriceAdjustment>;
}

export interface MyBookingFlights {
  carrier: string;
  status: string;
  outbound: Array<MyBookingRoute>;
  inbound: Array<MyBookingRoute>;
  includes: Array<string>;
  erratas: Array<string>;
  extras: Array<string>;
  travelTime: TravelTime;
  isOneWay?: boolean;
  documentation?: Keyable;
}

export interface MyBookingRoute extends Route {
  direction: string;
  sequence: number;
}

export interface MyBookingTransfer {
  type: string;
  occupancy: Occupancy;
  notes?: string;
  status: string;
  departs: string;
  arrives: string;
  departureDate: string;
  documentation?: Keyable;
}

export interface MyBookingLeadGuest {
  title: string;
  firstName: string;
  lastName: string;
  address1: string;
  dob: string;
  address2: string;
  townCity: string;
  county: string;
  postcode: string;
  phone: string;
  mobile: string;
  email: string;
}

export interface MyBookingGuest {
  title: string;
  firstNames: string;
  lastName: string;
  dob: string;
  type: string;
}

export interface MyBookingPayment {
  total: Price;
  paid: Price;
  due: Array<MyBookingPaymentDue>;
  totalOutstanding: Price;
  adjustments: Array<MyBookingPriceAdjustment>;
}

export interface MyBookingPaymentDue {
  dueDate: string;
  amount: Price;
}

export interface MyBookingPriceAdjustment extends Partial<Price> {
  type: MyBookingPriceAdjustmentType;
  description?: string;
}

export enum MyBookingPriceAdjustmentType {
  SUPPLEMENT = 'Supplement',
  OFFER = 'Offer',
  OPTIONAL_SUPPLEMENT = 'Optional Supplement',
  GOVENT_ATOL_FEE = 'Govnt ATOL Fee'
}

export interface MyBookingLoginPayload {
  bookingId: string;
  lastName: string;
  departureDate: string;
  reCaptcha: string;
}

export interface MyBookingDocuments {
  documents: Array<MyBookingDocument>;
}

export interface MyBookingDocument {
  name: string;
  url: string;
}

export interface MyBookingExtra {
  status: string;
  extraName: string;
  price: Price;
}

export enum MyBookingPaymentState {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  EXCEPTION = 'EXCEPTION'
}

export enum BookingStatus {
  LIVE = 'Live',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  ENQUIRY = 'Enquiry'
}

export interface MyBookingErrorDetails {
  status: number | string;
  data?: Keyable;
}

export interface MyBookingDetailsPending {
  errorCode: number | string;
  errorText?: string;
}
