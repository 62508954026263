import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { Theme, ThemeProps } from '@theme/base';
import { TextMarkdown } from './TextMarkdown';

/* ***************** *
 *       Types       *
 * ***************** */
export interface TitleAndTextProps {
  theme?: Theme;
  title?: string;
  titleType?: string;
  text?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div({});

interface TitleProps extends ThemeProps {
  titleType?: string;
}
const Title = styled.div(({ theme, titleType }: TitleProps) => ({
  ...(theme.custom.typography[titleType || 'h2'] as any)
})) as any;

/* *********************** *
 *    TitleAndTextComponent    *
 * *********************** */

export const TitleAndTextComponent = ({ title, titleType, text }: TitleAndTextProps) => {
  return (
    <Container>
      {title && <Title titleType={titleType}>{title}</Title>}
      {text && <TextMarkdown description={text} />}
    </Container>
  );
};

export const TitleAndText = withTheme(TitleAndTextComponent);
