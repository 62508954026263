import { BookingPages } from './booking-pages';
import uri from 'urijs';
import { Page } from '@model/common';

export const getBookingPageFromUrl = (url: string = ''): Page | undefined => {
  if (typeof url !== 'string') {
    return;
  }
  const basketRoutes: Array<Page> = BookingPages;
  const uriUrl = url ? uri(url) : uri();
  const pageName = uriUrl.path();
  if (pageName) {
    return basketRoutes.find((page: Page) => {
      if (page === pageName) {
        return page;
      }
    });
  }
};
