import { tourTypes, TripTypes } from '@model/common/tours/trip-types';

export enum DataLayerDestinationMapperType {
  NONE = '',
  DESTINATION = 'DESTINATION',
  TOUR = 'TOUR'
}

export class DataLayerDestinationMapper {
  constructor(private readonly path: string) {}

  public get(): DataLayerDestinationMapperType {
    if (this.isDestination()) {
      return DataLayerDestinationMapperType.DESTINATION;
    }
    if (this.isTour()) {
      return DataLayerDestinationMapperType.TOUR;
    }
    return DataLayerDestinationMapperType.NONE;
  }

  private isDestination(): boolean {
    return !!this.path.match(/\/destination\/(?!.*hotels\/.*\b)(?!.*holidays\/.*\b).*$/g);
  }

  private isTour(): boolean {
    return tourTypes.some(
      (tripType: TripTypes) => this.path.match(`/${tripType}$`) || this.path.match(`/${tripType}/tours`)
    );
  }
}
