import { OnInit } from '@model/bootstrap/on-init';
import TagManager from 'react-gtm-module';
import { EnvConfig } from '@model/config/env-config';
import { Uuid } from '@model/bootstrap/uuid-bootstrap';

export interface GtmParams {
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_referrer?: string;
  utm_source?: string;
  utm_term?: string;
}

export class GtmBootstrap implements OnInit {
  initialize(): void {
    if (!(window as any).dataLayer) {
      const gtmId: string = EnvConfig.get().GOOGLE_GTM;
      TagManager.initialize({ gtmId });
    }
  }

  performPageView(abta?: string): void {
    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      const uuid = new Uuid().getUuid();

      dataLayer.push({
        event: 'pageview',
        uuid,
        ...(abta ? { tradeId: abta } : {}),
        page: window.location.pathname
      });
    }
  }

  getUtmParams(): GtmParams | null {
    const gtm = (window as any).google_tag_manager;

    if (gtm) {
      const gtmId: string = EnvConfig.get().GOOGLE_GTM;
      const getFn = gtm[gtmId].dataLayer.get;

      return {
        utm_campaign: getFn('utm_campaign'),
        utm_content: getFn('utm_content'),
        utm_id: getFn('utm_id'),
        utm_medium: getFn('utm_medium'),
        utm_referrer: getFn('utm_referrer'),
        utm_source: getFn('utm_source'),
        utm_term: getFn('utm_term')
      };
    }
    return null;
  }
}
