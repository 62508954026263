import { takeLatest, put, call } from 'redux-saga/effects';
import { ToursApi, TourDossiersParams } from '@model/iceberg/service/tours';
import { BaseAction } from '@model/redux';
import { TourDossier } from '@model/tours';

export enum TourDossiersActions {
  FETCH_TOUR_DOSSIERS = '@TOURS/FETCH_TOUR_DOSSIERS',
  RECEIVE_TOUR_DOSSIERS_SUCCESS = '@TOURS/RECEIVE_TOUR_DOSSIERS_SUCCESS',
  RECEIVE_TOUR_DOSSIERS_FAILURE = '@TOURS/RECEIVE_TOUR_DOSSIERS_FAILURE'
}

export interface TourDossiersState {
  data: Array<TourDossier>;
  loading: boolean;
  error: string | null;
}

export const INITIAL_TOUR_DOSSIERS_STATE: TourDossiersState = {
  data: [],
  loading: false,
  error: null
};

export interface FetchTourDossiersAction extends BaseAction {
  type: TourDossiersActions.FETCH_TOUR_DOSSIERS;
  payload: TourDossiersParams;
}

export const fetchTourDossiers: (params: TourDossiersParams) => FetchTourDossiersAction = (
  params: TourDossiersParams
) => ({
  type: TourDossiersActions.FETCH_TOUR_DOSSIERS,
  payload: params
});

export interface ReceiveTourDossiersSuccessAction {
  type: TourDossiersActions.RECEIVE_TOUR_DOSSIERS_SUCCESS;
  data: Array<TourDossier>;
}

export const receiveTourDossiersSuccess: (data: Array<TourDossier>) => ReceiveTourDossiersSuccessAction = (
  data: Array<TourDossier>
) => ({
  type: TourDossiersActions.RECEIVE_TOUR_DOSSIERS_SUCCESS,
  data
});

export interface ReceiveTourDossiersFailureAction {
  type: TourDossiersActions.RECEIVE_TOUR_DOSSIERS_FAILURE;
  error: boolean;
}

export const receiveTourDossiersFailure: () => ReceiveTourDossiersFailureAction = () => ({
  type: TourDossiersActions.RECEIVE_TOUR_DOSSIERS_FAILURE,
  error: true
});

export function* onFetchTourDossiers() {
  yield takeLatest(TourDossiersActions.FETCH_TOUR_DOSSIERS, performFetchTourDossiers);
}

export function callGetTourDossiers(payload: TourDossiersParams) {
  const api = new ToursApi();
  return api
    .getTourDossiers(payload)
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function* performGetTourDossiers(payload: TourDossiersParams) {
  const { response } = yield call(callGetTourDossiers, payload);

  if (response) {
    if (response.data) {
      yield put(receiveTourDossiersSuccess(response?.data));
      return response.data;
    } else {
      yield put(receiveTourDossiersFailure());
      return null;
    }
  } else {
    yield put(receiveTourDossiersFailure());
    return null;
  }
}

export function* performFetchTourDossiers({ payload }: any) {
  yield performGetTourDossiers(payload);
}

export const tourDossiersReducer: any = (state: any = INITIAL_TOUR_DOSSIERS_STATE, { type, error, data }: any) => {
  switch (type) {
    case TourDossiersActions.FETCH_TOUR_DOSSIERS:
      return { ...state, data: null, loading: true };
    case TourDossiersActions.RECEIVE_TOUR_DOSSIERS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case TourDossiersActions.RECEIVE_TOUR_DOSSIERS_FAILURE:
      return { ...state, data: INITIAL_TOUR_DOSSIERS_STATE.data, error, loading: false };
    default:
      return state;
  }
};
