import { SearchCriteria } from '@model/iceberg/deal-finder/deal-finder';
import { DealFinderState, DOB, Occupancy } from '@model/state';
import moment from 'moment';
import { ISO_8601_DATE_FORMAT, YEAR_MONTH_FORMAT } from '@model/common';
import { Occupancy as DealFinderOccupancy } from '@model/iceberg/deal-finder/deal-finder';

export type PackageDealFinderState = Omit<DealFinderState, 'hotelLandingSearch' | 'destinationSearch'>;

export const mapChildrenDobToString = (occupancy: Occupancy[]): DealFinderOccupancy[] =>
  occupancy.map(({ adults, children }: Occupancy) => ({
    adults,
    children: children.map((dob: DOB) =>
      moment(`${dob.year}-${dob.month}-${dob.day}`, ISO_8601_DATE_FORMAT).format(ISO_8601_DATE_FORMAT)
    )
  }));

export const mapChildrenStringToDob = (occupancy: DealFinderOccupancy[]): Occupancy[] =>
  occupancy.map(({ adults, children }: DealFinderOccupancy) => ({
    adults,
    children: children.map((value: string) => {
      const dob: moment.Moment = moment(value);
      return {
        day: dob.format('DD'),
        month: dob.format('MM'),
        year: dob.format('YYYY')
      };
    })
  }));

export class DealFinderMapper {
  public static fromDealFinder(payload: DealFinderState): SearchCriteria {
    const {
      duration,
      destinations: places,
      airports: from,
      date,
      occupancy,
      month,
      flexibleDays: flexible,
      searchType,
      preferredBoardBasis
    } = payload;
    return {
      duration,
      dateFrom: date ? moment(date).format(ISO_8601_DATE_FORMAT) : undefined,
      month: month ? moment(month).format(YEAR_MONTH_FORMAT) : undefined,
      flexible,
      from,
      occupancy: mapChildrenDobToString(occupancy),
      places,
      searchType,
      preferredBoardBasis
    };
  }

  public static fromPackageParams(payload: SearchCriteria): PackageDealFinderState {
    const {
      searchType,
      places: destinations,
      occupancy,
      month,
      from: airports,
      flexible,
      dateFrom: date,
      duration,
      preferredBoardBasis
    } = payload;
    const dealFinderOccupancy: Array<Occupancy> = occupancy.map(
      ({ adults, children }: { adults: number; children: Array<string> }) => ({
        adults,
        children: (children || []).map((value: string) => {
          const dob: moment.Moment = moment(value);
          return {
            day: dob.format('DD'),
            month: dob.format('MM'),
            year: dob.format('YYYY')
          };
        })
      })
    );
    return {
      searchType,
      flexibleDays: date ? flexible : false,
      month: month || '',
      occupancy: dealFinderOccupancy,
      date: date || '',
      airports,
      destinations,
      duration,
      preferredBoardBasis: preferredBoardBasis || '',
      errors: {
        occupancy: false,
        dates: false,
        airports: false,
        destinations: false
      }
    };
  }
}
