import styled from '@emotion/styled';
import React from 'react';
import { TestId } from '@components/test-ids';
import { TourHotel, TourVesselInfo } from '@model/tours';
import { ThemeProps } from '@theme/base';
import { StarRating } from '../star-rating';
import { getHTML } from '@util/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface VesselDescriptionProps {
  testId?: string;
  vessel: TourVesselInfo;
  hotels?: Array<TourHotel>;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container: any = styled.div({});

const VesselName: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: 0,
  marginBottom: theme.custom.spacing.xSmall
}));

const VesselDescriptionText: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginTop: theme.custom.spacing.xLarge
}));

/* *********************** *
 *    VesselDescription    *
 * *********************** */

export const VesselDescription = ({ testId, vessel, hotels }: VesselDescriptionProps) => {
  return (
    <Container data-testid={testId || TestId.vesselDescription.main}>
      {vessel.name && <VesselName>{vessel.name}</VesselName>}
      {hotels?.map(
        (hotel: TourHotel) =>
          vessel.rating && (
            <StarRating
              key={`${vessel.name}-${hotel.name}`}
              rating={vessel.rating}
              size={'1.8rem'}
              ratingType={hotel.ratingType}
            />
          )
      )}
      {vessel.description && <VesselDescriptionText dangerouslySetInnerHTML={getHTML(vessel.description)} />}
    </Container>
  );
};
