import { FlightsSortBy } from '@model/flights';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';

export enum FlightSortActions {
  SET_SORT = '@FLIGHTS_SORT/SET_SORT'
}

export const setFlightsSort = (payload: FlightsSortBy) => ({
  type: FlightSortActions.SET_SORT,
  payload
});

export const INITIAL_FLIGHTS_SORT_STATE: FlightsSortBy = FlightsSortBy.CHEAPEST;

export const flightsSortReducer: any = (state: FlightsSortBy = INITIAL_FLIGHTS_SORT_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, INITIAL_FLIGHTS_SORT_STATE)) {
        return action.payload.flightSort || state;
      }
      return state;
    case FlightSortActions.SET_SORT:
      return action.payload;
    case DealFinderResultsActions.CLEAR_RESULTS:
      return INITIAL_FLIGHTS_SORT_STATE;
    default:
      return state;
  }
};
