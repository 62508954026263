import React from 'react';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';

export interface UserProps {
  username: string;
  locationName: string;
}

const Reviewer = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginTop: theme.custom.spacing.large,
  marginBottom: theme.custom.spacing.large,
  marginRight: theme.custom.spacing.medium
}));

const ReviewDate = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  color: theme.custom.colors.group4.light,
  fontWeight: 500,
  marginLeft: theme.custom.spacing.xSmall
}));

export const User = ({ username, locationName }: UserProps) => {
  const t: any = useI18NextContext();
  return (
    <Reviewer data-testid={TestId.productReview.user}>
      {t('product__review--review-by')} {username}
      <ReviewDate>{locationName}</ReviewDate>
    </Reviewer>
  );
};
