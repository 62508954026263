export enum BoardBasis {
  SC = 'Self Catering',
  RO = 'Room Only',
  BB = 'Bed and Breakfast',
  HB = 'Half Board',
  HBP = 'Half Board  Plus',
  HBPLUS = 'Half Board Plus',
  FB = 'Full Board',
  FBP = 'Full Board Plus',
  FBPLUS = 'Full Board  Plus',
  AI = 'All Inclusive',
  AIP = 'All Inclusive Plus',
  AIPLUS = 'All Inclusive  Plus',
  XMASBR = 'Christmas Day Brunch',
  PHB = 'Half Board Premium',
  PFB = 'Full Board Premium',
  LAI = 'Luxury All Inclusive',
  PAIDA = 'Premium All Inclusive Dine Around',
  PFAI = "'Pocket Free' All Inclusive",
  TEST = 'Test Meal Plan do not delete',
  ULAI = 'Ultra All Inclusive',
  PREAI = 'Premium All Inclusive',
  BAI = 'Blue All Inclusive',
  FBDO = 'Full Board Dine Around',
  CB = 'Continental Breakfast',
  SAVSCLAI = 'Savoy S Class Ultra All Inclusive',
  SHB = 'Super Half Board',
  ACC = 'Accommodation Only',
  NON = 'None',
  AIPRE = 'All Inclusive Premium',
  AISTD = 'All Inclusive Standard',
  AIULT = 'All Inclusive Ultra',
  BREAKF = 'Breakfast',
  DINEAR = 'Dine Around (HB Plus)',
  'N/A' = 'Not Applicable',
  AD = 'All Inclusive Dine Around',
  AIGOLD = 'All Inclusive Gold',
  AIEX = 'All Inclusive Experience',
  AIFUN = 'All Inclusive FUN',
  LI = 'Light Inclusive',
  AIS = 'Silver All Inclusive',
  AdAI = "'All-Add-In' All Inclusive",
  AILI = 'All Inclusive LITE',
  AIT = 'All Inclusive Turquoise',
  FBAI = 'Full Board All Inclusive',
  VARIES = 'Meal Plan Varies',
  AILG = 'All Inclusive Light',
  VIEHB = 'Vietnamese Set Lunch or Dinner',
  WEHB = 'Western Set Lunch or Dinner',
  BBHB = 'Bed and Breakfast (Free upgrade to HB)',
  AIG = 'Gold All Inclusive',
  AISOFT = 'ALL Inclusive SOFT',
  HBDA = 'Half Board Dine Around',
  BUFFET = 'Buffet Lunch',
  PID = 'Pure Indulgence Dine Around',
  DINNER = 'Dinner',
  SET = 'Set Menu',
  HBSAF = 'Half Board Saffron',
  BBQ = 'Barbeque',
  HBBB = 'Half Board & Bed and Breakfast',
  'HB+D' = 'Half Board Plus Dine Around',
  HBD = 'Half Board Deluxe',
  AID = 'All Inclusive Deluxe',
  AIDP = 'All Inclusive Diamond Package',
  BUFFETBB = 'Buffet Breakfast',
  PresAI = 'Prestige All Inclusive',
  XXLAI = 'XXL All Inclusive',
  XMASEVE = 'Christmas Eve Dinner',
  CPAI = 'All Inclusive Crystal Package',
  XMAS = 'Christmas Dinner',
  XMASLUN = 'Christmas Lunch',
  GALA = 'Gala Dinner',
  DAILYBUF = 'Daily Buffet',
  EASTBR = 'Easter Brunch',
  EASTLUN = 'Easter Lunch',
  FEST = 'Festive Dinner',
  FESTDI = 'Festive Meal Plan',
  GOODFR = 'Good Friday Lunch',
  NYE = 'New Years Gala Dinner',
  VAL = 'Valentines Day Supplement',
  SUNAI = 'Sun All Inclusive',
  SEMAI = 'Semi All Inclusive'
}

export const BoardBasisSearch = {
  [BoardBasis.RO]: 'RO',
  [BoardBasis.SC]: 'SC',
  [BoardBasis.BB]: 'BB',
  [BoardBasis.HB]: 'HB',
  [BoardBasis.HBP]: 'HBP',
  [BoardBasis.FB]: 'FB',
  [BoardBasis.FBP]: 'FBP',
  [BoardBasis.AI]: 'AI',
  [BoardBasis.AIP]: 'AIP',
  [BoardBasis.XMASBR]: 'XMASBR',
  [BoardBasis.PHB]: 'PHB',
  [BoardBasis.PFB]: 'PFB',
  [BoardBasis.LAI]: 'LAI',
  [BoardBasis.PAIDA]: 'PAIDA',
  [BoardBasis.PFAI]: 'PFAI',
  [BoardBasis.TEST]: 'TEST',
  [BoardBasis.ULAI]: 'ULAI',
  [BoardBasis.PREAI]: 'PREAI',
  [BoardBasis.BAI]: 'BAI',
  [BoardBasis.FBDO]: 'FBDO',
  [BoardBasis.CB]: 'CB',
  [BoardBasis.SAVSCLAI]: 'SAVSCLAI',
  [BoardBasis.SHB]: 'SHB',
  [BoardBasis.ACC]: 'ACC',
  [BoardBasis.NON]: 'NON',
  [BoardBasis.AIPRE]: 'AIPRE',
  [BoardBasis.AISTD]: 'AISTD',
  [BoardBasis.AIULT]: 'AIULT',
  [BoardBasis.BREAKF]: 'BREAKF',
  [BoardBasis.DINEAR]: 'DINEAR',
  [BoardBasis['N/A']]: 'N/A',
  [BoardBasis.AD]: 'AD',
  [BoardBasis.AIGOLD]: 'AIGOLD',
  [BoardBasis.AIEX]: 'AIEX',
  [BoardBasis.AIFUN]: 'AIFUN',
  [BoardBasis.LI]: 'LI',
  [BoardBasis.AIS]: 'AIS',
  [BoardBasis.AdAI]: 'AdAI',
  [BoardBasis.AILI]: 'AILI',
  [BoardBasis.AIT]: 'AIT',
  [BoardBasis.FBAI]: 'FBAI',
  [BoardBasis.VARIES]: 'VARIES',
  [BoardBasis.AILG]: 'AILG',
  [BoardBasis.VIEHB]: 'VIEHB',
  [BoardBasis.WEHB]: 'WEHB',
  [BoardBasis.BBHB]: 'BBHB',
  [BoardBasis.AIG]: 'AIG',
  [BoardBasis.AISOFT]: 'AISOFT',
  [BoardBasis.HBDA]: 'HBDA',
  [BoardBasis.BUFFET]: 'BUFFET',
  [BoardBasis.PID]: 'PID',
  [BoardBasis.DINNER]: 'DINNER',
  [BoardBasis.SET]: 'SET',
  [BoardBasis.HBSAF]: 'HBSAF',
  [BoardBasis.BBQ]: 'BBQ',
  [BoardBasis.HBBB]: 'HBBB',
  [BoardBasis['HB+D']]: 'HB+D',
  [BoardBasis.HBD]: 'HBD',
  [BoardBasis.AID]: 'AID',
  [BoardBasis.AIDP]: 'AIDP',
  [BoardBasis.BUFFETBB]: 'BUFFETBB',
  [BoardBasis.PresAI]: 'PresAI',
  [BoardBasis.XXLAI]: 'XXLAI',
  [BoardBasis.XMASEVE]: 'XMASEVE',
  [BoardBasis.CPAI]: 'CPAI',
  [BoardBasis.XMAS]: 'XMAS',
  [BoardBasis.XMASLUN]: 'XMASLUN',
  [BoardBasis.GALA]: 'GALA',
  [BoardBasis.DAILYBUF]: 'DAILYBUF',
  [BoardBasis.EASTBR]: 'EASTBR',
  [BoardBasis.EASTLUN]: 'EASTLUN',
  [BoardBasis.FEST]: 'FEST',
  [BoardBasis.FESTDI]: 'FESTDI',
  [BoardBasis.GOODFR]: 'GOODFR',
  [BoardBasis.NYE]: 'NYE',
  [BoardBasis.VAL]: 'VAL',
  [BoardBasis.SUNAI]: 'SUNAI',
  [BoardBasis.SEMAI]: 'SEMAI'
};
