/* *** ENUMS *** */
export enum PaymentMethod {
  FULL_AMOUNT = 'Full amount',
  DEPOSIT = 'Deposit',
  NEXT_PAYMENT = 'Next Payment',
  CUSTOM_AMOUNT = 'Custom Amount'
}

export const paymentMethodMapping = {
  [PaymentMethod.FULL_AMOUNT]: 0,
  [PaymentMethod.DEPOSIT]: 1,
  [PaymentMethod.NEXT_PAYMENT]: 2, // this is FE only (MMB), this value is not implemented on the BE side
  [PaymentMethod.CUSTOM_AMOUNT]: 3 // this is FE only (MMB), this value is not implemented on the BE side
};

/* *** MODELS *** */
export interface PaymentFormValues {
  contactByPost: boolean;
  address: string;
  address2: string;
  townCity: string;
  county: string;
  postCode: string;
  discountCode: string;
  paymentMethod: PaymentMethod;
  cardNumber: string;
  cardExpiryDate: string;
  cvv: string;
  termsAccepted: boolean;
  nameOnCard: string;
  amount?: string;
  totalOutstandingAmount?: number;
  tradeEmail: string;
}
