import { combineReducers } from 'redux';
import { modalReducer } from '../modal/modalOperations';
import { loadingReducer } from './loading/loadingOperations';
import { pathReducer } from '@state/app/routing';
import { historyReducer } from '@state/app/history';
import { destinationReducer } from '../app/destination/destinationOperations';
import { cmsConfigIdReducer } from './cms-config-id';
import { packageReferencesReducer } from './package-references';
import { promoModalReducer } from './promo-modal';
import { shareLinkReducer } from './share-link';
import { paymentErrorReducer } from './payment-error';

export const combinedAppReducers = combineReducers({
  destination: destinationReducer,
  path: pathReducer,
  history: historyReducer,
  modal: modalReducer,
  loading: loadingReducer,
  cmsConfigId: cmsConfigIdReducer,
  packageReferences: packageReferencesReducer,
  promoModal: promoModalReducer,
  shareLink: shareLinkReducer,
  paymentError: paymentErrorReducer
});

export type CombinedAppReducersType = ReturnType<typeof combinedAppReducers>;
