import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { FiltersAdapter } from '@model/iceberg/filters/deal-finder/filters-adapter';
import { SearchResultsState } from '@model/state/search-results-state';

const RESULTS_PER_PAGE = 24;

export const getSearchResults = (state: GlobalAppState) => state.searchResults;
export const getFromPricePackages = (state: GlobalAppState) => state.searchResults?.fromPrices?.data?.results;
export const getHasFromPricePackages = (state: GlobalAppState) =>
  !!state.searchResults.fromPrices?.data?.facets?.counts?.total;
export const getFromPricePackagesData = (state: GlobalAppState) => state.searchResults.fromPrices?.data;
export const getFromPricePackageFilters = (state: GlobalAppState) =>
  state.searchResults.fromPrices?.data?.facets?.filters || [];
export const getFromPricePackagesLoading = (state: GlobalAppState) => state.searchResults.fromPrices?.loading;
export const getFromPricePackageFiltered = (state: GlobalAppState) => {
  const filtered = state.searchResults.fromPrices?.data?.facets?.counts?.filtered || 0;
  if (filtered < RESULTS_PER_PAGE) {
    return state.searchResults.fromPrices?.data?.results?.length;
  }
  return filtered;
};
export const getFromPricePackageHasMore = ({ searchResults }: GlobalAppState) => {
  const filtered = searchResults.fromPrices?.data?.facets?.counts?.filtered || 0;
  if (filtered < RESULTS_PER_PAGE) {
    return false;
  }
  const nextSet = searchResults.fromPrices?.data?.nextSet;
  const results = searchResults.fromPrices?.data?.results || [];
  return !!nextSet && results.length < filtered;
};

export const getHasFromPricePackageErrors = (state: GlobalAppState) => state.searchResults.fromPrices?.error;

export const getFromPricePackagesHasResults = createSelector(
  getSearchResults,
  ({ fromPrices: { data, error, loading } }: SearchResultsState) => {
    return !!data?.results?.length && !error && !loading;
  }
);

export const getFromPricePackagesHasAppliedFilters = (state: GlobalAppState) =>
  FiltersAdapter.hasAppliedFilters(state.searchResults.fromPrices?.data?.facets?.filters || []);

export const getCanShowFromPriceNoResults = createSelector(
  getFromPricePackagesLoading,
  getHasFromPricePackageErrors,
  getHasFromPricePackages,
  (loading: boolean, packageErrors: boolean, hasPackages: boolean) => !loading && !hasPackages && packageErrors
);
