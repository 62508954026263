import { LogType } from '@model/common/logging';
import { BasketActions } from '@state/basket';
import { Logging, LoggingParams } from '@util/logging';
import { takeEvery } from 'redux-saga/effects';
import { LogCategory } from '@model/common/logging/log-category';
import { FromPricePackagesActions, ToursActions } from '@state/search-results';

export interface LogParams extends LoggingParams {
  type: LogType;
}

export interface LogItem {
  [key: string]: LogParams;
}

const actionLogMapping: LogItem = {
  [BasketActions.RECEIVE_BASKET_SUCCESS]: {
    text: 'Basket update successful',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.RECEIVE_BASKET_FAILURE]: {
    text: 'Basket update failed',
    category: LogCategory.BASKET,
    type: LogType.WARN
  },
  [BasketActions.RECEIVE_ADD_TO_BASKET_FAILURE]: {
    text: 'Basket update failed',
    category: LogCategory.BASKET,
    type: LogType.WARN
  },
  [FromPricePackagesActions.PERFORM_FROM_PRICE_SEARCH]: {
    text: 'Performing from price search',
    category: LogCategory.FROM_PRICE_PACKAGES,
    type: LogType.INFO
  },
  [FromPricePackagesActions.RECEIVE_FROM_PRICE_PACKAGES_FAILURE]: {
    text: 'Fetching from price packages failed',
    category: LogCategory.FROM_PRICE_PACKAGES,
    type: LogType.WARN
  },
  [FromPricePackagesActions.RECEIVE_FROM_PRICE_PACKAGES_SUCCESS]: {
    text: 'Fetched from price packages successfully',
    category: LogCategory.FROM_PRICE_PACKAGES,
    type: LogType.INFO
  },
  [ToursActions.FETCH_TOURS]: {
    text: 'Performing tours search',
    category: LogCategory.TOURS,
    type: LogType.INFO
  },
  [ToursActions.RECEIVE_TOURS_SUCCESS]: {
    text: 'Fetched tours successfully',
    category: LogCategory.TOURS,
    type: LogType.INFO
  },
  [ToursActions.RECEIVE_TOURS_FAILURE]: {
    text: 'Fetching tours failed',
    category: LogCategory.TOURS,
    type: LogType.WARN
  },
  [BasketActions.ADD_ROOMS_TO_BASKET]: {
    text: 'Rooms added to basket',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.ADD_ROOMS_TO_BASKET_AND_NAVIGATE]: {
    text: 'Rooms added to basket & navigation performed',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.ADD_FLIGHT_TO_BASKET]: {
    text: 'Flight added to basket',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.ADD_LUGGAGE_TO_BASKET]: {
    text: 'Luggage added to basket',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.ADD_TRANSFERS_TO_BASKET]: {
    text: 'Transfer added to basket',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.ADD_SPECIAL_REQUESTS_TO_BASKET]: {
    text: 'Special request added to basket',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.ADD_SUPPLEMENTS_TO_BASKET]: {
    text: 'Supplements added to basket',
    category: LogCategory.BASKET,
    type: LogType.INFO
  },
  [BasketActions.PREBOOK_FLIGHT_TO_BASKET]: {
    text: 'Flight PreBook performed',
    category: LogCategory.BASKET,
    type: LogType.INFO
  }
};

export function* onActions() {
  yield takeEvery(Object.keys(actionLogMapping), logAction);
}

export function* logAction(action: any) {
  const type: string = action.type;
  const data: any = action.payload;
  const logParam: LogParams = actionLogMapping[type];
  if (!logParam) return;
  switch (logParam.type) {
    case LogType.ERROR:
      yield Logging.error({ ...logParam, data });
      return;
    case LogType.WARN:
      yield Logging.warn({ ...logParam, data });
      return;
    case LogType.LOG:
      yield Logging.log({ ...logParam, data });
      return;
    default:
      yield Logging.log({ ...logParam, data });
      return;
  }
}
