import uri from 'urijs';
import { ProductBookingPageSections } from '@model/pages';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';

const removeEmptyParameters = (data: any) => {
  const result: any = {};
  Object.keys(data)
    .filter((key) => Boolean(data[key]) && data[key].length)
    .forEach((key) => (result[key] = data[key]));
  return result;
};

const arrayToString = (data: any) => {
  return Array.isArray(data) ? data.join(',') : typeof data.toString === 'function' ? data.toString() : data;
};

/*
 *  Constructs a URL that the UI can use to navigate using the search form data.
 */
export class SearchUrlBuilder {
  private _searchToken: string;
  private _searchType: SearchType | null;
  private _productPath: string;
  private _hash: string;
  private _from: string;
  private _duration: string;
  private _adults: string;
  private _children: string;
  private _date: string;
  private _boardBasis: string;

  constructor() {
    this._searchToken = '';
    this._searchType = null;
    this._productPath = '';
    this._hash = '';
    this._from = '';
    this._duration = '';
    this._adults = '';
    this._children = '';
    this._date = '';
    this._boardBasis = '';
  }

  withSearchToken(searchToken: string) {
    this._searchToken = searchToken;
    return this;
  }

  withSearchType(searchType: SearchType) {
    this._searchType = searchType;
    return this;
  }

  withProductPath(path?: string) {
    if (path) {
      this._productPath = path;
    }
    return this;
  }

  withHash(section?: ProductBookingPageSections) {
    if (section) {
      this._hash = section as string;
    }
    return this;
  }

  withFrom(from: string | Array<string>) {
    this._from = arrayToString(from);
    return this;
  }

  withDuration(duration: number | string) {
    this._duration = duration.toString();
    return this;
  }

  withAdults(adults: string) {
    this._adults = arrayToString(adults);
    return this;
  }

  withChildren(children: string) {
    this._children = arrayToString(children);
    return this;
  }

  withDate(date: string) {
    this._date = date;
    return this;
  }

  withBoardBasis(boardBasis: string) {
    this._boardBasis = boardBasis;
    return this;
  }

  build(url: string): string {
    return uri(url)
      .search({
        searchToken: this._searchToken,
        searchType: this._searchType,
        productPath: this._productPath,
        from: this._from,
        duration: this._duration,
        adults: this._adults,
        children: this._children,
        date: this._date,
        boardBasis: this._boardBasis
      })
      .search(removeEmptyParameters)
      .hash(this._hash)
      .href();
  }
}
