import { combineReducers } from 'redux';
import { tourReferencesReducer } from './tour-references';
import { tourAvailabilityReducer } from './tour-availability';
import { tourFiltersReducer } from './tour-filters';
import { tourDossiersReducer } from '@state/tours/tour-dossiers';

export const combinedToursReducers = combineReducers({
  tourReferences: tourReferencesReducer,
  tourAvailability: tourAvailabilityReducer,
  tourFilters: tourFiltersReducer,
  tourDossiers: tourDossiersReducer
});

export type CombinedToursReducersType = ReturnType<typeof combinedToursReducers>;
