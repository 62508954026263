import { DestinationsResponse, Place } from '@model/iceberg/geography';
import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';

interface GeographyApiRequests {
  getDestinations: (code: string) => Promise<DestinationsResponse>;
  getPlaceByPath: (path: string) => Promise<GetPlaceByPath>;
}

export interface GetPlaceByPath {
  place: Place;
}

export class GeographyApi implements GeographyApiRequests {
  private api: Request = new Request();

  public async getDestinations(): Promise<DestinationsResponse> {
    return await this.api.get(uri(getURL()).path(EndPoint.GEOGRAPHY.concat(EndPoint.DESTINATIONS)).href());
  }

  public async getPlaceByPath(path: string): Promise<GetPlaceByPath> {
    return await this.api
      .get(uri(getURL()).path(`${EndPoint.GEOGRAPHY}${EndPoint.PLACE}`).search({ path }).href())
      .catch(() => {
        return { place: null };
      });
  }
}
