import { all } from 'redux-saga/effects';
import {
  handleOnLoadMore,
  handleOnPerformProductSearch,
  handleOnPerformSearch,
  handleOnPerformDestinationSearch,
  handleOnGetPackages
} from '@state/deal-finder-results/dealFinderResultsOperations';

export function* combinedDealFinderResultsSagas() {
  yield all([
    handleOnPerformSearch(),
    handleOnPerformDestinationSearch(),
    handleOnLoadMore(),
    handleOnPerformProductSearch(),
    handleOnGetPackages()
  ]);
}
