import { TagBuilder } from './tag-builder';

export enum BooleanTagType {
  OR = 'OR'
}

export class BooleanTagBuilder extends TagBuilder {
  public readonly tags: Array<string> = [];
  public readonly type: BooleanTagType = BooleanTagType.OR;

  constructor(tags: Array<string> = [], type: BooleanTagType = BooleanTagType.OR) {
    super();
    this.tags = tags;
    this.type = type;
  }

  public build(): string {
    const tag: string = this.getBooleanTag();
    if (this.tags.length) {
      return super.build(tag);
    }
    return '';
  }

  private getBooleanTag() {
    return this.tags.join(` ${this.type} `);
  }
}
