import { GlobalAppState } from '@model/state';

export const getAllCMSDestinationsSelector = (state: GlobalAppState) => state.cms.cmsDestinations;
export const getCMSDestination = (path: string) => (state: GlobalAppState) =>
  state.cms.cmsDestinations.find((destination: any) => destination.destinationPath.fields.path === path);
export const getCMSAppVariantId = (state: GlobalAppState) => state.cms.cmsGlobalConfig.appVariantId;
export const getCMSLogoLightUrl = (state: GlobalAppState) => state.cms.cmsGlobalConfig.logoLight?.fields?.file?.url;
export const getCMSLogoDarkUrl = (state: GlobalAppState) => state.cms.cmsGlobalConfig.logoDark?.fields?.file?.url;
export const getCMSOpeningTimes = (state: GlobalAppState) => state.cms.cmsGlobalConfig.openingTimes;
export const getCMSContactDetails = (state: GlobalAppState) => state.cms.cmsGlobalConfig.contactDetails;
export const getCMSHereToHelp = (state: GlobalAppState) => state.cms.cmsHereToHelp;
export const getCMSOffers = (state: GlobalAppState) => state.cms.cmsOffers;
export const getMainPhoneNumber = (state: GlobalAppState) =>
  state.cms.cmsGlobalConfig.contactDetails?.fields.mainTelephoneNumber;
export const getTravelInsurancePhoneNumber = (state: GlobalAppState) =>
  state.cms.cmsGlobalConfig.contactDetails?.fields.travelInsurancePhoneNumber;
