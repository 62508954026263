import { Testimonial } from '@model/iceberg/testimonial';
import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';

interface TestimonialsApiRequests {
  get: (limit: number) => Promise<Array<Testimonial>>;
}

const TESTIMONIAL_DEFAULT_LIMIT = 4;

export class TestimonialsApi implements TestimonialsApiRequests {
  private request: Request = new Request();

  public async get(limit?: number): Promise<Array<Testimonial>> {
    return await this.request.get(
      uri(getURL())
        .path(EndPoint.TESTIMONIALS)
        .search({ limit: limit || TESTIMONIAL_DEFAULT_LIMIT })
        .href()
    );
  }
}
