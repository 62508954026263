import { all } from 'redux-saga/effects';
import {
  onSendDataLayer,
  onShouldSendBasketUpdateDataLayer,
  onShouldSendBookingDataLayer,
  onShouldSendCatalogueDataLayer,
  onShouldSendHotelDataLayer,
  onShouldSendToursDataLayer
} from './data-layer-middleware';

export function* combinedDataLayerMiddlewareSagas() {
  yield all([
    onShouldSendBookingDataLayer(),
    onShouldSendHotelDataLayer(),
    onShouldSendToursDataLayer(),
    onShouldSendCatalogueDataLayer(),
    onSendDataLayer(),
    onShouldSendBasketUpdateDataLayer()
  ]);
}
