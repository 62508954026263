import { MAX_CHILD_AGE } from '@model/common/guest-age';
import moment from 'moment';
import { ISO_8601_DATE_FORMAT } from '@model/common';

export class Child {
  public readonly day: string;
  public readonly month: string;
  public readonly year: string;

  constructor(day: string = '', month: string = '', year: string = '') {
    this.day = day;
    this.month = month;
    this.year = year;
  }

  public get isEmpty(): boolean {
    const daysEmpty: boolean = this.day.length < 1;
    const monthsEmpty: boolean = this.month.length < 1;
    const yearsEmpty: boolean = this.year.length < 4;
    return daysEmpty || monthsEmpty || yearsEmpty;
  }

  public get isOverAge(): boolean {
    if (this.isEmpty || !this.isValid) return false;
    const age: number = this.age;
    return age >= MAX_CHILD_AGE;
  }

  public get isUnderAge(): boolean {
    if (this.isEmpty || !this.isValid) return false;
    const dob: moment.Moment = this.moment;
    return dob.isSameOrAfter(moment());
  }

  public get isValid(): boolean {
    const dob: moment.Moment = this.moment;
    return dob.isValid() && !this.isEmpty;
  }

  public get age(): number {
    if (!this.isValid) return 0;
    const age = parseInt(moment().diff(this.moment, 'years').toFixed(0));
    return age < 0 ? 0 : age;
  }

  public isOverAgeOnDate(date?: string): boolean {
    const endDate = moment(date);
    const ageDuringHoliday = endDate.diff(this.moment, 'years');
    return ageDuringHoliday.valueOf() >= MAX_CHILD_AGE;
  }

  public get moment(): moment.Moment {
    return moment(`${this.year}-${this.month}-${this.day}`, ISO_8601_DATE_FORMAT);
  }
}
