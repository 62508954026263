import { isServer } from '@util/is-server';
import { GeneratedByValue } from '@model/iceberg/service/contact-us';

/* *** MODELS *** */
export interface WebsiteCommentsFormValues {
  name: string;
  email: string;
  comments: string;
  userAgent: string;
}

export interface WebsiteCommentsFormPayload extends WebsiteCommentsFormValues, GeneratedByValue {
  reCaptcha: string;
}

/* *** INITIAL VALUES *** */
export const websiteCommentsInitialValues: WebsiteCommentsFormValues = {
  name: '',
  email: '',
  comments: '',
  userAgent: !isServer ? navigator.userAgent : ''
};
