import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import { withTheme } from '@emotion/react';
import React from 'react';
import Markdown from 'react-markdown';
import * as gfm from 'remark-gfm';
import { MarkdownContainer as Container } from './styles/common';
import { RTAP_LINK, RTapNumber } from '@components/common/r-tap-number';
import { AppVariant } from '@model/config/brands';

export interface TextMarkdownProps {
  theme?: Theme;
  testId?: string;
  description: string;
  className?: string;
}

export const LinkComponent = {
  a: ({ node, ...props }) => {
    if (props.href === RTAP_LINK) {
      const number = props.children[0];
      if (AppVariant.isNonMercuryBrand()) {
        return number;
      }
      return <RTapNumber telephoneNumber={number} />;
    } else {
      const decodedURI = decodeURIComponent(props.href as string);
      const newWindow = /\|blank$/.test(decodeURIComponent(props.href as string));
      if (newWindow) {
        const href = decodedURI.split('|')[0];
        return <a {...props} href={href} target="_blank" rel="noreferrer" />;
      }
      return <a {...props} />;
    }
  }
};

export const TextMarkdownComponent = (props: TextMarkdownProps) => {
  const { description, className } = props;
  return (
    <Container data-testid={props.testId || TestId.description.main} className={className} css={{ marginBottom: 0 }}>
      <Markdown children={description} plugins={[gfm as any]} components={LinkComponent} />
    </Container>
  );
};

export const TextMarkdown = withTheme(TextMarkdownComponent);
