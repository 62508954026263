import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { compact, pick, values, every, isEmpty } from 'lodash';
import { ERROR_CODES } from '@model/common/error-codes';
import { TripTypes } from '@model/contentful';
import { TourHit } from '@model/search/alogolia/tour-hit';
import { performNavigate } from '@state/app';
import { getAllCMSDestinationsSelector } from '@state/cms';
import { getSearchParams, setSearchParams, getToursByType, setTourByType } from '@state/search/tours';
import { getLeadInTourReferences } from '@state/tours/tour-references/tourReferencesSelectors';
import { fetchTours } from '@state/search-results/tours/toursOperations';
import { getToursError, getToursSearchResults, getToursSearchToken } from '@state/search-results/tours/toursSelectors';
import { getAlgoliaToursByType } from '@state/algolia/algoliaSelectors';
import { performSearchTours } from '@state/algolia/algoliaOperations';
import { parseOccupancy } from '@util/deal-finder';
import { toursSearchMapper } from '@util/tours';

export const withToursParams = (WrappedComponent: any) => (props: any) => {
  const { query, path } = props;

  if (!query) {
    return <WrappedComponent {...props} />;
  }

  const { searchToken, tripType: tripTypeString, destinations, airports, month, adults, departureDate } = query;
  const tripType = tripTypeString as TripTypes;

  const [onloadComplete, setOnloadComplete] = useState(false);

  const router = useRouter();
  const dispatch = useDispatch();
  const tourReferences = useSelector(getLeadInTourReferences);
  const selectedTours = useSelector(getToursByType(tripType));
  const { data: algoliaTours } = useSelector(getAlgoliaToursByType(tripType));
  const cmsDestinations = useSelector(getAllCMSDestinationsSelector);
  const searchParams = useSelector(getSearchParams);
  const toursResults = useSelector(getToursSearchResults);
  const toursError = useSelector(getToursError);
  const storedSearchToken = useSelector(getToursSearchToken);
  const nonDefaultSearchParams = pick(searchParams, ['airports', 'month']);

  const fetchSingleTour = async (tourPath) => {
    const { response } = (await performSearchTours({
      tripType: tripType,
      query: tourPath
    })) as any;

    if (response) {
      const { hits } = response;
      const tourMatch = hits.length === 1 ? hits[0] : hits.find(({ path }) => path.split('/').pop() === tourPath);
      if (tourMatch) {
        dispatch(
          setTourByType({
            tripType: tripType,
            tour: tourMatch
          })
        );
      }
    }
  };

  useEffect(() => {
    if (query && (every(values(nonDefaultSearchParams), isEmpty) || departureDate)) {
      const departureDateAsMonth = departureDate?.split('-').slice(0, 2).join('-');
      const validMonth = month?.split(',').map((m) => m.split('-').slice(0, 2).join('-'));
      dispatch(
        setSearchParams({
          airports: airports && airports.split(','),
          month: validMonth ? validMonth : departureDateAsMonth ? [departureDateAsMonth] : undefined,
          occupancy: adults ? parseOccupancy({ adults, children: '' }) : undefined
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!onloadComplete) {
      if (algoliaTours.length) {
        setOnloadComplete(true);

        if (destinations && tripType && !selectedTours.length) {
          const destinationsArray = destinations?.split(',');
          const destinationTours: Array<TourHit> = compact(
            destinationsArray?.map((path) => {
              const [continent, country, tourName] = path.split('/');
              if (tourName) {
                return;
              }
              const matchPath = `${continent}/${country}`;
              return algoliaTours.find((tour) => {
                return tour.path === matchPath;
              });
            })
          );

          // will populate tours for destination pages
          if (destinationTours && destinationTours.length === destinationsArray?.length) {
            destinationTours.forEach((tour) => {
              if (tour) {
                dispatch(
                  setTourByType({
                    tripType: tripType,
                    tour
                  })
                );
              }
            });
          } else {
            const [_a, _b, tourPath] = destinationsArray[0].split('/');
            if (tourPath) {
              fetchSingleTour(tourPath);
            }
          }
        }
      }
    }
  }, [algoliaTours]);

  useEffect(() => {
    if ((!tourReferences || !tourReferences.length || !toursResults.length) && !path && cmsDestinations.length > 0) {
      const searchParams = searchToken ? { searchToken } : toursSearchMapper(query, cmsDestinations);
      dispatch(fetchTours(searchParams));
    }
  }, [searchToken, query, cmsDestinations]);

  useEffect(() => {
    if (toursError) {
      const { searchToken, ...validParams } = query;
      if (toursError === ERROR_CODES.TOKEN_EXPIRED) {
        const searchParams = toursSearchMapper(validParams, cmsDestinations);
        dispatch(fetchTours(searchParams));
      }
    }
  }, [toursError]);

  useEffect(() => {
    if (storedSearchToken && searchToken && storedSearchToken !== searchToken) {
      const { searchToken: querySearchToken, ...validParams } = query;
      const { pathname } = router;
      dispatch(
        performNavigate({ pathname, query: { searchToken: storedSearchToken, ...validParams } }, undefined, {
          shallow: true
        })
      );
    }
  }, [storedSearchToken, searchToken]);

  return <WrappedComponent {...props} />;
};
