import { useEffect, useState } from 'react';

const TIMEOUT: number = 25;

export function useElapsedTimer(loopTime: number, counter: number) {
  const [elapsedTime, setElapsedTime] = useState(TIMEOUT);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let interval: any;
    if (counter > 1) {
      interval = setInterval(() => {
        setElapsedTime((current) => {
          if (current >= loopTime) {
            return 0;
          }
          return current + TIMEOUT;
        });
      }, TIMEOUT);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (elapsedTime === 0) {
      const nextIndex: number = index + 1;
      if (nextIndex >= counter) {
        setIndex(0);
      } else {
        setIndex(nextIndex);
      }
    }
  }, [elapsedTime]);
  return { elapsedTime, index };
}
