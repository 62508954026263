import { FlightsSortBy } from './flights-sort-by';
import { Flight } from '@model/iceberg/booking/package';

const getFlightsSortedByBestValue = (a: Flight, b: Flight) => {
  return b.value - a.value;
};

const getFlightsSortedByCheapest = (a: Flight, b: Flight) => {
  return a.price.amount - b.price.amount;
};

const getFlightsSortedByFastest = (a: Flight, b: Flight) => a.travelTime.total - b.travelTime.total;

const flightSortMapping = {
  [FlightsSortBy.BEST_VALUE]: getFlightsSortedByBestValue,
  [FlightsSortBy.CHEAPEST]: getFlightsSortedByCheapest,
  [FlightsSortBy.FASTEST]: getFlightsSortedByFastest
};

export const sortFlights = (flights: Array<Flight>, sort: FlightsSortBy) => {
  const sorter = flightSortMapping[sort];
  return flights.sort(sorter);
};
