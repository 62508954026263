/* *** MODELS *** */
import { PreferredContact } from '@model/forms/preferred-contact';
import { GeneratedByValue } from '@model/iceberg/service/contact-us';

export interface GeneralEnquiryFormValues {
  name: string;
  email: string;
  telephone: string;
  enquiry: string;
  preferredContact: PreferredContact;
}

export interface GeneralEnquiryFormPayload extends GeneralEnquiryFormValues, GeneratedByValue {
  reCaptcha: string;
}

/* *** INITIAL VALUES *** */
export const generalEnquiryInitialValues: GeneralEnquiryFormValues = {
  name: '',
  email: '',
  telephone: '',
  enquiry: '',
  preferredContact: PreferredContact.EMAIL
};
