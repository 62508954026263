import { ImageLoaderProps } from 'next/image';
import { breakpoints } from '@styles/breakpoints';
import { IMAGE_FALLBACK } from '@mock/fallback';
import { Image } from '@model/common';

const breakpointImageSizeMapper = {
  [breakpoints.small]: 'bigger-',
  [breakpoints.medium]: 'bigger-',
  [breakpoints.large]: 'xl-',
  [breakpoints.xLarge]: 'xxl-'
};

export const imageFlexLoader = ({ src, width }: ImageLoaderProps) => {
  if (/[?&;]w=/.test(src)) {
    return src.replace(/w\=\w*/, `w=${width}`);
  }
  return `${src}?w=${width}`;
};

export const IMAGE_FLEX_URL = 'https://images.mercuryholidays.co.uk';

export const isImageFlexUrl = (src) => typeof src === 'string' && src.includes(IMAGE_FLEX_URL);

export const getImageUrl = (url: string, width: number, original: boolean = false, isNextImage: boolean = false) => {
  if (!url) return IMAGE_FALLBACK[0].imageSet['600'];
  const w = Object.keys(breakpointImageSizeMapper).includes(`${width}`) ? width : breakpoints.small;

  if (isImageFlexUrl(url)) {
    return imageFlexLoader({ src: url, width: w });
  }

  if (url.includes('image-stock.mercuryholidays.co.uk')) {
    return url.replace(/xxl-/g, breakpointImageSizeMapper[width] || breakpointImageSizeMapper[breakpoints.small]);
  }
  const MH_CDN_URL = 'cfimages.mercuryholidays.co.uk';
  const format: string = original ? '' : '&fm=jpg';
  let imageUrl;
  if (url.includes('images.ctfassets.net')) {
    imageUrl = url.replace(/.*images.ctfassets.net/gi, 'https://' + MH_CDN_URL);
  }
  if (url.includes(MH_CDN_URL) || url.includes('downloads.ctfassets.net')) {
    imageUrl = url.replace(/downloads.ctfassets.net/gi, MH_CDN_URL).replace(/^\/\//, 'https://');
  }
  if (/^\/\w/.test(url) && !/^\/assets/.test(url)) {
    return imageFlexLoader({ src: `${IMAGE_FLEX_URL}${url}`, width: w });
  }
  return imageUrl ? `${imageUrl}${isNextImage ? '' : `?w=${w}${format}&fl=progressive&q=80`}` : url;
};

export const replaceImageSrc: (image: any) => void = (image: any) => {
  if (image.dataset.src) {
    image.src = image.dataset.src;
  }
  if (image.dataset.srcset) {
    image.srcset = image.dataset.srcset;
  }
};

export const removeFlexUrl = (img: Image) => {
  return {
    url: img.url.replace(IMAGE_FLEX_URL, '')
  };
};

export const truncateGallery = (gallery: Array<Image>, featuredImageUrl: string) =>
  gallery
    .filter(({ url }) => {
      return url !== featuredImageUrl;
    })
    .slice(0, 9)
    .map(removeFlexUrl);
