import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import {
  MediaPost,
  MediaPostBaseRequestPayload,
  MediaPostRequestPayload,
  MediaPostTripTypes
} from '@model/agents/agents-social-posts-models';
import { AsyncData } from '@model/common';

interface MediaPostApiRequests {
  getTripTypes: (paylod: MediaPostBaseRequestPayload) => Promise<MediaPostTripTypes>;
  getPostList: (payload: any) => Promise<AsyncData<MediaPost>>;
}

export class MediaPostApi implements MediaPostApiRequests {
  private api: Request = new Request();

  public async getTripTypes({ loginToken }: MediaPostBaseRequestPayload): Promise<MediaPostTripTypes> {
    return await this.api.get(uri(getURL()).path(EndPoint.MEDIA_POST_TRIP_TYPE).search({ loginToken }).href());
  }

  public async getPostList({ loginToken, payload }: MediaPostRequestPayload): Promise<AsyncData<MediaPost>> {
    const queryParamas = payload.constructor === Number ? `?id=` : `?tripType=`;

    return await this.api.get(
      uri(getURL()).path(EndPoint.MEDIA_POST).search(`${queryParamas}${payload}`).href(),
      loginToken
    );
  }
}
