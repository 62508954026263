interface Builder {
  withName: (name: string) => FilterBuilder;
  withQuery: (query: Array<string>) => FilterBuilder;
  build: () => Record<string, string> | null;
}

export class FilterBuilder implements Builder {
  private _name: string = '';
  private _query: Array<string> = [];

  public withName(name: string): FilterBuilder {
    this._name = name;
    return this;
  }

  public withQuery(query: Array<string>): FilterBuilder {
    this._query = query;
    return this;
  }

  public build(): Record<string, string> {
    if (!this.isEmpty()) {
      const { _name, _query } = this;
      return {
        [_name]: _query.join(',')
      };
    }
    return {};
  }

  private isEmpty(): boolean {
    const { _name, _query } = this;
    return !_name || !_query.length;
  }
}
