import { AirportMobilityOptions } from '@model/forms';

export enum GuestType {
  NONE = '',
  ADULT = 'Adult',
  CHILD = 'Child',
  INFANT = 'Infant'
}

export interface Guest {
  token: string;
  type: GuestType;
  lead: boolean;
  email: string;
  telephone: string;
  title: string;
  firstNames: string;
  lastName: string;
  dob: string;
  isVegan: boolean;
  isGlutenFree: boolean;
  isDiabetic: boolean;
  isVegetarian: boolean;
  dietaryRequirements: string;
  airportMobility: AirportMobilityOptions;
  contactByEmail: boolean;
}
