import { OffersResponse, OffersDestination, OfferType } from '@model/offers/offers';
import { EndPoint, Request, getURL } from '@model/service';
import uri from 'urijs';

interface OffersApiRequests {
  getOffersByType: (offerType: OfferType) => Promise<OffersResponse<OffersDestination>>;
  getOffersByDestination: (path: string) => Promise<OffersResponse<OfferType>>;
}

export class OffersApi implements OffersApiRequests {
  private api: Request = new Request();

  public async getOffersByType(offerType: OfferType): Promise<OffersResponse<OffersDestination>> {
    return await this.api.get(uri(getURL()).path(`${EndPoint.OFFERS}/${offerType}`).href()).catch(() => {
      return null;
    });
  }

  public async getOffersByDestination(path: string): Promise<OffersResponse<OfferType>> {
    return await this.api.get(uri(getURL()).path(`${EndPoint.OFFERS_DESTINATION}`).search({ path }).href());
  }
}
