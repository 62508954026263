import { combineReducers } from 'redux';
import { flightsReducer } from './flights/flightsOperations';
import { earlyFlightsReducer } from './early-flights/earlyFlightsOperations';
import { toursReducer } from './tours/toursOperations';
import { fromPricePackagesReducer } from './from-price-packages/fromPricePackagesOperations';

export const combinedSearchResultsReducers = combineReducers({
  flights: flightsReducer,
  earlyFlights: earlyFlightsReducer,
  tours: toursReducer,
  fromPrices: fromPricePackagesReducer
});
