import { OnInit } from '@model/bootstrap/on-init';
import axios from 'axios';
import { validate, v4 as uuidv4 } from 'uuid';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export class Uuid implements OnInit {
  uuid: string = '';
  setUuid(): void {
    const storedUuid = new Cookies().get(Cookie.X_UUID) || localStorage.getItem('uuid');
    if (!storedUuid || !validate(storedUuid)) {
      const newUuid = uuidv4();
      localStorage.setItem('uuid', newUuid);
      this.uuid = newUuid;
    } else {
      localStorage.setItem('uuid', storedUuid);
      this.uuid = storedUuid;
    }
  }
  getUuid(): string {
    if (!this.uuid) {
      this.setUuid();
    }
    return this.uuid;
  }
  initialize(): void {
    this.setUuid();
    const uuid = this.uuid;
    axios.interceptors.request.use(
      function (config) {
        config.headers['X-SESSION-UUID'] = uuid;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}
