import { omitBy, isNil, intersection } from 'lodash';
import {
  SearchPageUrlParams,
  UrlSearchParams,
  DealFinderUrlParams,
  DealFinderSearchParams
} from '@model/iceberg/deal-finder/deal-finder';
import { Occupancy, DOB } from '@model/state';
import { DealFinderState } from '@model/state/deal-finder-state';
import { SearchUrlBuilder } from '@model/search/fit/search-params/search-url-builder';
import { BoardBasis } from '@model/common/board-basis';
import { FILTER_DEFAULTS } from '@model/price-calendar';
import { isValidSearchDate } from '@util/date-time';
import { getQueryAsObject } from '@util/common';

export const getDealFinderUrlParams = (
  urlQuery: string,
  destinationAirports?: Array<string>
): DealFinderSearchParams => {
  const searchParams = getQueryAsObject(urlQuery);
  const { adults = '', children = '', duration, from, month, year, boardBasis }: UrlSearchParams = searchParams;
  const searchMonth =
    month && year && isValidSearchDate({ month, year, wholeMonth: true })
      ? `${year}-${Number(month) < 10 ? '0' : ''}${Number(month)}`
      : undefined;

  const urlParams = {
    airports: validateAirports(from, destinationAirports),
    duration: duration && !!Number(duration) ? Number(duration) : undefined,
    occupancy: adults ? parseOccupancy({ adults, children }) : undefined,
    month: searchMonth,
    preferredBoardBasis: boardBasis && BoardBasis[boardBasis] ? boardBasis : undefined
  };

  return omitBy(urlParams, isNil);
};

export const validateAirports = (
  airports?: Array<string> | string,
  airportList?: Array<string>
): Array<string> | undefined => {
  if (!airports) return undefined;
  const airportsArray = Array.isArray(airports) ? airports : airports.split(',');
  if (!airportList) return airportsArray;
  const validAirports = intersection(airportsArray, airportList);
  if (validAirports.length > 0) return validAirports;
  return undefined;
};

const parseChildren = (children: string): DOB[] => {
  return children.split(':').map(parseChild);
};

const parseChild = (child: string): DOB => {
  const [year, month, day] = child.split('-');
  return { year, month, day };
};

export const parseOccupancy = ({ adults = '', children = '' }: { adults: string; children?: string }): Occupancy[] => {
  if (!adults || !Number(adults.split(',')[0])) {
    return FILTER_DEFAULTS.occupancy;
  }
  const childArray = (children || '').split(',');
  return adults.split(',').map((roomAdults, index) => {
    const roomChildren = childArray[index] || '';
    return { adults: Number(roomAdults), children: roomChildren ? parseChildren(roomChildren) : [] };
  });
};

export const getAdults = (occupancy: Occupancy[]): string => {
  return occupancy.map(({ adults }) => adults).join(',');
};

const stringifyChildren = (children: DOB[]): string => {
  return children
    .map(({ day, month, year }) => `${year}-${Number(month) < 10 ? '0' : ''}${Number(month)}-${day}`)
    .join(':');
};

export const getChildren = (occupancy: Occupancy[]): string => {
  return occupancy.map(({ children }) => stringifyChildren(children)).join(',');
};

export const getDealFinderUrl = ({
  token,
  path,
  page,
  from,
  duration,
  occupancy,
  date,
  searchType,
  section,
  preferredBoardBasis
}: DealFinderUrlParams) => {
  const url = new SearchUrlBuilder()
    .withSearchToken(token)
    .withSearchType(searchType)
    .withProductPath(path)
    .withFrom(from)
    .withDuration(duration)
    .withAdults(getAdults(occupancy))
    .withChildren(getChildren(occupancy))
    .withDate(date)
    .withHash(section)
    .withBoardBasis(preferredBoardBasis)
    .build(page);
  return url;
};

export const getSearchQuery = ({
  airports,
  destinations,
  date,
  month,
  duration,
  occupancy,
  searchType,
  flexibleDays
}: DealFinderState): SearchPageUrlParams => {
  return {
    airports: airports.join(','),
    destinations: destinations.map(({ path }) => path).join(','),
    date,
    month,
    duration: duration.toString(),
    adults: getAdults(occupancy),
    children: getChildren(occupancy),
    searchType,
    flexibleDays: flexibleDays.toString()
  };
};

export const checkInDateToQuery = (checkInDate?: string) => {
  if (checkInDate) {
    const [year, month] = checkInDate?.split('-');
    if (year && month) {
      return `year=${year}&month=${month}`;
    }
    return '';
  }
  return '';
};
