import { BasketResponse } from '@model/iceberg';
import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { BookingEndpoints } from '@model/service/booking-endpoints';

export class RoomsApi {
  private api: Request = new Request();
  public async put(token: string, rooms: Array<string>): Promise<BasketResponse> {
    return await this.api.put(
      uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.ROOMS}`).href(),
      { rooms }
    );
  }
}
