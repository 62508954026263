import { DepartureDate } from '@model/forms/mmb-login-form';
/*
  Valid Telephone formats:
  (123) 456-78
  (123)456-78
  123-456-78
  123.456.78
  12345678
  +31636363
  075-63546
*/

export class Validation {
  private static TELEPHONE_REGEX: RegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,}$/i;
  private static BOOKING_REFERENCE_REGEX: RegExp = /^[0-9]{7}$/;
  private static DATE_FORMAT_VALIDATION_REGEX: RegExp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  private static EMAIL_REGEX: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

  public static isTelephoneValid(number: string): boolean {
    if (number === '') return false;
    const cleansed = number.replace(/ /g, '');
    return Validation.TELEPHONE_REGEX.test(cleansed);
  }

  public static isEmailValid(email: string): boolean {
    return Validation.EMAIL_REGEX.test(email);
  }

  public static isBookingReferenceValid(bookingReference: string): boolean {
    return Validation.BOOKING_REFERENCE_REGEX.test(bookingReference);
  }

  public static isDateFormatValid(date: string | DepartureDate): boolean {
    let dateValue;
    if (typeof date !== 'string') {
      const { day, month, year } = date;
      dateValue = `${year}-${month}-${day}`;
    } else {
      dateValue = date;
    }
    return Validation.DATE_FORMAT_VALIDATION_REGEX.test(dateValue);
  }

  public static isDepartureDateValid(date: DepartureDate): boolean {
    const { day, month, year } = date;
    return this.isDepartureDayValid(day) && this.isDepartureMonthValid(month) && this.isDepartureYearValid(year);
  }

  public static isDepartureDayValid(dayStr: string): boolean {
    const day = parseInt(dayStr, 10);
    const invalid = isNaN(day) || day < 1 || day > 31;
    return !invalid;
  }

  public static isDepartureMonthValid(monthStr: string): boolean {
    const month = parseInt(monthStr, 10);
    const invalid = isNaN(month) || month < 1 || month > 12;
    return !invalid;
  }

  public static isDepartureYearValid(yearStr: string): boolean {
    const year = parseInt(yearStr, 10);
    const invalid = isNaN(year) || year < 2013 || year > new Date().getFullYear() + 50;
    return !invalid;
  }
}
