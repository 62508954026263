import { map, pick } from 'lodash';
import {
  TruncatedEntryCollection,
  CMSDestination,
  ComponentTypes,
  Experience,
  MiniExperience,
  TourDataReference
} from '@model/contentful';
import { Destination, Name } from '@model/iceberg';
import { Keyable, DEFAULT_PRICE_OBJECT } from '@model/common';

export const getValidFields = (obj: Keyable) => {
  if (Array.isArray(obj)) {
    return obj.map(getValidFields);
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  const newObj = Object.assign({}, obj);
  delete newObj.metadata;
  const { sys } = newObj;
  if (sys) {
    const newSys = {} as Keyable;
    if (sys.contentType) {
      newSys.contentType = sys.contentType;
    }
    if (sys.id) {
      newSys.id = sys.id;
    }
    newObj.sys = Object.entries(newSys).length > 0 ? newSys : undefined;
  }
  Object.entries(newObj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      newObj[key] = getValidFields(value);
    }
  });
  return newObj;
};

export const findCmsDestinationByPath = (
  path: string,
  cmsDestinations: Array<CMSDestination>
): CMSDestination | undefined => {
  return cmsDestinations.find(({ destinationPath }) => path.indexOf(destinationPath.fields.path) === 0);
};

export const getCmsDestinationByPath = (
  path: string,
  cmsDestinations: Array<CMSDestination>
): CMSDestination | undefined => {
  return cmsDestinations.find(({ destinationPath }) => path === destinationPath.fields.path);
};

export const mapPickFields = (arr) => (obj) => {
  return {
    fields: pick(obj.fields, arr)
  };
};

export const filterComponentFieldsByComponentType = (cmsComponent: any): any => {
  const { sys, fields } = cmsComponent;
  const componentType = sys.contentType.sys.id;
  switch (componentType) {
    case ComponentTypes.destinationGroupComponent: {
      const destPick = mapPickFields([
        'destinationPath',
        'title',
        'fromPriceHoliday',
        'fromPriceEscortedTour',
        'fromPriceRiverCruise',
        'fromPriceMultiCentre',
        'fromPriceWalkingTour',
        'fromPriceRailJourney',
        'fromPricePrivateTour',
        'fromPriceFlyDrive'
      ]);
      return {
        sys,
        fields: {
          ...fields,
          destinations: fields.destinations?.map(destPick) || []
        }
      };
    }
    case ComponentTypes.productTileGroupComponent:
      return {
        sys,
        fields: pick(fields, ['title', 'subTitle', 'ctaTitle', 'ctaPath', 'products'])
      };
    case ComponentTypes.expandableListGroup: {
      const itemPick = mapPickFields(['title', 'icon', 'text']);

      return {
        sys,
        fields: {
          ...fields,
          items: fields.items.map(itemPick)
        }
      };
    }
  }
  return cmsComponent;
};

export const mapExperiences = (experiences: TruncatedEntryCollection<Experience>): Array<MiniExperience> =>
  (experiences?.items || []).map((experience) => {
    return {
      ...pick(experience.fields, ['days', 'title', 'path', 'hero']),
      price: {
        ...DEFAULT_PRICE_OBJECT,
        amount: experience.fields.price
      }
    };
  });

export const mapToursToNames = (tours: Array<TourDataReference>): Array<Name> =>
  tours.map((tour) => {
    return {
      path: tour.destination?.fields?.destinationPath?.fields?.path || '',
      display: tour.destination?.fields?.title || ''
    };
  });

export const mapDestinationsToNames = (destinations: Array<Destination>): Array<Name> =>
  map(destinations, (dest) => pick(dest, ['path', 'display']) as Name);
