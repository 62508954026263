import { ImageParams, ImageResponse } from '@model/iceberg';
import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';

interface ImageApiRequests {
  get: (payload: ImageParams) => Promise<Array<ImageResponse>>;
}

export class ImageApi implements ImageApiRequests {
  private request: Request = new Request();

  public async get(payload: ImageParams): Promise<Array<ImageResponse>> {
    const { category, sizes, count = 1, path } = payload;
    return await this.request
      .get(
        uri(getURL())
          .path(`${EndPoint.IMAGE}/${category.toLowerCase()}/${count}`)
          .search({ w: sizes.join(','), path })
          .href()
      )
      .catch(() => {
        return [];
      });
  }
}
