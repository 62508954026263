import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { Agent } from '@model/agents/agents-models';
import { AsyncData } from '@model/common';

export const getAgent = (state: GlobalAppState) => state.agent;
export const getIsAgent = (state: GlobalAppState) => !!state.agent.data.abta;
export const getAbta = (state: GlobalAppState) => state.agent.data.abta;

export const getRequiresPayment = createSelector(
  getIsAgent,
  getAgent,
  (isAgent: boolean, agent: AsyncData<Agent>) => isAgent && agent.data.requiresPayment
);

export const getIsAgentLockedOut = (state: GlobalAppState) =>
  !!state.agent.error && !!state.agent.error.lockoutTimeRemaining && !state.agent.error.attemptsRemaining;
export const getAgentAttemptsRemaining = (state: GlobalAppState) =>
  (!!state.agent.error && state.agent.error.attemptsRemaining) || 0;
