import { EnvConfig } from '@model/config/env-config';
import algoliaSearch, { SearchClient } from 'algoliasearch';
import { TripTypes } from '@model/common/tours/trip-types';
import { GeographyResult } from '@model/search';
import { SearchResponse } from '@algolia/client-search';
import { isTourTripType } from '@util/tours';

export enum AlgoliaIndex {
  GEOGRAPHY = 'geography',
  AIRPORT = 'airport',
  TOURS = 'tours_v2',
  PRIVATE_TOURS = 'private_tours_v2',
  RIVER_CRUISES = 'river_cruises_v2',
  MULTI_CENTRE = 'multicentre_v2',
  HELP_CENTRE = 'help_centre_articles'
}

export enum AlgoliaFilter {
  DESTINATIONS = 'type:1 TO 2',
  SUGGESTED = 'suggested: 1 TO 100',
  TOUR_DESTINATIONS = 'type:destination'
}

enum ENVIRONMENT_PREFIXES {
  develop = 'dev',
  production = 'production'
}

export enum BRAND_SUFFIXES {
  mercury_holidays = 'mercuryholidays',
  mercury_holidays_ireland = 'mercuryholidays-ireland',
  sunspottours = 'sunspottours'
}

const TENANT_SPECIFIC_INDEXES = [AlgoliaIndex.AIRPORT, AlgoliaIndex.GEOGRAPHY];

export interface SearchConfig {
  index: AlgoliaIndex;
  query?: string;
  filters?: string;
  facetFilters?: Array<string>;
  tripType?: TripTypes;
}

export interface BrowseConfig {
  index: AlgoliaIndex;
  query?: string;
  filters?: string;
}

interface AlgoliaApiRequests {
  search: (config: SearchConfig) => Promise<SearchResponse<GeographyResult>>;
  browse: (config: BrowseConfig) => Promise<any>;
}

export class AlgoliaApi implements AlgoliaApiRequests {
  private client: SearchClient;
  private environment: string;
  private brand: string;

  public constructor() {
    const { ALGOLIA_APP_ID, ALGOLIA_API_KEY, APP_ENV, APP_VARIANT_ID } = EnvConfig.get();
    const algoliaAppId: string = ALGOLIA_APP_ID;
    const algoliaAppKey: string = ALGOLIA_API_KEY;
    this.client = algoliaSearch(algoliaAppId, algoliaAppKey);
    this.environment = ENVIRONMENT_PREFIXES[APP_ENV || 'develop'] || ENVIRONMENT_PREFIXES.develop;
    this.brand = BRAND_SUFFIXES[APP_VARIANT_ID];
  }

  public getIndex(index: AlgoliaIndex, tripType?: TripTypes): string {
    return `${this.environment}_${index}${
      TENANT_SPECIFIC_INDEXES.includes(index)
        ? `_${isTourTripType(tripType) ? BRAND_SUFFIXES['mercury_holidays'] : this.brand}`
        : ''
    }`;
  }

  public async search<T>(config: SearchConfig): Promise<SearchResponse<T>> {
    const { index, query = '', facetFilters = [], filters = '', tripType } = config;
    return this.client
      .initIndex(this.getIndex(index, tripType))
      .search(query, { hitsPerPage: 100, facetFilters, filters });
  }

  public async browse(config: BrowseConfig): Promise<any> {
    const { query = '', filters = '', index } = config;
    let hits: any = [];
    await this.client
      .initIndex(this.getIndex(index))
      .browseObjects({ query, filters, batch: (batch: any) => (hits = hits.concat(batch)) });
    return hits;
  }
}
