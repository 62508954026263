export enum FilterTypes {
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  STAR_RATING = 'STAR_RATING',
  TRIP_ADVISOR = 'TRIP_ADVISOR',
  PRICE_RANGE = 'PRICE_RANGE',
  RANGE_SLIDER = 'RANGE_SLIDER'
}

export interface FilterMappingValue {
  type: FilterTypes;
  variant?: MultiSelectFilterVariants;
  displayAllOption?: string; // this is the filter option, which will be treated as ALL (will display ALL the results)
  tooltip?: string;
  subHeading?: string;
}

export interface FilterMapping {
  [key: string]: FilterMappingValue;
}

export enum SpecialFilters {
  ALL = 'ALL'
}

export enum HotelAccessible {
  YES = 'Yes',
  NO = 'No'
}

export enum MultiSelectFilterVariants {
  SELECT = 'select-wanted-options',
  UNSELECT = 'unselect-unwanted-options'
}

export enum SortByOptions {
  PRICE = 'price',
  POPULAR = 'popular',
  RESORTS = 'resorts',
  HOTELS = 'hotels',
  TOUR_LENGTH = 'tour-length'
}

export enum FilterNames {
  HOTEL_RATING_OCCURENCE = 'hotelRatingOccurrence',
  RESORTS = 'resorts',
  MEAL_PLANS = 'mealPlans',
  ACCESSIBLE = 'accessible',
  STOPS = 'stops',
  DEPARTING_FROM = 'departingFrom',
  AIRLINE = 'airline',
  MINIMUM_PRICE = 'minimumPrice',
  MAXIMUM_PRICE = 'maximumPrice',
  OUTBOUND_DEPARTURE_TIME = 'outboundDepartureTime',
  RETURN_DEPARTURE_TIME = 'returnDepartureTime',
  TOUR_DURATION = 'duration',
  TOUR_TRIP_TYPE = 'tripType',
  TOUR_TYPE = 'tourType'
}

export enum FiltersSideBarType {
  SEARCH = 'search',
  FLIGHTS = 'flights',
  TOURS = 'tours'
}

export enum TourTypeToggleOnOption {
  MAIN_TOURS = 'mainTours'
}
