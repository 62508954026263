import dinero, { Currency } from 'dinero.js';
import { getLocaleByCurrencyCode } from '@util/currency';
import { DEFAULT_CURRENCY } from '@model/common/currency/currency';
import { Currency as CommonCurrency } from '@model/common/currency/currency';

export enum RoundingDirection {
  UP = 0,
  DOWN = 1
}

export class Amount {
  public readonly amount: dinero.Dinero;
  public readonly currency: Currency;
  constructor(amount: number = 0, currency: Currency = DEFAULT_CURRENCY) {
    this.amount = dinero({ amount, currency }).setLocale(getLocaleByCurrencyCode(currency));
    this.currency = currency;
  }

  add(addend: number, currency?: Currency): Amount {
    const addendDinero: dinero.Dinero = dinero({ amount: addend, currency: currency || this.currency });
    return new Amount(this.amount.add(addendDinero).getAmount(), this.currency);
  }

  multiply(multiplier: number): Amount {
    return new Amount(this.amount.multiply(multiplier).getAmount(), this.currency);
  }

  divide(divisor: number): Amount {
    if (divisor) {
      return new Amount(this.amount.divide(divisor).getAmount(), this.currency);
    }
    return new Amount(this.amount.getAmount(), this.currency);
  }

  round(mode: RoundingDirection): Amount {
    const amount: number = this.amount.toUnit();
    switch (mode) {
      case RoundingDirection.UP:
        return new Amount(Math.ceil(amount) * 100, this.currency);
      case RoundingDirection.DOWN:
        return new Amount(Math.floor(amount) * 100, this.currency);
      default:
        return new Amount(amount, this.currency);
    }
  }

  subtract(subtrahend: number, currency?: Currency): Amount {
    const subtrahendDinero: dinero.Dinero = dinero({ amount: subtrahend, currency: currency || this.currency });
    return new Amount(this.amount.subtract(subtrahendDinero).getAmount(), this.currency);
  }

  format() {
    return this.amount.toFormat('$0,0.00').replace('.00', '');
  }

  formatDiff() {
    const sign = this.amount.getAmount() >= 0 ? '+' : '';
    return sign.concat(this.amount.toFormat('$0,0.00').replace('.00', ''));
  }

  formatNoSign() {
    return this.amount.toFormat('0.00');
  }

  get value(): number {
    return this.amount.getAmount();
  }
}

export const formatAmount = (amount: number, currency: CommonCurrency) => {
  return new Amount(amount, currency.code).format();
};
