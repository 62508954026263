import { AsyncData } from '@model/common';
import { PromoBannerApi } from '@model/iceberg/service/promo-banner/promo-banner-api';
import { GetPromoBannerDataState, PromoBannerData } from '@model/promo-banner';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseAction } from '@model/redux';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';

export enum PromoBannerActions {
  FETCH_PROMO_BANNER = '@PROMO_BANNER/FETCH_PROMO_BANNER',
  RECEIVE_PROMO_BANNER_SUCCESS = '@PROMO_BANNER/RECEIVE_PROMO_BANNER_SUCCESS',
  RECEIVE_PROMO_BANNER_FAILURE = '@PROMO_BANNER/RECEIVE_PROMO_BANNER_FAILURE',
  SET_PROMO_BANNER = '@PROMO_BANNER/SET_PROMO_BANNER'
}

export interface SetPromoBannerType extends BaseAction {
  type: PromoBannerActions.SET_PROMO_BANNER;
  payload: Array<PromoBannerData>;
}

export const performFetchPromoBanner = () => ({
  type: PromoBannerActions.FETCH_PROMO_BANNER
});

export const receivePromoBannerSuccess = (payload: PromoBannerData) => ({
  type: PromoBannerActions.RECEIVE_PROMO_BANNER_SUCCESS,
  payload
});

export const receivePromoBannerFailure = (error: any) => ({
  type: PromoBannerActions.RECEIVE_PROMO_BANNER_FAILURE,
  error
});

export function* onFetchPromoBanner() {
  yield takeLatest(PromoBannerActions.FETCH_PROMO_BANNER, handleOnPerformFetchPromoBanner);
}

export function* handleOnPerformFetchPromoBanner() {
  try {
    const response = yield call(fetchPromoBanner);
    if (response) {
      yield put(receivePromoBannerSuccess(response.data));
    }
  } catch (e: any) {
    const {
      response: { status, data }
    } = e;
    yield put(receivePromoBannerFailure({ status, data }));
  }
}

export function fetchPromoBanner() {
  const api: PromoBannerApi = new PromoBannerApi();
  return api
    .getPromoBanner()
    .then((response: GetPromoBannerDataState) => ({ response }))
    .catch((error: any) => ({
      error
    }));
}

export const setPromoBanner: (payload: Array<PromoBannerData>) => SetPromoBannerType = (
  payload: Array<PromoBannerData>
) => ({
  type: PromoBannerActions.SET_PROMO_BANNER,
  payload
});

export const INITIAL_PROMO_BANNER_STATE: AsyncData<GetPromoBannerDataState> = {
  data: [] as any,
  loading: false,
  error: null
};

export const promoBannerReducer: any = (state: any = INITIAL_PROMO_BANNER_STATE, { type, payload, error }: any) => {
  switch (type) {
    case HYDRATE:
      if (isEqual(state, INITIAL_PROMO_BANNER_STATE)) {
        return payload?.promoBanner?.data?.length ? payload.promoBanner : state;
      }
      return state;
    case PromoBannerActions.RECEIVE_PROMO_BANNER_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case PromoBannerActions.RECEIVE_PROMO_BANNER_FAILURE:
      return { ...state, data: INITIAL_PROMO_BANNER_STATE.data, error, loading: false };
    case PromoBannerActions.FETCH_PROMO_BANNER:
      return { ...state, loading: true };
    case PromoBannerActions.SET_PROMO_BANNER:
      return { ...state, data: payload, error: null, loading: false };
    default:
      return state;
  }
};
