export interface BuildTag {
  build: (tag: string) => string;
}

export class TagBuilder implements BuildTag {
  private readonly TAG_PREFIX: string = '_tags:';

  public build(tag: string): string {
    return tag ? `${this.TAG_PREFIX}${tag}` : '';
  }
}
