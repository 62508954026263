import { createTheme } from '@mui/material/styles';
import {
  FontSize,
  GridSpacing,
  Spacing,
  MercuryTheme,
  ContentSizes,
  Transitions,
  SkeletonElements
} from '../base/theme';
import { colorGroups } from './colors';
import { typography } from '@theme/mercuryHolidaysTheme/typography';
import { SkeletonSize } from '@components/common/skeleton-loading';
import { breakpoints } from '@styles/breakpoints';

const MAX_CONTENT_WIDTH = 1318;
const MIN_CONTENT_WIDTH = 320;
const SIDEBAR_WIDTH = 330;
const DRAWER_WIDTH = 350;
const TAB_NAV_HEIGHT = 50;

const spacing: Spacing = {
  xxSmall: 2,
  xSmall: 5,
  small: 10,
  medium: 16,
  large: 20,
  xLarge: 30,
  xxLarge: 50,
  xxxLarge: 100
};

const gridSpacing: GridSpacing = {
  xSmall: 1,
  small: 2,
  default: 4
};

const fontSize: FontSize = {
  xSmall: '0.8rem',
  small: '1rem',
  medium: '1.6rem',
  large: '2rem',
  xLarge: '3rem'
};

const contentSizes: ContentSizes = {
  minContentWidth: MIN_CONTENT_WIDTH,
  maxContentWidth: MAX_CONTENT_WIDTH,
  sidebarWidth: SIDEBAR_WIDTH,
  tabNavHeight: TAB_NAV_HEIGHT,
  drawerWidth: DRAWER_WIDTH
};

const transitions: Transitions = {
  longSmooth: '0.5s ease',
  default: '0.3s linear',
  smooth: '0.3s ease-in-out',
  fastSmooth: '0.1s ease-in-out'
};

const skeleton: SkeletonElements = {
  text: {
    [SkeletonSize.SMALL]: {
      width: '100%',
      height: 20
    },
    [SkeletonSize.MEDIUM]: {
      width: '100%',
      height: 30
    },
    [SkeletonSize.LARGE]: {
      width: '100%',
      height: 50
    }
  },
  heading: {
    [SkeletonSize.SMALL]: {
      width: '50%',
      height: 50
    },
    [SkeletonSize.MEDIUM]: {
      width: '50%',
      height: 70
    },
    [SkeletonSize.LARGE]: {
      width: '50%',
      height: 100
    }
  },
  rectangle: {
    [SkeletonSize.SMALL]: {
      width: 20,
      height: 20
    },
    [SkeletonSize.MEDIUM]: {
      width: 50,
      height: 50
    },
    [SkeletonSize.LARGE]: {
      width: 100,
      height: 100
    }
  },
  circle: {
    [SkeletonSize.SMALL]: {
      width: 20,
      height: 20
    },
    [SkeletonSize.MEDIUM]: {
      width: 50,
      height: 50
    },
    [SkeletonSize.LARGE]: {
      width: 100,
      height: 100
    }
  }
};

export const mercuryHolidaysTheme: MercuryTheme = {
  colors: {
    ...colorGroups,
    white: '#fff',
    black: '#000',
    thirdParty: {
      tripAdvisor: '#00AA6C'
    },
    overlay: {
      lighter: 'rgba(19, 19, 19, 0.5)',
      light: 'rgba(19, 19, 19, 0.6)',
      base: 'rgba(19, 19, 19, 0.7)',
      dark: 'rgba(2, 37, 49, 0.9)',
      darker: 'rgba(19, 19, 19, 0.9)'
    }
  },
  states: {
    hover: {
      light: colorGroups.group7.light,
      dark: colorGroups.group7.base
    },
    selected: {
      light: colorGroups.group1.light,
      dark: colorGroups.group1.base
    },
    error: {
      light: colorGroups.group11.lighter,
      dark: colorGroups.group11.base
    },
    default: {
      light: '#fff',
      dark: colorGroups.group10.base
    },
    link: {
      default: colorGroups.group2.base,
      hover: colorGroups.group2.dark,
      active: colorGroups.group2.base,
      visited: colorGroups.group2.base
    },
    warning: {
      light: colorGroups.group8.lighter,
      dark: colorGroups.group8.base
    },
    info: {
      light: colorGroups.group1.lighter,
      dark: colorGroups.group1.base
    },
    success: {
      light: colorGroups.group5.lighter,
      dark: colorGroups.group5.base
    },
    buttons: {
      primary: {
        default: colorGroups.group2.base,
        hover: colorGroups.group2.dark,
        disabled: colorGroups.group2.lighter,
        light: colorGroups.group2.lighter
      },
      secondary: {
        default: colorGroups.group2.base,
        hover: colorGroups.group2.dark,
        disabled: colorGroups.group2.lighter,
        light: colorGroups.group2.lighter
      },
      tertiary: {
        default: colorGroups.group5.base,
        hover: colorGroups.group5.dark,
        disabled: colorGroups.group5.lighter,
        light: colorGroups.group5.lighter
      },
      quaternary: {
        default: '#fff',
        hover: colorGroups.group2.dark,
        disabled: '#fff',
        light: colorGroups.group2.dark
      }
    }
  },
  spacing,
  gridSpacing,
  fontSize,
  contentSizes,
  transitions,
  typography,
  skeleton
};

export const MuiTheme = createTheme({
  typography: {
    fontFamily: typography.defaultFont
  },
  palette: {
    primary: {
      main: colorGroups.group1.base
    },
    text: {
      primary: colorGroups.group4.base,
      secondary: colorGroups.group4.base
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: breakpoints.small,
      md: breakpoints.medium,
      lg: breakpoints.large,
      xl: breakpoints.xLarge
    }
  },
  custom: mercuryHolidaysTheme
});
