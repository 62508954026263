import { CMSDestinations, CMSDestination } from '@model/contentful';
import { CmsGlobalConfigState } from '@model/state/cms-global-config-state';
import { isEqual } from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';
import { GlobalActions } from '@state/store';
import { BaseAction } from '@model/redux';

/* ***************** *
 *       TYPES       *
 * ***************** */
export type CmsPayload = Array<any> | any;

export interface CMSState {
  cmsDestinations: CMSDestinations;
  cmsGlobalConfig: CmsGlobalConfigState;
  cmsHereToHelp: any;
  cmsOffers: any;
}

export interface CMSAction extends BaseAction {
  type: CMSActionTypes | GlobalActions;
  payload: any;
}

/* ***************** *
 *   ACTION TYPES    *
 * ***************** */

export enum CMSActionTypes {
  SET_CMS_DESTINATIONS = '@CMS/SET_CMS_DESTINATIONS',
  SET_CMS_GLOBAL_CONFIG = '@CMS/SET_CMS_GLOBAL_CONFIG',
  SET_CMS_HERE_TO_HELP = '@CMS/SET_CMS_HERE_TO_HELP',
  SET_CMS_OFFERS = '@CMS/SET_CMS_OFFERS'
}

/* ***************** *
 *     ACTIONS       *
 * ***************** */

export interface SetCMSDestinationsType extends BaseAction {
  type: CMSActionTypes.SET_CMS_DESTINATIONS;
  payload: CmsPayload;
}

export function setCMSDestinations(payload: CMSDestination[]): SetCMSDestinationsType {
  return {
    type: CMSActionTypes.SET_CMS_DESTINATIONS,
    payload
  };
}

export interface SetCMSGlobalConfig extends BaseAction {
  type: CMSActionTypes.SET_CMS_GLOBAL_CONFIG;
  payload: any;
}

export function setCMSGlobalConfig(payload: any): SetCMSGlobalConfig {
  return {
    type: CMSActionTypes.SET_CMS_GLOBAL_CONFIG,
    payload
  };
}

export interface SetCMSHereToHelp extends BaseAction {
  type: CMSActionTypes.SET_CMS_HERE_TO_HELP;
  payload: any;
}

export function setCMSHereToHelp(payload: any): SetCMSHereToHelp {
  return {
    type: CMSActionTypes.SET_CMS_HERE_TO_HELP,
    payload
  };
}

export interface SetCMSOffers extends BaseAction {
  type: CMSActionTypes.SET_CMS_OFFERS;
  payload: any;
}

export function setCMSOffers(payload: any): SetCMSOffers {
  return {
    type: CMSActionTypes.SET_CMS_OFFERS,
    payload
  };
}

/* ***************** *
 *     REDUCER       *
 * ***************** */

export const CMS_INITIAL_STATE: CMSState = {
  cmsDestinations: [],
  cmsGlobalConfig: {
    appVariantId: 'MercuryHolidays',
    themeId: 'mercuryHolidaysTheme'
  },
  cmsHereToHelp: {},
  cmsOffers: {}
};

export const cmsReducer = (state: CMSState = CMS_INITIAL_STATE, action: CMSAction) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, CMS_INITIAL_STATE)) {
        return action.payload?.cms || state;
      }
      return state;
    case CMSActionTypes.SET_CMS_DESTINATIONS:
      return {
        ...state,
        cmsDestinations: action.payload
      };
    case CMSActionTypes.SET_CMS_GLOBAL_CONFIG:
      return {
        ...state,
        cmsGlobalConfig: action.payload
      };
    case CMSActionTypes.SET_CMS_HERE_TO_HELP:
      return {
        ...state,
        cmsHereToHelp: action.payload
      };
    case CMSActionTypes.SET_CMS_OFFERS:
      return {
        ...state,
        cmsOffers: action.payload
      };
    default:
      return state;
  }
};
