export enum HelpCategoryStatus {
  DRAFT = 'Draft',
  PREVIEW = 'Preview',
  PUBLISHED = 'Published'
}

export interface HelpCategoryListItem {
  id: number;
  title: string;
  description: string;
}

export interface HelpCategoriesResponse {
  data: Array<HelpCategoryListItem>;
}
