import { BaseAction } from '@model/redux';

export enum PaymentErrorActions {
  SET_PAYMENT_ERROR = '@APP/SET_PAYMENT_ERROR'
}

export interface SetPaymentErrorAction extends BaseAction {
  type: PaymentErrorActions.SET_PAYMENT_ERROR;
  payload: string;
}

export const setPaymentError: (payload: string) => SetPaymentErrorAction = (payload: string) => ({
  type: PaymentErrorActions.SET_PAYMENT_ERROR,
  payload
});

export const INITIAL_PAYMENT_ERROR_STATE: string = '';

export const paymentErrorReducer = (state: string = INITIAL_PAYMENT_ERROR_STATE, action: any) => {
  switch (action.type) {
    case PaymentErrorActions.SET_PAYMENT_ERROR:
      return action.payload;
    default:
      return state;
  }
};
