import { PromoModalData } from '@model/promo-banner';
import { BaseAction } from '@model/redux';

export enum PromoModalActions {
  SET_PROMO_MODAL = '@APP/SET_PROMO_MODAL'
}

export interface SetPromoModalType extends BaseAction {
  type: PromoModalActions.SET_PROMO_MODAL;
  payload: PromoModalData;
}

export const setPromoModal: (payload: PromoModalData) => SetPromoModalType = (payload: PromoModalData) => ({
  type: PromoModalActions.SET_PROMO_MODAL,
  payload
});

export const INITIAL_PROMO_MODAL_STATE = {
  id: null,
  centreAlignText: false,
  markdown: '',
  title: ''
};

export const promoModalReducer = (state: PromoModalData = INITIAL_PROMO_MODAL_STATE, action: any) => {
  switch (action.type) {
    case PromoModalActions.SET_PROMO_MODAL:
      return action.payload;
    default:
      return state;
  }
};
