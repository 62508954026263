export enum Months {
  JAN = 'jan',
  FEB = 'feb',
  MAR = 'mar',
  APR = 'apr',
  MAY = 'may',
  JUN = 'jun',
  JUL = 'jul',
  AUG = 'aug',
  SEP = 'sep',
  OCT = 'oct',
  NOV = 'nov',
  DEC = 'dec'
}

export const monthsWeight = {
  [Months.JAN]: 0,
  [Months.FEB]: 1,
  [Months.MAR]: 2,
  [Months.APR]: 3,
  [Months.MAY]: 4,
  [Months.JUN]: 5,
  [Months.JUL]: 6,
  [Months.AUG]: 7,
  [Months.SEP]: 8,
  [Months.OCT]: 9,
  [Months.NOV]: 10,
  [Months.DEC]: 11
};
