import { TripTypes } from '@model/common/tours/trip-types';
import { TourHit } from '@model/search';

export interface ToursByTypeProps {
  tripType: TripTypes;
  tours: Array<TourHit>;
}

export interface TourByTypeProps {
  tripType: TripTypes;
  tour: TourHit;
}

export enum ToursStateName {
  ESCORTED_TOURS = 'escortedTours',
  RIVER_CRUISES = 'riverCruises',
  PRIVATE_TOURS = 'privateTours',
  MULTI_CENTRE = 'multiCentres'
}
