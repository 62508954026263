import { Icon } from '@components/common/generic-icon';
import AccordionMUI from '@mui/material/Accordion';
import AccordionSummaryMUI from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';
import { CSSObject } from '@emotion/serialize';
import { Icons } from '@model/common/icons';
import React, { useState } from 'react';
import { ThemeProps } from '@theme/base';

const Chevron = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.5rem',
  color: theme.custom.colors.group4.base
}));

interface AccordionStyleProps extends ThemeProps {
  styles?: CSSObject;
}

const AccordionMUIStyled: any = styled(AccordionMUI)(({ theme, styles }: AccordionStyleProps) => ({
  border: `1px solid ${theme.custom.colors.group10.light}`,
  marginBottom: -1,
  boxShadow: 'none',
  ['.MuiAccordionSummary-content']: {
    margin: `${theme.custom.spacing.large}px 0`
  },
  '&::before': {
    content: 'none'
  },
  ['&.MuiAccordion-rounded']: {
    ['&:first-of-type']: {
      borderTopLeftRadius: theme.custom.spacing.small,
      borderTopRightRadius: theme.custom.spacing.small
    },
    ['&:last-of-type']: {
      borderBottomLeftRadius: theme.custom.spacing.small,
      borderBottomRightRadius: theme.custom.spacing.small
    },
    ['&.last-expanded']: {
      borderBottomLeftRadius: theme.custom.spacing.small,
      borderBottomRightRadius: theme.custom.spacing.small
    },
    ['&.Mui-expanded']: {
      borderRadius: theme.custom.spacing.small,
      ['+ :not(.Mui-expanded)']: {
        borderTopLeftRadius: theme.custom.spacing.small,
        borderTopRightRadius: theme.custom.spacing.small
      }
    }
  },
  ...styles
}));

const Title = styled(AccordionSummaryMUI)(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xSmall,
  marginBottom: theme.custom.spacing.xSmall,
  transition: `color ${theme.custom.transitions.default}`,
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  color: theme.custom.colors.group4.base,
  margin: 0,
  ':hover': {
    color: theme.custom.states.link.hover,
    i: {
      color: theme.custom.states.link.hover
    }
  }
}));

export interface AccordionProps {
  items: Array<any>;
  renderItem: (item: any, index?: number) => JSX.Element | Array<JSX.Element>;
  renderTitle: (item: any) => JSX.Element;
  styles?: CSSObject;
  itemIsExpanded?: (item: any) => boolean;
  resetExpanded?: (item: any, itemExpanded: boolean) => void;
}

export const Accordion = ({
  items,
  renderItem,
  renderTitle,
  styles,
  itemIsExpanded,
  resetExpanded
}: AccordionProps) => {
  const [expandedItems, updateExpandedItems] = useState(new Array(items.length).fill(false));

  const onChange = (event, expanded) => {
    const index = event.currentTarget?.parentNode?.getAttribute('data-index');
    const updatedExpandedItems = expandedItems.slice();
    updatedExpandedItems[Number(index)] = expanded;
    updateExpandedItems(updatedExpandedItems);
    if (resetExpanded) {
      resetExpanded(items[index], expanded);
    }
  };

  const renderExpansionItems = () =>
    items.map((item: any, index: number) => {
      const itemExpanded = expandedItems[index] || (itemIsExpanded && itemIsExpanded(item));
      const nextItemExpanded = !!expandedItems[index + 1];
      return (
        <AccordionMUIStyled
          styles={styles}
          key={index}
          onChange={onChange}
          className={!itemExpanded && nextItemExpanded ? 'last-expanded' : ''}
          data-index={index}
          expanded={itemExpanded}
        >
          <Title expandIcon={<Chevron name={Icons.CHEVRON_DOWN} />}>{renderTitle(item)}</Title>
          {renderItem(item, index)}
        </AccordionMUIStyled>
      );
    });
  return <>{renderExpansionItems()}</>;
};
