import React, { useEffect } from 'react';
import { Logging } from '@util/logging';

export interface RTapNumberProps {
  telephoneNumber?: string;
}

export const rTapNotifyDOMChangeWrapper = () => {
  if (window['rTapNotifyDOMChange'] && typeof window['rTapNotifyDOMChange'] === 'function') {
    try {
      window['rTapNotifyDOMChange'](document.body);
    } catch (e) {
      Logging.error({
        text: `rTapNotifyDOMChange Error`
      });
    }
  }
};

export const RTAP_CODE = '383796';

export const RTAP_CLASSNAME = `rTapNumber${RTAP_CODE}`;

export const RTAP_LINK = 'responseTap';

export const clickToCallWrapper = () => {
  if (window['rTapClickToCall'] && typeof window['rTapClickToCall'] === 'function') {
    try {
      window['rTapClickToCall'](RTAP_CODE);
    } catch (e) {
      Logging.error({
        text: `rTapClickToCall Error`
      });
    }
  }
};

export const RTapNumber = ({ telephoneNumber }: RTapNumberProps) => {
  useEffect(() => {
    rTapNotifyDOMChangeWrapper();
  }, []);
  const match = telephoneNumber ? telephoneNumber.match(/\[(.*?)\]/) : '';
  const phoneNumber = match ? match[1] : telephoneNumber;
  return <span className={RTAP_CLASSNAME}>{phoneNumber}</span>;
};

export const rTapNumberHTML = ({ telephoneNumber }: RTapNumberProps) => {
  return `<span class=${RTAP_CLASSNAME}>${telephoneNumber}</span>`;
};
