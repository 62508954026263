import { Price } from '@model/common';
import { DataEnvelope } from '@model/iceberg';

export enum EarlyFlightType {
  ADD_NIGHT = 'add-night',
  SUB_NIGHT = 'sub-night',
  UNAVAILABLE = 'unavailable',
  NOT_REQUIRED = 'not-required'
}

export interface EarlyFlight {
  type: EarlyFlightType;
  token?: string;
  originalPrice?: Price;
  originalPricePP?: Price;
  newPrice?: Price;
  newPricePP?: Price;
  diff?: Price;
  diffPP?: Price;
  totalOccupants?: number;
  date?: string;
}

export type EarlyFlightResponse = DataEnvelope<Array<EarlyFlight>>;
