import { RadioToggleOptions } from './generic-forms';
import { GuestType } from '@model/iceberg/booking/basket/guest/guest';

/* *** ENUMS *** */

export enum AirportMobilityOptions {
  NONE = '',
  DEPARTURE_ONLY = 'departureGateOnly',
  DEPARTURE_AND_BOARDING = 'departureGateAndBoarding',
  DEPARTURE_BOARDING_AND_SEAT = 'departureGateBoardingAndSeat'
}
export enum DietaryRequirmentsOptions {
  DIABETIC = 'Diabetic',
  GLUTEN_FREE = 'GlutenFree',
  VEGAN = 'Vegan',
  VEGETARIAN = 'Vegetarian'
}

/* *** MODELS *** */

export interface GuestFormValues {
  token: string;
  type: GuestType;
  title: string;
  email: string;
  telephone: string;
  contactByEmail: boolean;
  firstNames: string;
  lastName: string;
  lead?: boolean;
  dob: DateOfBirth;
  isVegan: boolean;
  isGlutenFree: boolean;
  isDiabetic: boolean;
  isVegetarian: boolean;
  dietaryRequirements: string;
  airportMobility: AirportMobilityOptions;
  hasDietaryRequirements: RadioToggleOptions;
  hasMobilityRequirements: RadioToggleOptions;
}

export interface DateOfBirth {
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
}

/* *** INITIAL VALUES *** */

export const guestFormInitialValues: GuestFormValues = {
  token: '',
  title: '',
  email: '',
  firstNames: '',
  lastName: '',
  telephone: '',
  contactByEmail: false,
  type: GuestType.NONE,
  dob: {
    dayOfBirth: '',
    monthOfBirth: '',
    yearOfBirth: ''
  },
  isDiabetic: false,
  isGlutenFree: false,
  isVegan: false,
  isVegetarian: false,
  dietaryRequirements: '',
  airportMobility: AirportMobilityOptions.NONE,
  hasDietaryRequirements: RadioToggleOptions.NO,
  hasMobilityRequirements: RadioToggleOptions.NO
};
