import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';
import { DestinationFaqRequestPayload, DestinationFaqResponse } from '@state/destination-faq';

interface DestinationFaqApiRequests {
  getDestinationFaq: (path: DestinationFaqRequestPayload) => Promise<DestinationFaqResponse>;
}

export class DestinationFaqApi implements DestinationFaqApiRequests {
  private api: Request = new Request();

  public async getDestinationFaq({ path }: DestinationFaqRequestPayload): Promise<DestinationFaqResponse> {
    return await this.api.get(uri(getURL()).path(`${EndPoint.DESTINATION_FAQ}`).search({ path }).href(), undefined, {
      suppressErrorLogs: true
    });
  }
}
