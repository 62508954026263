import { GetPromoBannerDataState } from '@model/promo-banner';
import { EndPoint, Request, getURL } from '@model/service';
import uri from 'urijs';

interface PromoBannerApiRequests {
  getPromoBanner: () => Promise<GetPromoBannerDataState>;
}

export class PromoBannerApi implements PromoBannerApiRequests {
  private api: Request = new Request();

  public async getPromoBanner(): Promise<GetPromoBannerDataState> {
    return await this.api.get(uri(getURL()).path(EndPoint.PROMO_BANNER).href());
  }
}
