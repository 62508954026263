import { Logo } from './Logo';
import { getLogoDarkUrl } from '@model/state/cms-global-config-state';

export interface LogoContainerProps {
  className?: string;
  disableLink?: boolean;
}

export const LogoContainer = (props: LogoContainerProps) => {
  const logoDarkUrl = getLogoDarkUrl();

  return <Logo {...props} logoDarkUrl={logoDarkUrl || ''} />;
};
