import { FiltersState, GlobalAppState, SelectedFilterNames, SelectedFilters } from '@model/state';
import {
  FilterMappingValue,
  FilterNames,
  FiltersSideBarType,
  MultiSelectFilterVariants,
  SortByOptions,
  SpecialFilters
} from '@model/filters';
import { getFlightAvailabilityFilters, getFlightAvailabilityFlights } from '@state/search-results/flights';
import { getFilteredTokens } from '@util/filters';
import { put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { getSelectedFilters } from './filterSelectors';
import {
  getToursSearchResults,
  getToursFilters,
  getHasTourExtensions
} from '@state/search-results/tours/toursSelectors';
import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { GlobalActions } from '@state/store';
import { FlightFilters } from '@model/iceberg';
import { BaseAction } from '@model/redux';
import { FilterTypesMapping } from '@config/filters';
import { ToursActions } from '@state/search-results/tours/toursOperations';
import { FromPricePackagesActions, getFromPricePackageFilters, getFromPricePackages } from '@state/search-results';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';

/* ***************** *
 *       TYPES       *
 * ***************** */

export interface FiltersAction {
  type: FiltersActionTypes | GlobalActions;
  payload?: any;
}

/* ***************** *
 *   ACTION TYPES    *
 * ***************** */

export enum FiltersActionTypes {
  SET_FILTERED_TOKENS = '@FILTERS/SET_FILTERED_TOKENS',
  SET_SELECTED_FILTERS = '@FILTERS/SET_SELECTED_FILTERS',
  RESET_SELECTED_FILTERS = '@FILTERS/RESET_SELECTED_FILTERS',
  SET_SORTING_OPTION = '@FILTERS/SET_SORTING_OPTION',
  RESET_FLIGHT_FILTERS = '@FILTERS/RESET_FLIGHT_FILTERS'
}

/* ***************** *
 *     ACTIONS       *
 * ***************** */

export interface FilteredTokensPayload {
  type: FiltersSideBarType;
  filteredTokens: Array<string>;
}

export interface SetFilteredTokens extends BaseAction {
  type: FiltersActionTypes.SET_FILTERED_TOKENS;
  payload: FilteredTokensPayload;
}

export function setFilteredTokens(payload: FilteredTokensPayload): SetFilteredTokens {
  return {
    type: FiltersActionTypes.SET_FILTERED_TOKENS,
    payload
  };
}

export interface SelectedFiltersPayload {
  type: FiltersSideBarType;
  selectedFilters: SelectedFilterNames;
}

export interface SetSelectedFilters extends BaseAction {
  type: FiltersActionTypes.SET_SELECTED_FILTERS;
  payload: SelectedFiltersPayload;
}

export function setSelectedFilters(payload: SelectedFiltersPayload): SetSelectedFilters {
  return {
    type: FiltersActionTypes.SET_SELECTED_FILTERS,
    payload
  };
}

export interface SetSortingOption extends BaseAction {
  type: FiltersActionTypes.SET_SORTING_OPTION;
  payload: SortByOptions;
}

export function setSortingOption(payload: SortByOptions): SetSortingOption {
  return {
    type: FiltersActionTypes.SET_SORTING_OPTION,
    payload
  };
}

export interface ResetSelectedFilters extends BaseAction {
  type: FiltersActionTypes.RESET_SELECTED_FILTERS;
  payload: FiltersSideBarType;
}

export function resetSelectedFilters(payload: FiltersSideBarType): ResetSelectedFilters {
  return {
    type: FiltersActionTypes.RESET_SELECTED_FILTERS,
    payload
  };
}

export function resetFlightFilters() {
  return {
    type: FiltersActionTypes.RESET_FLIGHT_FILTERS
  };
}

/* *************** *
 *     SAGAS       *
 * *************** */

export function* onResetFlightFilters() {
  yield takeLatest(FiltersActionTypes.RESET_FLIGHT_FILTERS, resetFlightFilterTokens);
}

export function getInitialFlightFilters(filters: FlightFilters): SelectedFilterNames {
  return Object.entries(filters).reduce(
    (acc: SelectedFilterNames, [key, value]: any) => {
      if (Object.keys(value).length <= 1) return acc;
      const filterName: FilterMappingValue = FilterTypesMapping[key];
      let filters: Array<string> = [];
      if (filterName.variant === MultiSelectFilterVariants.SELECT) {
        filters = [SpecialFilters.ALL];
      }
      if (filterName.variant === MultiSelectFilterVariants.UNSELECT) {
        filters = Object.keys(value);
      }
      return {
        ...acc,
        [key]: filters
      };
    },
    {
      [FilterNames.OUTBOUND_DEPARTURE_TIME]: [SpecialFilters.ALL],
      [FilterNames.RETURN_DEPARTURE_TIME]: [SpecialFilters.ALL]
    }
  );
}

function* resetFlightFilterTokens() {
  const filters: FlightFilters = yield select(getFlightAvailabilityFilters);
  const selectedFilters: SelectedFilterNames = getInitialFlightFilters(filters);
  yield put(
    setSelectedFilters({
      type: FiltersSideBarType.FLIGHTS,
      selectedFilters
    })
  );
}

export function* onFiltersChange() {
  yield takeLeading(FiltersActionTypes.SET_SELECTED_FILTERS, filterTokens);
}

function* filterTokens(action: FiltersAction) {
  const searchType: FiltersSideBarType = action.payload.type;

  let filtersSelector: any;
  let noOfFixedFilters: number = 0; // number of extra FE-only filters

  switch (searchType) {
    case FiltersSideBarType.SEARCH:
      filtersSelector = getFromPricePackageFilters;
      break;
    case FiltersSideBarType.FLIGHTS:
      filtersSelector = getFlightAvailabilityFilters;
      noOfFixedFilters = 2;
      break;
    case FiltersSideBarType.TOURS: {
      filtersSelector = getToursFilters;
      const hasExtensions = yield select(getHasTourExtensions);
      noOfFixedFilters = hasExtensions ? 1 : 0;
    }
  }

  const filters = yield select(filtersSelector);

  const activeFilters = Object.entries(filters).reduce((acc: any, [filterName, filterValue]: any) => {
    if (Object.keys(filterValue).length > 1 || typeof filterValue !== 'object') {
      return { ...acc, [filterName]: filterValue };
    }
    return acc;
  }, {});

  const selectedFilters: SelectedFilters = yield select((state: GlobalAppState) =>
    getSelectedFilters(state, searchType)
  );

  const shouldFilterTokens =
    Object.keys(activeFilters).length + noOfFixedFilters === Object.keys(selectedFilters).length;

  if (shouldFilterTokens) {
    const availablePackagesData = yield select(getFromPricePackages);
    const availableFlightsData = yield select(getFlightAvailabilityFlights);
    const availableToursData = yield select(getToursSearchResults);

    const getSearchData: () => any = () => {
      switch (searchType) {
        case FiltersSideBarType.FLIGHTS:
          return availableFlightsData;
        case FiltersSideBarType.TOURS:
          return availableToursData.tours;
        default:
          return availablePackagesData || [];
      }
    };

    const filteredTokenList: Array<string> = getFilteredTokens(searchType, getSearchData(), selectedFilters);

    yield put(setFilteredTokens({ type: searchType, filteredTokens: filteredTokenList || [] }));
  }
}

/* ***************** *
 *     REDUCER       *
 * ***************** */

export const FILTERS_INITIAL_STATE: FiltersState = {
  filteredTokens: {},
  selectedFilters: {},
  sorting: SortByOptions.POPULAR
};

export const filtersReducer = (state: FiltersState = FILTERS_INITIAL_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, FILTERS_INITIAL_STATE)) {
        return action.payload?.filters || state;
      }
      return state;
    case FiltersActionTypes.SET_FILTERED_TOKENS:
      return {
        ...state,
        filteredTokens: {
          ...state.filteredTokens,
          [action.payload.type]: action.payload.filteredTokens
        }
      };
    case FiltersActionTypes.SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.type]: {
            ...state.selectedFilters[action.payload.type],
            ...action.payload.selectedFilters
          }
        }
      };
    case FiltersActionTypes.RESET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload]: []
        }
      };
    case FiltersActionTypes.SET_SORTING_OPTION:
      return {
        ...state,
        sorting: action.payload
      };
    case FromPricePackagesActions.CLEAR_FROM_PRICE_PACKAGES:
    case DealFinderResultsActions.CLEAR_RESULTS:
    case ToursActions.FETCH_TOURS:
      return FILTERS_INITIAL_STATE;
    default:
      return state;
  }
};
