import { mq } from '@styles/breakpoints';
import { Labels, Typography } from '@theme/base/typography-v2';

const labels: Labels = {
  default: {
    fontSize: '1.5rem',
    [mq.medium]: {
      fontSize: '1.4rem'
    }
  },
  attribute: {
    fontSize: '1.4rem',
    [mq.medium]: {
      fontSize: '1.2rem'
    }
  },
  hero: {
    fontSize: '1.5rem',
    [mq.medium]: {
      fontSize: '1.6rem'
    }
  },
  small: {
    fontSize: '1.2rem',
    [mq.medium]: {
      fontSize: '1.1rem'
    }
  },
  responsive: {
    fontSize: '1.4rem',
    [mq.small]: {
      fontSize: '1.6rem'
    }
  }
};

export const typography: Typography = {
  defaultFont:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  h1: {
    fontSize: '3.2rem',
    fontWeight: 'bold',
    [mq.medium]: {
      fontSize: '5.6rem'
    }
  },
  h2: {
    fontSize: '2.6rem',
    fontWeight: 'bold',
    [mq.medium]: {
      fontSize: '4rem'
    }
  },
  h3: {
    fontSize: '2.4rem',
    fontWeight: 'bold',
    [mq.medium]: {
      fontSize: '3rem'
    }
  },
  h4: {
    fontSize: '2.2rem',
    fontWeight: 'bold',
    [mq.medium]: {
      fontSize: '2.4rem'
    }
  },
  h5: {
    fontSize: '2rem',
    fontWeight: 'bold',
    [mq.medium]: {
      fontSize: '2rem'
    }
  },
  h6: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    [mq.medium]: {
      fontSize: '1.6rem'
    }
  },
  paragraph: {
    fontSize: '1.6rem'
  },
  labels
};
