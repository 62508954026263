import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';

export const MarkdownContainer: any = styled.div(({ theme }: ThemeProps) => ({
  p: {
    padding: 0
  },
  ['h2']: {
    lineHeight: '2.8rem'
  },

  ['h3, h4, h5']: {
    marginBottom: theme.custom.spacing.xSmall,
    marginTop: theme.custom.spacing.xLarge
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    marginTop: theme.custom.spacing.xLarge,
    marginBottom: theme.custom.spacing.xLarge
  },
  ['td, th']: {
    border: `1px solid ${theme.custom.colors.group10.light}`,
    padding: theme.custom.spacing.small
  },
  th: {
    textAlign: 'left',
    backgroundColor: theme.custom.colors.group1.base,
    color: theme.custom.colors.white
  },
  tr: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.custom.colors.group10.lighter
    }
  },
  ['ul > li']: {
    fontSize: theme.custom.fontSize.medium
  }
}));
