import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { DealFinderResultsState } from '@model/state/deal-finder-results-state';
import { FiltersAdapter } from '@model/iceberg/filters/deal-finder/filters-adapter';

export const getDealFinderResults = (state: GlobalAppState) => state.dealFinderResults;
export const getDealFinderResultsData = (state: GlobalAppState) => state.dealFinderResults.data;
export const getDealFinderResultsToken = (state: GlobalAppState) => state.dealFinderResults.data.searchToken;
export const getDealFinderResultsFilters = (state: GlobalAppState) => state.dealFinderResults.data.facets.filters;
export const getDealFinderIsLoading = (state: GlobalAppState) => state.dealFinderResults.loading;
export const getDealFinderHasError = (state: GlobalAppState) => state.dealFinderResults.error;

export const getDealFinderHasResults = createSelector(
  getDealFinderResults,
  ({ data: { results }, error, loading }: DealFinderResultsState) => {
    return !!results.length && !error && !loading;
  }
);

export const getHasAppliedFilters = (state: GlobalAppState) =>
  !state.dealFinderResults.data.results.length ||
  FiltersAdapter.hasAppliedFilters(state.dealFinderResults.data?.facets.filters || []);
