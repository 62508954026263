import { AirportGroup } from '@model/search';
import { DateRange } from '@model/common';
import { SelectedDestination } from '@model/search/fit/selected-destination';
import { PreferredContact } from '@model/forms/preferred-contact';
import { GeneratedByValue } from '@model/iceberg/service/contact-us';

/* *** MODELS *** */
export interface RequestQuoteFormValues {
  name: string;
  email: string;
  telephone: string;
  adults: number;
  children: number;
  infants: number;
  destination: SelectedDestination;
  holiday: string;
  dateOfTravel: DateRange;
  departureAirports: string;
  boardBasis: string;
  comments: string;
  preferredContact: PreferredContact;
  airports: AirportGroup;
}

export interface RequestQuoteFormPayload extends GeneratedByValue {
  name: string;
  email: string;
  telephone: string;
  adults: number;
  children: number;
  infants: number;
  destination: string;
  destinationPath: string;
  holiday: string;
  departureDate: string;
  returnDate: string;
  departureAirports: string;
  boardBasis: string;
  comments: string;
  preferredContact: PreferredContact;
  reCaptcha: string;
}

export enum BoardBasisOptionsLabels {
  ANY = 'common__label--any',
  ROOM_ONLY = 'form-option__board-basis--room-only',
  SELF_CATERING = 'form-option__board-basis--self-catering',
  BED_AND_BREAKFAST = 'form-option__board-basis--bed-and-breakfast',
  HALF_BOARD = 'form-option__board-basis--half-board',
  FULL_BOARD = 'form-option__board-basis--full-board',
  ALL_INCLUSIVE = 'form-option__board-basis--all-inclusive'
}

export const boardBasisOptions = (t: any) => [
  { name: t(BoardBasisOptionsLabels.ANY), value: '' },
  { name: t(BoardBasisOptionsLabels.ROOM_ONLY), value: t(BoardBasisOptionsLabels.ROOM_ONLY) },
  { name: t(BoardBasisOptionsLabels.SELF_CATERING), value: t(BoardBasisOptionsLabels.SELF_CATERING) },
  { name: t(BoardBasisOptionsLabels.BED_AND_BREAKFAST), value: t(BoardBasisOptionsLabels.BED_AND_BREAKFAST) },
  { name: t(BoardBasisOptionsLabels.HALF_BOARD), value: t(BoardBasisOptionsLabels.HALF_BOARD) },
  { name: t(BoardBasisOptionsLabels.FULL_BOARD), value: t(BoardBasisOptionsLabels.FULL_BOARD) },
  { name: t(BoardBasisOptionsLabels.ALL_INCLUSIVE), value: t(BoardBasisOptionsLabels.ALL_INCLUSIVE) }
];

/* *** INITIAL VALUES *** */
export const requestQuoteInitialValues: RequestQuoteFormValues = {
  name: '',
  email: '',
  telephone: '',
  adults: 1,
  children: 0,
  infants: 0,
  destination: {
    name: {
      display: '',
      path: ''
    },
    airports: []
  },
  holiday: '',
  dateOfTravel: {
    startDate: '',
    endDate: ''
  },
  departureAirports: '',
  boardBasis: '',
  comments: '',
  preferredContact: PreferredContact.EMAIL,
  airports: []
};
