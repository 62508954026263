import { Page } from '@model/common/pages';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';

export enum BookingSteps {
  SEARCH = 'common__label--back-to-search',
  ROOM_TYPES = 'common__label--room-types',
  FLIGHTS = 'common__label--flights',
  REVIEW = 'common__label--review',
  GUESTS = 'common__label--guests',
  PAYMENT = 'common__label--payment',
  TOUR_DETAILS = 'common__label--tour-details',
  EXTRAS = 'common__label--extras'
}

interface BookingStepsProps {
  searchType: SearchType;
  hotelLandingSearch?: boolean;
  destinationSearch?: boolean;
}

export const bookingSteps = ({
  searchType,
  hotelLandingSearch,
  destinationSearch
}: BookingStepsProps): Array<BookingSteps> => {
  return [
    ...(!destinationSearch && !hotelLandingSearch ? [BookingSteps.SEARCH] : []),
    BookingSteps.ROOM_TYPES,
    ...(searchType === SearchType.HOTEL_ONLY ? [] : [BookingSteps.FLIGHTS]),
    BookingSteps.REVIEW,
    BookingSteps.GUESTS,
    BookingSteps.PAYMENT
  ];
};

export const bookingStepsToPagesMapping = {
  [BookingSteps.SEARCH]: Page.SEARCH,
  [BookingSteps.ROOM_TYPES]: Page.PRODUCT,
  [BookingSteps.FLIGHTS]: Page.FLIGHTS,
  [BookingSteps.REVIEW]: Page.REVIEW,
  [BookingSteps.GUESTS]: Page.GUESTS,
  [BookingSteps.PAYMENT]: Page.PAYMENT
};

export const toursBookingSteps = (isTourSearch: boolean, isTourLandingSearch: boolean): Array<BookingSteps> => {
  return [
    ...(isTourSearch || isTourLandingSearch ? [] : [BookingSteps.SEARCH]),
    BookingSteps.TOUR_DETAILS,
    BookingSteps.EXTRAS,
    BookingSteps.GUESTS,
    BookingSteps.PAYMENT
  ];
};

export const toursBookingStepsToPagesMapping = {
  [BookingSteps.SEARCH]: Page.TOURS_SEARCH,
  [BookingSteps.TOUR_DETAILS]: Page.TOURS_PRODUCT,
  [BookingSteps.EXTRAS]: Page.TOURS_EXTRAS,
  [BookingSteps.GUESTS]: Page.GUESTS,
  [BookingSteps.PAYMENT]: Page.PAYMENT
};
