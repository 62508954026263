import { Months } from '@model/common/dates';

export interface ClimateMonths {
  [Months.JAN]: number;
  [Months.FEB]: number;
  [Months.MAR]: number;
  [Months.APR]: number;
  [Months.MAY]: number;
  [Months.JUN]: number;
  [Months.JUL]: number;
  [Months.AUG]: number;
  [Months.SEP]: number;
  [Months.OCT]: number;
  [Months.NOV]: number;
  [Months.DEC]: number;
}

export enum ClimateType {
  TEMPERATURE = 'temperature',
  RAINFALL = 'rainfall',
  SUNSHINE = 'sunshine'
}
