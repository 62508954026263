import { BasketResponse, PackageAvailabilityResponse, PackagesResponse } from '@model/iceberg';
import { ISO_8601_DATE_FORMAT } from '@model/common/dates';
import { Guests, Rooms } from '@model/search';
import moment from 'moment';
import uri from 'urijs';
import { AvailabilityParams, FlightsAvailabilityResponse, OccupancyParam } from '@model/iceberg/service';
import { EndPoint, getURL, Request } from '@model/service';
import { BookingEndpoints } from '@model/service/booking-endpoints';

interface BookingApiRequests {
  get: (token: string) => Promise<BasketResponse>;
  delete: (token: string) => Promise<BasketResponse>;
  getPackageAvailability: (searchToken: string, params: AvailabilityParams) => Promise<PackageAvailabilityResponse>;
  getFlights: (token: string) => Promise<FlightsAvailabilityResponse>;
  getExtras: (token: string) => Promise<any>;
  getExtrasOptions: (token: string) => Promise<any>;
  putExtrasOptions: (payload: PutExtrasOptionsParams) => Promise<any>;
}

export interface PromoCodeResponse {
  data: PromoCode;
}

export interface PromoCode {
  promoCode: string;
  valid: boolean;
  error?: string;
  discount?: number;
}

export interface TokenResponse {
  searchToken: string;
  expires: string;
}

export interface SearchPayload {
  path: string;
  from: Array<string>;
  display: string;
  startDate: string;
  endDate: string;
  occupancy: Array<OccupancyParam>;
  preferredBoardBasis: string;
}

export interface FilterOption {
  name: string;
  value: any;
  selected: boolean | number;
  count: number;
}

export interface Filter {
  name: string;
  type: FilterType;
  formattedName: string;
  values: Array<FilterOption>;
}

export enum FilterType {
  NONE = '',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  STARS = 'stars',
  TOGGLE = 'toggle',
  PRICE = 'price',
  SORT = 'sort'
}

export interface DataEnvelope<T> {
  data: T;
}

export interface PutExtrasOptionsParams {
  searchToken: string;
  token: string;
  optionToken: string;
}

export class BookingApi implements BookingApiRequests {
  private api: Request = new Request();

  public async get(token: string, preBook: boolean = false): Promise<BasketResponse> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}`).query({ preBook }).href()
    );
  }

  public async delete(token: string): Promise<BasketResponse> {
    return await this.api.delete(uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}`).href());
  }

  public async promoCode(token: string, promoCode: string): Promise<PromoCodeResponse> {
    return await this.api.get(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.PROMO_CODE}/${promoCode}`)
        .href()
    );
  }

  public async getPackageAvailability(token: string, params: AvailabilityParams): Promise<PackageAvailabilityResponse> {
    const occupancy: Array<OccupancyParam> = this.getOccupancy(params.occupancy);
    const url = uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.PACKAGES}`).href();
    return await this.api.post(url, { ...params, searchToken: token, occupancy });
  }

  public async getToken(payload: SearchPayload): Promise<TokenResponse> {
    return await this.api.post(uri(getURL()).path(EndPoint.BOOKING.concat(EndPoint.PACKAGE)).href(), payload);
  }

  public async packages(token: string, query: Record<string, string>): Promise<DataEnvelope<PackagesResponse>> {
    return await this.api.get(
      uri(getURL())
        .path(EndPoint.BOOKING.concat(`/${token}`).concat(EndPoint.PACKAGE))
        .search(query)
        .href()
    );
  }

  public async getSinglePackage(token: string, accommodationToken: string): Promise<any> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.PACKAGES}/${accommodationToken}`).href()
    );
  }

  public async getFlights(token: string): Promise<FlightsAvailabilityResponse> {
    const url = uri(getURL()).path(`${EndPoint.DEAL_FINDER}/${token}${BookingEndpoints.FLIGHTS}`).href();
    return await this.api.get(url);
  }

  public async getExtras(token: string): Promise<any> {
    const url = uri(getURL()).path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.EXTRAS}`).href();
    return await this.api.get(url);
  }

  public async getExtrasOptions(token: string): Promise<any> {
    const url = uri(getURL())
      .path(`${EndPoint.DEAL_FINDER}/${token}${BookingEndpoints.EXTRAS}${BookingEndpoints.OPTIONS}`)
      .href();
    return await this.api.get(url);
  }

  public async putExtrasOptions({ searchToken, token, optionToken }: PutExtrasOptionsParams): Promise<any> {
    const url = uri(getURL())
      .path(`${EndPoint.DEAL_FINDER}/${searchToken}${BookingEndpoints.EXTRAS}${BookingEndpoints.OPTION}`)
      .href();
    return await this.api.put(url, { extra: { token, optionToken } });
  }

  private getOccupancy(rooms: Rooms): Array<OccupancyParam> {
    return rooms.map((guests: Guests) => ({
      adults: guests.adults,
      children: guests.children.map(({ year, month, day }) =>
        moment(`${year}-${month}-${day}`, ISO_8601_DATE_FORMAT).format(ISO_8601_DATE_FORMAT)
      )
    }));
  }
}
