import uri from 'urijs';
import { EndPoint, getURL, MyBookingEndpoints, Request } from '@model/service';
import { MyBookingDocumentsRequestPayload, MyBookingDocumentsResponse } from '@state/mmb/documents';

interface MyBookingDocumentsApiRequests {
  getBookingDocuments: ({
    bookingId,
    loginToken
  }: MyBookingDocumentsRequestPayload) => Promise<MyBookingDocumentsResponse>;
}

export class MyBookingDocumentsApi implements MyBookingDocumentsApiRequests {
  private api: Request = new Request();

  public async getBookingDocuments({
    bookingId,
    loginToken
  }: MyBookingDocumentsRequestPayload): Promise<MyBookingDocumentsResponse> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.MMB_BOOKING}/${bookingId}${MyBookingEndpoints.DOCUMENTS}`).href(),
      loginToken
    );
  }

  public async getTradeBookingDocuments({
    bookingId,
    loginToken
  }: MyBookingDocumentsRequestPayload): Promise<MyBookingDocumentsResponse> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.BOOKINGS}/${bookingId}${MyBookingEndpoints.DOCUMENTS}`).href(),
      loginToken
    );
  }
}
