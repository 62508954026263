import { useEffect, useState, useCallback } from 'react';

export const useCountDown = (timeToCount: number = 60 * 1000, interval: number = 1000) => {
  const [timeLeft, setTimeLeft] = useState<number>(timeToCount);
  const start: any = useCallback((newTime: number) => setTimeLeft(newTime !== undefined ? newTime : timeToCount), []);
  let timer: any = null;
  useEffect(() => {
    if (timeLeft > 0) {
      window.clearTimeout(timer);
      timer = window.setTimeout(() => {
        const nextSecondsLeft = timeLeft - interval > 0 ? timeLeft - interval : 0;
        setTimeLeft(nextSecondsLeft);
      }, interval);
    }
    return () => window.clearTimeout(timer);
  }, [timeLeft, timer]);
  return [timeLeft, start];
};
