import { createSelector } from 'reselect';
import { GlobalAppState } from '@model/state';
import { TourResult } from '@model/tours';
import { FiltersAdapter } from '@model/iceberg/filters/deal-finder/filters-adapter';

export const getToursSearchData = (state: GlobalAppState) => state.searchResults.tours.data;
export const getToursSearchToken = (state: GlobalAppState) => state.searchResults.tours.data.searchToken;
export const getToursSearchResults = (state: GlobalAppState) => state.searchResults.tours.data.results;
export const getIsToursSearching = (state: GlobalAppState) => state.searchResults.tours.loading;
export const getHasTourExtensions = (state: GlobalAppState) =>
  !!state.searchResults.tours.data?.results.find((tour: TourResult) => tour.summary.isExtension);
export const getToursFilters = (state: GlobalAppState) => state.searchResults.tours.data.facets;
export const getToursError = (state: GlobalAppState) => state.searchResults.tours.error;

export const getToursHasResults = createSelector(
  getToursSearchResults,
  getToursError,
  getIsToursSearching,
  (results, error, loading) => {
    return !!results.length && !error && !loading;
  }
);

export const getHasAppliedFilters = (state: GlobalAppState) => {
  return (
    !state.searchResults.tours.data.results.length ||
    (Array.isArray(state.searchResults.tours.data?.facets.filters) &&
      FiltersAdapter.hasAppliedFilters(state.searchResults.tours.data?.facets.filters || []))
  );
};
