import { FiltersData, FiltersResponse } from '@model/iceberg/service/price-calendar/filters-response';
import { Filters, PriceCalendarFiltersState } from '@model/state/price-calendar-state';
import { put, putResolve, takeEvery } from 'redux-saga/effects';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { showModal, ModalType } from '@state/modal/modalOperations';
import { PriceCalendarApi } from '@model/iceberg/service/price-calendar';
import { PriceCalendarParams } from '@model/price-calendar';
import { pickBy, identity, isEqual, isEmpty, pick } from 'lodash';
import { isValidSearchDate } from '@util/date-time';
import { getErrorCode } from '@util/error';

export enum PriceCalendarFilterActions {
  FETCH_FILTERS = '@PRICE_CALENDAR_FILTERS/FETCH_FILTERS',
  RECEIVE_FILTERS_SUCCESS = '@PRICE_CALENDAR_FILTERS/RECEIVE_FILTERS_SUCCESS',
  RECEIVE_FILTERS_FAILURE = '@PRICE_CALENDAR_FILTERS/RECEIVE_FILTERS_FAILURE',
  SET_FILTERS = '@PRICE_CALENDAR_FILTERS/SET_FILTERS',
  SET_AIRPORT = '@PRICE_CALENDAR_FILTERS/SET_AIRPORT'
}

export const INITIAL_PRICE_CALENDAR_FILTERS_STATE: PriceCalendarFiltersState = {
  response: {
    data: {
      filters: {
        durations: [],
        boards: [],
        airports: []
      },
      defaults: {
        month: '',
        year: '',
        from: '',
        occupancy: [],
        boardBasis: '',
        duration: 0
      }
    },
    loading: false,
    error: null
  },
  airport: '',
  board: '',
  duration: 0,
  occupancy: [{ adults: 2, children: [] }],
  lastGood: null
};

interface LastGoodFiltersState extends PriceCalendarFiltersState {
  month: number;
  year: number;
}

export const fetchFilters = (payload: { path: string; urlParams: PriceCalendarParams }) => ({
  type: PriceCalendarFilterActions.FETCH_FILTERS,
  payload
});

export const receiveFiltersSuccess = (payload: FiltersData) => ({
  type: PriceCalendarFilterActions.RECEIVE_FILTERS_SUCCESS,
  payload
});

export const receiveFiltersFailure = () => ({
  type: PriceCalendarFilterActions.RECEIVE_FILTERS_FAILURE
});

export const setFilters = (payload: Filters) => ({ type: PriceCalendarFilterActions.SET_FILTERS, payload });

export const setAirport = (payload: string) => ({ type: PriceCalendarFilterActions.SET_AIRPORT, payload });

export function* onFetchFilters() {
  yield takeEvery(PriceCalendarFilterActions.FETCH_FILTERS, handleOnFetchFilters);
}

export function* handleOnFetchFilters({ payload: { path, urlParams } }: any) {
  try {
    const { month, year, ...nonDateParams } = urlParams || {};
    const searchParams = isNaN(month) || isNaN(year) || !isValidSearchDate({ month, year }) ? nonDateParams : urlParams;
    const response: FiltersResponse = yield new PriceCalendarApi().filters(path, searchParams);
    if (response && response.data) {
      yield putResolve(receiveFiltersSuccess(response.data));
    } else {
      yield putResolve(receiveFiltersFailure());
    }
  } catch (e: any) {
    const errorCode = getErrorCode(e);
    if (errorCode) {
      yield put(showModal(ModalType.GENERIC_ERROR, errorCode));
    }
    yield putResolve(receiveFiltersFailure());
  }
}

export const getFilters = (params: PriceCalendarParams) => {
  if (!params) return undefined;
  const { from, boardBasis, duration, occupancy, year, month } = params;
  return {
    duration,
    airport: from,
    board: boardBasis,
    occupancy,
    year,
    month
  };
};

const getLastKnownGoodState = (lastGood: LastGoodFiltersState) => {
  if (!lastGood) {
    return INITIAL_PRICE_CALENDAR_FILTERS_STATE;
  }
  return {
    ...INITIAL_PRICE_CALENDAR_FILTERS_STATE,
    ...lastGood,
    lastGood: JSON.stringify(lastGood),
    response: {
      ...INITIAL_PRICE_CALENDAR_FILTERS_STATE.response,
      ...(lastGood.response || {}),
      data: {
        ...lastGood.response?.data,
        defaults: {
          ...lastGood.response?.data?.defaults,
          month: lastGood.month,
          year: lastGood.year
        }
      },
      loading: false,
      error: false
    }
  };
};

export const priceCalendarFilterReducer: any = (
  state: PriceCalendarFiltersState = INITIAL_PRICE_CALENDAR_FILTERS_STATE,
  action: any
) => {
  const lastGood = typeof state.lastGood === 'string' ? JSON.parse(state.lastGood) : null;
  const { payload, type } = action;
  const stateIsDefault = isEqual(pickBy(state, identity), pickBy(INITIAL_PRICE_CALENDAR_FILTERS_STATE, identity));
  const currentState = stateIsDefault
    ? null
    : pick(state, ['airport', 'board', 'duration', 'month', 'occupancy', 'response']);

  switch (type) {
    case PriceCalendarFilterActions.FETCH_FILTERS: {
      const { urlParams } = payload;
      const filters = isEmpty(urlParams) ? null : getFilters(urlParams);
      const sendState =
        !stateIsDefault || !!filters ? { ...(!!currentState && currentState), ...(!!filters && filters) } : null;
      return {
        ...(!!sendState && sendState),
        lastGood: sendState && JSON.stringify(sendState),
        response: {
          ...INITIAL_PRICE_CALENDAR_FILTERS_STATE.response,
          loading: true,
          error: null
        }
      };
    }
    case PriceCalendarFilterActions.RECEIVE_FILTERS_SUCCESS: {
      const filters = getFilters(payload.defaults);
      const receiveState = {
        ...currentState,
        ...filters,
        response: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
      return {
        ...receiveState,
        lastGood: JSON.stringify(receiveState || null)
      };
    }
    case PriceCalendarFilterActions.SET_FILTERS: {
      const { airport, board, duration, occupancy } = action.payload;
      return { ...state, duration, airport, board, occupancy, response: { ...state.response, error: null } };
    }
    case PriceCalendarFilterActions.SET_AIRPORT: {
      return {
        ...state,
        airport: payload
      };
    }
    case PriceCalendarFilterActions.RECEIVE_FILTERS_FAILURE: {
      const lastGoodState = getLastKnownGoodState(lastGood);
      return {
        ...lastGoodState,
        response: {
          ...lastGoodState.response,
          error: true
        }
      };
    }
    case DealFinderResultsActions.CLEAR_RESULTS: {
      return {
        ...INITIAL_PRICE_CALENDAR_FILTERS_STATE,
        lastGood: state.lastGood
      };
    }
    case DealFinderResultsActions.SET_FILTERS: {
      return INITIAL_PRICE_CALENDAR_FILTERS_STATE;
    }
    case DealFinderResultsActions.RECEIVE_RESULTS_FAILURE: {
      return getLastKnownGoodState(lastGood);
    }
    default:
      return state;
  }
};
