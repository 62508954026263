import { Currency } from 'dinero.js';
import {
  CurrencyLocaleMapping,
  CurrencySymbolMapping,
  DEFAULT_CURRENCY_LOCALE,
  DEFAULT_CURRENCY_SYMBOL
} from '@model/common/currency/currency';

const CurrencyRegExp = /[^0-9\.,-]+/g;

export const amountStringToFloat = (amount: string) => {
  return parseFloat(amount.replace(CurrencyRegExp, '').replace(/,/g, ''));
};

export const getLocaleByCurrencyCode = (code: Currency) => {
  return CurrencyLocaleMapping[code] || DEFAULT_CURRENCY_LOCALE;
};

export const getSymbolByCurrencyCode = (code: Currency) => {
  return CurrencySymbolMapping[code] || DEFAULT_CURRENCY_SYMBOL;
};
