import { BaseSpinner } from '../base/BaseSpinner';
import { Theme } from '@theme/base';
import { withTheme } from '@emotion/react';
import React from 'react';

export interface PrimarySpinnerProps {
  theme: Theme;
  css?: any;
  color?: string;
  className?: string;
  size?: number;
}

const PrimarySpinnerComponent = ({ theme, color, className, size, css }: PrimarySpinnerProps) => (
  <BaseSpinner size={size || 50} color={color || theme.custom.colors.group1.base} className={className} css={css} />
);

export const PrimarySpinner = withTheme(PrimarySpinnerComponent);
