export enum AgentPage {
  LOGIN = '/login',
  BOOKINGS = '/bookings',
  NEWS_AND_PROMOTIONS = '/news-and-promotions',
  INCENTIVES = '/incentives',
  CLAIM_INCENTIVES = '/claim-incentives',
  FAM_TRIP_COMPETITION = '/fam-trip-competition',
  MEET_YOUR_TEAM = '/meet-your-team',
  WINDOW_POSTERS = '/window-posters',
  RESET_PASSWORD = '/reset',
  SOCIAL_MEDIA_POST = '/social-media-posts'
}

export const agentPageLabels: Record<AgentPage, string> = {
  [AgentPage.LOGIN]: 'agent__page--login',
  [AgentPage.BOOKINGS]: 'agent__page--bookings',
  [AgentPage.NEWS_AND_PROMOTIONS]: 'agent__page--news-and-promotions',
  [AgentPage.INCENTIVES]: 'agent__page--incentives',
  [AgentPage.FAM_TRIP_COMPETITION]: 'agents__page-fam-trip-competition',
  [AgentPage.MEET_YOUR_TEAM]: 'agent__page--meet-your-team',
  [AgentPage.WINDOW_POSTERS]: 'agent__page--window-posters',
  [AgentPage.CLAIM_INCENTIVES]: 'agent__page--claim-incentives',
  [AgentPage.RESET_PASSWORD]: 'agent__page--claim-incentives',
  [AgentPage.SOCIAL_MEDIA_POST]: 'agent__page--social-media-posts'
};

export const navAgentPages: Array<AgentPage> = [
  AgentPage.NEWS_AND_PROMOTIONS,
  AgentPage.INCENTIVES,
  AgentPage.CLAIM_INCENTIVES,
  AgentPage.FAM_TRIP_COMPETITION,
  AgentPage.MEET_YOUR_TEAM,
  AgentPage.WINDOW_POSTERS,
  AgentPage.SOCIAL_MEDIA_POST
];
