import { BaseAction } from '@model/redux';

export enum PackageReferencesActions {
  SET_PACKAGE_REFERENCES = '@APP/SET_PACKAGE_REFERENCES',
  UNSET_PACKAGE_REFERENCES = '@APP/UNSET_PACKAGE_REFERENCES'
}

export interface SetPackageReferencesType extends BaseAction {
  type: PackageReferencesActions.SET_PACKAGE_REFERENCES;
  payload: string[];
}

export interface UnsetPackageReferencesType extends BaseAction {
  type: PackageReferencesActions.UNSET_PACKAGE_REFERENCES;
}

export const setPackageReferences: (payload: string[]) => SetPackageReferencesType = (payload: string[]) => ({
  type: PackageReferencesActions.SET_PACKAGE_REFERENCES,
  payload
});

export const unsetPackageReferences: () => UnsetPackageReferencesType = () => ({
  type: PackageReferencesActions.UNSET_PACKAGE_REFERENCES
});

export const INITIAL_PACKAGE_REFERENCES_STATE: string[] = [];

export const packageReferencesReducer = (state: string[] = INITIAL_PACKAGE_REFERENCES_STATE, action: any) => {
  switch (action.type) {
    case PackageReferencesActions.SET_PACKAGE_REFERENCES:
      return action.payload;
    case PackageReferencesActions.UNSET_PACKAGE_REFERENCES:
      return INITIAL_PACKAGE_REFERENCES_STATE;
    default:
      return state;
  }
};
