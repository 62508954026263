import { CSSProperties } from 'react';
import { Theme as MuiTheme } from '@mui/material';
import { getImageUrl } from '@util/images';

interface MixinsType {
  truncate: CSSProperties;
  aspect16by9: CSSProperties;
  textShadow: CSSProperties;
  textShadowLight: CSSProperties;
  defaultDropShadow: DropShadow;
  lightDropShadow: DropShadow;
  dropdownBorder: CSSProperties;
  formFieldBorder: CSSProperties;
  link: CSSProperties;
  edgeToEdge: CSSProperties;
  card: CSSProperties;
}

interface DropShadow {
  textShadow?: CSSProperties;
  boxShadow?: CSSProperties;
  filter?: CSSProperties;
}

interface InsetBoxShadows {
  default: string;
  heavy: string;
}

export interface BoxShadowMixin {
  lighter: string;
  light: string;
  default: string;
  heavy: string;
  glow: string;
  inset: InsetBoxShadows;
}

export interface TextShadowMixin {
  light: string;
  default: string;
  heavy: string;
  stroke: string;
}

export const boxShadowMixins: (color: string) => BoxShadowMixin = (color: string) => ({
  lighter: `0px 1px 2px 0px  ${color}`,
  light: `0px 0px 2px 0px ${color}`,
  default: `0px 2px 4px 0px ${color}`,
  heavy: `0px 3px 6px 0px ${color}`,
  glow: `0px 0px 10px 0px ${color}`,
  inset: {
    default: `inset 0px 0px 2px 0px ${color}`,
    heavy: `inset 0px 2px 4px 0px ${color}`
  }
});

export const textShadowMixins: (color: string) => TextShadowMixin = (color: string) => ({
  light: `0px 0px 2px ${color}`,
  default: `0 1px 1px ${color}`,
  heavy: `0px 2px 4px ${color}`,
  stroke: `-1px 1px 0 ${color}, 1px 1px 0 ${color}, 1px -1px 0 ${color}, -1px -1px 0 ${color}`
});

export const contentfulBackgroundImage = (url: string, breakpoint: number) => `url(${getImageUrl(url, breakpoint)})`;

export const mixins: (theme: MuiTheme) => MixinsType = (theme: MuiTheme) => ({
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  edgeToEdge: {
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw'
  },
  dropdownBorder: {
    borderRadius: 2,
    border: `2px solid ${theme.custom.colors.group10.base}`,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.dark).glow
  },
  formFieldBorder: {
    borderRadius: 2,
    border: `1px solid ${theme.custom.colors.group10.light}`,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.lighter).lighter,
    ['&:focus']: {
      borderColor: theme.custom.colors.group7.base
    }
  },
  aspect16by9: {
    paddingTop: '56.25%'
  },
  textShadow: {
    textShadow: `2px 2px 2px ${theme.custom.colors.black}`
  },
  textShadowLight: {
    textShadow: `0 2px 4px ${theme.custom.colors.black}`
  },
  defaultDropShadow: {
    textShadow: {
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
    },
    boxShadow: {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
    },
    filter: {
      filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))'
    }
  },
  lightDropShadow: {
    boxShadow: {
      boxShadow: `0px 0px 2px 0px ${theme.custom.colors.group10.base}, 0px 1px 4px 0px ${theme.custom.colors.group10.lighter}`
    }
  },
  link: {
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    transition: `color ${theme.custom.transitions.default}`,
    color: theme.custom.states.link.default,
    cursor: 'pointer',
    userSelect: 'none',
    fontWeight: 500,

    ['&:link']: {
      color: theme.custom.states.link.default
    },

    ['&:visited']: {
      color: theme.custom.states.link.visited
    },

    ['&:active']: {
      color: theme.custom.states.link.active
    },

    ['&:hover']: {
      color: theme.custom.states.link.hover
    }
  },
  card: {
    border: `1px solid ${theme.custom.colors.group10.light}`,
    borderRadius: theme.custom.spacing.small,
    backgroundColor: theme.custom.colors.white,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.base).default,
    overflow: 'hidden',
    ['&:hover']: {
      color: theme.custom.states.link.hover,
      boxShadow: boxShadowMixins(theme.custom.colors.group10.base).heavy
    }
  }
});
