import { AsyncData, Page } from '@model/common';
import { call, put, takeLatest } from 'redux-saga/effects';
import { TradeApi } from '@model/iceberg/service/trade/TradeApi';
import { Agent, AuthenticatePayload, AuthenticateResponse, GetAgentsResponse } from '@model/agents/agents-models';
import { AgentLoginFormValues } from '@model/forms/agents/agent-login-form';
import { performNavigate } from '@state/app';
import { AgentPage } from '@model/common/pages/agent-pages';
import { Logging } from '@util/logging';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import moment from 'moment/moment';

export enum AgentActions {
  PERFORM_FETCH_AGENT = '@AGENTS/PERFORM_FETCH_AGENT',
  PERFORM_LOGIN_AGENT = '@AGENTS/PERFORM_LOGIN_AGENT',
  PERFORM_LOGOUT_AGENT = '@AGENTS/PERFORM_LOGOUT_AGENT',
  CLEAR_AGENT = '@AGENTS/CLEAR_AGENT',
  RECEIVE_AGENT_SUCCESS = '@AGENTS/RECEIVE_AGENT_SUCCESS',
  RECEIVE_AGENT_FAILURE = '@AGENTS/RECEIVE_AGENT_FAILURE',
  RECEIVE_AGENT_LOGIN_SUCCESS = '@AGENTS/RECEIVE_AGENT_LOGIN_SUCCESS',
  RECEIVE_AGENT_LOGIN_FAILURE = '@AGENTS/RECEIVE_AGENT_LOGIN_FAILURE'
}

export const INITIAL_AGENT_STATE: AsyncData<Agent> = {
  data: {
    abta: '',
    name: '',
    requiresPayment: false
  },
  loading: false,
  error: null
};

export const performFetchAgent = () => ({ type: AgentActions.PERFORM_FETCH_AGENT });

export const performLoginAgent = (payload: AgentLoginFormValues) => ({
  type: AgentActions.PERFORM_LOGIN_AGENT,
  payload
});

export const performLogoutAgent = () => ({ type: AgentActions.PERFORM_LOGOUT_AGENT });

export const clearAgent = () => ({ type: AgentActions.CLEAR_AGENT });

export const receiveAgentSuccess = (payload: Agent) => ({
  type: AgentActions.RECEIVE_AGENT_SUCCESS,
  payload
});

export const receiveAgentFailure = () => ({
  type: AgentActions.RECEIVE_AGENT_FAILURE
});

export const receiveAgentLoginSuccess = () => ({
  type: AgentActions.RECEIVE_AGENT_LOGIN_SUCCESS
});

export const receiveAgentLoginFailure = (error: any) => ({
  type: AgentActions.RECEIVE_AGENT_LOGIN_FAILURE,
  error
});

export function* onPerformFetchAgent() {
  yield takeLatest(AgentActions.PERFORM_FETCH_AGENT, handleOnPerformFetchAgent);
}

export function* onLoginAgent() {
  yield takeLatest(AgentActions.PERFORM_LOGIN_AGENT, handleLoginAgent);
}

export function* onLogoutAgent() {
  yield takeLatest(AgentActions.PERFORM_LOGOUT_AGENT, handleLogoutAgent);
}

export function* onClearAgent() {
  yield takeLatest(AgentActions.CLEAR_AGENT, handleClearAgent);
}

export function* handleOnPerformFetchAgent() {
  const { response } = yield call(fetchAgent);
  if (response) {
    yield put(receiveAgentSuccess(response.data));
  } else {
    yield put(receiveAgentFailure());
    yield put(clearAgent());
  }
}

export function* handleClearAgent() {
  yield new Cookies().destroy(Cookie.TRADE_AUTHORIZATION);
  yield put(performNavigate(Page.AGENTS.concat(AgentPage.LOGIN)));
}

export function* handleLoginAgent({ payload }: any) {
  const { response, error } = yield call(authenticateAgent, payload);
  if (response) {
    const cookies = new Cookies();
    cookies.set({
      id: Cookie.TRADE_AUTHORIZATION,
      value: response.loginToken as string,
      options: {
        path: '/',
        domain: cookies.getDomain(),
        expires: moment().add(1, 'days').toDate(),
        sameSite: 'strict',
        secure: true
      }
    });
    yield put(receiveAgentLoginSuccess());
    yield handleOnPerformFetchAgent();
    yield put(performNavigate(Page.AGENTS));
  } else {
    const tradeRemaining: string = error?.response?.headers['x-ratelimit-trade-remaining'];
    const attemptsRemaining: number = tradeRemaining ? parseInt(tradeRemaining) : 0;
    const lockoutTimeRemaining: string = error?.response?.headers['x-ratelimit-trade-reset'];
    yield put(
      receiveAgentLoginFailure({
        attemptsRemaining,
        lockoutTimeRemaining
      })
    );
  }
}

export function* handleLogoutAgent() {
  const { error } = yield call(logoutAgent);
  if (error) {
    Logging.error({
      text: 'Failed to logout agent'
    });
  } else {
    yield put(clearAgent());
  }
}

export function authenticateAgent(payload: AuthenticatePayload) {
  const api: TradeApi = new TradeApi();
  return api
    .authenticate(payload)
    .then((response: AuthenticateResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function logoutAgent() {
  const api: TradeApi = new TradeApi();
  return api
    .logout()
    .then((response: any) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function fetchAgent() {
  const api: TradeApi = new TradeApi();
  return api
    .get()
    .then((response: GetAgentsResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export const agentReducer: any = (state: any = INITIAL_AGENT_STATE, { type, error, payload }: any) => {
  switch (type) {
    case AgentActions.RECEIVE_AGENT_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case AgentActions.RECEIVE_AGENT_FAILURE:
    case AgentActions.RECEIVE_AGENT_LOGIN_FAILURE:
      return { ...state, data: INITIAL_AGENT_STATE.data, error, loading: false };
    case AgentActions.PERFORM_FETCH_AGENT:
      return { ...state, loading: true };
    case AgentActions.CLEAR_AGENT:
    case AgentActions.PERFORM_LOGOUT_AGENT:
      return INITIAL_AGENT_STATE;
    default:
      return state;
  }
};
