import { Price } from '@model/common';
import { ImageResponse } from '@model/iceberg';

export enum OfferType {
  ALL_INCLUSIVE = 'all-inclusive',
  NO_SINGLE_SUPPLEMENT = 'no-single-supplement',
  SECOND_WEEK_FREE = '2nd-week-free',
  THIRD_WEEK_FREE = '3rd-week-free',
  LATE_AVAILABILITY_TOURS = 'late-availability-tours',
  BLACK_FRIDAY_HOLIDAYS = 'black-friday-holidays',
  BLACK_FRIDAY_TOURS = 'black-friday-tours'
}

export const CUSTOM_PAGE_OFFER_TYPES = [
  OfferType.NO_SINGLE_SUPPLEMENT,
  OfferType.SECOND_WEEK_FREE,
  OfferType.THIRD_WEEK_FREE,
  OfferType.BLACK_FRIDAY_HOLIDAYS,
  OfferType.BLACK_FRIDAY_TOURS
];

export interface OffersResponse<T> {
  data: Array<T>;
}

export interface OffersDestination {
  name: string;
  path: string;
  hotels: number;
  cheapestPrice: Price;
}

export interface OffersDestinationWithImage extends OffersDestination {
  images: Array<ImageResponse>;
}

export const offerTypesList: Array<OfferType> = [
  OfferType.ALL_INCLUSIVE,
  OfferType.NO_SINGLE_SUPPLEMENT,
  OfferType.SECOND_WEEK_FREE,
  OfferType.THIRD_WEEK_FREE
];

export const offerDescriptionFieldMapper: any = {
  [OfferType.ALL_INCLUSIVE]: 'descriptionAllInclusive',
  [OfferType.NO_SINGLE_SUPPLEMENT]: 'descriptionNoSingleSupplement',
  [OfferType.SECOND_WEEK_FREE]: 'descriptionSecondWeekFree',
  [OfferType.THIRD_WEEK_FREE]: 'descriptionThirdWeekFree'
};
