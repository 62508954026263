import { all } from 'redux-saga/effects';
import { onFetchFlights } from '@state/search-results/flights';
import { onFetchEarlyFlights, onApplyEarlyFlights } from '@state/search-results/early-flights';
import { onFetchTours, onSetFilters, handleOnLoadMoreTours } from '@state/search-results/tours/toursOperations';

export function* combinedSearchResultsSagas() {
  yield all([
    onFetchFlights(),
    onFetchEarlyFlights(),
    onApplyEarlyFlights(),
    onFetchTours(),
    onSetFilters(),
    handleOnLoadMoreTours()
  ]);
}
