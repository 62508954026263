import { PackagesResponse } from '@model/iceberg/deal-finder/deal-finder';

export const MOCK_PACKAGES_RESPONSE: PackagesResponse = {
  data: {
    searchToken: '123',
    expires: '2021-01-20T14:00:00.000Z',
    results: [
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: 'europe',
              display: 'Europe'
            },
            country: {
              name: {
                path: 'europe/malta-and-gozo',
                display: 'Malta and Gozo'
              },
              isoCode: 'MT'
            },
            region: {
              path: 'europe/malta-and-gozo/malta',
              display: 'Malta'
            },
            resort: {
              path: 'europe/malta-and-gozo/malta/bugibba',
              display: 'Bugibba'
            },
            hotel: {
              path: 'europe/malta-and-gozo/malta/bugibba/topaz-hotel',
              display: 'Topaz Hotel'
            }
          },
          startDate: '2021-02-19',
          nights: 7,
          hotelRating: 3,
          tripAdvisorRating: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.0-20236-5.svg',
          gallery: [
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-a-bedroom-id/45e4c925bd51b9a28b903b4bf2afa6d9/qawra-palace-hotel-a-bedroom.jpg',
              title: 'Qawra Palace Hotel - A Bedroom'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-aerial-pool-view-id/614b3f6dc5c0c55517b7c30480cc4d3c/qawra-palace-hotel-aerial-pool-view.jpg',
              title: 'Qawra Palace Hotel - Aerial Pool View'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-coffee-shop-id/e0971bdad77c68c732c99447707489a1/qawra-palace-hotel-coffee-shop.jpg',
              title: 'Qawra Palace Hotel - Coffee Shop'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-courtyard-id/50bca1368798f8489bc3d280b622417b/qawra-palace-hotel-courtyard.jpg',
              title: 'Qawra Palace Hotel - Courtyard'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-at-night-id/00a2a4199860914009bed318ca81717f/qawra-palace-hotel-hotel-at-night.jpg',
              title: 'Qawra Palace Hotel - Hotel At Night'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-facade-id/7d9fa76c039a3b7cd84cf476f0a3278d/qawra-palace-hotel-hotel-facade.jpg',
              title: 'Qawra Palace Hotel - Hotel Facade'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-and-jacuzzi-id/9b8934b49355b8fdbf36a33cb594766c/qawra-palace-hotel-indoor-pool-and-jacuzzi.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool And Jacuzzi'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-id/cb3b80e1f6a2a0e367fbd64e119497ca/qawra-palace-hotel-indoor-pool.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-inside-the-hotel-id/1772270f5390776b49ca99c6977625e9/qawra-palace-hotel-inside-the-hotel.jpg',
              title: 'Qawra Palace Hotel - Inside The Hotel'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-main-pool-id/6a6ec5db5b69c217a3a95550c4107bcb/qawra-palace-hotel-main-pool.jpg',
              title: 'Qawra Palace Hotel - Main Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-and-seaview-at-dawn-id/b25b7c4ba02158c47eac6b51ac5b6935/qawra-palace-hotel-pool-and-seaview-at-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool And Seaview At Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-dawn-id/86d9ecfd59f3409673eaf3029c0ece97/qawra-palace-hotel-pool-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-reception-area-id/513eecbcdbb303e1de8adcd7546e915a/qawra-palace-hotel-reception-area.jpg',
              title: 'Qawra Palace Hotel - Reception Area'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-restaurant-id/e6ceb83cc0b3b9172df0315db21d0983/qawra-palace-hotel-restaurant.jpg',
              title: 'Qawra Palace Hotel - Restaurant'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-swimming-pool-and-sea-id/26e834f56b9cadef49a821906ad25528/qawra-palace-hotel-swimming-pool-and-sea.jpg',
              title: 'Qawra Palace Hotel - Swimming Pool And Sea'
            }
          ],
          featuredImage: {
            url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-a-bedroom-id/45e4c925bd51b9a28b903b4bf2afa6d9/qawra-palace-hotel-a-bedroom.jpg',
            title: 'Qawra Palace Hotel - A Bedroom'
          }
        },
        leadInPrice: {
          packageReferences: ['000M77PJ601AA'],
          perPerson: {
            amount: 13000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 26000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: 'Room Only'
        },
        otherPrices: [
          {
            perPerson: {
              amount: 16300,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 32700,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Bed and Breakfast'
          },
          {
            perPerson: {
              amount: 21800,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 43700,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Half Board'
          }
        ]
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: 'europe',
              display: 'Europe'
            },
            country: {
              name: {
                path: 'europe/malta-and-gozo',
                display: 'Malta and Gozo'
              },
              isoCode: 'MT'
            },
            region: {
              path: 'europe/malta-and-gozo/malta',
              display: 'Malta'
            },
            resort: {
              path: 'europe/malta-and-gozo/malta/bugibba',
              display: 'Bugibba'
            },
            hotel: {
              path: 'europe/malta-and-gozo/malta/bugibba/bella-vista-hotel',
              display: 'Bella Vista Hotel'
            }
          },
          startDate: '2021-02-19',
          nights: 7,
          hotelRating: 2,
          tripAdvisorRating: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.5-20236-5.svg',
          gallery: [
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-a-bedroom-id/45e4c925bd51b9a28b903b4bf2afa6d9/qawra-palace-hotel-a-bedroom.jpg',
              title: 'Qawra Palace Hotel - A Bedroom'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-aerial-pool-view-id/614b3f6dc5c0c55517b7c30480cc4d3c/qawra-palace-hotel-aerial-pool-view.jpg',
              title: 'Qawra Palace Hotel - Aerial Pool View'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-coffee-shop-id/e0971bdad77c68c732c99447707489a1/qawra-palace-hotel-coffee-shop.jpg',
              title: 'Qawra Palace Hotel - Coffee Shop'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-courtyard-id/50bca1368798f8489bc3d280b622417b/qawra-palace-hotel-courtyard.jpg',
              title: 'Qawra Palace Hotel - Courtyard'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-at-night-id/00a2a4199860914009bed318ca81717f/qawra-palace-hotel-hotel-at-night.jpg',
              title: 'Qawra Palace Hotel - Hotel At Night'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-facade-id/7d9fa76c039a3b7cd84cf476f0a3278d/qawra-palace-hotel-hotel-facade.jpg',
              title: 'Qawra Palace Hotel - Hotel Facade'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-and-jacuzzi-id/9b8934b49355b8fdbf36a33cb594766c/qawra-palace-hotel-indoor-pool-and-jacuzzi.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool And Jacuzzi'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-id/cb3b80e1f6a2a0e367fbd64e119497ca/qawra-palace-hotel-indoor-pool.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-inside-the-hotel-id/1772270f5390776b49ca99c6977625e9/qawra-palace-hotel-inside-the-hotel.jpg',
              title: 'Qawra Palace Hotel - Inside The Hotel'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-main-pool-id/6a6ec5db5b69c217a3a95550c4107bcb/qawra-palace-hotel-main-pool.jpg',
              title: 'Qawra Palace Hotel - Main Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-and-seaview-at-dawn-id/b25b7c4ba02158c47eac6b51ac5b6935/qawra-palace-hotel-pool-and-seaview-at-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool And Seaview At Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-dawn-id/86d9ecfd59f3409673eaf3029c0ece97/qawra-palace-hotel-pool-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-reception-area-id/513eecbcdbb303e1de8adcd7546e915a/qawra-palace-hotel-reception-area.jpg',
              title: 'Qawra Palace Hotel - Reception Area'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-restaurant-id/e6ceb83cc0b3b9172df0315db21d0983/qawra-palace-hotel-restaurant.jpg',
              title: 'Qawra Palace Hotel - Restaurant'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-swimming-pool-and-sea-id/26e834f56b9cadef49a821906ad25528/qawra-palace-hotel-swimming-pool-and-sea.jpg',
              title: 'Qawra Palace Hotel - Swimming Pool And Sea'
            }
          ],
          featuredImage: {
            url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-aerial-pool-view-id/614b3f6dc5c0c55517b7c30480cc4d3c/qawra-palace-hotel-aerial-pool-view.jpg',
            title: 'Qawra Palace Hotel - Aerial Pool View'
          }
        },
        leadInPrice: {
          packageReferences: ['000M77PJ601AA'],
          perPerson: {
            amount: 30000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 26000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: 'Room Only'
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: 'europe',
              display: 'Europe'
            },
            country: {
              name: {
                path: 'europe/malta-and-gozo',
                display: 'Malta and Gozo'
              },
              isoCode: 'MT'
            },
            region: {
              path: 'europe/malta-and-gozo/malta',
              display: 'Malta'
            },
            resort: {
              path: 'europe/malta-and-gozo/malta/bugibba',
              display: 'Bugibba'
            },
            hotel: {
              path: 'europe/malta-and-gozo/malta/bugibba/jimmy-hotel',
              display: 'Jimmy La Hotel'
            }
          },
          startDate: '2021-02-19',
          nights: 7,
          hotelRating: 1,
          tripAdvisorRating: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.5-20236-5.svg',
          gallery: [
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-bay-leaf-restaurant-id/37ebec09e098088cd21d3c03fe26feb3/soreda-hotel-bay-leaf-restaurant.jpg',
              title: 'Soreda Hotel - Bay Leaf Restaurant'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-buffet-id/a3fe01359f347d252709e444d1df6f51/soreda-hotel-buffet.jpg',
              title: 'Soreda Hotel - Buffet'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-carvery-id/0ff3620857faa6c71dc7470fc3c33288/soreda-hotel-carvery.jpg',
              title: 'Soreda Hotel - Carvery'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-facade-id/c5cfa1163e699be10e9cf2d49afb5f28/soreda-hotel-facade.jpg',
              title: 'Soreda Hotel - Facade'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-family-room-id/375fe02bc14feaebf9e07a24b1c2c5e9/soreda-hotel-family-room.jpg',
              title: 'Soreda Hotel - Family Room'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-indoor-pool-id/97c95cf80ed17559685216ef95f7e90f/soreda-hotel-indoor-pool.jpg',
              title: 'Soreda Hotel - Indoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-main-pool-id/ebee974e16689f3cc87915e913a3cde9/soreda-hotel-main-pool.jpg',
              title: 'Soreda Hotel - Main Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-reception-id/81aa45806f62d4070ca3667b643aeb0e/soreda-hotel-reception.jpg',
              title: 'Soreda Hotel - Reception'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-self-catering-apartment-id/3e7dc64809b2e2186a7b95d86e4c673b/soreda-hotel-self-catering-apartment.jpg',
              title: 'Soreda Hotel - Self Catering Apartment'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-standard-twin-room-id/ba791a6f38c1a168f6a2cfe3e7b127a7/soreda-hotel-standard-twin-room.jpg',
              title: 'Soreda Hotel - Standard Twin Room'
            }
          ],
          featuredImage: {
            url: '//images.ctfassets.net/3vgdkcmqrnp2/soreda-hotel-bay-leaf-restaurant-id/37ebec09e098088cd21d3c03fe26feb3/soreda-hotel-bay-leaf-restaurant.jpg',
            title: 'Soreda Hotel - Bay Leaf Restaurant'
          }
        },
        leadInPrice: {
          packageReferences: ['000M77PJ601AA'],
          perPerson: {
            amount: 1000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 2000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: 'Room Only'
        },
        otherPrices: [
          {
            perPerson: {
              amount: 1300,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 3700,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Bed and Breakfast'
          },
          {
            perPerson: {
              amount: 28500,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 4700,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Half Board'
          },
          {
            perPerson: {
              amount: 21800,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 42700,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Full board'
          },
          {
            perPerson: {
              amount: 112800,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 422700,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'All Inclusive'
          }
        ]
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: 'europe',
              display: 'Europe'
            },
            country: {
              name: {
                path: 'europe/malta-and-gozo',
                display: 'Malta and Gozo'
              },
              isoCode: 'MT'
            },
            region: {
              path: 'europe/malta-and-gozo/malta',
              display: 'Malta'
            },
            resort: {
              path: 'europe/malta-and-gozo/malta/bugibba',
              display: 'Bugibba'
            },
            hotel: {
              path: 'europe/malta-and-gozo/malta/bugibba/mercury-holidays-hotel',
              display: 'Mercury Holidays Hotel'
            }
          },
          startDate: '2021-02-19',
          nights: 7,
          hotelRating: 5,
          tripAdvisorRating: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.5-20236-5.svg',
          gallery: [
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-diving-shop-id/28431378c84741f4770e8eff7cc211b2/porto-azzurro-diving-shop.jpg',
              title: 'Porto Azzurro - Diving Shop'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-facade-night-id/8c771510a8f3b25920b04f59f52ebe1d/porto-azzurro-facade-night.jpg',
              title: 'Porto Azzurro - Facade Night'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-hotel-facade-id/70160d203e394509be0a986e58e3e79b/porto-azzurro-hotel-facade.jpg',
              title: 'Porto Azzurro - Hotel Facade'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-hotel-room-id/b4d317b5bf1a975798d798ee4d7621d6/porto-azzurro-hotel-room.jpg',
              title: 'Porto Azzurro - Hotel Room'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-kids-pool-id/5b2e622380a61a62559573199266a213/porto-azzurro-kids-pool.jpg',
              title: 'Porto Azzurro - Kids Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-outdoor-pool-id/6c7ebbd076da710f80ee69ff4cd2ccf5/porto-azzurro-outdoor-pool.jpg',
              title: 'Porto Azzurro - Outdoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-pool-bar-id/c41ca42a76a5322506592a21d5f6e81e/porto-azzurro-pool-bar.jpg',
              title: 'Porto Azzurro - Pool Bar'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-pool-landscape-id/f7a7f6539dfad3516b841f007a16a790/porto-azzurro-pool-landscape.jpg',
              title: 'Porto Azzurro - Pool Landscape'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-pool-under-blue-skies-id/1ba3a786c624e940501bfdd4707bb697/porto-azzurro-pool-under-blue-skies.jpg',
              title: 'Porto Azzurro - Pool Under Blue Skies'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-pool-view-room-id/eebe66006766fbd8f6311efe267a9ba7/porto-azzurro-pool-view-room.jpg',
              title: 'Porto Azzurro - Pool View Room'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-reception-id/cb5bf42440f90d30c5af331aca7c5eb4/porto-azzurro-reception.jpg',
              title: 'Porto Azzurro - Reception'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-studio-apartment-id/61b87753c943a5e3372797ab67547803/porto-azzurro-studio-apartment.jpg',
              title: 'Porto Azzurro - Studio Apartment'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-studio-apartment-kitchen-id/037b1a2c67da1c326511d59a468328e4/porto-azzurro-studio-apartment-kitchen.jpg',
              title: 'Porto Azzurro - Studio Apartment Kitchen'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-the-indoor-pool-id/a53234deba82ea7942a0f8af8caa1740/porto-azzurro-the-indoor-pool.jpg',
              title: 'Porto Azzurro - The Indoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-the-pool-id/dcd6de230771cc0b95d47d342a3e8092/porto-azzurro-the-pool.jpg',
              title: 'Porto Azzurro - The Pool'
            }
          ],
          featuredImage: {
            url: '//images.ctfassets.net/3vgdkcmqrnp2/porto-azzurro-diving-shop-id/28431378c84741f4770e8eff7cc211b2/porto-azzurro-diving-shop.jpg',
            title: 'Porto Azzurro - Diving Shop'
          }
        },
        leadInPrice: {
          packageReferences: ['000M77PJ601AA'],
          perPerson: {
            amount: 113000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 260100,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: 'Room Only'
        },
        otherPrices: [
          {
            perPerson: {
              amount: 161300,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 327100,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Bed and Breakfast'
          },
          {
            perPerson: {
              amount: 218100,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 437100,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Half Board'
          }
        ]
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: 'europe',
              display: 'Europe'
            },
            country: {
              name: {
                path: 'europe/malta-and-gozo',
                display: 'Malta and Gozo'
              },
              isoCode: 'MT'
            },
            region: {
              path: 'europe/malta-and-gozo/malta',
              display: 'Malta'
            },
            resort: {
              path: 'europe/malta-and-gozo/malta/bugibba',
              display: 'Bugibba'
            },
            hotel: {
              path: 'europe/malta-and-gozo/malta/bugibba/preluna-hotel',
              display: 'Preluna Hotel'
            }
          },
          startDate: '2021-02-19',
          nights: 7,
          hotelRating: 2,
          tripAdvisorRating: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.5-20236-5.svg',
          gallery: [
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-a-bedroom-id/45e4c925bd51b9a28b903b4bf2afa6d9/qawra-palace-hotel-a-bedroom.jpg',
              title: 'Qawra Palace Hotel - A Bedroom'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-aerial-pool-view-id/614b3f6dc5c0c55517b7c30480cc4d3c/qawra-palace-hotel-aerial-pool-view.jpg',
              title: 'Qawra Palace Hotel - Aerial Pool View'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-coffee-shop-id/e0971bdad77c68c732c99447707489a1/qawra-palace-hotel-coffee-shop.jpg',
              title: 'Qawra Palace Hotel - Coffee Shop'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-courtyard-id/50bca1368798f8489bc3d280b622417b/qawra-palace-hotel-courtyard.jpg',
              title: 'Qawra Palace Hotel - Courtyard'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-at-night-id/00a2a4199860914009bed318ca81717f/qawra-palace-hotel-hotel-at-night.jpg',
              title: 'Qawra Palace Hotel - Hotel At Night'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-facade-id/7d9fa76c039a3b7cd84cf476f0a3278d/qawra-palace-hotel-hotel-facade.jpg',
              title: 'Qawra Palace Hotel - Hotel Facade'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-and-jacuzzi-id/9b8934b49355b8fdbf36a33cb594766c/qawra-palace-hotel-indoor-pool-and-jacuzzi.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool And Jacuzzi'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-id/cb3b80e1f6a2a0e367fbd64e119497ca/qawra-palace-hotel-indoor-pool.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-inside-the-hotel-id/1772270f5390776b49ca99c6977625e9/qawra-palace-hotel-inside-the-hotel.jpg',
              title: 'Qawra Palace Hotel - Inside The Hotel'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-main-pool-id/6a6ec5db5b69c217a3a95550c4107bcb/qawra-palace-hotel-main-pool.jpg',
              title: 'Qawra Palace Hotel - Main Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-and-seaview-at-dawn-id/b25b7c4ba02158c47eac6b51ac5b6935/qawra-palace-hotel-pool-and-seaview-at-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool And Seaview At Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-dawn-id/86d9ecfd59f3409673eaf3029c0ece97/qawra-palace-hotel-pool-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-reception-area-id/513eecbcdbb303e1de8adcd7546e915a/qawra-palace-hotel-reception-area.jpg',
              title: 'Qawra Palace Hotel - Reception Area'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-restaurant-id/e6ceb83cc0b3b9172df0315db21d0983/qawra-palace-hotel-restaurant.jpg',
              title: 'Qawra Palace Hotel - Restaurant'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-swimming-pool-and-sea-id/26e834f56b9cadef49a821906ad25528/qawra-palace-hotel-swimming-pool-and-sea.jpg',
              title: 'Qawra Palace Hotel - Swimming Pool And Sea'
            }
          ],
          featuredImage: {
            url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-aerial-pool-view-id/614b3f6dc5c0c55517b7c30480cc4d3c/qawra-palace-hotel-aerial-pool-view.jpg',
            title: 'Qawra Palace Hotel - Aerial Pool View'
          }
        },
        leadInPrice: {
          packageReferences: ['000M77PJ601AA'],
          perPerson: {
            amount: 30000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 26000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: 'Room Only'
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: 'europe',
              display: 'Europe'
            },
            country: {
              name: {
                path: 'europe/malta-and-gozo',
                display: 'Malta and Gozo'
              },
              isoCode: 'MT'
            },
            region: {
              path: 'europe/malta-and-gozo/malta',
              display: 'Malta'
            },
            resort: {
              path: 'europe/malta-and-gozo/malta/bugibba',
              display: 'Bugibba'
            },
            hotel: {
              path: 'europe/malta-and-gozo/malta/bugibba/canifor-hotel',
              display: 'Canifor Hotel'
            }
          },
          startDate: '2021-02-19',
          nights: 7,
          hotelRating: 3,
          tripAdvisorRating: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.0-20236-5.svg',
          gallery: [
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-a-bedroom-id/45e4c925bd51b9a28b903b4bf2afa6d9/qawra-palace-hotel-a-bedroom.jpg',
              title: 'Qawra Palace Hotel - A Bedroom'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-aerial-pool-view-id/614b3f6dc5c0c55517b7c30480cc4d3c/qawra-palace-hotel-aerial-pool-view.jpg',
              title: 'Qawra Palace Hotel - Aerial Pool View'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-coffee-shop-id/e0971bdad77c68c732c99447707489a1/qawra-palace-hotel-coffee-shop.jpg',
              title: 'Qawra Palace Hotel - Coffee Shop'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-courtyard-id/50bca1368798f8489bc3d280b622417b/qawra-palace-hotel-courtyard.jpg',
              title: 'Qawra Palace Hotel - Courtyard'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-at-night-id/00a2a4199860914009bed318ca81717f/qawra-palace-hotel-hotel-at-night.jpg',
              title: 'Qawra Palace Hotel - Hotel At Night'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-hotel-facade-id/7d9fa76c039a3b7cd84cf476f0a3278d/qawra-palace-hotel-hotel-facade.jpg',
              title: 'Qawra Palace Hotel - Hotel Facade'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-and-jacuzzi-id/9b8934b49355b8fdbf36a33cb594766c/qawra-palace-hotel-indoor-pool-and-jacuzzi.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool And Jacuzzi'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-indoor-pool-id/cb3b80e1f6a2a0e367fbd64e119497ca/qawra-palace-hotel-indoor-pool.jpg',
              title: 'Qawra Palace Hotel - Indoor Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-inside-the-hotel-id/1772270f5390776b49ca99c6977625e9/qawra-palace-hotel-inside-the-hotel.jpg',
              title: 'Qawra Palace Hotel - Inside The Hotel'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-main-pool-id/6a6ec5db5b69c217a3a95550c4107bcb/qawra-palace-hotel-main-pool.jpg',
              title: 'Qawra Palace Hotel - Main Pool'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-and-seaview-at-dawn-id/b25b7c4ba02158c47eac6b51ac5b6935/qawra-palace-hotel-pool-and-seaview-at-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool And Seaview At Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-pool-dawn-id/86d9ecfd59f3409673eaf3029c0ece97/qawra-palace-hotel-pool-dawn.jpg',
              title: 'Qawra Palace Hotel - Pool Dawn'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-reception-area-id/513eecbcdbb303e1de8adcd7546e915a/qawra-palace-hotel-reception-area.jpg',
              title: 'Qawra Palace Hotel - Reception Area'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-restaurant-id/e6ceb83cc0b3b9172df0315db21d0983/qawra-palace-hotel-restaurant.jpg',
              title: 'Qawra Palace Hotel - Restaurant'
            },
            {
              url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-swimming-pool-and-sea-id/26e834f56b9cadef49a821906ad25528/qawra-palace-hotel-swimming-pool-and-sea.jpg',
              title: 'Qawra Palace Hotel - Swimming Pool And Sea'
            }
          ],
          featuredImage: {
            url: '//images.ctfassets.net/3vgdkcmqrnp2/qawra-palace-hotel-a-bedroom-id/45e4c925bd51b9a28b903b4bf2afa6d9/qawra-palace-hotel-a-bedroom.jpg',
            title: 'Qawra Palace Hotel - A Bedroom'
          }
        },
        leadInPrice: {
          packageReferences: ['000M77PJ601AA'],
          perPerson: {
            amount: 13000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 26000,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: 'Room Only'
        },
        otherPrices: [
          {
            perPerson: {
              amount: 1630000,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 3270000,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Bed and Breakfast'
          },
          {
            perPerson: {
              amount: 2180000,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            total: {
              amount: 4370000,
              currency: {
                code: 'GBP',
                symbol: '£'
              }
            },
            mealPlan: 'Half Board'
          }
        ]
      }
    ],
    facets: {
      filters: [
        {
          name: 'profile',
          type: 'sort',
          formattedName: 'Sort By',
          values: [
            {
              name: 'Popularity: High to Low',
              value: '1',
              selected: true,
              count: 0
            },
            {
              name: 'Price: High to Low',
              value: '3',
              selected: false,
              count: 0
            },
            {
              name: 'Price: Low to High',
              value: '2',
              selected: false,
              count: 0
            },
            {
              name: 'TripAdvisor: High to Low',
              value: '4',
              selected: false,
              count: 0
            },
            {
              name: 'Hotels: A to Z',
              value: '5',
              selected: false,
              count: 0
            },
            {
              name: 'Hotels: Z to A',
              value: '6',
              selected: false,
              count: 0
            }
          ]
        },
        {
          name: 'mealbases',
          type: 'checkbox',
          formattedName: 'Meal Options',
          values: [
            {
              name: 'Bed and Breakfast',
              value: '1',
              selected: false,
              count: 33
            },
            {
              name: 'Half Board',
              value: '2',
              selected: false,
              count: 28
            },
            {
              name: 'All Inclusive',
              value: '3',
              selected: false,
              count: 8
            },
            {
              name: 'Half Board Plus',
              value: '5',
              selected: false,
              count: 4
            },
            {
              name: 'Room Only',
              value: '6',
              selected: false,
              count: 6
            },
            {
              name: 'Self Catering',
              value: '7',
              selected: false,
              count: 5
            },
            {
              name: 'Full Board',
              value: '8',
              selected: false,
              count: 11
            }
          ]
        },
        {
          name: 'mintripadvisorrating',
          type: 'radio',
          formattedName: 'Trip Advisor Rating',
          values: [
            {
              name: '5',
              value: '5',
              selected: false,
              count: 6
            },
            {
              name: '4 and above',
              value: '4',
              selected: false,
              count: 27
            },
            {
              name: '3 and above',
              value: '3',
              selected: false,
              count: 39
            },
            {
              name: '2 and above',
              value: '2',
              selected: false,
              count: 39
            }
          ]
        },
        {
          name: 'priceband',
          type: 'radio',
          formattedName: 'Price Range',
          values: [
            {
              name: '£200 - £400',
              value: '2',
              selected: false,
              count: 12
            },
            {
              name: '£400 - £600',
              value: '3',
              selected: false,
              count: 20
            },
            {
              name: '£600 - £800',
              value: '4',
              selected: false,
              count: 6
            },
            {
              name: '£800 - £1000',
              value: '5',
              selected: false,
              count: 1
            }
          ]
        },
        {
          name: 'ratings',
          type: 'stars',
          formattedName: 'Star Rating',
          values: [
            {
              name: '3 Star',
              value: '3',
              selected: false,
              count: 12
            },
            {
              name: '4 Star',
              value: '4',
              selected: false,
              count: 21
            },
            {
              name: '5 Star',
              value: '5',
              selected: false,
              count: 6
            }
          ]
        },
        {
          name: 'facilities',
          type: 'checkbox',
          formattedName: 'Facilities',
          values: [
            {
              name: 'Adults Only',
              value: '47',
              selected: false,
              count: 1
            }
          ]
        },
        {
          name: 'g3s',
          type: 'checkbox',
          formattedName: 'Resort',
          values: [
            {
              name: 'Bugibba',
              value: '2',
              selected: false,
              count: 3
            },
            {
              name: 'Golden Bay',
              value: '4',
              selected: false,
              count: 1
            },
            {
              name: 'Marfa',
              value: '5',
              selected: false,
              count: 1
            },
            {
              name: 'Mellieha',
              value: '6',
              selected: false,
              count: 5
            },
            {
              name: 'Qawra',
              value: '8',
              selected: false,
              count: 9
            },
            {
              name: 'San Anton',
              value: '11',
              selected: false,
              count: 1
            },
            {
              name: 'Sliema',
              value: '12',
              selected: false,
              count: 13
            },
            {
              name: 'St Georges Bay',
              value: '13',
              selected: false,
              count: 3
            },
            {
              name: 'Mdina',
              value: '493',
              selected: false,
              count: 2
            },
            {
              name: 'San Gwann',
              value: '983',
              selected: false,
              count: 1
            }
          ]
        }
      ],
      counts: {
        total: 39,
        filtered: 39
      }
    },
    nextSet: 1285
  }
};

export const MOCK_SKELETON_LOADING_PACKAGES_RESPONSE: PackagesResponse = {
  data: {
    searchToken: '',
    expires: '',
    results: [
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '0',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '1',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '2',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '3',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '4',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '5',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '6',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '7',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '8',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '9',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '10',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      },
      {
        summary: {
          connectId: 0,
          place: {
            continent: {
              path: '',
              display: ''
            },
            country: {
              name: {
                path: '',
                display: ''
              },
              isoCode: ''
            },
            region: {
              path: '',
              display: ''
            },
            resort: {
              path: '',
              display: ''
            },
            hotel: {
              path: '11',
              display: ''
            }
          },
          startDate: '',
          nights: 0,
          hotelRating: 0,
          tripAdvisorRating: '',
          gallery: [],
          featuredImage: {
            url: '',
            title: ''
          }
        },
        leadInPrice: {
          packageReferences: [],
          perPerson: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          total: {
            amount: 0,
            currency: {
              code: 'GBP',
              symbol: '£'
            }
          },
          mealPlan: ''
        },
        otherPrices: []
      }
    ],
    facets: {
      filters: [],
      counts: {
        filtered: 0,
        total: 0
      }
    },
    nextSet: 1285
  }
};
