export interface PrivateTourInquiryFormValues {
  departureDate: string;
  comments: string;
  name: string;
  email: string;
  telephone: string;
  preferredContact: PreferredTourContact;
  tourName: string;
  reCaptcha?: string;
}

export enum PreferredTourContact {
  EMAIL = 'email',
  TELEPHONE = 'telephone'
}

export const privateTourInquiryFormInitialValues: ({ selectedDate, tourName }: any) => PrivateTourInquiryFormValues = ({
  selectedDate,
  tourName
}: any) => ({
  tourName: tourName || '',
  departureDate: selectedDate || '',
  comments: '',
  name: '',
  email: '',
  telephone: '',
  preferredContact: PreferredTourContact.EMAIL
});
