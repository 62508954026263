import { EndPoint, getURL, Request } from '@model/service';
import { BasketResponse } from '@model/iceberg';
import uri from 'urijs';
import { BookingEndpoints } from '@model/service/booking-endpoints';

export interface SupplementsPayload {
  token: string;
  supplement: string;
}

export class SupplementsApi {
  private api: Request = new Request();
  public async put(token: string, rooms: Array<SupplementsPayload> = []): Promise<BasketResponse> {
    return await this.api.put(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.SUPPLEMENTS}`)
        .href(),
      { rooms }
    );
  }
}
