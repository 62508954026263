import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { CookieSerializeOptions } from 'cookie';
import { Environment } from '@model/config/environment';
import { EnvConfig } from '@model/config/env-config';
import { BrandCodes, GlobalDomains } from '@model/config/brands';

export enum Cookie {
  TRADE_AUTHORIZATION = 'TradeAuthorizationToken',
  MARKETING_CODE = 'mcode',
  COOKIES_ACCEPTED = 'f_cookies',
  SFSK = 'X-SFSK',
  X_UUID = 'X-UUID',
  EHID = 'EHID',
  CANCEL_PROMO = 'CancelPromoModal',
  XDEBUG = 'XDEBUG_SESSION'
}

export interface GetCookie {
  get: (id: Cookie) => string | undefined;
}

export interface SetCookie {
  set: (payload: SetCookiePayload) => void;
}

export interface DestroyCookie {
  destroy: (id: Cookie) => void;
}

export interface GetDomain {
  getDomain: () => string;
}

export interface SetCookiePayload {
  id: Cookie;
  value: string;
  options: CookieSerializeOptions;
}

export const domainMapping = {
  [Environment.LOCAL]: {
    [BrandCodes.MERCURY_HOLIDAYS]: 'localhost',
    [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: 'localhost',
    [BrandCodes.SUNSPOT_TOURS]: 'localhost'
  },
  [Environment.DEVELOP]: {
    [BrandCodes.MERCURY_HOLIDAYS]: GlobalDomains[BrandCodes.MERCURY_HOLIDAYS],
    [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: GlobalDomains[BrandCodes.MERCURY_HOLIDAYS_IRELAND],
    [BrandCodes.SUNSPOT_TOURS]: GlobalDomains[BrandCodes.SUNSPOT_TOURS]
  },
  [Environment.STAGING]: {
    [BrandCodes.MERCURY_HOLIDAYS]: GlobalDomains[BrandCodes.MERCURY_HOLIDAYS],
    [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: GlobalDomains[BrandCodes.MERCURY_HOLIDAYS_IRELAND],
    [BrandCodes.SUNSPOT_TOURS]: GlobalDomains[BrandCodes.SUNSPOT_TOURS]
  },
  [Environment.PRODUCTION]: {
    [BrandCodes.MERCURY_HOLIDAYS]: GlobalDomains[BrandCodes.MERCURY_HOLIDAYS],
    [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: GlobalDomains[BrandCodes.MERCURY_HOLIDAYS_IRELAND],
    [BrandCodes.SUNSPOT_TOURS]: GlobalDomains[BrandCodes.SUNSPOT_TOURS]
  }
};

export default class Cookies implements GetCookie, SetCookie, DestroyCookie, GetDomain {
  public get(id: Cookie): string | undefined {
    return parseCookies({})[id];
  }

  public set(payload: SetCookiePayload) {
    const { id, value, options } = payload;
    setCookie({}, id, value, options);
  }

  public destroy(id: Cookie) {
    destroyCookie(null, id, {
      path: '/',
      domain: this.getDomain()
    });
  }

  public getDomain() {
    const env = EnvConfig.get().APP_ENV;
    const appVariantId = EnvConfig.get().APP_VARIANT_ID;
    return domainMapping[env || Environment.PRODUCTION][appVariantId || BrandCodes.MERCURY_HOLIDAYS];
  }
}
