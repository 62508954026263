import { FilterMapping, FilterNames, FilterTypes, MultiSelectFilterVariants } from '@model/filters';

export const FilterTypesMapping: FilterMapping = {
  [FilterNames.HOTEL_RATING_OCCURENCE]: {
    type: FilterTypes.STAR_RATING,
    variant: MultiSelectFilterVariants.SELECT
  },
  [FilterNames.RESORTS]: { type: FilterTypes.MULTI_SELECT, variant: MultiSelectFilterVariants.SELECT },
  [FilterNames.MEAL_PLANS]: { type: FilterTypes.SINGLE_SELECT },
  [FilterNames.ACCESSIBLE]: {
    type: FilterTypes.SINGLE_SELECT,
    displayAllOption: 'No',
    subHeading: 'filters__meal-options--subheading',
    tooltip: 'filters__meal-options--tooltip'
  },
  [FilterNames.STOPS]: { type: FilterTypes.MULTI_SELECT, variant: MultiSelectFilterVariants.SELECT },
  [FilterNames.DEPARTING_FROM]: { type: FilterTypes.MULTI_SELECT, variant: MultiSelectFilterVariants.SELECT },
  [FilterNames.AIRLINE]: { type: FilterTypes.MULTI_SELECT, variant: MultiSelectFilterVariants.UNSELECT },
  [FilterNames.MINIMUM_PRICE]: { type: FilterTypes.PRICE_RANGE },
  [FilterNames.MAXIMUM_PRICE]: { type: FilterTypes.PRICE_RANGE },
  [FilterNames.OUTBOUND_DEPARTURE_TIME]: { type: FilterTypes.RANGE_SLIDER },
  [FilterNames.RETURN_DEPARTURE_TIME]: { type: FilterTypes.RANGE_SLIDER },
  [FilterNames.TOUR_DURATION]: { type: FilterTypes.MULTI_SELECT, variant: MultiSelectFilterVariants.SELECT },
  [FilterNames.TOUR_TRIP_TYPE]: { type: FilterTypes.MULTI_SELECT, variant: MultiSelectFilterVariants.SELECT }
};
