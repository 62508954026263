import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { keyframes } from '@emotion/css';
import { TestId } from '@components/test-ids';
import { ZIndex } from '@styles/z-index';
import { CssThemeProps } from '@theme/base';

export interface BaseSpinnerProps {
  color?: string;
  size?: number;
  thickness?: number;
  css?: any;
  className?: string;
}

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
}
`;

interface SpinnerProps extends CssThemeProps {
  color?: string;
  size?: number;
  thickness?: number;
}
const Spinner: any = styled.div(({ theme, color, size, thickness, css }: SpinnerProps) => ({
  display: 'block',
  position: 'relative',
  width: size || 30,
  height: size || 30,
  zIndex: ZIndex.LOADING_SPINNER,
  ['& div']: {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: size || 50,
    height: size || 50,
    border: `${thickness || 5}px solid ${color || theme.custom.colors.white}`,
    borderRadius: '50%',
    animation: `${spinnerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    borderColor: `${color || theme.custom.colors.white} transparent transparent transparent`
  },

  ['& div:nth-of-type(1)']: {
    animationDelay: '-0.45s'
  },

  ['& div:nth-of-type(2)']: {
    animationDelay: '-0.3s'
  },

  ['& div:nth-of-type(3)']: {
    animationDelay: '-0.15s'
  },

  ...css
}));

export const BaseSpinnerComponent = ({ color, size, thickness, css, className }: BaseSpinnerProps) => (
  <Spinner color={color} size={size} thickness={thickness} data-testid={TestId.spinner} css={css} className={className}>
    <div />
    <div />
    <div />
    <div />
  </Spinner>
);

export const BaseSpinner = withTheme(BaseSpinnerComponent);
