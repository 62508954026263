import { DealFinderErrors, DealFinderState, Destination, Occupancy } from '@model/state/deal-finder-state';
import { takeLatest } from 'redux-saga/effects';
import { ModalType, showModal } from '@state/modal/modalOperations';
import { call, put, select } from '@redux-saga/core/effects';
import { GeographyResult, hasChildrenWithErrors } from '@model/search';
import { setTabIndexBySearchType } from '@state/search';
import {
  isChildValidAgeOnHoliday,
  getDealFinderErrors,
  getHotelOnlySearch
} from '@state/deal-finder/dealFinderSelectors';
import { performSearch, setToken } from '@state/deal-finder-results/dealFinderResultsOperations';
import { CriteriaResponse, SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { DealFinderApi } from '@model/iceberg/service/deal-finder/deal-finder-api';
import { DealFinderMapper, PackageDealFinderState } from '@model/iceberg/service/deal-finder/deal-finder-mapper';
import { FILTER_DEFAULTS, MAXIMUM_DESTINATIONS } from '@model/price-calendar';
import { BaseAction } from '@model/redux';
import { isHotelPath } from '@util/path';
import { getDestinationTags } from '@util/destination';

export enum DealFinderActions {
  ADD_DESTINATION = '@DEAL_FINDER/ADD_DESTINATION',
  ADD_HOTEL = '@DEAL_FINDER/ADD_HOTEL',
  REMOVE_DESTINATION = '@DEAL_FINDER/REMOVE_DESTINATION',
  CLEAR_DESTINATIONS = '@DEAL_FINDER/CLEAR_DESTINATIONS',
  SET_AIRPORTS = '@DEAL_FINDER/SET_AIRPORTS',
  SET_DURATION = '@DEAL_FINDER/SET_DURATION',
  SET_DATE = '@DEAL_FINDER/SET_DATE',
  SET_MONTH = '@DEAL_FINDER/SET_MONTH',
  SET_FLEXIBLE_DAYS = '@DEAL_FINDER/SET_FLEXIBLE_DAYS',
  SET_OCCUPANCY = '@DEAL_FINDER/SET_OCCUPANCY',
  SET_SEARCH_TYPE = '@DEAL_FINDER/SET_SEARCH_TYPE',
  DISPLAY_ERRORS = '@DEAL_FINDER/DISPLAY_ERRORS',
  SET_ERRORS = '@DEAL_FINDER/SET_ERRORS',
  OCCUPANCY_INCREMENT = '@DEAL_FINDER/OCCUPANCY_INCREMENT',
  OCCUPANCY_DECREMENT = '@DEAL_FINDER/OCCUPANCY_DECREMENT',
  OCCUPANCY_CLEAR = '@DEAL_FINDER/OCCUPANCY_CLEAR',
  OCCUPANCY_BLUR = '@DEAL_FINDER/OCCUPANCY_BLUR',
  CLEAR_DATES = '@DEAL_FINDER/CLEAR_DATES',
  VALIDATE_SEARCH = '@DEAL_FINDER/VALIDATE_SEARCH',
  HYDRATE_SEARCH = '@DEAL_FINDER/HYDRATE_SEARCH',
  RECEIVE_CRITERIA_SUCCESS = '@DEAL_FINDER/RECEIVE_CRITERIA_SUCCESS',
  RECEIVE_CRITERIA_FAILURE = '@DEAL_FINDER/RECEIVE_CRITERIA_FAILURE',
  RECEIVE_PARTIAL_SEARCH = '@DEAL_FINDER/RECEIVE_PARTIAL_SEARCH',
  SET_HOTEL_LANDING_SEARCH = '@DEAL_FINDER/SET_HOTEL_LANDING_SEARCH',
  RESET_STATE = '@DEAL_FINDER/RESET_STATE'
}

export const INITIAL_DEAL_FINDER_STATE: DealFinderState = {
  searchType: SearchType.MAIN,
  duration: FILTER_DEFAULTS.duration,
  date: '',
  month: '',
  destinations: [],
  airports: [],
  flexibleDays: false,
  occupancy: [{ adults: 2, children: [] }],
  preferredBoardBasis: '',
  hotelLandingSearch: false,
  destinationSearch: false,
  errors: {
    airports: false,
    dates: false,
    destinations: false,
    occupancy: false
  },
  restoreState: {
    airports: []
  }
};

export type PartialDealFinderState = Pick<
  DealFinderState,
  'destinations' | 'airports' | 'duration' | 'occupancy' | 'month'
> & {
  preferredBoardBasis?: string;
};

export const setDuration = (payload: number) => ({ type: DealFinderActions.SET_DURATION, payload });
export const setDate = (payload: string) => ({ type: DealFinderActions.SET_DATE, payload });
export const setMonth = (payload: string) => ({ type: DealFinderActions.SET_MONTH, payload });
export const setFlexibleDays = (payload: boolean) => ({ type: DealFinderActions.SET_FLEXIBLE_DAYS, payload });
export const addDestination = (payload: GeographyResult) => ({ type: DealFinderActions.ADD_DESTINATION, payload });
export const addHotel = (payload: GeographyResult) => ({ type: DealFinderActions.ADD_HOTEL, payload });
export const removeDestination = (payload: Destination) => ({
  type: DealFinderActions.REMOVE_DESTINATION,
  payload
});
export const clearDestinations = () => ({ type: DealFinderActions.CLEAR_DESTINATIONS });
export const setAirports = (payload: Array<string>) => ({ type: DealFinderActions.SET_AIRPORTS, payload });
export const clearDates = () => ({ type: DealFinderActions.CLEAR_DATES });
export const setOccupancy = (payload: Array<Occupancy>) => ({ type: DealFinderActions.SET_OCCUPANCY, payload });
export const occupancyIncrement = () => ({ type: DealFinderActions.OCCUPANCY_INCREMENT });
export const occupancyDecrement = () => ({ type: DealFinderActions.OCCUPANCY_DECREMENT });
export const onOccupancyClear = () => ({ type: DealFinderActions.OCCUPANCY_CLEAR });
export const onOccupancyBlur = () => ({ type: DealFinderActions.OCCUPANCY_BLUR });
export const hydrateSearch = (payload: string) => ({ type: DealFinderActions.HYDRATE_SEARCH, payload });
export const receiveCriteriaSuccess = (payload: DealFinderState | PackageDealFinderState) => ({
  type: DealFinderActions.RECEIVE_CRITERIA_SUCCESS,
  payload
});
export const receiveCriteriaFailure = () => ({ type: DealFinderActions.RECEIVE_CRITERIA_FAILURE });
export const setPartialSearch = (payload: PartialDealFinderState) => ({
  type: DealFinderActions.RECEIVE_PARTIAL_SEARCH,
  payload
});
export const setErrors = (payload: DealFinderErrors) => ({ type: DealFinderActions.SET_ERRORS, payload });
export const setSearchType = (payload: SearchType) => ({ type: DealFinderActions.SET_SEARCH_TYPE, payload });

export const displayErrors = () => ({ type: DealFinderActions.DISPLAY_ERRORS });

export const validateSearch = (payload: DealFinderState) => ({ type: DealFinderActions.VALIDATE_SEARCH, payload });

export const setHotelLandingSearch = (payload: boolean) => ({
  type: DealFinderActions.SET_HOTEL_LANDING_SEARCH,
  payload: { hotelLandingSearch: payload }
});

export const resetDealFinder = () => ({ type: DealFinderActions.RESET_STATE });

export interface ValidateSearchAction extends BaseAction {
  type: DealFinderActions.VALIDATE_SEARCH;
  payload: DealFinderState;
}

export function* handleOnValidateSearch() {
  yield takeLatest(DealFinderActions.VALIDATE_SEARCH, performOnValidateSearch);
}

export function* handleOnHydrateSearch() {
  yield takeLatest(DealFinderActions.HYDRATE_SEARCH, performHydrateSearch);
}

export function* performOnValidateSearch({ payload }: ValidateSearchAction) {
  const { airports, destinations, date, month, duration, occupancy, searchType, flexibleDays } = payload;
  const hasDate: boolean = !!date || !!month;
  const hasOccupancy: boolean = !hasChildrenWithErrors(occupancy);
  const hotelOnly = yield select(getHotelOnlySearch);
  const isFormValid =
    (!!airports.length || hotelOnly) && !!destinations.length && hasDate && !!duration && hasOccupancy;

  if (isFormValid) {
    const isChildDOBsValid = yield select(isChildValidAgeOnHoliday);
    if (isChildDOBsValid) {
      yield put(
        performSearch({
          ...INITIAL_DEAL_FINDER_STATE,
          airports: hotelOnly ? [] : airports,
          destinations,
          date,
          month,
          duration,
          occupancy,
          searchType,
          flexibleDays
        })
      );
    } else {
      yield put(showModal(ModalType.SEARCH_GUEST_FORM_ERROR));
      yield put(setErrors({ ...payload.errors, occupancy: true }));
    }
  }
}

export function* performHydrateSearch({ payload }: any) {
  yield put(setToken(payload));
  const { response } = yield call(callGetCriteria, payload);
  if (response) {
    const update: PackageDealFinderState = DealFinderMapper.fromPackageParams(response.data);
    yield put(setTabIndexBySearchType(update.searchType));
    if (Object.values(SearchType).includes(update.searchType)) {
      yield put(receiveCriteriaSuccess(update));
    }
  } else {
    yield put(receiveCriteriaFailure());
  }
}

export function callGetCriteria(payload: string) {
  if (payload) {
    const api: DealFinderApi = new DealFinderApi();
    return api
      .criteria(payload)
      .then((response: CriteriaResponse) => ({
        response
      }))
      .catch((error: any) => ({
        error
      }));
  }
}

export const dealFinderReducer: any = (state: DealFinderState = INITIAL_DEAL_FINDER_STATE, { type, payload }: any) => {
  switch (type) {
    case DealFinderActions.ADD_DESTINATION: {
      const result: GeographyResult = payload;
      const maxDestinations: boolean = state.destinations.length === MAXIMUM_DESTINATIONS;
      const destinationsOnly: Array<Destination> = state.destinations.filter(
        (destination: Destination) => !isHotelPath(destination.path)
      );
      const destinations: Array<Destination> = maxDestinations
        ? destinationsOnly
        : destinationsOnly.find(({ path }) => path === result.name.path)
          ? destinationsOnly
          : destinationsOnly.concat({ ...result.name, airports: getDestinationTags(result) });
      return {
        ...state,
        destinations,
        destinationSearch: false,
        errors: {
          ...state.errors,
          destinations: false
        }
      };
    }
    case DealFinderActions.ADD_HOTEL: {
      const result: GeographyResult = payload;
      return {
        ...state,
        destinations: [{ ...result.name, airports: result._tags }],
        destinationSearch: true,
        errors: {
          ...state.errors,
          destinations: false
        }
      };
    }
    case DealFinderActions.REMOVE_DESTINATION:
      return {
        ...state,
        destinations: state.destinations.filter((destination: Destination) => destination.path !== payload.path)
      };
    case DealFinderActions.CLEAR_DESTINATIONS:
      return {
        ...state,
        destinations: []
      };
    case DealFinderActions.SET_AIRPORTS:
      return {
        ...state,
        airports: payload,
        errors: {
          ...state.errors,
          airports: false
        }
      };
    case DealFinderActions.SET_DURATION:
      return {
        ...state,
        duration: payload
      };
    case DealFinderActions.SET_DATE:
      return {
        ...state,
        date: payload,
        month: '',
        errors: {
          ...state.errors,
          dates: false
        }
      };
    case DealFinderActions.SET_MONTH:
      return {
        ...state,
        month: payload,
        date: '',
        flexibleDays: false,
        errors: {
          ...state.errors,
          dates: false
        }
      };
    case DealFinderActions.SET_FLEXIBLE_DAYS:
      return {
        ...state,
        flexibleDays: payload
      };
    case DealFinderActions.SET_SEARCH_TYPE: {
      const hotelOnly = payload === SearchType.HOTEL_ONLY;
      return {
        ...state,
        searchType: payload,
        airports: !hotelOnly ? (state.airports.length ? state.airports : state.restoreState?.airports || []) : [],
        restoreState: {
          airports: hotelOnly && !!state.airports.length ? state.airports : state.restoreState?.airports || []
        }
      };
    }
    case DealFinderActions.CLEAR_DATES:
      return {
        ...state,
        month: '',
        date: '',
        flexibleDays: false
      };
    case DealFinderActions.SET_OCCUPANCY:
      return {
        ...state,
        occupancy: payload
      };
    case DealFinderActions.OCCUPANCY_CLEAR:
      return {
        ...state,
        occupancy: INITIAL_DEAL_FINDER_STATE.occupancy
      };
    case DealFinderActions.OCCUPANCY_BLUR:
      return {
        ...state,
        errors: {
          ...state.errors,
          occupancy: hasChildrenWithErrors(state.occupancy)
        }
      };
    case DealFinderActions.OCCUPANCY_INCREMENT:
      return {
        ...state,
        occupancy: [...state.occupancy, { adults: 1, children: [] }]
      };
    case DealFinderActions.OCCUPANCY_DECREMENT:
      if (state.occupancy.length === 1) return state;
      return {
        ...state,
        occupancy: state.occupancy.filter((_, index: number) => state.occupancy.length - 1 !== index)
      };
    case DealFinderActions.VALIDATE_SEARCH: {
      const { airports, searchType } = payload;
      const hotelOnly = searchType === SearchType.HOTEL_ONLY;
      const errors = getDealFinderErrors({ ...state, ...payload });
      return {
        ...state,
        ...payload,
        airports: hotelOnly ? [] : airports,
        errors
      };
    }
    case DealFinderActions.SET_ERRORS:
      return {
        ...state,
        errors: payload
      };
    case DealFinderActions.DISPLAY_ERRORS: {
      const errors = getDealFinderErrors(state);
      return {
        ...state,
        errors
      };
    }
    case DealFinderActions.RECEIVE_PARTIAL_SEARCH:
      return {
        ...state,
        ...payload
      };
    case DealFinderActions.RECEIVE_CRITERIA_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case DealFinderActions.SET_HOTEL_LANDING_SEARCH:
      return {
        ...state,
        hotelLandingSearch: payload.hotelLandingSearch
      };
    case DealFinderActions.RESET_STATE:
      return INITIAL_DEAL_FINDER_STATE;
    default:
      return state;
  }
};
