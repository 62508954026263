import { Page } from '@model/common';
import { BasketResponse } from '@model/iceberg';
import { SpecialRequestsPayload } from '@model/iceberg/service/booking/special-requests-api';
import { SupplementsPayload } from '@model/iceberg/service/booking/supplements-api';
import { ProductBookingPageSections } from '@model/pages';

export enum BasketActions {
  FETCH_BASKET = '@BASKET/FETCH_BASKET',
  RECEIVE_BASKET_SUCCESS = '@BASKET/RECEIVE_BASKET_SUCCESS',
  RECEIVE_BASKET_FAILURE = '@BASKET/RECEIVE_BASKET_FAILURE',
  RECEIVE_ADD_TO_BASKET_SUCCESS = '@BASKET/RECEIVE_ADD_TO_BASKET_SUCCESS',
  RECEIVE_ADD_TO_BASKET_FAILURE = '@BASKET/RECEIVE_ADD_TO_BASKET_FAILURE',
  RECEIVE_ADD_TO_BASKET_NOOP = '@BASKET/RECEIVE_ADD_TO_BASKET_NOOP',
  ADD_ROOMS_TO_BASKET = '@BASKET/ADD_ROOMS_TO_BASKET',
  ADD_ROOMS_TO_BASKET_AND_NAVIGATE = '@BASKET/ADD_ROOMS_TO_BASKET_AND_NAVIGATE',
  ADD_FLIGHT_TO_BASKET = '@BASKET/ADD_FLIGHT_TO_BASKET',
  PREBOOK_FLIGHT_TO_BASKET = '@BASKET/PREBOOK_FLIGHT_TO_BASKET',
  ADD_TRANSFERS_TO_BASKET = '@BASKET/ADD_TRANSFERS_TO_BASKET',
  ADD_LUGGAGE_TO_BASKET = '@BASKET/ADD_LUGGAGE_TO_BASKET',
  ADD_SPECIAL_REQUESTS_TO_BASKET = '@BASKET/ADD_SPECIAL_REQUESTS_TO_BASKET',
  ADD_SUPPLEMENTS_TO_BASKET = '@BASKET/ADD_SUPPLEMENTS_TO_BASKET',
  CONFIRM_EXTRAS = '@BASKET/CONFIRM_EXTRAS',
  NAVIGATE = '@BASKET_NAVIGATION/NAVIGATE',
  NAVIGATE_BACK = '@BASKET_NAVIGATION/NAVIGATE_BACK'
}

export const fetchBasket = (searchToken: string, prePay: boolean = false) => ({
  type: BasketActions.FETCH_BASKET,
  searchToken,
  prePay
});

export const addRoomsToBasket = (token: string, rooms: Array<string>) => ({
  type: BasketActions.ADD_ROOMS_TO_BASKET,
  token,
  rooms
});

export const addRoomsToBasketAndNavigate = (token: string, rooms: Array<string>) => ({
  type: BasketActions.ADD_ROOMS_TO_BASKET_AND_NAVIGATE,
  token,
  rooms
});

export const addFlightToBasket = (token: string, flightToken: string) => ({
  type: BasketActions.ADD_FLIGHT_TO_BASKET,
  token,
  flightToken
});

export const preBookFlightToBasket = (token: string, flightToken: string) => ({
  type: BasketActions.PREBOOK_FLIGHT_TO_BASKET,
  token,
  flightToken
});

export const confirmExtras = () => ({
  type: BasketActions.CONFIRM_EXTRAS
});

export const addTransfersToBasket = (token: string, transfer: string) => ({
  type: BasketActions.ADD_TRANSFERS_TO_BASKET,
  token,
  transfer
});

export const addLuggageToBasket = (token: string, luggageToken: string, count: number) => ({
  type: BasketActions.ADD_LUGGAGE_TO_BASKET,
  token,
  luggageToken,
  count
});

export const addSpecialRequestsToBasket = (token: string, specialRequests: Array<SpecialRequestsPayload>) => ({
  type: BasketActions.ADD_SPECIAL_REQUESTS_TO_BASKET,
  token,
  specialRequests
});

export const addSupplementsToBasket = (token: string, supplements: Array<SupplementsPayload>) => ({
  type: BasketActions.ADD_SUPPLEMENTS_TO_BASKET,
  token,
  supplements
});

export const receiveBasketSuccess = (payload: BasketResponse) => ({
  type: BasketActions.RECEIVE_BASKET_SUCCESS,
  payload
});

export const receiveBasketFailure = (payload: string) => ({
  type: BasketActions.RECEIVE_BASKET_FAILURE,
  payload
});

export const receiveAddToBasketSuccess = (payload: BasketResponse) => ({
  type: BasketActions.RECEIVE_ADD_TO_BASKET_SUCCESS,
  payload
});

export const receiveAddToBasketFailure = (payload: string) => ({
  type: BasketActions.RECEIVE_ADD_TO_BASKET_FAILURE,
  payload
});

export const receiveAddToBasketNoop = () => ({
  type: BasketActions.RECEIVE_ADD_TO_BASKET_NOOP
});

export const navigate = (page: Page, section?: ProductBookingPageSections) => ({
  type: BasketActions.NAVIGATE,
  payload: {
    page,
    section
  }
});

export const navigateBack = (url: Page) => ({
  type: BasketActions.NAVIGATE_BACK,
  url
});
