export enum SearchPathLevel {
  CONTINENT = 'CONTINENT',
  COUNTRY = 'COUNTRY',
  REGION = 'REGION',
  RESORT = 'RESORT',
  HOTEL = 'HOTEL'
}

export const getPathLevel: (path: string) => SearchPathLevel | null = (path: string = '') => {
  if (!path.length) return null;
  const pathLevelSlugs = [
    SearchPathLevel.CONTINENT,
    SearchPathLevel.COUNTRY,
    SearchPathLevel.REGION,
    SearchPathLevel.RESORT,
    SearchPathLevel.HOTEL
  ];

  return pathLevelSlugs[path.split('/').length - 1];
};

export const isHotelPath: (path: string) => boolean = (path: string) => getPathLevel(path) === SearchPathLevel.HOTEL;

export const getDestinationPath = (path: string) => {
  if (isHotelPath(path)) {
    const pathSlugs = path.split('/');
    pathSlugs.pop();
    return pathSlugs.join('/');
  }
  return path;
};

export const getCountryPath = (path: string) => {
  const [continent, country] = path.split('/');
  return [continent, country].join('/');
};

export const getRegionPath = (path: string) => {
  const [continent, country, region] = path.split('/');
  return [continent, country, region].join('/');
};

export const getPathFromName = (name: string) =>
  name
    .replace(/[^\w\s]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

export const removeSearchTokenFromPath = (path: string): string => path.replace(/searchToken=[^&]+&?/, '');

export const findByPath = (findPath: string, list?: Array<any>) => (list || []).find(({ path }) => path === findPath);
