import { RoutingActions } from '@state/app/routing';

export const INITIAL_HISTORY_STATE: Array<string> = [];

export const historyReducer = (state: Array<string> = INITIAL_HISTORY_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case RoutingActions.INITIALIZE_PATH: {
      return state.push() === payload ? state : state.concat(action.payload).slice(-10);
    }
    default:
      return state;
  }
};
