import { put, select, takeEvery } from 'redux-saga/effects';
import { RoutingActions } from '@state/app';
import { getPath } from '@state/app/routing/routingSelectors';
import { TripTypes } from '@model/common/tours/trip-types';
import { Page } from '@model/common';
import { TAB_INDEXES } from '@model/search/common';
import { BaseAction } from '@model/redux';
import { CommonSearchState } from '@model/state/search-forms-state';
import { getSearchType } from '@state/deal-finder/dealFinderSelectors';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';

export enum CommonSearchActions {
  SET_TAB_INDEX = '@SEARCH/SET_TAB_INDEX',
  SET_TAB_INDEX_BY_SEARCH_TYPE = '@SEARCH/SET_TAB_INDEX_BY_SEARCH_TYPE'
}

export const INITIAL_COMMON_SEARCH_STATE: CommonSearchState = {
  tabIndex: TAB_INDEXES.HOLIDAYS
};

interface SetTabIndex extends BaseAction {
  type: CommonSearchActions.SET_TAB_INDEX;
  payload: number;
}

export const setTabIndex: (payload: number) => SetTabIndex = (payload: number) => ({
  type: CommonSearchActions.SET_TAB_INDEX,
  payload
});

interface SetTabIndexBySearchType {
  type: CommonSearchActions.SET_TAB_INDEX_BY_SEARCH_TYPE;
  payload: SearchType;
}

export const setTabIndexBySearchType: (payload: SearchType) => SetTabIndexBySearchType = (payload: SearchType) => ({
  type: CommonSearchActions.SET_TAB_INDEX_BY_SEARCH_TYPE,
  payload
});

export function* onSetPath() {
  yield takeEvery(RoutingActions.INITIALIZE_PATH, handleOnSetPath);
}

export const getCurrentTab = (state) => state.search.common.tabIndex;

export function* handleOnSetPath() {
  const path: string = yield select(getPath);
  const searchType = yield select(getSearchType);
  const tab = yield select(getCurrentTab);
  yield put(setTabIndex(getTabIndexFromPath(path, searchType, tab)));
}

export function getTabIndexFromPath(path: string, searchType?: SearchType, currentTab?: number): number {
  if (path === '/') {
    return currentTab || TAB_INDEXES.HOLIDAYS;
  }
  const tours = [
    TripTypes.ESCORTED_TOURS,
    TripTypes.FLY_DRIVES,
    TripTypes.MULTI_CENTRE,
    TripTypes.RAIL_JOURNEYS,
    TripTypes.RIVER_CRUISES,
    TripTypes.WALKING_ROUTES,
    TripTypes.MULTI_CENTRE,
    Page.TOURS_SEARCH
  ];
  if (path.includes(TripTypes.PRIVATE_TOURS)) {
    return TAB_INDEXES.PRIVATE_TOURS;
  }

  if (path.includes(TripTypes.RIVER_CRUISES)) {
    return TAB_INDEXES.RIVER_CRUISES;
  }

  if (path.includes(TripTypes.MULTI_CENTRE)) {
    return TAB_INDEXES.MULTI_CENTRE;
  }

  if (tours.some((tour: string) => path.includes(tour))) {
    return TAB_INDEXES.ESCORTED_TOURS;
  }
  if (
    path.includes(Page.SEARCH) &&
    (searchType === SearchType.HOTEL_ONLY || path.includes(`searchType=${SearchType.HOTEL_ONLY}`))
  ) {
    return TAB_INDEXES.HOTEL_ONLY;
  }
  if (path.includes(Page.HOLIDAYS)) {
    return searchType === SearchType.HOTEL_ONLY ? TAB_INDEXES.HOTEL_ONLY : TAB_INDEXES.HOLIDAYS;
  }
  return currentTab || TAB_INDEXES.HOLIDAYS;
}

export const commonSearchReducer: any = (
  state: CommonSearchState = INITIAL_COMMON_SEARCH_STATE,
  { type, payload }: any
) => {
  switch (type) {
    case CommonSearchActions.SET_TAB_INDEX:
      return { ...state, tabIndex: payload };
    case CommonSearchActions.SET_TAB_INDEX_BY_SEARCH_TYPE:
      return { ...state, tabIndex: payload === SearchType.HOTEL_ONLY ? TAB_INDEXES.HOTEL_ONLY : TAB_INDEXES.HOLIDAYS };
    default:
      return state;
  }
};
