import { Entry } from 'contentful';
import { OpeningTimes } from '@model/contentful/opening-times';
import { ContactDetails } from '@model/contentful/contact-details';
import { File } from '@model/contentful';
import { BrandCodes } from '@model/config/brands';
import { EnvConfig } from '@model/config/env-config';

export interface CmsGlobalConfigState {
  appVariantId: string;
  themeId: string;
  openingTimes?: Entry<OpeningTimes>;
  contactDetails?: Entry<ContactDetails>;
  logoLight?: LogoFields;
  logoDark?: LogoFields;
}

export interface LogoFields {
  fields: Logo;
}

export type LogoFile = Pick<File, 'url'>;

export interface Logo {
  title: string;
  file: LogoFile;
}

export enum NavBarVariants {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export const LOGO_IMAGE = {
  [BrandCodes.MERCURY_HOLIDAYS]: {
    [NavBarVariants.DARK]: '/assets/images/logo/mercury_holidays_logo_dark.svg',
    [NavBarVariants.LIGHT]: '/assets/images/logo/mercury_holidays_logo_light.svg'
  },
  [BrandCodes.SUNSPOT_TOURS]: {
    [NavBarVariants.DARK]: '/assets/images/logo/sunspot_tours_website_logo_dark.svg',
    [NavBarVariants.LIGHT]: '/assets/images/logo/sunspot_tours_website_logo_light.svg'
  },
  [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: {
    [NavBarVariants.DARK]: '/assets/images/logo/mercury_holidays_logo_dark.svg',
    [NavBarVariants.LIGHT]: '/assets/images/logo/mercury_holidays_logo_light.svg'
  }
};

export const getLogoByVariant = (type: NavBarVariants = NavBarVariants.DARK): string | null => {
  const appVariant = EnvConfig.get().APP_VARIANT_ID;
  return LOGO_IMAGE[appVariant] ? LOGO_IMAGE[appVariant][type] : null;
};

export const getLogoLightUrl = () => getLogoByVariant(NavBarVariants.LIGHT);
export const getLogoDarkUrl = () => getLogoByVariant(NavBarVariants.DARK);
