import React from 'react';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';
import { CssBaseline } from '@material-ui/core';
import { storeWrapper } from '@state/store';
import { FontIcons } from '@components/common/head/FontIcons';
import Error from './_error';

interface CustomAppProps extends AppProps {
  pageProps: any;
}

function App({ Component, pageProps }: CustomAppProps) {
  const { store, props } = storeWrapper.useWrappedStore(pageProps);

  const statusCode: string = props?.err?.statusCode || '';
  const code: string | undefined = props?.err?.code;
  const hasError: boolean = !!statusCode;

  if (hasError) return <Error statusCode={statusCode} code={code} />;

  return (
    <Provider store={store}>
      <CssBaseline />
      <FontIcons />
      <Component {...pageProps} />
    </Provider>
  );
}

export default App;
