import { AsyncData } from '@model/common';
import { putResolve, takeLatest } from 'redux-saga/effects';
import { DataEnvelope } from '@model/iceberg';
import { ExtrasOptions } from '@model/iceberg/booking/extras/extras-options';
import { BookingApi } from '@model/iceberg/service/booking';
import { fetchBasket } from '@state/basket';
import { getErrorCode } from '@util/error';

export enum ExtrasOptionsActions {
  FETCH_EXTRAS_OPTIONS = '@EXTRAS_OPTIONS/FETCH_EXTRAS_OPTIONS',
  CLEAR_EXTRAS_OPTIONS = '@EXTRAS_OPTIONS/CLEAR_EXTRAS_OPTIONS',
  SET_EXTRA_OPTION = '@EXTRAS_OPTIONS/SET_EXTRA_OPTION',
  RECEIVE_EXTRAS_OPTIONS_SUCCESS = '@EXTRAS_OPTIONS/RECEIVE_EXTRAS_OPTIONS_SUCCESS',
  RECEIVE_EXTRAS_OPTIONS_FAILURE = '@EXTRAS_OPTIONS/RECEIVE_EXTRAS_OPTIONS_FAILURE'
}

export interface SetExtraOptionParams {
  searchToken: string;
  token: string;
  optionToken: string;
}

export const INITIAL_EXTRAS_OPTIONS_STATE: AsyncData<Array<ExtrasOptions>> = {
  data: [],
  loading: false,
  error: null
};

export const fetchExtrasOptions = (token: string) => ({
  type: ExtrasOptionsActions.FETCH_EXTRAS_OPTIONS,
  token
});

export const receiveExtrasOptionsSuccess = (data: Array<ExtrasOptions>) => ({
  type: ExtrasOptionsActions.RECEIVE_EXTRAS_OPTIONS_SUCCESS,
  data
});

export const receiveExtrasOptionsFailure = (error: string) => ({
  type: ExtrasOptionsActions.RECEIVE_EXTRAS_OPTIONS_FAILURE,
  error
});

export const setExtraOption = (payload: SetExtraOptionParams) => ({
  type: ExtrasOptionsActions.SET_EXTRA_OPTION,
  payload
});

export function* onFetchExtrasOptions() {
  yield takeLatest(ExtrasOptionsActions.FETCH_EXTRAS_OPTIONS, performFetchExtrasOptions);
}

export function* performFetchExtrasOptions({ token }: any) {
  const bookingApi: BookingApi = new BookingApi();
  try {
    const response: DataEnvelope<Array<ExtrasOptions>> = yield bookingApi.getExtrasOptions(token);
    yield putResolve(receiveExtrasOptionsSuccess(response.data));
  } catch (e: any) {
    yield putResolve(receiveExtrasOptionsFailure(getErrorCode(e)));
  }
}

export function* onSetExtrasOptions() {
  yield takeLatest(ExtrasOptionsActions.SET_EXTRA_OPTION, performSetExtrasOptions);
}

export function* performSetExtrasOptions({ payload }: any) {
  const { searchToken, token, optionToken } = payload;

  const bookingApi: BookingApi = new BookingApi();
  try {
    yield bookingApi.putExtrasOptions({
      searchToken,
      token,
      optionToken
    });
    yield putResolve(fetchBasket(searchToken));
  } catch (e: any) {
    // yield putResolve(receiveExtrasOptionsFailure(getErrorCode(e)));
  }
}

export const extrasOptionsReducer: any = (state: any = INITIAL_EXTRAS_OPTIONS_STATE, { type, data, error }: any) => {
  switch (type) {
    case ExtrasOptionsActions.RECEIVE_EXTRAS_OPTIONS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case ExtrasOptionsActions.RECEIVE_EXTRAS_OPTIONS_FAILURE:
      return { ...state, data: INITIAL_EXTRAS_OPTIONS_STATE.data, error, loading: false };
    case ExtrasOptionsActions.FETCH_EXTRAS_OPTIONS:
      return { ...state, loading: true };
    case ExtrasOptionsActions.CLEAR_EXTRAS_OPTIONS:
      return INITIAL_EXTRAS_OPTIONS_STATE;
    default:
      return state;
  }
};
