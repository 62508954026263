export enum Continents {
  EVERYWHERE = 'everywhere',
  AFRICA = 'africa',
  AUSTRALASIA = 'australasia',
  CARIBBEAN = 'caribbean',
  CENTRAL_AMERICA = 'central-america',
  EUROPE = 'europe',
  FAR_EAST_ASIA = 'far-east-asia',
  SOUTH_EAST_ASIA = 'south-east-asia',
  INDIAN_OCEAN = 'indian-ocean',
  INDIAN_SUBCONTINENT = 'indian-subcontinent',
  MIDDLE_EAST = 'middle-east',
  NORTH_AMERICA = 'north-america'
}
