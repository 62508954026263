import { HYDRATE } from 'next-redux-wrapper';
import { BaseAction } from '@model/redux';

export enum DestinationActions {
  SET_DESTINATION = '@APP/SET_DESTINATION'
}

export interface SetDestinationType extends BaseAction {
  type: DestinationActions.SET_DESTINATION;
  payload: string;
}

export const setDestination: (payload: string) => SetDestinationType = (payload: string) => ({
  type: DestinationActions.SET_DESTINATION,
  payload
});

export const INITIAL_DESTINATION_STATE: string = '';

export const destinationReducer = (state: string = INITIAL_DESTINATION_STATE, action: any) => {
  switch (action.type) {
    case HYDRATE:
      if (action.payload?.app?.destination !== state) {
        return action.payload?.app?.destination || state;
      }
      return state;
    case DestinationActions.SET_DESTINATION:
      return action.payload;
    default:
      return state;
  }
};
