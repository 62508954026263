import { AsyncData } from '@model/common/async-data';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { RequestType, setLoading } from '@state/app';
import { DealFinderApi } from '@model/iceberg/service/deal-finder/deal-finder-api';
import {
  DestinationParams,
  DestinationResponse,
  PackagesParams,
  PackagesResponse,
  Result,
  Results
} from '@model/iceberg/deal-finder/deal-finder';
import { getFromPricePackagesData } from '@state/search-results/from-price-packages/fromPricePackagesSelectors';
import { FiltersAdapter } from '@model/iceberg/filters/deal-finder/filters-adapter';
import { Logging } from '@util/logging';

export enum FromPricePackagesActions {
  PERFORM_FROM_PRICE_SEARCH = '@FROM_PRICE_PACKAGES/PERFORM_FROM_PRICE_SEARCH',
  CLEAR_FROM_PRICE_PACKAGES = '@FROM_PRICE_PACKAGES/CLEAR_FROM_PRICE_PACKAGES',
  RECEIVE_FROM_PRICE_PACKAGES_SUCCESS = '@FROM_PRICE_PACKAGES/RECEIVE_FROM_PRICES_SUCCESS',
  RECEIVE_FROM_PRICE_PACKAGES_FAILURE = '@FROM_PRICE_PACKAGES/RECEIVE_FROM_PRICES_FAILURE',
  SET_FILTERS = '@FROM_PRICE_PACKAGES/SET_FILTERS',
  LOAD_MORE = '@FROM_PRICE_PACKAGES/LOAD_MORE'
}

export const INITIAL_FROM_PRICE_PACKAGES_STATE: AsyncData<Results | null> = {
  data: null,
  loading: false,
  error: null
};

export const setFromPriceFilters = (payload: Record<string, string>) => ({
  type: FromPricePackagesActions.SET_FILTERS,
  payload
});

export const performFromPriceSearch = (payload: DestinationParams) => ({
  type: FromPricePackagesActions.PERFORM_FROM_PRICE_SEARCH,
  payload
});

export const clearFromPricePackages = () => ({
  type: FromPricePackagesActions.CLEAR_FROM_PRICE_PACKAGES
});

export const receiveFromPricePackagesSuccess = (payload: Results) => ({
  type: FromPricePackagesActions.RECEIVE_FROM_PRICE_PACKAGES_SUCCESS,
  payload
});

export const receiveFromPricePackagesFailure = (error: any) => ({
  type: FromPricePackagesActions.RECEIVE_FROM_PRICE_PACKAGES_FAILURE,
  error
});

export const loadMore = () => ({
  type: FromPricePackagesActions.LOAD_MORE
});

export function* onSetFromPriceFilters() {
  yield takeLatest(FromPricePackagesActions.SET_FILTERS, handleSetFromPricePackageFilters);
}

export function* onRequestFromPricePackagesSearch() {
  yield takeLatest(FromPricePackagesActions.PERFORM_FROM_PRICE_SEARCH, handleFromPricePackagesSearch);
}

export function* handleSetFromPricePackageFilters({ payload }: any) {
  yield handleFromPricePackagesSearch({ payload });
}

export function* handleFromPricePackagesSearch({ payload }: any) {
  const { response, error } = yield call(fetchDestination, payload);
  if (response) {
    yield put(receiveFromPricePackagesSuccess(response.data));
  } else {
    yield put(receiveFromPricePackagesFailure(error));
  }
}

export function* handleOnLoadMore() {
  yield takeLatest(FromPricePackagesActions.LOAD_MORE, onLoadMore);
}

export function fetchDestination(payload: DestinationParams) {
  const api: DealFinderApi = new DealFinderApi();
  return api.destination(payload).then((response: DestinationResponse) => {
    return fetchFromPricePackages({ query: { ...payload }, ...response });
  });
}

export function fetchFromPricePackages(payload: PackagesParams) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .packages(payload)
    .then((response: PackagesResponse) => ({ response }))
    .catch((error: any) => {
      const { config, response } = error;
      Logging.warn({
        statusCode: response?.status,
        url: config?.url,
        text: response?.statusText,
        data: JSON.stringify(payload)
      });
      return { error };
    });
}

export function* onLoadMore() {
  yield put(setLoading(true, RequestType.LOAD_MORE_RESULTS));
  const {
    searchToken,
    nextSet,
    results,
    facets: { filters }
  } = yield select(getFromPricePackagesData);
  const query: Record<string, string> = FiltersAdapter.getQuery(filters);
  const { response, error } = yield call(fetchFromPricePackages, { searchToken, nextSet, query });
  if (response) {
    const nextSet: Array<Result> = response.data.results;
    const update: Array<Result> = results.concat(nextSet);
    yield put(
      receiveFromPricePackagesSuccess({
        ...response.data,
        results: update
      })
    );
    yield put(setLoading(false, RequestType.LOAD_MORE_RESULTS));
  } else {
    yield put(receiveFromPricePackagesFailure(error));
  }
}

export const fromPricePackagesReducer: any = (
  state: any = INITIAL_FROM_PRICE_PACKAGES_STATE,
  { type, error, payload }: any
) => {
  switch (type) {
    case HYDRATE:
      return payload?.searchResults?.fromPrices || state;
    case FromPricePackagesActions.RECEIVE_FROM_PRICE_PACKAGES_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case FromPricePackagesActions.RECEIVE_FROM_PRICE_PACKAGES_FAILURE:
      return { ...state, data: INITIAL_FROM_PRICE_PACKAGES_STATE.data, error, loading: false };
    case FromPricePackagesActions.PERFORM_FROM_PRICE_SEARCH:
      return { ...state, loading: true };
    case FromPricePackagesActions.CLEAR_FROM_PRICE_PACKAGES:
      return INITIAL_FROM_PRICE_PACKAGES_STATE;
    default:
      return state;
  }
};
