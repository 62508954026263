import { AsyncData } from '@model/common/async-data';
import { Flights } from '@model/iceberg/booking/package';
import { put, takeLatest } from 'redux-saga/effects';
import { FlightsAvailabilityResponse } from '@model/iceberg/service/booking/flights-availability-response';
import { FlightApi, GetFlightOptions } from '@model/iceberg/service/booking/flight-api';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { PackageReferencesActions } from '@state/app/package-references/packageReferencesOperations';

export enum FlightsActions {
  FETCH_FLIGHTS = '@FLIGHTS/FETCH_FLIGHTS',
  RECEIVE_FLIGHTS_SUCCESS = '@FLIGHTS/RECEIVE_FLIGHTS_SUCCESS',
  RECEIVE_FLIGHTS_FAILURE = '@FLIGHTS/RECEIVE_FLIGHTS_FAILURE'
}

export const INITIAL_FLIGHTS_STATE: AsyncData<Flights> = {
  data: {
    flights: []
  },
  loading: false,
  error: null
};

export const fetchFlights = (searchToken: string, options?: GetFlightOptions) => ({
  type: FlightsActions.FETCH_FLIGHTS,
  searchToken,
  options
});

export const receiveFlightsSuccess = (data: Flights) => ({
  type: FlightsActions.RECEIVE_FLIGHTS_SUCCESS,
  data
});

export const receiveFlightsFailure = () => ({
  type: FlightsActions.RECEIVE_FLIGHTS_FAILURE
});

export function* onFetchFlights() {
  yield takeLatest(FlightsActions.FETCH_FLIGHTS, performFetchFlights);
}

export function* performFetchFlights({ searchToken, options }: any) {
  const api: FlightApi = new FlightApi();
  try {
    const flights: FlightsAvailabilityResponse = yield api.get(searchToken, options);
    yield put(receiveFlightsSuccess(flights.data));
  } catch (e) {
    yield put(receiveFlightsFailure());
  }
}

export const flightsReducer: any = (state: any = INITIAL_FLIGHTS_STATE, { type, data }: any) => {
  switch (type) {
    case FlightsActions.RECEIVE_FLIGHTS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case FlightsActions.RECEIVE_FLIGHTS_FAILURE:
      return { ...state, data: INITIAL_FLIGHTS_STATE.data, error: true, loading: false };
    case FlightsActions.FETCH_FLIGHTS:
      return { ...state, data: INITIAL_FLIGHTS_STATE.data, error: null, loading: true };
    case DealFinderResultsActions.CLEAR_RESULTS:
      return INITIAL_FLIGHTS_STATE;
    case PackageReferencesActions.SET_PACKAGE_REFERENCES:
      return INITIAL_FLIGHTS_STATE;
    default:
      return state;
  }
};
