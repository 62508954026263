import {
  CriteriaResponse,
  PackageResponse,
  PackagesParams,
  PackagesResponse,
  ReferenceParams,
  RoomsResponse,
  SearchCriteria,
  DestinationParams,
  DestinationResponse,
  FromTourReferenceResponse
} from '@model/iceberg/deal-finder/deal-finder';
import { ToursPackageResponse } from '@model/tours';
import { DealFinderEndpoints } from '@model/iceberg/service/deal-finder/deal-finder-endpoints';
import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { Logging } from '@util/logging';

interface DealFinderApiRequest {
  package: (params: SearchCriteria) => Promise<PackageResponse>;
  packages: (params: PackagesParams) => Promise<PackagesResponse>;
  reference: (params: ReferenceParams) => Promise<Object>;
  criteria: (params: string) => Promise<CriteriaResponse>;
  destination: (params: DestinationParams) => Promise<DestinationResponse>;
  fromTourReference: (params: string) => Promise<FromTourReferenceResponse>;
}

export class DealFinderApi implements DealFinderApiRequest {
  private api: Request = new Request();

  public async package(payload: SearchCriteria): Promise<PackageResponse> {
    return await this.api.post(uri(getURL()).path(EndPoint.DEAL_FINDER.concat(EndPoint.PACKAGE)).href(), payload);
  }

  public async packages(payload: PackagesParams): Promise<PackagesResponse> {
    const { searchToken, nextSet, query = {} } = payload;
    return await this.api.get(
      uri(getURL())
        .path(EndPoint.DEAL_FINDER.concat('/').concat(searchToken).concat(DealFinderEndpoints.PACKAGES))
        .search({ nextSet, ...query })
        .href()
    );
  }

  public async reference(payload: ReferenceParams): Promise<Object> {
    return await this.api.put(
      uri(getURL())
        .path(EndPoint.DEAL_FINDER.concat('/').concat(payload.searchToken).concat(DealFinderEndpoints.PACKAGE))
        .href(),
      { packageReferences: payload.packageReferences }
    );
  }

  public async criteria(payload: string): Promise<CriteriaResponse> {
    return await this.api.get(
      uri(getURL()).path(EndPoint.DEAL_FINDER.concat('/').concat(payload).concat(DealFinderEndpoints.CRITERIA)).href()
    );
  }

  public async updateCriteria(token: string, payload: Partial<SearchCriteria>): Promise<CriteriaResponse> {
    return await this.api.put(
      uri(getURL()).path(EndPoint.DEAL_FINDER.concat('/').concat(token).concat(DealFinderEndpoints.CRITERIA)).href(),
      payload
    );
  }

  public async destination(payload: DestinationParams): Promise<DestinationResponse> {
    return await this.api.post(
      uri(getURL()).path(EndPoint.DEAL_FINDER.concat(DealFinderEndpoints.DESTINATION)).href(),
      payload
    );
  }

  public async rooms(payload: string): Promise<RoomsResponse> {
    const path = uri(getURL())
      .path(EndPoint.DEAL_FINDER.concat('/').concat(payload).concat(DealFinderEndpoints.ROOMS))
      .href();
    return await this.api.get(path).catch((e) => {
      const { config, response } = e;
      Logging.warn({
        statusCode: response?.status,
        url: config?.url,
        text: response?.statusText,
        data: path
      });
      return null;
    });
  }

  public async getTourSearchToken(payload: any): Promise<ToursPackageResponse> {
    return await this.api.post(uri(getURL()).path(EndPoint.DEAL_FINDER.concat(EndPoint.PACKAGE)).href(), payload);
  }

  public async fromTourReference(payload: string): Promise<FromTourReferenceResponse> {
    return await this.api.post(
      uri(getURL()).path(EndPoint.DEAL_FINDER.concat(DealFinderEndpoints.FROM_TOUR_REFERENCE)).href(),
      { reference: payload }
    );
  }
}
