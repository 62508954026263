import Markdown from 'react-markdown';

import { LinkComponent } from './TextMarkdown';

export interface RTapMarkdownProps {
  description: string;
}

export const RTapMarkdown = ({ description }: RTapMarkdownProps) => {
  return <Markdown children={description} components={LinkComponent} />;
};
