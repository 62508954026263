export enum LoadingActions {
  SET_LOADING = '@LOADING/SET_LOADING'
}

export enum RequestType {
  LUGGAGE = 'LUGGAGE',
  LOAD_MORE_RESULTS = 'LOAD_MORE_RESULTS',
  BASKET = 'BASKET'
}

export const INITIAL_LOADING_STATE: Record<string, boolean> = {
  [RequestType.LUGGAGE]: false,
  [RequestType.LOAD_MORE_RESULTS]: false
};

export const setLoading = (loading: boolean, request: RequestType) => ({
  type: LoadingActions.SET_LOADING,
  loading,
  request
});

export const loadingReducer = (
  state: Record<string, boolean> = INITIAL_LOADING_STATE,
  { type, loading, request }: any
) => {
  switch (type) {
    case LoadingActions.SET_LOADING:
      return {
        ...state,
        [request]: loading
      };
    default:
      return state;
  }
};
