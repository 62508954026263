import { DealFinderState } from '@model/state/deal-finder-state';
import { takeLatest } from 'redux-saga/effects';
import { put } from '@redux-saga/core/effects';
import { DealFinderActions } from '@state/deal-finder/dealFinderOperations';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { FILTER_DEFAULTS } from '@model/price-calendar';

export enum DealFinderLastSearchedActions {
  SET_LAST_SEARCHED = '@DEAL_FINDER_LAST_SEARCHED/SET_LAST_SEARCHED'
}

export const INITIAL_DEAL_FINDER_LAST_SEARCHED_STATE: DealFinderState = {
  searchType: SearchType.MAIN,
  duration: FILTER_DEFAULTS.duration,
  date: '',
  month: '',
  destinations: [],
  airports: [],
  flexibleDays: false,
  occupancy: [{ adults: 2, children: [] }],
  preferredBoardBasis: '',
  hotelLandingSearch: false,
  destinationSearch: false,
  errors: {
    airports: false,
    dates: false,
    destinations: false,
    occupancy: false
  }
};

export const setLastSearched = (payload: number) => ({
  type: DealFinderLastSearchedActions.SET_LAST_SEARCHED,
  payload
});

export function* handleOnHydrateSearch() {
  yield takeLatest(
    [DealFinderActions.RECEIVE_CRITERIA_SUCCESS, DealFinderResultsActions.PERFORM_SEARCH],
    performSetLastSearched
  );
}

export function* performSetLastSearched({ payload }: any) {
  yield put(setLastSearched(payload));
}

export const dealFinderLastSearchedReducer: any = (
  state: DealFinderState = INITIAL_DEAL_FINDER_LAST_SEARCHED_STATE,
  { type, payload }: any
) => {
  switch (type) {
    case DealFinderLastSearchedActions.SET_LAST_SEARCHED: {
      return payload;
    }
    default:
      return state;
  }
};
