import { call, takeLatest } from 'redux-saga/effects';
import { put, select } from '@redux-saga/core/effects';
import { cloneDeep } from 'lodash';
import { DealFinderResultsState } from '@model/state/deal-finder-results-state';
import { Destination, DealFinderState } from '@model/state';
import { Page } from '@model/common';
import { performNavigate, RequestType, setLoading, setPackageReferences, unsetPackageReferences } from '@state/app';
import { ToursActions } from '@state/search-results/tours/toursOperations';
import {
  PackageResponse,
  PackagesParams,
  PackagesResponse,
  ReferenceParams,
  Result,
  Results,
  SearchCriteria,
  DestinationParams,
  DestinationResponse,
  SearchType
} from '@model/iceberg/deal-finder/deal-finder';
import { DealFinderApi } from '@model/iceberg/service/deal-finder/deal-finder-api';
import { DealFinderMapper, mapChildrenStringToDob } from '@model/iceberg/service/deal-finder/deal-finder-mapper';
import { Logging } from '@util/logging';
import { getDealFinderResultsData } from '@state/deal-finder-results/dealFinderResultsSelectors';
import { FiltersAdapter } from '@model/iceberg/filters/deal-finder/filters-adapter';
import { MOCK_SKELETON_LOADING_PACKAGES_RESPONSE } from '@mock/iceberg/deal-finder/packages';
import { isHotelPath } from '@util/path';
import { isValidSearchDate, getDefaultSearchMonth } from '@util/date-time';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import { getDealFinderUrl } from '@util/deal-finder';
import { BaseAction } from '@model/redux';

export enum DealFinderResultsActions {
  PERFORM_SEARCH = '@DEAL_FINDER_RESULTS/PERFORM_SEARCH',
  PERFORM_DESTINATION_SEARCH = '@DEAL_FINDER_RESULTS/PERFORM_DESTINATION_SEARCH',
  PERFORM_PRODUCT_SEARCH = '@DEAL_FINDER_RESULTS/PERFORM_PRODUCT_SEARCH',
  SEARCH_FAILURE = '@DEAL_FINDER_RESULTS/SEARCH_FAILURE',
  RECEIVE_TOKEN_SUCCESS = '@DEAL_FINDER_RESULTS/RECEIVE_TOKEN_SUCCESS',
  RECEIVE_RESULTS_SUCCESS = '@DEAL_FINDER_RESULTS/RECEIVE_RESULTS_SUCCESS',
  RECEIVE_RESULTS_FAILURE = '@DEAL_FINDER_RESULTS/RECEIVE_RESULTS_FAILURE',
  LOAD_MORE = '@DEAL_FINDER/LOAD_MORE',
  SET_FILTERS = '@DEAL_FINDER/SET_FILTERS',
  TOKEN_EXPIRED = '@DEAL_FINDER_RESULTS/TOKEN_EXPIRED',
  CLEAR_RESULTS = '@DEAL_FINDER_RESULTS/CLEAR_RESULTS',
  SET_TOKEN = '@DEAL_FINDER_RESULTS/SET_TOKEN',
  GET_PACKAGES = '@DEAL_FINDER_RESULTS/GET_PACKAGES'
}

export const INITIAL_DEAL_FINDER_RESULTS_STATE: DealFinderResultsState = {
  data: {
    searchToken: '',
    expires: '',
    results: [],
    facets: {
      filters: [],
      counts: {
        total: 0,
        filtered: 0
      }
    },
    nextSet: 0
  },
  loading: false,
  error: false
};

export interface PerformSearchAction extends BaseAction {
  payload: DealFinderState;
}

export interface PerformDestinationSearchAction extends BaseAction {
  payload: DestinationParams;
}

export interface GetPackagesAction extends BaseAction {
  payload: string;
}

export const performSearch = (payload: DealFinderState) => ({ type: DealFinderResultsActions.PERFORM_SEARCH, payload });

export const performDestinationSearch = (payload: DestinationParams) => ({
  type: DealFinderResultsActions.PERFORM_DESTINATION_SEARCH,
  payload
});

export const clearResults = () => ({ type: DealFinderResultsActions.CLEAR_RESULTS });

export const performProductSearch = (payload: DealFinderState) => ({
  type: DealFinderResultsActions.PERFORM_PRODUCT_SEARCH,
  payload
});
export const loadMore = () => ({
  type: DealFinderResultsActions.LOAD_MORE
});

export const tokenExpired = () => ({ type: DealFinderResultsActions.TOKEN_EXPIRED });

export const receiveResultsSuccess = (payload: Results) => ({
  type: DealFinderResultsActions.RECEIVE_RESULTS_SUCCESS,
  payload
});

export const receiveTokenSuccess = (payload: PackageResponse) => ({
  type: DealFinderResultsActions.RECEIVE_TOKEN_SUCCESS,
  payload
});

export const receiveResultsFailure = (payload?: Results) => ({
  type: DealFinderResultsActions.RECEIVE_RESULTS_FAILURE,
  payload
});

export const setFilters = (payload: Record<string, string>) => ({
  type: DealFinderResultsActions.SET_FILTERS,
  payload
});

export const setToken = (payload: string) => ({
  type: DealFinderResultsActions.SET_TOKEN,
  payload
});

export const getPackages = (payload: string) => ({
  type: DealFinderResultsActions.GET_PACKAGES,
  payload
});

export const searchFailure = (payload: string) => ({
  type: DealFinderResultsActions.SEARCH_FAILURE,
  payload
});

export function* handleOnPerformSearch() {
  yield takeLatest(DealFinderResultsActions.PERFORM_SEARCH, onPerformSearch);
}

export function* handleOnPerformDestinationSearch() {
  yield takeLatest(DealFinderResultsActions.PERFORM_DESTINATION_SEARCH, onPerformDestinationSearch);
}

export function* handleOnLoadMore() {
  yield takeLatest(DealFinderResultsActions.LOAD_MORE, onLoadMore);
}

export function* onSetFilters() {
  yield takeLatest(DealFinderResultsActions.SET_FILTERS, handleSetFilters);
}

export function* handleOnPerformProductSearch() {
  yield takeLatest(DealFinderResultsActions.PERFORM_PRODUCT_SEARCH, onPerformProductSearch);
}

export function* handleOnShouldClear() {
  yield takeLatest(
    [DealFinderResultsActions.PERFORM_PRODUCT_SEARCH, DealFinderResultsActions.PERFORM_SEARCH],
    onShouldClear
  );
}

export function* onShouldClear() {
  yield put(clearResults());
}

export function* handleOnGetPackages() {
  yield takeLatest(DealFinderResultsActions.GET_PACKAGES, handleGetPackages);
}

export function* handleGetPackages(action: GetPackagesAction) {
  const { payload: searchToken } = action;
  yield performGetPackages({ searchToken });
}

export function* onPerformSearch(action: PerformSearchAction) {
  const destinations: Array<Destination> = action.payload.destinations;
  const { month: payloadMonth, date, ...nonDateParams } = action.payload;
  const [year, month, day] = (payloadMonth || date || '').split('-');
  const searchPayload =
    !year || !month || isValidSearchDate({ year, month, day, wholeMonth: !date && !!payloadMonth })
      ? action.payload
      : { ...nonDateParams, month: getDefaultSearchMonth(), date: '' };
  const isProductSearch: boolean = destinations.length === 1 && isHotelPath(destinations[0].path);
  yield put(unsetPackageReferences());
  if (isProductSearch) {
    yield put(performProductSearch(action.payload));
  } else {
    const payload: SearchCriteria = DealFinderMapper.fromDealFinder(searchPayload);
    const { response, error } = yield call(callGetPackage, payload);
    if (response) {
      yield put(receiveTokenSuccess(response));
      yield performGetPackages(response);
    } else {
      yield put(searchFailure(error));
      Logging.error({
        text: error.message || error
      });
    }
  }
}

export function* onPerformDestinationSearch(action: PerformDestinationSearchAction) {
  const { response } = yield call(callGetDestination, action.payload);
  if (response) {
    if (response.data.results.length) {
      yield put(
        receiveResultsSuccess({
          ...response.data
        })
      );
      return response.data;
    } else {
      yield put(receiveResultsFailure());
      return null;
    }
  } else {
    yield put(receiveResultsFailure());
    return null;
  }
}

export function* onPerformProductSearch(action: PerformSearchAction) {
  const payload: SearchCriteria = DealFinderMapper.fromDealFinder(action.payload);
  const destinations: Array<Destination> = action.payload.destinations;
  const hotel: Destination = destinations[0];
  const { response, error } = yield call(callGetPackage, payload);
  yield put(receiveTokenSuccess(response));
  if (response) {
    const { searchToken } = response;
    const packages: Results | null = yield performGetPackages(response);
    if (packages && packages.results.length > 0) {
      const result = packages.results[0];
      const packageReferences: Array<string> = result.leadInPrice?.packageReferences || [];
      const { searchType, dateFrom, month, duration, from, occupancy, preferredBoardBasis } = payload;
      const progressDisallowed = searchType === SearchType.HOTEL_ONLY && !result.hotelOnly;
      if (packageReferences.length && !progressDisallowed) {
        yield put(setPackageReferences(packageReferences));
        yield call(callSelectPackage, { searchToken, packageReferences });
        const dealFinderUrl = getDealFinderUrl({
          token: searchToken,
          path: hotel.path,
          page: Page.PRODUCT,
          from,
          duration,
          occupancy: mapChildrenStringToDob(occupancy),
          date: dateFrom || month || '',
          searchType,
          preferredBoardBasis: preferredBoardBasis || ''
        });
        yield put(performNavigate(dealFinderUrl));
      }
    }
  } else {
    yield put(searchFailure(error));
    Logging.error({
      text: error
    });
  }
}

export function* onLoadMore() {
  yield put(setLoading(true, RequestType.LOAD_MORE_RESULTS));
  const {
    searchToken,
    nextSet,
    results,
    facets: { filters }
  } = yield select(getDealFinderResultsData);
  const query: Record<string, string> = FiltersAdapter.getQuery(filters);
  const { response } = yield call(callGetPackages, { searchToken, nextSet, query });
  if (response) {
    const nextSet: Array<Result> = response.data.results;
    const update: Array<Result> = results.concat(nextSet);
    yield put(
      receiveResultsSuccess({
        ...response.data,
        results: update
      })
    );
    yield put(setLoading(false, RequestType.LOAD_MORE_RESULTS));
  } else {
    yield put(receiveResultsFailure());
  }
}

export function* performGetPackages(payload: PackagesParams) {
  const { response } = yield call(callGetPackages, payload);
  if (response) {
    if (response.data.results.length) {
      yield put(
        receiveResultsSuccess({
          ...response.data
        })
      );
      return response.data;
    } else {
      yield put(receiveResultsFailure(response.data));
      return null;
    }
  } else {
    yield put(receiveResultsFailure());
    return null;
  }
}

export function* handleSetFilters({ payload: query }: any) {
  const { searchToken } = yield select(getDealFinderResultsData);
  const { response } = yield call(callGetPackages, { searchToken, query });
  if (response) {
    yield put(
      receiveResultsSuccess({
        ...response.data
      })
    );
  } else {
    yield put(receiveResultsFailure());
  }
}

export function callGetDestination(payload: DestinationParams) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .destination(payload)
    .then((response: DestinationResponse) => {
      return api
        .packages(response)
        .then((response: PackagesResponse) => ({
          response
        }))
        .catch((error: any) => ({
          error
        }));
    })
    .catch((error: any) => ({
      error
    }));
}

export function callGetPackage(payload: SearchCriteria) {
  const api: DealFinderApi = new DealFinderApi();
  const marketingCode: string | undefined = new Cookies().get(Cookie.MARKETING_CODE);
  return api
    .package({
      ...payload,
      marketingCode
    })
    .then((response: PackageResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function callSelectPackage(payload: ReferenceParams) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .reference(payload)
    .then((response: Object) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

export function callGetPackages(payload: PackagesParams) {
  const api: DealFinderApi = new DealFinderApi();
  return api
    .packages(payload)
    .then((response: PackagesResponse) => ({
      response
    }))
    .catch((error: any) => ({
      error
    }));
}

const mergeTemporaryFilters = (state: DealFinderResultsState, payload: any) => {
  const newState: DealFinderResultsState = cloneDeep(state);
  newState.data.facets.filters = newState.data.facets.filters.map((filter) => {
    const updatedFilter = payload[filter.name];
    const selectedValues = (updatedFilter || '').split(',');
    filter.values = filter.values.map((value) => {
      value.selected = selectedValues.includes(value.value);
      return value;
    });
    return filter;
  });
  return newState;
};

export const dealFinderResultsReducer: any = (
  state: DealFinderResultsState = INITIAL_DEAL_FINDER_RESULTS_STATE,
  { type, payload }: any
) => {
  switch (type) {
    case DealFinderResultsActions.PERFORM_SEARCH:
    case DealFinderResultsActions.PERFORM_PRODUCT_SEARCH:
    case DealFinderResultsActions.PERFORM_DESTINATION_SEARCH:
    case DealFinderResultsActions.CLEAR_RESULTS:
      return {
        ...INITIAL_DEAL_FINDER_RESULTS_STATE,
        data: MOCK_SKELETON_LOADING_PACKAGES_RESPONSE.data,
        loading: true
      };
    case DealFinderResultsActions.SEARCH_FAILURE:
      return {
        ...INITIAL_DEAL_FINDER_RESULTS_STATE,
        error: true,
        loading: false
      };
    case DealFinderResultsActions.SET_FILTERS:
      return {
        ...mergeTemporaryFilters(state, payload),
        loading: true
      };
    case DealFinderResultsActions.RECEIVE_RESULTS_SUCCESS:
      return {
        ...INITIAL_DEAL_FINDER_RESULTS_STATE,
        data: payload
      };
    case DealFinderResultsActions.RECEIVE_TOKEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          searchToken: payload.searchToken,
          expires: payload.expires
        }
      };
    case DealFinderResultsActions.RECEIVE_RESULTS_FAILURE:
      return {
        ...state,
        data: {
          ...INITIAL_DEAL_FINDER_RESULTS_STATE.data,
          searchToken: state.data.searchToken,
          expires: state.data.expires
        },
        error: true,
        loading: false
      };
    case DealFinderResultsActions.SET_TOKEN:
      return {
        ...state,
        data: {
          ...state.data,
          searchToken: payload
        }
      };
    case ToursActions.RECEIVE_TOURS_TOKEN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          searchToken: INITIAL_DEAL_FINDER_RESULTS_STATE.data.searchToken,
          expires: INITIAL_DEAL_FINDER_RESULTS_STATE.data.expires
        }
      };
    default:
      return state;
  }
};
