import { useState, useRef, useEffect } from 'react';

export const TRANSITION_DURATION: number = 300;

export const useToggleShow = (show: boolean) => {
  const SHOW_TIMER: any = useRef(null);

  /* *** STATE *** */
  const [shouldRender, setShouldRender] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  /* *** LOGIC *** */
  const clearTimers = () => {
    if (SHOW_TIMER.current) {
      clearTimeout(SHOW_TIMER.current);
    }
  };

  /* *** HOOKS *** */
  useEffect(() => {
    if (shouldRender) {
      SHOW_TIMER.current = setTimeout(() => {
        shouldRender && setShouldShow(true);
      }, TRANSITION_DURATION / 3);
    }
  }, [shouldRender]);

  useEffect(() => {
    if (!show && !shouldShow) {
      SHOW_TIMER.current = setTimeout(() => {
        shouldRender && setShouldRender(false);
      }, TRANSITION_DURATION);
    }
  }, [shouldShow]);

  useEffect(() => {
    if (show) {
      shouldRender ? setShouldShow(true) : setShouldRender(true);
    } else {
      shouldShow ? setShouldShow(false) : setShouldRender(false);
    }

    return () => {
      clearTimers();
    };
  }, [show]);

  return { shouldRender, shouldShow };
};
