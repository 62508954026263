import { isEqual } from 'lodash';
import { FormikValues } from 'formik';
import { Validation } from './validation';

export const validateMyBookingLoginForm = (values: FormikValues) => {
  const errors: any = {
    departureDate: {}
  };

  const validFormErrors = {
    departureDate: {}
  };

  if (!values.bookingId) {
    errors.bookingId = 'form__field-error--required';
  } else if (!Validation.isBookingReferenceValid(values.bookingId)) {
    errors.bookingId = 'form__field-error--incorrect-value';
  }

  if (!values.lastName) {
    errors.lastName = 'form__field-error--required';
  }

  if (!values.departureDate.day) {
    errors.departureDate.day = 'form__field-error--required';
  } else if (!Validation.isDepartureDayValid(values.departureDate.day)) {
    errors.departureDate.day = 'form__field-error--incorrect-value';
  }

  if (!values.departureDate.month) {
    errors.departureDate.month = 'form__field-error--required';
  } else if (!Validation.isDepartureMonthValid(values.departureDate.month)) {
    errors.departureDate.month = 'form__field-error--incorrect-value';
  }

  if (!values.departureDate.year) {
    errors.departureDate.year = 'form__field-error--required';
  } else if (!Validation.isDepartureYearValid(values.departureDate.year)) {
    errors.departureDate.year = 'form__field-error--incorrect-value';
  }

  const isValid = isEqual(validFormErrors, errors);

  return { errors, isValid };
};
