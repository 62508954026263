import { BasketResponse } from '@model/iceberg';
import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { BookingEndpoints } from '@model/service/booking-endpoints';

export class TransferApi {
  private api: Request = new Request();
  public async put(token: string, transfer: string): Promise<BasketResponse> {
    return await this.api.put(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.TRANSFER}/${transfer}`)
        .href()
    );
  }

  public async delete(token: string, transfer: string): Promise<BasketResponse> {
    return await this.api.delete(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.TRANSFER}/${transfer}`)
        .href()
    );
  }
}
