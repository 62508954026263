export enum DealFinderEndpoints {
  PACKAGE = '/package',
  PACKAGES = '/packages',
  REFERENCE = '/reference',
  CRITERIA = '/criteria',
  DESTINATION = '/destination',
  ROOMS = '/rooms',
  FLIGHTS = '/flights',
  FROM_TOUR_REFERENCE = '/from-tour-reference',
  EARLY_FLIGHTS = '/early-morning-flights',
  CHECK = '/check',
  APPLY = '/apply'
}
