import { PriceCalendarFiltersState, PriceCalendarMonthlyState } from '@model/state/price-calendar-state';
import { Monthly, MonthlyResponse, Offer } from '@model/iceberg/service/price-calendar/monthly-response';
import { FromPricePayload, PriceCalendarApi } from '@model/iceberg/service/price-calendar/price-calendar-api';
import { PriceCalendarFilterActions } from '@state/price-calendar/filter/priceCalendarFilterOperations';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { PriceCalendarMonthlyActions } from './priceCalendarMonthlyActions';
import { putResolve, select, takeEvery } from 'redux-saga/effects';
import { getHotelPath } from '@state/hotel/hotelSelectors';
import { getPriceCalendarFilters } from '@state/price-calendar/filter/priceCalendarFilterSelectors';
import moment from 'moment';
import { ISO_8601_DATE_FORMAT, MONTH_NUMBER, YEAR_MONTH_FORMAT, YEAR_NUMBER } from '@model/common';
import { DOB, Occupancy } from '@model/state';
import { hasChildrenWithErrors } from '@model/search';
import { getHotelOnlySearch } from '@state/deal-finder/dealFinderSelectors';

export const INITIAL_PRICE_CALENDAR_MONTHLY_STATE: PriceCalendarMonthlyState = {
  response: {
    data: {
      prices: [],
      query: {
        duration: 0,
        month: 0,
        year: 0,
        path: '',
        boardBasis: '',
        from: '',
        occupancy: []
      }
    },
    loading: false,
    error: null
  },
  date: '',
  defaultDate: '',
  month: ''
};

export const receiveMonthlyPricesSuccess = (payload: Monthly) => ({
  type: PriceCalendarMonthlyActions.RECEIVE_MONTHLY_PRICES_SUCCESS,
  payload
});

export const receiveMonthlyEmptyPrices = (payload: Monthly) => ({
  type: PriceCalendarMonthlyActions.RECEIVE_MONTHLY_EMPTY_PRICES,
  payload
});

export const receiveMonthlyPricesFailure = () => ({
  type: PriceCalendarMonthlyActions.RECEIVE_MONTHLY_PRICES_FAILURE
});

export const clearMonthly = () => ({
  type: PriceCalendarMonthlyActions.CLEAR_MONTHLY
});

export const setDay = (payload: string) => ({
  type: PriceCalendarMonthlyActions.SET_DAY,
  payload
});

export const setMonth = (payload: string) => ({
  type: PriceCalendarMonthlyActions.SET_MONTH,
  payload
});

export const setLoading = (payload: boolean) => ({
  type: PriceCalendarMonthlyActions.SET_LOADING,
  payload
});

export function* onReceiveFiltersSuccess() {
  yield takeEvery(
    [PriceCalendarFilterActions.RECEIVE_FILTERS_SUCCESS, PriceCalendarFilterActions.SET_FILTERS],
    handleOnReceiveFiltersSuccess
  );
}

export function* onSetMonth() {
  yield takeEvery([PriceCalendarMonthlyActions.SET_MONTH], handleOnSetMonth);
}

export function getOccupancy(occupancy: Array<Occupancy>) {
  return (occupancy || []).map(({ adults, children }: Occupancy) => ({
    adults,
    children: children.map((dob: DOB) =>
      moment(`${dob.year}-${dob.month}-${dob.day}`, ISO_8601_DATE_FORMAT).format(ISO_8601_DATE_FORMAT)
    )
  }));
}

export function* handleOnReceiveFiltersSuccess() {
  const path: string = yield select(getHotelPath);
  const hotelOnly = yield select(getHotelOnlySearch);
  const filters: PriceCalendarFiltersState = yield select(getPriceCalendarFilters);
  const { duration, airport: from, board: boardBasis, occupancy } = filters;
  const { month, year } = filters.response.data.defaults;
  const today = moment();
  const isValid: boolean = !hasChildrenWithErrors(occupancy);

  if (isValid) {
    yield requestMonthly({
      path,
      duration,
      from: hotelOnly ? '' : from,
      boardBasis,
      occupancy: getOccupancy(occupancy),
      month: month || (today.month() + 1).toString(),
      year: year || today.year().toString()
    });
  }
}

export function* handleOnSetMonth({ payload }: any) {
  const path: string = yield select(getHotelPath);
  const filters: PriceCalendarFiltersState = yield select(getPriceCalendarFilters);
  const { duration, airport: from, board: boardBasis, occupancy } = filters;
  const date: moment.Moment = moment(payload);
  yield requestMonthly({
    path,
    duration,
    from,
    boardBasis,
    occupancy: getOccupancy(occupancy),
    month: date.format(MONTH_NUMBER),
    year: date.format(YEAR_NUMBER)
  });
}

export function* requestMonthly(payload: FromPricePayload) {
  yield putResolve(setLoading(true));
  try {
    const monthly: MonthlyResponse = yield new PriceCalendarApi().monthly(payload);
    if (monthly.data.prices.length) {
      yield putResolve(receiveMonthlyPricesSuccess(monthly.data));
    } else {
      yield putResolve(receiveMonthlyEmptyPrices(monthly.data));
    }
  } catch (e) {
    yield putResolve(receiveMonthlyPricesFailure());
  }
  yield putResolve(setLoading(false));
}

export function getDefaultDate(offers: Array<Offer> = []): string {
  if (!offers.length) return '';
  const cheapest: Offer | undefined = offers.find((offer: Offer) => offer.cheapest);
  const cheapestDate: string = cheapest ? cheapest.date : offers[0].date;
  return moment(cheapestDate).format(ISO_8601_DATE_FORMAT);
}

export const priceCalendarMonthlyReducer: any = (
  state: PriceCalendarMonthlyState = INITIAL_PRICE_CALENDAR_MONTHLY_STATE,
  action: any
) => {
  switch (action.type) {
    case PriceCalendarMonthlyActions.RECEIVE_MONTHLY_PRICES_SUCCESS: {
      const monthly: Monthly = action.payload;
      const {
        query: { month: queryMonth, year }
      } = monthly;
      const defaultDate = getDefaultDate(monthly.prices);
      const month = moment()
        .year(year)
        .month(queryMonth - 1)
        .format(YEAR_MONTH_FORMAT);
      return {
        ...state,
        response: {
          data: monthly,
          loading: false,
          error: null
        },
        defaultDate,
        month
      };
    }
    case PriceCalendarMonthlyActions.RECEIVE_MONTHLY_PRICES_FAILURE: {
      return {
        ...state,
        response: {
          data: INITIAL_PRICE_CALENDAR_MONTHLY_STATE.response.data,
          loading: false,
          error: null
        }
      };
    }
    case PriceCalendarMonthlyActions.RECEIVE_MONTHLY_EMPTY_PRICES: {
      return {
        ...state,
        response: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case DealFinderResultsActions.CLEAR_RESULTS: {
      return INITIAL_PRICE_CALENDAR_MONTHLY_STATE;
    }
    case DealFinderResultsActions.SET_FILTERS: {
      return INITIAL_PRICE_CALENDAR_MONTHLY_STATE;
    }
    case PriceCalendarMonthlyActions.CLEAR_MONTHLY:
      return INITIAL_PRICE_CALENDAR_MONTHLY_STATE;
    case PriceCalendarMonthlyActions.SET_DAY:
      return {
        ...state,
        date: action.payload
      };
    case PriceCalendarMonthlyActions.SET_MONTH:
      return {
        ...state,
        month: action.payload
      };
    case PriceCalendarMonthlyActions.SET_LOADING:
      return {
        ...state,
        response: {
          ...state.response,
          loading: action.payload
        }
      };
    case PriceCalendarFilterActions.FETCH_FILTERS: {
      if (!action.payload) {
        return INITIAL_PRICE_CALENDAR_MONTHLY_STATE;
      }
      const { path, urlParams } = action.payload;
      const { from, boardBasis, duration, occupancy, year, month, day } = urlParams;
      const dayOfMonth = state.date ? moment(state.date).date() : day;

      let responseDate: string = '';
      let responseMonth: string = '';

      if (month && year && dayOfMonth) {
        const daysInMonth = moment()
          .year(year)
          .month(month - 1)
          .daysInMonth();
        const momentDate = moment()
          .year(year)
          .month(month - 1)
          .date(Math.min(dayOfMonth, daysInMonth));
        responseDate = momentDate.format(ISO_8601_DATE_FORMAT);
        responseMonth = momentDate.format(YEAR_MONTH_FORMAT);
      }

      return {
        ...INITIAL_PRICE_CALENDAR_MONTHLY_STATE,
        response: {
          data: {
            prices: [],
            query: {
              duration,
              month,
              year,
              path,
              boardBasis,
              from,
              occupancy
            }
          },
          loading: true,
          error: null
        },
        month: responseMonth,
        date: state.date || responseDate
      };
    }
    case PriceCalendarFilterActions.RECEIVE_FILTERS_FAILURE:
      return {
        ...state,
        response: { ...INITIAL_PRICE_CALENDAR_MONTHLY_STATE.response }
      };
    default:
      return state;
  }
};
