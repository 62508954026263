import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';
import axiosRetry from 'axios-retry';
import axios from 'axios';

export enum HotelSortBy {
  POPULARITY = 'popularity'
}

interface ProductApiRequests {
  getDepAirports: (path: string) => Promise<GetDepAirportsResponse>;
  getHotelPaths: () => Promise<GetHotelPathsResponse>;
}

export interface GetHotelPathsResponse {
  data: Array<string>;
}

export interface GetDepAirportsResponse {
  data: Array<string>;
}

export class ProductApi implements ProductApiRequests {
  private request: Request = new Request();

  constructor() {
    axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
  }

  public async getDepAirports(path: string): Promise<GetDepAirportsResponse> {
    return await this.request.get(uri(getURL()).path(EndPoint.DEPARTURE_AIRPORTS).search({ path }).href());
  }

  public async getHotelPaths(): Promise<GetHotelPathsResponse> {
    return await this.request.get(uri(getURL()).path(EndPoint.HOTEL_PATHS).href());
  }
}
