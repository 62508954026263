import { withTheme } from '@emotion/react';
import React, { FC } from 'react';
import { Theme } from '@theme/base';
import { TestId } from '@components/test-ids';
import { noop } from 'lodash';

/** @jsxRuntime classic /
 /* @jsx jsx */
// @ts-ignore
import { jsx } from '@emotion/react';

export interface IconProps {
  name: string;
  theme: Theme;
  disabled?: boolean;
  onClick?: any;
  testId?: string;
  css?: any;
  className?: string;
  tabIndex?: number;
  onKeyPress?: (event: any) => void;
  onMouseOver?: (event: any) => void;
  onMouseOut?: (event: any) => void;
}

export const IconComponent: FC<IconProps> = ({
  disabled,
  theme,
  testId,
  onClick,
  tabIndex,
  onKeyPress,
  onMouseOver,
  onMouseOut,
  name,
  className,
  css
}: IconProps) => {
  const opacity: number = disabled ? 0.5 : 1;
  return (
    <i
      data-testid={testId || TestId.icon}
      onClick={disabled ? noop : onClick}
      className={`${name} ${className || ''}`}
      tabIndex={tabIndex}
      onKeyPress={onKeyPress}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={noop}
      onBlur={noop}
      aria-hidden
      css={{
        fontSize: '1rem',
        color: theme.custom.colors.group4.base,
        transition: `color ${theme.custom.transitions.default}`,
        opacity,
        ['&hover']: {
          color: theme.custom.colors.group2.dark
        },
        ...css
      }}
    />
  );
};

export const Icon = withTheme(React.memo(IconComponent));
