export enum ERROR_CODES {
  PRODUCT_NO_HOTEL = 'FE_1001',
  PRODUCT_HAS_BOOKING_REF = 'FE_1002',
  FLIGHTS_NO_HOTEL = 'FE_2001',
  FLIGHTS_HAS_BOOKING_REF = 'FE_2002',
  FLIGHTS_HOTEL_ONLY = 'FE_2003',
  FLIGHTS_NOT_HOTEL_ONLY_NO_FLIGHT = 'FE_2004',
  REVIEW_NO_HOTEL = 'FE_3001',
  REVIEW_HOTEL_ONLY_HAS_FLIGHT = 'FE_3002',
  REVIEW_NOT_HOTEL_ONLY_NO_FLIGHT = 'FE_3003',
  REVIEW_HAS_BOOKING_REF = 'FE_3004',
  GUESTS_NO_HOTEL = 'FE_4001',
  GUESTS_HOTEL_ONLY_HAS_FLIGHT = 'FE_4002',
  GUESTS_NOT_HOTEL_ONLY_NO_FLIGHT = 'FE_4003',
  GUESTS_HAS_BOOKING_REF = 'FE_4004',
  PAYMENT_NO_HOTEL = 'FE_5001',
  PAYMENT_HOTEL_ONLY_HAS_FLIGHT = 'FE_5002',
  PAYMENT_NOT_HOTEL_ONLY_NO_FLIGHT = 'FE_5003',
  PAYMENT_NO_GUESTS = 'FE_5004',
  PAYMENT_HAS_BOOKING_REF = 'FE_5005',
  CONFIRMATION_NO_HOTEL = 'FE_6001',
  CONFIRMATION_HOTEL_ONLY_HAS_FLIGHT = 'FE_6002',
  CONFIRMATION_NOT_HOTEL_ONLY_NO_FLIGHT = 'FE_6003',
  CONFIRMATION_NO_GUESTS = 'FE_6004',
  PAGE_NOT_MATCHED = 'FE_7001',
  NO_CMS_CONFIG = 'FE_7003',
  NO_LAYOUT = 'FE_7004',
  NO_SEARCH_TOKEN = 'FE_7002',
  NO_TOURS = 'FE_7010',
  NO_CMS_DESTINATION = 'FE_7011',
  NO_ARTICLE = 'FE_7012',
  NO_PRODUCT_PATH = 'FE_7013',
  NO_HOTEL = 'FE_7014',
  NO_TRAVEL_GUIDE = 'FE_7015',
  NO_VIDEO_GUIDE = 'FE_7016',
  NO_API_DESTINATION = 'FE_7017',
  TOUR_TYPE_MISMATCH = 'FE_8000',
  PACKAGE_TIMEOUT = 'BMSE001',
  TOKEN_EXPIRED = '410'
}

export interface ErrorProps {
  code?: string;
}

export interface ErrorPageProps extends ErrorProps {
  statusCode?: string;
  isServer?: boolean;
}

export const isTimeoutError = (code?: string) => {
  return code === ERROR_CODES.PACKAGE_TIMEOUT;
};
