import { all } from 'redux-saga/effects';
import {
  onSetAirportsQuery,
  onFetchDestinations,
  onSetGeographyQuery,
  onSetHelpArticlesQuery,
  onFetchToursByType,
  onSetToursQueryByType
} from '@state/algolia/algoliaOperations';

export function* combinedAlgoliaSagas() {
  yield all([
    onSetGeographyQuery(),
    onSetAirportsQuery(),
    onFetchDestinations(),
    onSetHelpArticlesQuery(),
    onFetchToursByType(),
    onSetToursQueryByType()
  ]);
}
