import { HotelContentResponse, HotelTile, HotelTileResponse } from '@model/iceberg/hotel-content/hotel-content';
import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { Pagination } from '@model/iceberg';
import { Logging } from '@util/logging';

interface HotelContentRequests {
  get: (path: string) => Promise<HotelContentResponse>;
  tiles: (paths: Array<string>) => Promise<HotelTileResponse>;
  allTiles: (paths: Array<string>) => Promise<Array<HotelTile>>;
}

export enum SortBy {
  POPULARITY_HIGH_TO_LOW = 'PopularityHighToLow'
}

export class HotelContentApi implements HotelContentRequests {
  private api: Request = new Request();
  public async get(path: string): Promise<HotelContentResponse> {
    return await this.api.get(uri(getURL()).path(EndPoint.HOTEL_CONTENT).search({ path }).href()).catch((e) => {
      const { config, response } = e;
      Logging.warn({
        statusCode: response?.status,
        url: config?.url,
        text: response?.statusText,
        data: path
      });
      return null;
    });
  }

  public async tiles(paths: Array<string>, perPage?: number): Promise<HotelTileResponse> {
    return await this.api
      .get(
        uri(getURL())
          .path(EndPoint.HOTEL_TILE)
          .search({ paths: paths.join(','), perPage, page: 1, sort: SortBy.POPULARITY_HIGH_TO_LOW })
          .href()
      )
      .catch((e) => {
        const { config, response } = e;
        Logging.warn({
          statusCode: response?.status,
          url: config?.url,
          text: response?.statusText,
          data: paths.join(',')
        });
        return null;
      });
  }

  public async allTiles(paths: Array<string>): Promise<Array<HotelTile>> {
    return await this.paginate(paths, 1).catch((e) => {
      const { config, errorText, errorCode } = e;
      Logging.warn({
        statusCode: errorCode,
        url: config?.url,
        text: errorText,
        data: paths
      });
      return [];
    });
  }

  private async paginate(paths: Array<string>, page: number): Promise<Array<HotelTile>> {
    const response: HotelTileResponse = await this.api.get(
      uri(getURL())
        .path(EndPoint.HOTEL_TILE)
        .search({ paths: paths.join(','), perPage: 24, page, sort: SortBy.POPULARITY_HIGH_TO_LOW })
        .href()
    );
    const pagination: Pagination = response.pagination;
    const hotels: Array<HotelTile> = response.data;
    if (pagination.to < pagination.total) {
      const update: Array<HotelTile> = await this.paginate(paths, page + 1);
      return hotels.concat(update);
    } else {
      return hotels;
    }
  }
}
