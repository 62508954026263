import moment, { Moment } from 'moment';

import { ISO_8601_DATE_FORMAT } from '@model/common/dates';
import { DateRangeMoment } from '@model/common';

export const dateRangeMomentToString = (dateRangeMoment: DateRangeMoment) => {
  const dates = {
    startDate: '',
    endDate: ''
  };
  if (dateRangeMoment.startDate) {
    dates.startDate = dateRangeMoment.startDate.format(ISO_8601_DATE_FORMAT);
  }
  if (dateRangeMoment.endDate) {
    dates.endDate = dateRangeMoment.endDate.format(ISO_8601_DATE_FORMAT);
  }
  return dates;
};

export const MINIMUM_BOOKING_DAYS = 3;

export const MAXIMUM_BOOKING_YEARS = 2;

export const getDefaultDay = (dateString?: string) => {
  const current = moment(dateString);
  const returnVal = moment(dateString);
  // new calendar month should be displayed when we are 4 days from the last day in the current month
  return moment(dateString).endOf('month').diff(current, 'days') <= MINIMUM_BOOKING_DAYS
    ? returnVal.add(1, 'M').startOf('month')
    : returnVal.add(MINIMUM_BOOKING_DAYS, 'days');
};

export const monthIsDisabled = (monthString: string, currentString?: string) => {
  const month = moment(monthString);
  const current = moment(currentString);
  const maxMonth: Moment = moment(currentString).add(MAXIMUM_BOOKING_YEARS, 'years');
  const atEndOfCurrentMonth =
    month.isSame(current, 'month') && current.date() + MINIMUM_BOOKING_DAYS >= month.daysInMonth();

  return month.isBefore(current, 'month') || month.isAfter(maxMonth) || atEndOfCurrentMonth;
};

export const dateIsDisabled = (date: Moment | string, currentString?: string) => {
  const day = moment(date);
  const isDayBefore3DaysFromToday: boolean = day.isBefore(
    moment(currentString).add(MINIMUM_BOOKING_DAYS + 1, 'days'),
    'day'
  );
  const isDay2YearsAfterToday: boolean = day.isAfter(moment(currentString).add(MAXIMUM_BOOKING_YEARS, 'years'));
  return isDayBefore3DaysFromToday || isDay2YearsAfterToday;
};
