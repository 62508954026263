import { createSelector } from 'reselect';
import { GlobalAppState } from '@model/state';
import { TripTypes } from '@model/common/tours/trip-types';
import { getToursStateNameByType } from '@util/tours';
import { TourType } from '@model/search';
import { getFromTourReference } from '@state/tours/tour-references';

export const getSearchParams = (state: GlobalAppState) => state.search.tours.searchParams;
export const getToursByType = (tripType: TripTypes) => (state: GlobalAppState) => {
  const stateName = getToursStateNameByType(tripType);
  return state.search.tours[stateName];
};
export const getIsTourSearchTypeByType = (tripType: TripTypes) =>
  createSelector(
    getToursByType(tripType),
    getFromTourReference,
    (tours, fromTourReference) => tours.length === 1 && (tours[0].type === TourType.TOUR || !!fromTourReference)
  );
export const getTourLandingSearch = (state: GlobalAppState) => state.search.tours.tourLandingSearch;
