import uri from 'urijs';
import { BasketResponse } from '@model/iceberg';
import { EndPoint, getURL, Request, PollOptions } from '@model/service';
import { BookingEndpoints } from '@model/service/booking-endpoints';
import { DealFinderEndpoints } from '@model/iceberg/service/deal-finder/deal-finder-endpoints';
import { EarlyFlightResponse } from '@model/flights';

export type GetFlightOptions = Partial<PollOptions>;

export class FlightApi {
  private api: Request = new Request();

  public async get(payload: string, options?: GetFlightOptions): Promise<any> {
    const query: string = uri(getURL())
      .path(EndPoint.DEAL_FINDER.concat('/').concat(payload).concat(DealFinderEndpoints.FLIGHTS))
      .href();
    return await this.api.poll(query, {
      maxAttempts: 50,
      interval: 500,
      ...options
    });
  }

  public async put(token: string, flightToken: string, preBook: boolean): Promise<BasketResponse> {
    return await this.api.put(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.FLIGHT}/${flightToken}`)
        .href(),
      {
        preBook
      }
    );
  }

  public async delete(token: string, flightToken: string): Promise<BasketResponse> {
    return await this.api.delete(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.FLIGHT}/${flightToken}`)
        .href()
    );
  }

  public async fetchEarlyFlights(searchToken: string): Promise<EarlyFlightResponse> {
    const query: string = uri(getURL())
      .path(
        EndPoint.DEAL_FINDER.concat('/')
          .concat(searchToken)
          .concat(DealFinderEndpoints.EARLY_FLIGHTS)
          .concat(DealFinderEndpoints.CHECK)
      )
      .href();
    return await this.api.get(query);
  }

  public async applyEarlyFlights(searchToken: string, roomTokens: Array<string>): Promise<EarlyFlightResponse> {
    return await this.api.put(
      uri(getURL())
        .path(
          EndPoint.DEAL_FINDER.concat('/')
            .concat(searchToken)
            .concat(DealFinderEndpoints.EARLY_FLIGHTS)
            .concat(DealFinderEndpoints.APPLY)
        )
        .href(),
      {
        roomTokens
      }
    );
  }
}
