import NextImage, { ImageProps } from 'next/image';
import { imageFlexLoader, isImageFlexUrl } from '@util/images';

export const Image = ({ src, ...props }: ImageProps) => {
  const loader = isImageFlexUrl(src) ? imageFlexLoader : undefined;

  if (!src) {
    return null;
  }
  return <NextImage {...props} src={src} loader={loader} />;
};
