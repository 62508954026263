import { FilterOption, FilterType } from '@model/iceberg/service';
import { FilterBuilder } from './filter-builder';

export interface GetFilterParams {
  name: string;
  values: Array<FilterOption>;
}

export abstract class FilterQueryFactory {
  static getFactory(key: FilterType) {
    switch (key) {
      case FilterType.SORT:
        return new SortFilterFactory();
      case FilterType.RADIO:
        return new RadioFilterFactory();
      case FilterType.CHECKBOX:
      case FilterType.STARS:
        return new CheckboxFilterFactory();
      case FilterType.TOGGLE:
        return new ToggleFilterFactory();
      case FilterType.PRICE:
        return new PriceFilterFactory();
      default:
        return new RadioFilterFactory();
    }
  }
  abstract getQuery(params: GetFilterParams): Record<string, string>;
}

export class RadioFilterFactory extends FilterQueryFactory {
  getQuery({ values, name }: GetFilterParams) {
    const query: string = values.filter((f: FilterOption) => f.selected).shift()?.value || '';
    return new FilterBuilder()
      .withName(name)
      .withQuery(query ? [query] : [])
      .build();
  }
}

export class CheckboxFilterFactory extends FilterQueryFactory {
  getQuery({ values, name }: GetFilterParams) {
    const query: Array<string> = values.filter((f: FilterOption) => f.selected).map((f: FilterOption) => f.value);
    return new FilterBuilder().withName(name).withQuery(query).build();
  }
}

export class ToggleFilterFactory extends FilterQueryFactory {
  getQuery({ values, name }: GetFilterParams) {
    const query: Array<string> = values.filter((f: FilterOption) => f.selected).map((f: FilterOption) => f.value);
    return new FilterBuilder().withName(name).withQuery(query).build();
  }
}

export class PriceFilterFactory extends FilterQueryFactory {
  getQuery({ values, name }: GetFilterParams) {
    const minSelected: number = values[0].selected as any;
    const maxSelected: number = values[1].selected as any;
    const minValue: number = values[0].value;
    const maxValue: number = values[1].value;
    if (!minSelected && !maxSelected) return {};
    const min: number = minSelected || minValue;
    const max: number = maxSelected || maxValue;
    return new FilterBuilder().withName(name).withQuery([min.toString(), max.toString()]).build();
  }
}

export class SortFilterFactory extends FilterQueryFactory {
  getQuery({ values, name }: GetFilterParams) {
    const query: Array<string> = values.filter((f: FilterOption) => f.selected).map((f: FilterOption) => f.value);
    return new FilterBuilder().withName(name).withQuery(query).build();
  }
}
