import { createSelector } from 'reselect';
import { GlobalAppState, AlgoliaAirportCacheTypes } from '@model/state';
import { GeographyResult } from '@model/search';
import { getToursStateNameByType, getAirportsStateNameByType, isTourTripType } from '@util/tours';
import { TripTypes } from '@model/common/tours/trip-types';

export const getAlgoliaState = (state: GlobalAppState) => state.algolia;
export const getAlgoliaGeography = (state: GlobalAppState) => state.algolia.geography.data;

const getPath = (_, path) => path;
const getParentDestinations = createSelector(
  getAlgoliaGeography,
  getPath,
  (geography: Array<GeographyResult>, path: string) =>
    geography.filter((destination: GeographyResult) => path.startsWith(destination.name.path))
);
export const getAlgoliaParentDestinations = (path: string) => (state: GlobalAppState) =>
  getParentDestinations(state, path);

const getMatchDestinations = createSelector(
  getAlgoliaGeography,
  getPath,
  (geography: Array<GeographyResult>, path: string) =>
    geography.filter((destination: GeographyResult) => destination.name.path.startsWith(path))
);
export const getAlgoliaMatchingDestinations = (path: string) => (state: GlobalAppState) =>
  getMatchDestinations(state, path);

export const getAlgoliaStateAirports = (tripType?: TripTypes) => (state: GlobalAppState) =>
  state.algolia.airports.data[
    isTourTripType(tripType) ? AlgoliaAirportCacheTypes.TOURS : AlgoliaAirportCacheTypes.HOLIDAYS
  ];
export const getAlgoliaCacheDestinations = (state: GlobalAppState) => state.algolia.cache.destinations;
export const getAlgoliaCacheAirportsByType = (tripType: TripTypes) => (state: GlobalAppState) => {
  return state.algolia.cache.airports && state.algolia.cache.airports[getAirportsStateNameByType(tripType)];
};
export const getAlgoliaCacheToursByType = (tripType: TripTypes) => (state: GlobalAppState) => {
  const stateName = getToursStateNameByType(tripType);
  return state.algolia.cache[stateName];
};

export const getAlgoliaToursByType = (tripType: TripTypes) => (state: GlobalAppState) => {
  const stateName = getToursStateNameByType(tripType);
  return state.algolia[stateName];
};

export const getAlgoliaTourAirportsByType = (tripType: TripTypes) => (state: GlobalAppState) => {
  const stateName = getToursStateNameByType(tripType);
  return state.algolia[stateName].airports;
};
