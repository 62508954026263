import { DateRange } from '@model/common';
import { PreferredContact } from '@model/forms/preferred-contact';
import { GeneratedByValue } from '@model/iceberg/service/contact-us';

/* *** MODELS *** */
export interface BookingEnquiryFormValues {
  name: string;
  email: string;
  telephone: string;
  bookingReference: string;
  dateOfTravel: DateRange;
  enquiry: string;
  preferredContact: PreferredContact;
}

export interface BookingEnquiryFormPayload extends GeneratedByValue {
  name: string;
  email: string;
  telephone: string;
  bookingReference: string;
  departureDate: string;
  returnDate: string;
  enquiry: string;
  preferredContact: PreferredContact;
  reCaptcha: string;
}

/* *** INITIAL VALUES *** */
export const bookingEnquiryInitialValues: BookingEnquiryFormValues = {
  name: '',
  email: '',
  telephone: '',
  bookingReference: '',
  dateOfTravel: {
    startDate: '',
    endDate: ''
  },
  enquiry: '',
  preferredContact: PreferredContact.EMAIL
};
