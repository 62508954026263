export const colorGroups = {
  group1: {
    lighter: '#effeff',
    light: '#c6eef0',
    base: '#31b7bc',
    dark: '#218084',
    darker: '#218084'
  },
  group2: {
    lighter: '#fbfbfb',
    light: '#bce4f1',
    base: '#005672',
    dark: '#003c4f',
    darker: '#1b3744'
  },
  group3: {
    lighter: '#ccede9',
    light: '#67c9be',
    base: '#02a694',
    dark: '#016358',
    darker: '#00423b'
  },
  group4: {
    lighter: '#c4c4c4',
    light: '#595959',
    base: '#404040',
    dark: '#262626',
    darker: '#191919'
  },
  group5: {
    lighter: '#d1f1dc',
    light: '#76d598',
    base: '#1cb954',
    dark: '#106f32',
    darker: '#0b4a21'
  },
  group6: {
    // unused
    lighter: '#f9cce5',
    light: '#ef66b2',
    base: '#e5017f',
    dark: '#89004c',
    darker: '#5b0032'
  },
  group7: {
    lighter: '#cdf3fc',
    light: '#6adbf8',
    base: '#07c4f4',
    dark: '#047592',
    darker: '#024e61'
  },
  group8: {
    lighter: '#fbf0cc',
    light: '#f5d367',
    base: '#efb702',
    dark: '#8f6d01',
    darker: '#5f4900'
  },
  group9: {
    lighter: '#fce7cc',
    light: '#f7b868',
    base: '#f38904',
    dark: '#915202',
    darker: '#613601'
  },
  group10: {
    lighter: '#f2f2f2',
    light: '#e5e5e5',
    base: '#bfbfbf',
    dark: '#7f7f7f',
    darker: '#191919'
  },
  group11: {
    lighter: '#fff3f4',
    light: '#ff767b',
    base: '#ff0000',
    dark: '#991015',
    darker: '#330507'
  },
  group12: {
    lighter: '#ddcddb',
    light: '#9a6992',
    base: '#57054a',
    dark: '#34032c',
    darker: '#23021e'
  }
};
