import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { MyBookingToken, MyBookingDetails, BookingStatus, MyBookingErrorDetails } from '@model/mmb';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export const getBookingToken: (state: GlobalAppState) => MyBookingToken = (state: GlobalAppState) => state.mmb.token;
export const getBookingDetails: (state: GlobalAppState) => MyBookingDetails = (state: GlobalAppState) =>
  state.mmb.bookingDetails;
export const getIsMyBookingError: (state: GlobalAppState) => boolean = (state: GlobalAppState) => state.mmb.error;
export const getIsMyBookingPending: (state: GlobalAppState) => boolean = (state: GlobalAppState) => state.mmb.pending;
export const getBookingErrorDetails: (state: GlobalAppState) => MyBookingErrorDetails | undefined = (
  state: GlobalAppState
) => state.mmb.bookingError;
export const getIsMyBookingLocked: (state: GlobalAppState) => boolean = (state: GlobalAppState) => state.mmb.locked;
export const getIsMyBookingAgent: (state: GlobalAppState) => boolean = (state: GlobalAppState) => state.mmb.agent;
export const getIsBookingCancelled: (state: GlobalAppState) => boolean = (state: GlobalAppState) =>
  state.mmb.bookingDetails.status === BookingStatus.CANCELLED;
export const getIsBookingClosed: (state: GlobalAppState) => boolean = (state: GlobalAppState) =>
  state.mmb.bookingDetails.status === BookingStatus.CLOSED;
export const getIsBookingEnquiry: (state: GlobalAppState) => boolean = (state: GlobalAppState) =>
  state.mmb.bookingDetails.status === BookingStatus.ENQUIRY;
export const getBookingId: (state: GlobalAppState) => string = (state: GlobalAppState) => state.mmb.bookingId;
export const getHasAgentData = createSelector(getBookingDetails, (bookingDetails: MyBookingDetails) => {
  return !!new Cookies().get(Cookie.TRADE_AUTHORIZATION) && !!bookingDetails.bookingID;
});

export const getCanRenderMyBooking = createSelector(
  getBookingToken,
  getBookingDetails,
  getIsMyBookingError,
  getHasAgentData,
  (token: MyBookingToken, bookingDetails: MyBookingDetails, error: boolean, hasAgentData) =>
    (!!token?.loginToken && !!bookingDetails.bookingID && !error) || hasAgentData
);
