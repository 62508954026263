import { all } from 'redux-saga/effects';
import {
  onNavigateBack,
  onAddFlightToBasket,
  onNavigate,
  onAddLuggageToBasket,
  onAddTransfersToBasket,
  onAddRoomsToBasket,
  onAddSpecialRequestsToBasket,
  onAddSupplementsToBasket,
  onPreBookFlightToBasket,
  onAddRoomsToBasketAndNavigate,
  onConfirmExtras
} from '@state/basket/basketOperations';

export default function* combinedBasketSagas() {
  yield all([
    onNavigate(),
    onNavigateBack(),
    onAddRoomsToBasket(),
    onAddFlightToBasket(),
    onAddTransfersToBasket(),
    onAddLuggageToBasket(),
    onAddSpecialRequestsToBasket(),
    onAddSupplementsToBasket(),
    onPreBookFlightToBasket(),
    onAddRoomsToBasketAndNavigate(),
    onConfirmExtras()
  ]);
}
