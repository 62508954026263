import { Page } from '@model/common/pages';

export class DestinationHrefBuilder {
  private CONTINENT = '[continent]';
  private COUNTRY = '[country]';
  private REGION = '[region]';
  private RESORT = '[resort]';
  private HOTEL = '[hotel]';
  public get(level?: number): string {
    switch (level) {
      case 1:
        return `${Page.DESTINATION}/${this.CONTINENT}`;
      case 2:
        return `${Page.DESTINATION}/${this.CONTINENT}/${this.COUNTRY}`;
      case 3:
        return `${Page.DESTINATION}/${this.CONTINENT}/${this.COUNTRY}/${this.REGION}`;
      case 4:
        return `${Page.DESTINATION}/${this.CONTINENT}/${this.COUNTRY}/${this.REGION}/${this.RESORT}`;
      case 5:
        return `${Page.DESTINATION}/${this.CONTINENT}/${this.COUNTRY}/${this.REGION}/${this.RESORT}/hotels/${this.HOTEL}`;
      default:
        return '';
    }
  }
}
