import moment from 'moment';

export interface Token {
  searchToken: string;
  expires: string;
  expired: boolean;
  loading: boolean;
}

export class BookingToken {
  public static isExpired({ expires }: Token) {
    if (!expires) return true;
    const date: moment.Moment = moment(expires);
    return date.diff(moment()) < 0;
  }
  public static isEmpty({ searchToken, expires }: Token) {
    return !searchToken || !expires;
  }

  public static isValid(token: Token) {
    return !BookingToken.isEmpty(token) && !BookingToken.isExpired(token);
  }
}

export class TokenExpires {
  private readonly expires: string;
  public constructor(expires: string = '') {
    this.expires = expires;
  }

  isExpired(): boolean {
    const date: moment.Moment = moment(this.expires);
    return date.diff(moment()) < 0;
  }

  isEmpty(): boolean {
    return !this.expires;
  }

  isValid(): boolean {
    return !this.isEmpty() && !this.isExpired();
  }
}
