import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { Skeleton } from '@mui/material';

/* ***************** *
 *       Types       *
 * ***************** */
export interface SkeletonTextProps {
  theme?: Theme;
  testId?: string;
  variant?: SkeletonVariant;
  size?: SkeletonSize;
  width?: number | string;
  height?: number | string;
  repeat?: number;
  className?: string;
  inline?: boolean;
  right?: boolean;
}

export enum SkeletonVariant {
  TEXT = 'TEXT',
  RECTANGLE = 'RECTANGLE',
  CIRCLE = 'CIRCLE',
  HEADING = 'HEADING'
}

export enum SkeletonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export interface SkeletonDimensions {
  width?: number | string;
  height?: number | string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  inline: boolean;
  width: number | string;
  right?: boolean;
}
const Container: any = styled.span(({ theme, inline, width, right }: ContainerProps) => ({
  width: width || '100%',
  marginLeft: right ? 'auto' : 0,
  display: inline ? 'flex' : 'block',
  ...(inline && {
    ['.MuiSkeleton-root']: {
      marginRight: theme.custom.spacing.xSmall,

      ['&:last-of-type']: {
        marginRight: 0
      }
    }
  })
}));

export const SkeletonInlineWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',

  ['& > span']: {
    marginRight: theme.custom.spacing.xSmall
  }
}));

const SkeletonStyled: any = styled(Skeleton)({});

/* *************************** *
 *    SkeletonTextComponent    *
 * *************************** */

export const SkeletonElementComponent = ({
  testId,
  variant = SkeletonVariant.TEXT,
  size = SkeletonSize.MEDIUM,
  width,
  height,
  theme,
  repeat = 1,
  className,
  inline,
  right
}: SkeletonTextProps) => {
  /* *** LOGIC *** */
  const getVariant: () => 'rect' | 'circle' | 'text' = () => {
    switch (variant) {
      case SkeletonVariant.RECTANGLE:
        return 'rect';
      case SkeletonVariant.CIRCLE:
        return 'circle';
      default:
        return 'text';
    }
  };

  const getDimensions: () => SkeletonDimensions = () => {
    switch (variant) {
      case SkeletonVariant.HEADING:
        return theme!.custom.skeleton.heading[size];
      case SkeletonVariant.RECTANGLE:
        return theme!.custom.skeleton.rectangle[size];
      case SkeletonVariant.CIRCLE:
        return theme!.custom.skeleton.circle[size];
      default:
        return theme!.custom.skeleton.text[size];
    }
  };

  return (
    <Container
      className={className}
      data-testid={testId || TestId.skeletonLoading.skeletonText}
      inline={inline}
      right={right}
      width={
        width
          ? typeof width === 'number'
            ? width * repeat + (inline ? repeat * theme!.custom.spacing.xSmall : 0)
            : width || getDimensions().width
          : getDimensions().width
      }
    >
      {[...Array(repeat)].map((_, index: number) => (
        <SkeletonStyled
          key={`skeletonElement-${index}`}
          variant={getVariant()}
          width={'100%'}
          height={height || getDimensions().height}
          animation={'wave'}
        />
      ))}
    </Container>
  );
};

export const SkeletonElement = withTheme(SkeletonElementComponent);
