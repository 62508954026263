import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { TestId } from '@components/test-ids';
import { Icon } from '../generic-icon/Icon';
import { Theme, ThemeProps, ExpandableThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import Markdown from 'react-markdown';
import { ReadMore } from '@styles/common';
import { LinkComponent } from './TextMarkdown';

/* ***************** *
 *       Types       *
 * ***************** */
export interface TextDestinationProps {
  theme?: Theme;
  testId?: string;
  description: string;
  initialNumOfSentences?: number;
  readMoreLabel?: string;
  isReadMoreVisible?: boolean;
  className?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div({
  ['p']: {
    margin: 0,
    padding: 0
  }
});

const Description = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: 0,

  ['pre ,code']: {
    whiteSpace: 'normal'
  }
}));

interface ReadMoreWrapperProps extends ExpandableThemeProps {
  expandedHeight: number;
}

const ReadMoreWrapper: any = styled.div(({ theme, isExpanded, expandedHeight }: ReadMoreWrapperProps) => ({
  ...(theme.custom.typography.paragraph as any),
  display: 'block',
  margin: 0,
  overflow: 'hidden',
  maxHeight: isExpanded ? expandedHeight : 0,
  transition: `max-height ${theme.custom.transitions.smooth}`
}));

const MoreCopyWrapper = styled.div({});

const ReadMoreLink = styled(ReadMore)({
  overflowY: 'hidden'
});

/* ****************************** *
 *    TextDescriptionComponent    *
 * ****************************** */

export const TextDescriptionComponent = (props: TextDestinationProps) => {
  const t: any = useI18NextContext();
  const { description, initialNumOfSentences = 3, readMoreLabel, isReadMoreVisible = true, className } = props;

  /* *** STATE *** */
  const [showReadMore, setShowReadMore] = useState<boolean>(false);
  const [descriptionSentences, setDescriptionSentences] = useState<Array<string>>(description.split('. '));
  const [moreCopyWrapperHeight, setMoreCopyWrapperHeight] = useState<number | null>(0);

  /* *** HOOKS *** */
  useEffect(() => {
    setDescriptionSentences(description.split('. '));
    setShowReadMore(false);
  }, [description]);

  /* *** LOGIC *** */
  const getInitialCopy = () => {
    const descriptionText = descriptionSentences.slice(0, initialNumOfSentences).join('. ');
    const shouldDisplaySuffix = descriptionSentences.length > initialNumOfSentences;
    const descriptionSuffix = showReadMore ? '.' : '...';

    return `${descriptionText}${shouldDisplaySuffix ? descriptionSuffix : ''}`;
  };

  const getReadMoreCopy = () =>
    `${descriptionSentences.slice(initialNumOfSentences, description.split('. ').length).join('. ')}`;

  const getReadMoreLabel = () => {
    if (readMoreLabel) {
      return typeof readMoreLabel === 'function' ? readMoreLabel : t(readMoreLabel);
    }

    return t(showReadMore ? 'common__description--read-less' : 'common__description--read-more');
  };

  /* *** RENDERES *** */
  return (
    <Container data-testid={props.testId || TestId.description.main} className={className}>
      <Description data-testid={TestId.productDescription.description}>
        <Markdown children={getInitialCopy()} components={LinkComponent} />
      </Description>
      {descriptionSentences.length > initialNumOfSentences && (
        <>
          <ReadMoreWrapper
            isExpanded={showReadMore}
            expandedHeight={moreCopyWrapperHeight}
            data-testid={TestId.description.readMoreWrapper}
          >
            <MoreCopyWrapper ref={(element) => !!element && setMoreCopyWrapperHeight(element.clientHeight)}>
              <Markdown children={getReadMoreCopy()} />
            </MoreCopyWrapper>
          </ReadMoreWrapper>
          {isReadMoreVisible && (
            <ReadMoreLink
              data-testid={TestId.description.readMoreToggle}
              onClick={() => setShowReadMore(!showReadMore)}
            >
              <span>{getReadMoreLabel()}</span>
              <Icon name={showReadMore ? 'mhi-chevron-up' : 'mhi-chevron-down'} />
            </ReadMoreLink>
          )}
        </>
      )}
    </Container>
  );
};

export const TextDescription = withTheme(TextDescriptionComponent);
