/*
 *  Constructs an object used got GTM analytics.
 */
import { DataLayerPayload, SalesForcePayload } from './data-layer-payload';
import { Guests, Rooms } from '../search';
import { Amount, Page, YEAR_MONTH_FORMAT } from '@model/common';
import { Flight, Luggage, Place, Pricing, Route, Transfer } from '@model/iceberg';
import uri from 'urijs';
import { isString } from 'lodash';
import { EnvConfig } from '@model/config/env-config';
import { Destination } from '@model/state';
import moment from 'moment';
import { Currency as CurrencyCode } from 'dinero.js';
import { getSymbolByCurrencyCode } from '@util/currency';
import { Uuid } from '@model/bootstrap/uuid-bootstrap';
import { TripTypes } from '@model/common/tours/trip-types';
import { sentryException } from '@util/sentry';

export enum PageType {
  PRODUCT = 'PRODUCT',
  CATEGORY = 'CATEGORY',
  BOOKING = 'BOOKING',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  ROOMS = 'ROOMS',
  FLIGHTS = 'FLIGHTS',
  EXTRAS = 'EXTRAS',
  GUESTS = 'GUESTS',
  PAYMENT = 'PAYMENT',
  CONFIRMATION = 'CONFIRMATION',
  TOUR_SEARCH = 'TOUR_SEARCH',
  TOUR_PRODUCT = 'TOUR_PRODUCT',
  TOUR_PRODUCT_BOOKING = 'TOUR_PRODUCT_BOOKING',
  TOUR_EXTRAS = 'TOUR_EXTRAS'
}

export enum SearchType {
  HOLIDAY = 'HOLIDAY',
  HOTEL_ONLY = 'HOTEL_ONLY',
  ESCORTED_TOUR = 'ESCORTED_TOUR',
  RIVER_CRUISE = 'RIVER_CRUISE'
}

export const tourSearchTypeMapping: { [key: string]: SearchType } = {
  [TripTypes.ESCORTED_TOURS]: SearchType.ESCORTED_TOUR,
  [TripTypes.RIVER_CRUISES]: SearchType.RIVER_CRUISE
};

const pageTypeMapping: { [key: string]: PageType } = {
  [Page.SEARCH]: PageType.SEARCH_RESULTS,
  [Page.PRODUCT]: PageType.ROOMS,
  [Page.FLIGHTS]: PageType.FLIGHTS,
  [Page.REVIEW]: PageType.EXTRAS,
  [Page.GUESTS]: PageType.GUESTS,
  [Page.PAYMENT]: PageType.PAYMENT,
  [Page.CONFIRMATION]: PageType.CONFIRMATION,
  [Page.TOURS_EXTRAS]: PageType.TOUR_EXTRAS
};

export class DataLayerPayloadBuilder {
  private readonly _dataLayerPayload: DataLayerPayload;

  constructor() {
    this._dataLayerPayload = {
      // Search Rooms
      searchAdultsCount: 0,
      searchChildrenCount: 0,
      searchRoomsCount: 0,

      // Search
      searchStartDate: '',
      searchMonth: '',
      searchDuration: 0,
      searchFlexible: false,
      searchDestinations: [],

      // Hotel
      sku: '',
      basketContinent: '',
      basketCountry: '',
      basketRegion: '',
      basketResort: '',
      basketHotel: '',

      // Pricing
      basketPerPersonPrice: '',
      basketTotalPrice: '',

      // Flight
      flightId: '',
      inboundAirlineName: '',
      inboundArrivalAirportId: '',
      inboundDepartureAirportId: '',
      outboundAirlineName: '',
      outboundArrivalAirportId: '',
      outboundDepartureAirportId: '',

      // Confirmation
      transactionId: '',
      transactionTotal: '',
      confirmationEhId: '',

      // Extras
      transferType: '',
      holdLuggageBagsCount: 0,

      // other
      currencyCode: '',
      pageType: '',
      searchType: '',
      preBookPriceChange: undefined,

      // Sales Force
      sfProductName: '',
      sfProductLink: '',
      sfImageLink: '',
      sfSalePrice: '',
      sfRegularPrice: '',
      sfDescription: '',
      sfStarRating: '',
      sfDestination: '',
      sfHolidayType: '',
      sfResort: '',
      sfRegion: '',
      sfKeywords: '',
      sfCategory: '',

      // Connect ID
      connectId: '',
      uuid: ''
    };
  }

  withSearchRooms(rooms: Rooms): DataLayerPayloadBuilder {
    this._dataLayerPayload.searchAdultsCount = rooms.reduce((curr: number, guest: Guests) => curr + guest.adults, 0);
    this._dataLayerPayload.searchChildrenCount = rooms.reduce(
      (curr: number, guest: Guests) => curr + guest.children.length,
      0
    );
    this._dataLayerPayload.searchRoomsCount = rooms.length;
    return this;
  }

  withSearchMonth(month: string): DataLayerPayloadBuilder {
    if (month) {
      this._dataLayerPayload.searchMonth = moment(month).format(YEAR_MONTH_FORMAT);
    }
    return this;
  }

  withSearchStartDate(date: string): DataLayerPayloadBuilder {
    this._dataLayerPayload.searchStartDate = date;
    return this;
  }

  withSearchFlexible(flexible: boolean): DataLayerPayloadBuilder {
    this._dataLayerPayload.searchFlexible = flexible;
    return this;
  }

  withSearchDuration(duration: number): DataLayerPayloadBuilder {
    this._dataLayerPayload.searchDuration = duration;
    return this;
  }

  withSearchDestinations(paths: Array<Destination | string>): DataLayerPayloadBuilder {
    this._dataLayerPayload.searchDestinations = paths.map((destination: Destination | string) =>
      isString(destination) ? destination : destination.path
    );
    return this;
  }

  withHotel(place: Place | undefined): DataLayerPayloadBuilder {
    this._dataLayerPayload.sku = place?.hotel?.path || '';
    this._dataLayerPayload.basketContinent = place?.continent?.path || '';
    this._dataLayerPayload.basketCountry = place?.country?.name?.path || '';
    this._dataLayerPayload.basketRegion = place?.region?.path || '';
    this._dataLayerPayload.basketResort = place?.resort?.path || '';
    this._dataLayerPayload.basketHotel = place?.hotel?.path || '';
    return this;
  }

  withPricing(pricing: Pricing): DataLayerPayloadBuilder {
    if (pricing?.total?.amount) {
      const total: Amount = new Amount(pricing.total.amount, pricing.total.currency.code);
      this._dataLayerPayload.basketTotalPrice = total.formatNoSign();
    }
    if (pricing?.perPerson?.amount) {
      const perPerson: Amount = new Amount(pricing.perPerson.amount, pricing.perPerson.currency.code);
      this._dataLayerPayload.basketPerPersonPrice = perPerson.formatNoSign();
    }
    this._dataLayerPayload.preBookPriceChange = pricing.preBookPriceChange;
    return this;
  }

  withFlight(flight: Flight | null): DataLayerPayloadBuilder {
    if (flight && flight.outbound.length && flight.return.length) {
      try {
        this._dataLayerPayload.inboundAirlineName = flight.return[flight.return.length - 1].airline;
        this._dataLayerPayload.inboundArrivalAirportId = flight.return[flight.return.length - 1].arrival.code;
        this._dataLayerPayload.inboundDepartureAirportId = flight.return[flight.return.length - 1].departure.code;

        this._dataLayerPayload.outboundAirlineName = flight.outbound[0].airline;
        this._dataLayerPayload.outboundArrivalAirportId = flight.outbound[flight.outbound.length - 1].arrival.code;
        this._dataLayerPayload.outboundDepartureAirportId = flight.outbound[0].departure.code;
        this._dataLayerPayload.flightId = [...flight.outbound, ...flight.return]
          .map((route: Route) => route.flightNumber)
          .join('-');
      } catch (e) {
        sentryException(`${e}: flight token: ${flight.token}`);
      }
    }
    return this;
  }

  withTransferType(transfer: Transfer | Array<Transfer> | null): DataLayerPayloadBuilder {
    if (transfer && !Array.isArray(transfer) && transfer.vehicle) {
      this._dataLayerPayload.transferType = transfer.vehicle;
    }
    return this;
  }

  withHoldLuggage(luggage: Array<Luggage> | null): DataLayerPayloadBuilder {
    if (luggage && luggage.length) {
      this._dataLayerPayload.holdLuggageBagsCount = luggage.reduce((count: number, curr) => count + curr.quantity, 0);
    }
    return this;
  }

  withTransactionTotal(pricing: Pricing): DataLayerPayloadBuilder {
    const total: Amount = new Amount(pricing.total.amount, pricing.total.currency.code);
    this._dataLayerPayload.transactionTotal = total.formatNoSign();
    return this;
  }

  withTransactionId(id: string): DataLayerPayloadBuilder {
    this._dataLayerPayload.transactionId = id;
    return this;
  }

  withCurrency(currencyCode: CurrencyCode): DataLayerPayloadBuilder {
    this._dataLayerPayload.currencyCode = getSymbolByCurrencyCode(currencyCode);
    return this;
  }

  withPageType(page?: string): DataLayerPayloadBuilder {
    const pageType: PageType | undefined = pageTypeMapping[page || ''];
    if (pageType) {
      this._dataLayerPayload.pageType = pageType;
    } else {
      this._dataLayerPayload.pageType = page;
    }
    return this;
  }

  withSearchType(searchType: SearchType): DataLayerPayloadBuilder {
    this._dataLayerPayload.searchType = searchType;
    return this;
  }

  withConnectId(connectId: string = ''): DataLayerPayloadBuilder {
    this._dataLayerPayload.connectId = connectId;
    return this;
  }

  withConfirmationEhId(confirmationEhId: string | null): DataLayerPayloadBuilder {
    if (confirmationEhId) {
      this._dataLayerPayload.confirmationEhId = confirmationEhId;
    }
    return this;
  }

  withSalesForce(payload: SalesForcePayload): DataLayerPayloadBuilder {
    const {
      sfDescription,
      sfDestination,
      sfHolidayType,
      sfImageLink,
      sfKeywords,
      sfProductName,
      sfRegion,
      sfRegularPrice,
      sfResort,
      sfSalePrice,
      sfStarRating,
      sfCategory
    } = payload;
    this._dataLayerPayload.sfDescription = sfDescription || '';
    this._dataLayerPayload.sfDestination = sfDestination || '';
    this._dataLayerPayload.sfHolidayType = sfHolidayType || '';
    this._dataLayerPayload.sfImageLink = sfImageLink || '';
    this._dataLayerPayload.sfKeywords = sfKeywords || '';
    this._dataLayerPayload.sfProductName = sfProductName || '';
    this._dataLayerPayload.sfRegion = sfRegion || '';
    this._dataLayerPayload.sfRegularPrice = sfRegularPrice || '';
    this._dataLayerPayload.sfResort = sfResort || '';
    this._dataLayerPayload.sfSalePrice = sfSalePrice || '';
    this._dataLayerPayload.sfStarRating = sfStarRating || '';
    this._dataLayerPayload.sfCategory = sfCategory || '';
    return this;
  }

  withProductLink(productLink: string): DataLayerPayloadBuilder {
    const withoutQueryParams: string = productLink.split('?').shift() || '';
    this._dataLayerPayload.sfProductLink = uri(EnvConfig.get().APP_URL).path(withoutQueryParams).href();
    return this;
  }

  withCookiesAccepted(): DataLayerPayloadBuilder {
    this._dataLayerPayload.userAccept = 1;
    return this;
  }

  withCookiesRejected(): DataLayerPayloadBuilder {
    this._dataLayerPayload.userAccept = 0;
    return this;
  }

  withUuid(): DataLayerPayloadBuilder {
    const uuid = new Uuid().getUuid();
    this._dataLayerPayload.uuid = uuid;
    return this;
  }

  withTradeId(tradeId: string): DataLayerPayloadBuilder {
    if (tradeId) {
      this._dataLayerPayload.tradeId = tradeId;
    }
    return this;
  }

  build(): DataLayerPayload {
    return this._dataLayerPayload;
  }
}
