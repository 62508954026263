export interface AppError {
  errorCode?: string;
  errorText?: string;
  response?: any;
}

export interface ApiError {
  code: string;
  message: string;
  httpStatusCode: number;
  identifier: string;
  stackTrace: any;
}

export interface ErrorResponse {
  error: ApiError;
}

export class CustomError extends Error implements AppError {
  errorCode: undefined;
  errorText: undefined;
  response: undefined;

  constructor(options) {
    super(options.errorText, options);
    this.errorCode = options.errorCode;
    this.errorText = options.errorText;
    this.response = options.response;
  }
}
