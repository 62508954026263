/* *** MODELS *** */
export interface MyBookingLoginFormValues {
  bookingId: string;
  lastName: string;
  departureDate: DepartureDate;
}

export interface DepartureDate {
  day: string;
  month: string;
  year: string;
}

export interface MyBookingLoginFormPayload {
  bookingId: string;
  lastName: string;
  departureDate: string;
  reCaptcha: string;
}

/* *** INITIAL VALUES *** */
export const myBookingLoginFormInitialValues: MyBookingLoginFormValues = {
  bookingId: '',
  lastName: '',
  departureDate: {
    day: '',
    month: '',
    year: ''
  }
};
