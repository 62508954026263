import { Occupancy } from '@model/state/deal-finder-state';

export interface PriceCalendarParams {
  searchType?: string;
  month?: number;
  year?: number;
  duration?: number;
  from?: string;
  boardBasis?: string;
  occupancy?: Occupancy[];
}

export const DURATIONS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 21, 22, 28, 29, 35, 36, 42, 43];

export const BOARD_BASES = [
  { code: 'AI', name: 'All Inclusive' },
  { code: 'BB', name: 'Bed and Breakfast' },
  { code: 'FB', name: 'Full Board' },
  { code: 'HB', name: 'Half Board' }
];

export interface FilterDefaultParams {
  airport: string;
  duration: number;
  boardBasis: string;
  occupancy: Array<Occupancy>;
}

export const FILTER_DEFAULTS: FilterDefaultParams = {
  airport: 'LGW',
  boardBasis: 'BB',
  duration: 7,
  occupancy: [{ adults: 2, children: [] }]
};

export const MAXIMUM_DESTINATIONS = 9;
