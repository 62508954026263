import { TourAvailability, TourAvailabilityHotelYear, TourAvailabilityYears } from '@model/tours/tour-product';
import moment from 'moment';
import { map, flatten, minBy, compact } from 'lodash';
import { YEAR_MONTH_FORMAT, MONTH_FORMAT_SHORT } from '@model/common';

export const getMinPrice = (availabilityMonths: Array<TourAvailability>) =>
  minBy(availabilityMonths, ({ price }) => price.amount);

/*
 * Iterate through tour prices to retrieve the cheapest tour availability
 */
export function getCheapestTour(tourAvailabilityYears: TourAvailabilityYears, months?: string): TourAvailability {
  if (months) {
    const cheapestPerMonth = months.split(',').map((month) => {
      const [searchYear] = month.split('-');
      const availabilityYear = tourAvailabilityYears[searchYear];
      const monthLabel = moment(month).format(MONTH_FORMAT_SHORT);
      const availabilityMonths = flatten(compact(map(map(availabilityYear, 'months'), monthLabel)));
      const lowestPrice = availabilityMonths.length > 0 && getMinPrice(availabilityMonths);
      if (lowestPrice) {
        return lowestPrice;
      }
    });
    const cheapestOverall = getMinPrice(compact(cheapestPerMonth));
    if (cheapestOverall) {
      return cheapestOverall;
    }
  }
  const years = Object.values(tourAvailabilityYears);
  let cheapest;
  for (let i = 0; i < years.length; i++) {
    const yearHotel: Array<TourAvailabilityHotelYear> = years[i];
    for (let j = 0; j < yearHotel.length; j++) {
      const year: TourAvailabilityHotelYear = yearHotel[j];
      const months: Array<Array<TourAvailability>> = Object.values(year.months || {});
      for (let k = 0; k < months.length; k++) {
        const month: Array<TourAvailability> = months[k];
        for (let l = 0; l < month.length; l++) {
          const availability = month[l];
          const monthString: string = Object.keys(year.months)[k];
          const yearString: string = Object.keys(tourAvailabilityYears)[i];
          const departureDate: string =
            availability.departureDate || moment(`${yearString}-${monthString}`, 'YYYY-mmm').format(YEAR_MONTH_FORMAT);
          if (
            !cheapest ||
            (!!availability.price && !!availability.price.amount && availability.price.amount < cheapest.price.amount)
          ) {
            cheapest = {
              ...availability,
              departureDate
            };
          }
        }
      }
    }
  }
  return cheapest;
}
