import { Theme } from '@theme/base';
import { mq } from '@styles/breakpoints';

export const globalStyles: (theme: Theme) => any = (theme: Theme) => ({
  '*, body': {
    fontDisplay: 'auto',
    fontFamily: theme.custom.typography.defaultFont,
    lineHeight: 1.5
  },
  ['#__next']: {
    position: 'relative'
  },
  ['html, body']: {
    fontSize: '10px !important',
    backgroundColor: 'white !important',
    color: theme.custom.colors.group4.base,
    height: '100%',
    width: '100%',
    margin: 0
  },
  '.react-tooltip-lite': {
    fontSize: '1.4rem',
    borderRadius: '1rem'
  },
  p: {
    ...theme.custom.typography.paragraph,
    margin: '10px 0'
  },
  h1: {
    ...theme.custom.typography.h1,
    marginTop: 0
  },
  h2: {
    ...theme.custom.typography.h2,
    marginBottom: theme.custom.spacing.large
  },
  h3: {
    ...theme.custom.typography.h3,
    marginBottom: theme.custom.spacing.medium
  },
  h4: {
    ...theme.custom.typography.h4,
    marginBottom: theme.custom.spacing.medium
  },
  h5: {
    ...theme.custom.typography.h5,
    marginBottom: theme.custom.spacing.medium
  },
  h6: {
    ...theme.custom.typography.h6,
    marginBottom: theme.custom.spacing.medium
  },
  hr: {
    marginBottom: theme.custom.spacing.large,
    marginTop: theme.custom.spacing.large
  },
  ['a, a:visited, a:active']: {
    textDecoration: 'underline',
    color: theme.custom.states.link.default,

    ['&:hover']: {
      color: theme.custom.states.link.hover
    }
  },
  body: {
    overflowX: 'hidden',
    ['&.no-scroll']: {
      overflow: 'hidden'
    },

    ['&.no-scroll-mobile']: {
      overflow: 'hidden',

      [mq.medium]: {
        overflow: 'auto'
      }
    }
  },
  '.grecaptcha-badge': {
    visibility: 'hidden'
  },
  '.lazy': {
    backgroundImage: 'url(/assets/images/fallback-images/image-loading.svg) !important',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.custom.colors.group10.light
  },
  '#wo_online_image': {
    [mq.small]: {
      bottom: theme.custom.spacing.large
    }
  },
  '.tmc-widget-section-blue-background, .tmc-widget-inner:before, .tmc-widget .btn': {
    background: theme.custom.colors.group2.base
  },
  'div.slick-dots ul li button:before': {
    color: theme.custom.colors.white,
    fontSize: theme.custom.fontSize.medium,
    opacity: 0.6,
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.6)'
  },
  'div.slick-dots ul li.slick-active button:before': {
    color: theme.custom.colors.white,
    opacity: 1
  }
});
