import { Name } from '../name';
import { CountryName } from '../country-name';
import { Place } from '../place';
import { PlaceType } from '../place-type';

const weightByPlaceType: any = {
  [PlaceType.CONTINENT]: 0,
  [PlaceType.COUNTRY]: 1,
  [PlaceType.REGION]: 2,
  [PlaceType.RESORT]: 3,
  [PlaceType.HOTEL]: 4
};

const getNameFromPlace = (place: Place, weight: number) => {
  switch (weight) {
    case 0:
      return place.continent;
    case 1:
      return place.country;
    case 2:
      return place.region;
    case 3:
      return place.resort;
    case 4:
      return place.hotel;
  }
};

function resolve(place: Place, type: PlaceType, prefix: string, weight: number): any {
  const nameFromPlace: any = getNameFromPlace(place, weight);
  const isCountry = nameFromPlace && nameFromPlace.isoCode;
  const displayName = nameFromPlace ? (isCountry ? nameFromPlace.name.display : nameFromPlace.display) : '';
  if (weight === 0 && (!nameFromPlace || !displayName)) {
    return '';
  }
  if (!prefix) {
    if (!nameFromPlace || !displayName) {
      return resolve(place, weight, prefix, weight - 1);
    }
    return resolve(place, weight, displayName, weight - 1);
  }
  const nameFromPlaces: Name | CountryName = getNameFromPlace(place, weight)!;
  if (nameFromPlaces) {
    if (prefix === displayName) {
      return resolve(place, type, prefix, weight - 1);
    }
    return `${prefix}, ${displayName}`;
  }
  return prefix;
}

export const getPlaceLabel = (place: Place, type: PlaceType) => {
  const weight: number = weightByPlaceType[type] - 1;
  return resolve(place, type, '', weight);
};
