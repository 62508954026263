import { AirportMobilityOptions, GuestFormValues, RadioToggleOptions } from '@model/forms';
import { Guest } from '@model/iceberg/booking/basket/guest/guest';
import moment from 'moment';
import { ISO_8601_DATE_FORMAT } from '@model/common';

export class GuestMapper {
  public static getGuestFormValues(guests: Array<Guest> = []): Array<GuestFormValues> {
    return guests.map(
      ({
        type,
        email,
        firstNames,
        lastName,
        dob,
        title,
        token,
        lead,
        isVegan,
        isGlutenFree,
        isVegetarian,
        isDiabetic,
        dietaryRequirements,
        airportMobility,
        contactByEmail,
        telephone
      }: Guest) => {
        const dobMoment: moment.Moment = moment(dob);
        const isDietaryRequirementsChecked: boolean =
          isVegan || isGlutenFree || isVegetarian || isDiabetic || !!dietaryRequirements;
        const isMobilityRequirementsChecked: boolean = !!airportMobility;
        return {
          token: token || '',
          type,
          email,
          contactByEmail,
          telephone,
          firstNames: firstNames || '',
          lastName: lastName || '',
          title: title || '',
          lead: lead || false,
          isVegan: isVegan || false,
          isGlutenFree: isGlutenFree || false,
          isVegetarian: isVegetarian || false,
          isDiabetic: isDiabetic || false,
          dietaryRequirements: dietaryRequirements || '',
          airportMobility: airportMobility || AirportMobilityOptions.NONE,
          hasDietaryRequirements: isDietaryRequirementsChecked ? RadioToggleOptions.YES : RadioToggleOptions.NO,
          hasMobilityRequirements: isMobilityRequirementsChecked ? RadioToggleOptions.YES : RadioToggleOptions.NO,
          dob: {
            yearOfBirth: dob ? dobMoment.format('YYYY') : '',
            monthOfBirth: dob ? dobMoment.format('MM') : '',
            dayOfBirth: dob ? dobMoment.format('DD') : ''
          }
        };
      }
    );
  }

  public static getGuestsPayload(guests: Array<GuestFormValues> = []): Array<Guest> {
    return guests.map((guest: GuestFormValues, index: number) => {
      const {
        title,
        type,
        dob,
        email = '',
        firstNames,
        lastName,
        token,
        lead,
        isVegetarian,
        isVegan,
        isGlutenFree,
        isDiabetic,
        dietaryRequirements,
        airportMobility,
        telephone,
        contactByEmail
      } = guest;
      const dobFormatted: string = moment(`${dob.yearOfBirth}-${dob.monthOfBirth}-${dob.dayOfBirth}`).format(
        ISO_8601_DATE_FORMAT
      );
      return {
        token,
        type,
        email,
        lead: lead || index === 0,
        title,
        firstNames,
        lastName,
        dob: dobFormatted,
        isVegan,
        isGlutenFree,
        isDiabetic,
        isVegetarian,
        dietaryRequirements,
        airportMobility,
        contactByEmail,
        telephone
      };
    });
  }
}
