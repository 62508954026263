import { EnvConfig } from './env-config';
import { CmsPages, ComponentTypes } from '@model/contentful';
import { GLOBAL_DOMAIN, Environment } from '@model/config/environment';
import { BRAND_SUFFIXES } from '@model/iceberg/service/algolia/AlgoliaApi';

export enum BrandCodes {
  MERCURY_HOLIDAYS = 'mercury_holidays',
  MERCURY_HOLIDAYS_IRELAND = 'mercury_holidays_ireland',
  SUNSPOT_TOURS = 'sunspottours'
}

export class AppVariant {
  public static isWhitelabel(): boolean {
    const appVariantId = EnvConfig.get().APP_VARIANT_ID;
    return !!appVariantId && appVariantId !== BrandCodes.MERCURY_HOLIDAYS;
  }

  public static isMercuryIreland(): boolean {
    return EnvConfig.get().APP_VARIANT_ID === BrandCodes.MERCURY_HOLIDAYS_IRELAND;
  }

  public static isSunspot(): boolean {
    return EnvConfig.get().APP_VARIANT_ID === BrandCodes.SUNSPOT_TOURS;
  }

  public static isNonMercuryBrand(): boolean {
    const appVariant = EnvConfig.get().APP_VARIANT_ID;
    return appVariant === BrandCodes.SUNSPOT_TOURS;
  }
}

export const brandPagesBlacklist: any = {
  [BrandCodes.SUNSPOT_TOURS]: [CmsPages.ORDER_BROCHURE, CmsPages.ONLINE_BROCHURES],
  [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: [CmsPages.ORDER_BROCHURE, CmsPages.ONLINE_BROCHURES]
};

export const isPageBlacklisted: (pageId: string) => boolean = (pageId: string) =>
  brandPagesBlacklist[EnvConfig.get().APP_VARIANT_ID]?.includes(pageId);

export const brandPageVariants: any = {
  [BrandCodes.SUNSPOT_TOURS]: [
    CmsPages.HOME_PAGE,
    CmsPages.CONTACT_US,
    CmsPages.PRIVACY_POLICY,
    CmsPages.TERMS_AND_CONDITIONS
  ],
  [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: [
    CmsPages.HOME_PAGE,
    CmsPages.PRIVACY_POLICY,
    CmsPages.CAR_PROTECTION,
    CmsPages.INTERNATIONAL_PASSENGER_PROTECTION
  ]
};

export const getPageVariant = (pageId: string) => {
  const appVariantId = EnvConfig.get().APP_VARIANT_ID;
  return brandPageVariants[appVariantId]?.includes(pageId) ? `${pageId}-${appVariantId}` : pageId;
};

export const GlobalDomains = {
  [BrandCodes.MERCURY_HOLIDAYS]: GLOBAL_DOMAIN,
  [BrandCodes.MERCURY_HOLIDAYS_IRELAND]: '.mercuryholidays.ie',
  [BrandCodes.SUNSPOT_TOURS]: '.sunspottours.com'
};

export const getHostForBrand = (algoliaBrandSuffix?: string) => {
  const brandCode =
    algoliaBrandSuffix && GlobalDomains[algoliaBrandSuffix]
      ? algoliaBrandSuffix
      : Object.keys(BRAND_SUFFIXES).find((key) => BRAND_SUFFIXES[key] === algoliaBrandSuffix) ||
        BrandCodes.MERCURY_HOLIDAYS;
  const AppEnv = EnvConfig.get().APP_ENV;
  return `https://${AppEnv === Environment.PRODUCTION ? 'www' : 'dev'}${GlobalDomains[brandCode]}`;
};

export const MercuryOnlyComponents = [
  ComponentTypes.productTileGroupComponent,
  ComponentTypes.toursProductTileGroupComponent
];

export const filterComponentsForBrand = (components: Array<any>) => {
  if (!AppVariant.isWhitelabel()) {
    return components;
  }

  const CallToBookComponent = {
    sys: {
      contentType: {
        sys: {
          id: 'callToBook'
        }
      }
    },
    fields: {}
  };

  let callToBookExists = false;
  const filteredComponents = components.map((component) => {
    if (MercuryOnlyComponents.includes(component.sys.contentType.sys.id)) {
      const newComponent = callToBookExists ? null : CallToBookComponent;
      callToBookExists = true;
      return newComponent;
    }
    return component;
  });
  return filteredComponents;
};
