import { EndPoint, getURL, Request } from '@model/service';
import { BasketResponse } from '@model/iceberg';
import uri from 'urijs';
import { SpecialRequest } from '@model/iceberg/booking/basket/special-request';
import { BookingEndpoints } from '@model/service/booking-endpoints';

export interface SpecialRequestsPayload {
  token: string;
  specialRequest: SpecialRequest;
}

export class SpecialRequestsApi {
  private api: Request = new Request();
  public async put(token: string, rooms: Array<SpecialRequestsPayload> = []): Promise<BasketResponse> {
    return await this.api.put(
      uri(getURL())
        .path(`${EndPoint.BOOKING}/${token}${BookingEndpoints.BASKET}${BookingEndpoints.SPECIAL_REQUESTS}`)
        .href(),
      { rooms }
    );
  }
}
