import { onFetchBasket } from '@state/basket';
import { onActions } from '@state/logging/loggingOperations';
import { onNavigate, onRedirect } from '@state/app/routing';
import { all } from 'redux-saga/effects';
import { onFiltersChange, onResetFlightFilters } from '@state/filters';
import { onFetchGuests, onSetGuests } from '@state/guests/guestOperations';
import combinedBasketSagas from '@state/basket/combinedBasketSagas';
import { combinedModalSagas } from '@state/modal/combinedModalSagas';
import { onFetchExtras } from '@state/extras';
import { onFetchExtrasOptions, onSetExtrasOptions } from '@state/extras-options';
import { onPromoCodeSubmit } from '@state/promo-code/promoCodeOperations';
import { onSetPath } from '@state/search';
import { combinedPriceCalendarSagas } from '@state/price-calendar/combinedPriceCalendarSagas';
import { onRequestBookingDetails, onRequestBookingLogout, onRequestBookingToken } from '@state/mmb';
import {
  onRequestFromPricePackagesSearch,
  onSetFromPriceFilters,
  handleOnLoadMore
} from '@state/search-results/from-price-packages/fromPricePackagesOperations';
import { combinedAgentSagas } from '@state/agent/combinedAgentSagas';
import { combinedDealFinderSagas } from '@state/deal-finder/combineDealFinderSagas';
import { combinedAlgoliaSagas } from '@state/algolia/combinedAlgoliaSagas';
import { combinedDealFinderResultsSagas } from '@state/deal-finder-results/combineDealFinderResultsSagas';
import { combinedDataLayerMiddlewareSagas } from '../../middleware/combinedDataLayerMiddlewareSagas';
import { combinedDealFinderLastSearchedSagas } from '@state/deal-finder-last-searched/combineDealFinderLastSearchedSagas';
import { combinedSearchResultsSagas } from '@state/search-results/combineSearchResultsSagas';
import { combinedSocialMediaPostSagas } from '@state/agent/social-post/combinedSocialMediaPostSagas';
import { onFetchPromoBanner } from '@state/promo-banner/promoBannerOperations';
import combinedTourReferencesSagas from '@state/tours/tour-references/combinedTourReferencesSagas';
import { combinedTourSagas } from '@state/tours/combinedTourSagas';
import { onFetchDestinationFaq } from '@state/destination-faq';
import { onFetchBookingDocuments } from '@state/mmb/documents';

export default function* rootSaga() {
  yield all([
    onFiltersChange(),
    onRequestFromPricePackagesSearch(),
    onSetFromPriceFilters(),
    handleOnLoadMore(),
    onFetchBasket(),
    onNavigate(),
    onRedirect(),
    onActions(),
    onFetchExtras(),
    onFetchExtrasOptions(),
    onSetExtrasOptions(),
    combinedBasketSagas(),
    onFetchGuests(),
    onSetGuests(),
    combinedModalSagas(),
    onPromoCodeSubmit(),
    onSetPath(),
    onResetFlightFilters(),
    combinedPriceCalendarSagas(),
    onRequestBookingToken(),
    onRequestBookingDetails(),
    onRequestBookingLogout(),
    combinedAgentSagas(),
    combinedDataLayerMiddlewareSagas(),
    combinedDealFinderSagas(),
    combinedAlgoliaSagas(),
    combinedDealFinderResultsSagas(),
    combinedDealFinderLastSearchedSagas(),
    combinedSocialMediaPostSagas(),
    onFetchPromoBanner(),
    combinedSearchResultsSagas(),
    combinedTourReferencesSagas(),
    combinedTourSagas(),
    combinedSocialMediaPostSagas(),
    onFetchDestinationFaq(),
    onFetchBookingDocuments()
  ]);
}
