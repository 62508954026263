import { Keyable } from '@model/common';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AsyncData } from '@model/common';
import { DestinationFaqApi } from '@model/iceberg/service/geography/destination-faq-api';
import { DataEnvelope } from '@model/iceberg';
import { BaseAction } from '@model/redux';

export enum DestinationFaqActions {
  PERFORM_FETCH_FAQ = '@DESTINATION/PERFORM_FETCH_FAQ',
  RECEIVE_FAQ_SUCCESS = '@DESTINATION/RECEIVE_FAQ_SUCCESS',
  RECEIVE_FAQ_FAILURE = '@DESTINATION/RECEIVE_FAQ_FAILURE'
}

export interface DestinationFaqItem {
  id: number;
  title: string;
  description: string;
  destination_name: string;
}

export type DestinationFaqResponse = DataEnvelope<DestinationFaqItem>;

export interface ErrorDetails {
  status: number | string;
  data?: Keyable;
}

export interface DestinationFaqRequestPayload {
  path: string;
}

export const INITIAL_DESTINATION_FAQ_STATE: AsyncData<Array<DestinationFaqItem>> = {
  data: [] as any,
  loading: false,
  error: null
};

export interface RequestFAQ extends BaseAction {
  type: DestinationFaqActions.PERFORM_FETCH_FAQ;
  payload: DestinationFaqRequestPayload;
}

export const performFetchDestinationFaq: (payload: DestinationFaqRequestPayload) => RequestFAQ = (
  payload: DestinationFaqRequestPayload
) => {
  return {
    type: DestinationFaqActions.PERFORM_FETCH_FAQ,
    payload
  };
};

export interface RequestFetchFaqSuccess extends BaseAction {
  type: DestinationFaqActions.RECEIVE_FAQ_SUCCESS;
  payload: DestinationFaqItem;
}

export const receiveFetchFaqSuccess: (payload: DestinationFaqItem) => RequestFetchFaqSuccess = (
  payload: DestinationFaqItem
) => {
  return {
    type: DestinationFaqActions.RECEIVE_FAQ_SUCCESS,
    payload
  };
};

interface FaqError {
  type: DestinationFaqActions.RECEIVE_FAQ_FAILURE;
  payload?: ErrorDetails;
}

export const receiveFaqFailure: (payload?: ErrorDetails) => FaqError = (payload?: ErrorDetails) => {
  return {
    type: DestinationFaqActions.RECEIVE_FAQ_FAILURE,
    payload
  };
};

export function* onFetchDestinationFaq() {
  yield takeLatest(DestinationFaqActions.PERFORM_FETCH_FAQ, handleOnFetchDestinationFaq);
}

export function* handleOnFetchDestinationFaq({ payload }: any) {
  const { response, error } = yield call(fetchDestinationFaq, payload);

  if (response) {
    yield put(receiveFetchFaqSuccess(response.data));
  }

  if (error) {
    const {
      response: { status, data }
    } = error;

    yield put(receiveFaqFailure({ status, data }));
  }
}

export function fetchDestinationFaq(payload: DestinationFaqRequestPayload) {
  const api: DestinationFaqApi = new DestinationFaqApi();
  return api
    .getDestinationFaq(payload)
    .then((response: DestinationFaqResponse) => ({ response }))
    .catch((error: any) => ({ error }));
}

export const destinationFaqReducer: any = (
  state: any = INITIAL_DESTINATION_FAQ_STATE,
  { type, error, payload }: any
) => {
  switch (type) {
    case DestinationFaqActions.RECEIVE_FAQ_SUCCESS:
      return { ...state, data: payload, error: null, loading: false };
    case DestinationFaqActions.RECEIVE_FAQ_FAILURE:
      return { ...state, data: INITIAL_DESTINATION_FAQ_STATE.data, error, loading: false };
    default:
      return state;
  }
};
